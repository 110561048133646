
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image, Table, TableHeader, TableCell, TableBody } from '@react-pdf/renderer';
import Button from '@mui/material/Button';
import ImageContext  from "../ImageContext/ImageContext"
import React, { useContext } from 'react';


function ReportComponent({ InputDetails,HarmonicOrderinput,HarmonicOrderinput1,HarmonicOrderinput2,results,isPaidUser }) {

  const { editorImageUrl, setEditorImageUrl,editorImageUrl1, setEditorImageUrl1,editorImageUrl2, setEditorImageUrl2, } = useContext(ImageContext);

  const styles = StyleSheet.create({
    heading: {
      fontFamily: 'Times-Roman',
      fontSize: 18,
      fontWeight: 16,
      marginBottom: 1,
    },
    normalText: {
      fontFamily: 'Times-Roman',
      fontSize: 12,
      marginBottom: 4,
    },
    image: {
      width: 'auto', // Adjust as needed
      height: '70%', // Adjust for aspect ratio
      marginVertical: 0,
      alignItems: "center"},

    row: {
      flexDirection: 'row',
      alignItems: 'stretch',
    },
    column: {
      flex: 1,
      padding: 5,
    },
    inlineContainer: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    fullPageUnderline: {
      borderBottomWidth: 1,
      borderBottomColor: '#000',
      marginBottom: 1,
    },
    headerContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomColor: '#000',
      paddingBottom: 10,
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      width: '100%',
    },
    headerLeftText: {
      fontFamily: 'Times-Roman',
      fontSize: 18,
      textAlign: 'left',
    },
    headerRightText: {
      fontFamily: 'Times-Roman',
      fontSize: 18,
      textAlign: 'right',
    },
    footerContainer: {
      position: 'absolute',
      bottom: 1,
      borderTopWidth: 1,
      borderTopColor: '#000',
      paddingTop: 4,
      width: '100%',
    },
    footerText: {
      fontSize: 10,
      marginHorizontal: 12,
    },
    table: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#000',
      marginBottom: 10,
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableCellfirst: {
      flex: 1,
      padding: 5,
      border: 1,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
      alignItems: "center"
    },
    tableCellsecond: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
      alignItems: "center"
    },
    tableCellthird: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
      alignItems: "center"
    },
    tableCellforth: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
      alignItems: "center"
    },
    tableCellfifth: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
    },
    tableCellsixth: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
    },
    tableCellseventh: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
    },
    tableCelleight: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
    },
    lastTableCell: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
      alignItems: "center"
    },
    centeredText: {
      flex: 1,
      marginTop: 120,
      justifyContent: 'center',
      alignItems: 'center',
    },
    redText: {
      color: 'red',
      fontSize: 14,
    },
    watermark: {
      position: 'absolute',
      fontSize: 50,
      color: 'orange',
      opacity: 0.9,
      top: 170,
      left: 10,
      fontWeight: 'bold',
      transform: 'rotate(-45deg)',
    },
    watermark2: {
      position: 'absolute',
      fontSize: 50,
      color: 'orange',
      opacity: 0.9,
      top: 500,
      left: 10,
      fontWeight: 'bold',
      transform: 'rotate(-45deg)',
    },
  });



  const Header = () => (
    <View style={styles.headerContainer}>
      <Text style={styles.headerLeftText}>Harmonic Calculator Report</Text>
      <Text style={styles.headerRightText}>WIRING MASTER</Text>
    </View>
  );

  const Footer = () => (
    <View style={styles.footerContainer}>
      <Text style={styles.footerText}>
        Disclaimer: This information does not constitute legal, professional or commercial advice. Wiring Master gives no guarantees, undertakings or warranties in
        this regard, and does not accept any legal liability or responsibility for the content or the accuracy of the information so provided, or, for any loss or
        damage caused arising directly or indirectly in connection with reliance on the use of such information.
      </Text>
    </View>
  );

  const MyDocument = () => {
   

    return (
      <Document>

<Page > 
           <Header/>
           {!isPaidUser ? (
          <>
            <Text style={styles.watermark}>FREE PREVIEW - NOT FOR COMMERCIAL USE</Text>
            <Text style={styles.watermark2}>PLEASE PURCHASE A SUBSCRIPTION TO REMOVE WATERMARK</Text>
          </>
        ) : null}
            <View style={{ padding: 20 }}>
                    <View>
                        <View >
                        <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>Input Details</Text>
                                </View>

                                <View style={styles.row}>
                                        <View style={styles.column}>
                                        {InputDetails.phase === "Single-phase" && (
                                          <View>
                                            <Text style={styles.normalText}>Phase:</Text>
                                            <Text style={styles.normalText}>Load Current:</Text>
                                            <Text style={styles.normalText}>Load Power Factor:</Text>
                                          </View>
                                        )}
                                        {InputDetails.phase === "Three-phase" && InputDetails.isBalanced &&  (
                                          <View>
                                          <Text style={styles.normalText}>Phase:</Text>
                                          <Text style={styles.normalText}>Load Current Per Phase:</Text>
                                          <Text style={styles.normalText}>Load Power Factor:</Text>
                                        </View>
                                        )}
  
                                        {InputDetails.phase === "Three-phase" && InputDetails.isUnbalanced &&  (
                                          <View>
                                          <Text style={styles.normalText}>Phase:</Text>
                                          <Text style={styles.normalText}>Load Current Phase L1:</Text>
                                          <Text style={styles.normalText}>Load Power Factor L1:</Text>
                                          <Text style={styles.normalText}>Load Current Phase L2:</Text>
                                          <Text style={styles.normalText}>Load Power Factor L2:</Text>
                                          <Text style={styles.normalText}>Load Current Phase L3:</Text>
                                          <Text style={styles.normalText}>Load Power Factor L3:</Text>
                                        </View>
                                        )}
                                            
                                        </View>
                                        <View style={styles.column}>
                                        {InputDetails.phase === "Single-phase" && (
                                          <View>
                                            <Text style={styles.normalText}>{InputDetails?.phase}</Text>
                                            <Text style={styles.normalText}>{InputDetails?.phaseValues[1]?.loadCurrent} A</Text>
                                            <Text style={styles.normalText}>{InputDetails?.phaseValues[1]?.loadPowerFactor}</Text>
                                          </View>
                                        )}
                                        {InputDetails.phase === "Three-phase" && InputDetails.isBalanced &&  (
                                          <View>
                                            <Text style={styles.normalText}>{InputDetails?.phase} (Balanced)</Text>
                                            <Text style={styles.normalText}>{InputDetails?.phaseValues[1]?.loadCurrent} A</Text>
                                            <Text style={styles.normalText}>{InputDetails?.phaseValues[1]?.loadPowerFactor}</Text>
                                        </View>
                                        )}
  
                                        {InputDetails.phase === "Three-phase" && InputDetails.isUnbalanced &&  (
                                          <View>
                                          <Text style={styles.normalText}>{InputDetails?.phase} (Unbalanced)</Text>
                                          <Text style={styles.normalText}>{InputDetails?.phaseValues[1]?.loadCurrent} A</Text>
                                            <Text style={styles.normalText}>{InputDetails?.phaseValues[1]?.loadPowerFactor}</Text>
                                            <Text style={styles.normalText}>{InputDetails?.phaseValues[2]?.loadCurrent} A</Text>
                                            <Text style={styles.normalText}>{InputDetails?.phaseValues[2]?.loadPowerFactor}</Text>
                                            <Text style={styles.normalText}>{InputDetails?.phaseValues[3]?.loadCurrent} A</Text>
                                            <Text style={styles.normalText}>{InputDetails?.phaseValues[3]?.loadPowerFactor}</Text>
                                        </View>
                                        )}
                                            
                                        </View>
                                </View>
                                <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>Harmonic Calculator Results</Text>
                                </View>
                                <View style={styles.row}>
                                        <View style={styles.column}>
                                        {InputDetails.phase === "Single-phase" && (
                                          <View>
                                            <Text style={styles.normalText}>Total RMS Current Active: </Text>
                                            <Text style={styles.normalText}>Total RMS Current Neutral: </Text>
                                          </View>
                                        )}
                                        {InputDetails.phase === "Three-phase" && InputDetails.isBalanced &&  (
                                          <View>
                                            <Text style={styles.normalText}>Total RMS Current Active Per Phase: </Text>
                                            <Text style={styles.normalText}>Total RMS Current Neutral: </Text>
                                          </View>
                                        )}
  
                                        {InputDetails.phase === "Three-phase" && InputDetails.isUnbalanced &&  (
                                          <View>
                                            <Text style={styles.normalText}>Total RMS Current Active L1: </Text>
                                            <Text style={styles.normalText}>Total RMS Current Active L2: </Text>
                                            <Text style={styles.normalText}>Total RMS Current Active L3: </Text>
                                            <Text style={styles.normalText}>Total RMS Current Neutral: </Text>
                                          </View>
                                        )}
                                            
                                        </View>
                                        <View style={styles.column}>
                                        {InputDetails.phase === "Single-phase" && (
                                          <View>
                                            <Text style={styles.normalText}>{results?.total_current_active?.toFixed(3)} &lt; {results?.total_current_active_angle?.toFixed(3)}&nbsp;&deg; A</Text>
                                            <Text style={styles.normalText}>{results?.total_current_active?.toFixed(3)} &lt; {results?.total_current_active_angle?.toFixed(3)}&nbsp;&deg; A</Text>
                                          </View>
                                        )}
                                        {InputDetails.phase === "Three-phase" && InputDetails.isBalanced &&  (
                                          <View>
                                            <Text style={styles.normalText}>{results?.total_current_active?.toFixed(3)}  &lt; {results?.total_current_active_angle?.toFixed(3)}&nbsp;&deg; A</Text>
                                            <Text style={styles.normalText}>{results?.total_current_neutral?.toFixed(3)} &lt; {results?.total_current_neutral_angle?.toFixed(3)}&nbsp;&deg; A</Text>
                                          </View>
                                        )}
  
                                        {InputDetails.phase === "Three-phase" && InputDetails.isUnbalanced &&  (
                                          <View>
                                            <Text style={styles.normalText}>{results?.total_current_phaseA?.toFixed(3)} &lt; {results?.total_current_active_angle_phaseA?.toFixed(3)}&nbsp;&deg; A</Text>
                                            <Text style={styles.normalText}>{results?.total_current_phaseB?.toFixed(3)} &lt; {results?.total_current_active_angle_phaseB?.toFixed(3)}&nbsp;&deg; A</Text>
                                            <Text style={styles.normalText}>{results?.total_current_phaseC?.toFixed(3)} &lt; {results?.total_current_active_angle_phaseC?.toFixed(3)}&nbsp;&deg; A</Text>
                                            <View>
                                            {results && (
                                              <View>
                                                {/* Other result components */}
                                                {results.total_current_neutral.toFixed(2) !== '0.00' && (
                                                  <Text style={styles.normalText}>
                                                    {results.total_current_neutral.toFixed(2)} &lt; {results.total_current_neutral_angle.toFixed(3)}&nbsp;&deg; A
                                                  </Text>
                                                )}
                                                {results.total_current_neutral.toFixed(2) === '0.00' && (
                                                  <Text style={styles.normalText}>
                                                    {results.total_current_neutral.toFixed(2)} A
                                                  </Text>
                                                )}
                                              </View>
                                            )}
                                          </View>

                                          </View>
                                        )}
                                        </View>
                                        </View>
                                        <View style={styles.row}>
                                        <View style={styles.column}>
                                        {InputDetails.phase === "Single-phase" && (
                                          <View>
                                            <Text style={styles.normalText}>Power Factor Active: </Text>
                                            <Text style={styles.normalText}></Text>
                                          </View>
                                        )}
                                        {InputDetails.phase === "Three-phase" && InputDetails.isBalanced &&  (
                                          <View>
                                            <Text style={styles.normalText}>Power Factor Active: </Text>
                                            <Text style={styles.normalText}></Text>
                                          </View>
                                        )}
  
                                        {InputDetails.phase === "Three-phase" && InputDetails.isUnbalanced &&  (
                                          <View>
                                            <Text style={styles.normalText}>Power Factor Active L1: </Text>
                                            <Text style={styles.normalText}>Power Factor Active L2: </Text>
                                            <Text style={styles.normalText}>Power Factor Active L3: </Text>
                                            <Text style={styles.normalText}></Text>
                                          </View>
                                        )}
                                            
                                        </View>
                                        <View style={styles.column}>
                                        {InputDetails.phase === "Single-phase" && (
                                          <View>
                                            <Text style={styles.normalText}>{results?.total_powerfactor_active?.toFixed(3)} </Text>
                                            <Text style={styles.normalText}> </Text>
                                          </View>
                                        )}
                                        {InputDetails.phase === "Three-phase" && InputDetails.isBalanced &&  (
                                          <View>
                                            <Text style={styles.normalText}>{results?.total_powerfactor_active?.toFixed(3)}</Text>
                                            <Text style={styles.normalText}> </Text>
                                          </View>
                                        )}
  
                                        {InputDetails.phase === "Three-phase" && InputDetails.isUnbalanced &&  (
                                          <View>
                                            <Text style={styles.normalText}>{results?.total_powerfactor_active_phaseA?.toFixed(3)} </Text>
                                            <Text style={styles.normalText}>{results?.total_powerfactor_active_phaseB?.toFixed(3)} </Text>
                                            <Text style={styles.normalText}>{results?.total_powerfactor_active_phaseC?.toFixed(3)} </Text>
                                            <Text style={styles.normalText}></Text>
                                          </View>
                                        )}
                                            
                                        </View>
                                </View>


                                <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>THD (Total Harmonic Distortion) Results</Text>
                                </View>

                                <View style={styles.row}>
                                        <View style={styles.column}>
                                        {InputDetails.phase === "Single-phase" && (
                                          <View>
                                            <Text style={styles.normalText}>Total Harmonic Distortion:</Text>
                                          </View>
                                        )}
                                        {InputDetails.phase === "Three-phase" && InputDetails.isBalanced &&  (
                                          <View>
                                          <Text style={styles.normalText}>Total Harmonic Distortion Per Phase:</Text>
                                        </View>
                                        )}
  
                                        {InputDetails.phase === "Three-phase" && InputDetails.isUnbalanced &&  (
                                          <View>
                                          <Text style={styles.normalText}>Total Harmonic Distortion L1:</Text>
                                          <Text style={styles.normalText}>Total Harmonic Distortion L2:</Text>
                                          <Text style={styles.normalText}>Total Harmonic Distortion L3:</Text>
                                        </View>
                                        )}
                                            
                                        </View>
                                        <View style={styles.column}>
                                        {InputDetails.phase === "Single-phase" && (
                                          <View>
                                            <Text style={styles.normalText}>{results?.total_harmonic_distortion} %</Text>
                                          </View>
                                        )}
                                        {InputDetails.phase === "Three-phase" && InputDetails.isBalanced &&  (
                                          <View>
                                          <Text style={styles.normalText}>{results?.total_harmonic_distortion} %</Text>
                                        </View>
                                        )}
  
                                        {InputDetails.phase === "Three-phase" && InputDetails.isUnbalanced &&  (
                                          <View>
                                          <Text style={styles.normalText}>{results?.total_harmonic_distortion_phaseA} %</Text>
                                          <Text style={styles.normalText}>{results?.total_harmonic_distortion_phaseB} %</Text>
                                          <Text style={styles.normalText}>{results?.total_harmonic_distortion_phaseC} %</Text>
                                        </View>
                                        )}
                                            
                                        </View>

                                        </View>


                               
                        </View>
                        

                        </View>
                        <View style={styles.centeredText}>
            <Text style={styles.redText}>Refer the pages below for harmonic waveforms</Text>
          </View>
                        </View>
                        <Footer />
                        </Page>
                        <Page>
                        <Header/>
                        <View style={{ padding: 20 }}>
                        {InputDetails.phase === "Single-phase" && (
                                          <View style={styles.fullPageUnderline}>
                                          <Text style={styles.heading}>Harmonic Data</Text>
                                      </View>
                                        )}
                        {InputDetails.phase === "Three-phase" && InputDetails.isBalanced &&  (
                                          <View>
                                          <Text style={styles.normalText}>Harmonic Data Per Phase:</Text>
                                        </View>
                                        )}
                        {InputDetails.phase === "Three-phase" && InputDetails.isUnbalanced &&  (
                                          <View>
                                          <Text style={styles.normalText}>Harmonic Data Phase L1:</Text>
                                        </View>
                                        )}
                        <View>
                            <Image style={styles.image} src={editorImageUrl}/>
                        </View>
                        <View style={styles.row}>
                          <View style={styles.tableCellfirst} >
                            <Text style={styles.normalText}>Order</Text>
                          </View>
                          <View style={styles.tableCellsecond} >
                            <Text style={styles.normalText}> Frequency (Hz)</Text>
                          </View>
                          <View style={styles.tableCellthird}>
                            <Text style={styles.normalText}> Current (A)</Text>
                          </View>
                          <View style={styles.lastTableCell}>
                            <Text style={styles.normalText}> Percentage (%)</Text>
                          </View>
                        </View>
                        {HarmonicOrderinput.map((item, index) => (
                        <View style={styles.row}>
                          <View style={styles.tableCellfirst} >
                            <Text style={styles.normalText}>{item.order}</Text>
                          </View>
                          <View style={styles.tableCellsecond} >
                            <Text style={styles.normalText}>{item.frequency}</Text>
                          </View>
                          <View style={styles.tableCellthird}>
                            <Text style={styles.normalText}>{item.current}</Text>
                          </View>
                          <View style={styles.lastTableCell}>
                            <Text style={styles.normalText}>{item.percentage}</Text>
                          </View>
                        </View>
                        ))}
                        </View>
                        <Footer />
                        </Page>
                        {InputDetails.phase === "Three-phase" && InputDetails.isUnbalanced &&  (
                        <Page>
                        <Header/>
                        <View style={{ padding: 20 }}>
                        {InputDetails.phase === "Single-phase" && (
                                          <View style={styles.fullPageUnderline}>
                                          <Text style={styles.heading}>Harmonic Data</Text>
                                      </View>
                                        )}
                        {InputDetails.phase === "Three-phase" && InputDetails.isBalanced &&  (
                                          <View>
                                          <Text style={styles.normalText}>Harmonic Data Per Phase:</Text>
                                        </View>
                                        )}
                        {InputDetails.phase === "Three-phase" && InputDetails.isUnbalanced &&  (
                                          <View>
                                          <Text style={styles.normalText}>Harmonic Data Phase L2:</Text>
                                        </View>
                                        )}
                        <View>
                            <Image style={styles.image} src={editorImageUrl1}/>
                        </View>
                        <View style={styles.row}>
                          <View style={styles.tableCellfirst} >
                            <Text style={styles.normalText}>Order</Text>
                          </View>
                          <View style={styles.tableCellsecond} >
                            <Text style={styles.normalText}> Frequency (Hz)</Text>
                          </View>
                          <View style={styles.tableCellthird}>
                            <Text style={styles.normalText}> Current (A)</Text>
                          </View>
                          <View style={styles.lastTableCell}>
                            <Text style={styles.normalText}> Percentage (%)</Text>
                          </View>
                        </View>
                        {Array.isArray(HarmonicOrderinput1) && HarmonicOrderinput1.map((item, index) => (
                        <View style={styles.row}>
                          <View style={styles.tableCellfirst} >
                            <Text style={styles.normalText}>{item.order}</Text>
                          </View>
                          <View style={styles.tableCellsecond} >
                            <Text style={styles.normalText}>{item.frequency}</Text>
                          </View>
                          <View style={styles.tableCellthird}>
                            <Text style={styles.normalText}>{item.current}</Text>
                          </View>
                          <View style={styles.lastTableCell}>
                            <Text style={styles.normalText}>{item.percentage}</Text>
                          </View>
                        </View>
                        ))}
                        </View>
                        <Footer />
                        </Page>
                        )}
                         {InputDetails.phase === "Three-phase" && InputDetails.isUnbalanced &&  (
                        <Page>
                        <Header/>
                        <View style={{ padding: 20 }}>
                        {InputDetails.phase === "Single-phase" && (
                                          <View style={styles.fullPageUnderline}>
                                          <Text style={styles.heading}>Harmonic Data</Text>
                                      </View>
                                        )}
                        {InputDetails.phase === "Three-phase" && InputDetails.isBalanced &&  (
                                          <View>
                                          <Text style={styles.normalText}>Harmonic Data Per Phase:</Text>
                                        </View>
                                        )}
                        {InputDetails.phase === "Three-phase" && InputDetails.isUnbalanced &&  (
                                          <View>
                                          <Text style={styles.normalText}>Harmonic Data Phase L3:</Text>
                                        </View>
                                        )}
                        <View>
                            <Image style={styles.image} src={editorImageUrl2}/>
                        </View>
                        <View style={styles.row}>
                          <View style={styles.tableCellfirst} >
                            <Text style={styles.normalText}>Order</Text>
                          </View>
                          <View style={styles.tableCellsecond} >
                            <Text style={styles.normalText}> Frequency (Hz)</Text>
                          </View>
                          <View style={styles.tableCellthird}>
                            <Text style={styles.normalText}> Current (A)</Text>
                          </View>
                          <View style={styles.lastTableCell}>
                            <Text style={styles.normalText}> Percentage (%)</Text>
                          </View>
                        </View>
                        {Array.isArray(HarmonicOrderinput2) && HarmonicOrderinput2.map((item, index) => (
                        <View style={styles.row}>
                          <View style={styles.tableCellfirst} >
                            <Text style={styles.normalText}>{item.order}</Text>
                          </View>
                          <View style={styles.tableCellsecond} >
                            <Text style={styles.normalText}>{item.frequency}</Text>
                          </View>
                          <View style={styles.tableCellthird}>
                            <Text style={styles.normalText}>{item.current}</Text>
                          </View>
                          <View style={styles.lastTableCell}>
                            <Text style={styles.normalText}>{item.percentage}</Text>
                          </View>
                        </View>
                        ))}
                        </View>
                        <Footer />
                        </Page>
                        )}
                        
      </Document>
    );
  };

  return (
    <div>
        <PDFDownloadLink
            document={<MyDocument />}
            fileName="Harmonics Report.pdf"
        >
            {({ blob, url, loading, error }) =>
                loading ? (
                    <Button variant="contained" color="primary" disabled>
                        Loading document...
                    </Button>
                ) : (
                    <Button variant="contained" color="primary">
                        Download Report
                    </Button>
                )
            }
        </PDFDownloadLink>
    </div>
  );
}

export default ReportComponent;
