// ImageContext.js
import React from 'react';

const ImageContext = React.createContext({
  mapImageUrl: '',
  setMapImageUrl: () => {},
  financialImageUrl: '',
  setFinancialImageUrl: () => {},
});

export default ImageContext;