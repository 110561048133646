import React from 'react';
import './results.css';

function Results({ InputDetails, results }) {
    const { phase } = InputDetails;

    let phaseOutput;
    switch (phase) {
        case 'Single-phase':
            phaseOutput = (
                <>
                    <p><strong>Total RMS current active:</strong> {results?.total_current_active?.toFixed(3)} &lt; {results?.total_current_active_angle?.toFixed(3)}&nbsp;&deg; A </p>
                    <p><strong>Total RMS current neutral:</strong> {results?.total_current_active?.toFixed(3)} &lt; {results?.total_current_active_angle?.toFixed(3)}&nbsp;&deg; A </p>
                    

                    {/* Add more single-phase specific calculation results here */}
                </>
            );
            break;
        case 'Three-phase':
            if (InputDetails.isBalanced) {
                phaseOutput = (
                    <>
                        <p><strong>Total RMS current per phase:</strong> {results?.total_current_active?.toFixed(3)} &lt; {results?.total_current_active_angle?.toFixed(3)}&nbsp;&deg; A</p>
                        <p><strong>Total RMS current neutral:</strong> {results?.total_current_neutral?.toFixed(3)} &lt; {results?.total_current_neutral_angle?.toFixed(3)}&nbsp;&deg; A</p>

                        {/* Add more balanced three-phase specific calculation results here */}
                    </>
                );
            } else if (InputDetails.isUnbalanced) {
                phaseOutput = (
                    <>
                        <p><strong>Total RMS current Phase L1:</strong> {results?.total_current_phaseA?.toFixed(3)} &lt; {results?.total_current_active_angle_phaseA?.toFixed(3)}&nbsp;&deg; A</p>
                        <p><strong>Total RMS current Phase L2:</strong> {results?.total_current_phaseB?.toFixed(3)} &lt; {results?.total_current_active_angle_phaseB?.toFixed(3)}&nbsp;&deg; A</p>
                        <p><strong>Total RMS current Phase L3:</strong> {results?.total_current_phaseC?.toFixed(3)} &lt; {results?.total_current_active_angle_phaseC?.toFixed(3)}&nbsp;&deg; A</p>
                        {results?.total_current_neutral?.toFixed(2) !== '0.00' && (
                            <p><strong>Total RMS current Phase neutral:</strong> {results?.total_current_neutral?.toFixed(2)} &lt; {results?.total_current_neutral_angle?.toFixed(3)}&nbsp;&deg; A</p>
                        )}
                        {results?.total_current_neutral?.toFixed(2) == '0.00' && (
                            <p><strong>Total RMS current Phase neutral:</strong> {results?.total_current_neutral?.toFixed(2)} A</p>
                        )}
              
                    
                        {/* Add more unbalanced three-phase specific calculation results here */}
                    </>
                );
            }
            break;
        default:
            phaseOutput = null; // Handle other cases if needed
    }

    return (
        <div className="results-container">
            <h2>Results</h2>
            {/* Display calculation results */}
            <div className="calculation-results">
                {phaseOutput}
            </div>
        </div>
    );
}

export default Results;
