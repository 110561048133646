import React from 'react';
import './results.css';

function Results({ InputDetails, results }) {
    const { phase } = InputDetails;

    let phaseOutput;
    switch (phase) {
        case 'Single-phase':
            phaseOutput = (
                <>
                    <p><strong>Total RMS current:</strong> {results.total_current_active} &lt; {results.total_current_active_angle} </p>
                    <p><strong>Total power factor:</strong> {results.total_powerfactor_active}</p>
                    {/* Add more single-phase specific calculation results here */}
                </>
            );
            break;
        case 'Three-phase':
            if (InputDetails.isBalanced) {
                phaseOutput = (
                    <>
                        <p><strong>Total RMS current per phase:</strong> {results.total_current_active} &lt; {results.total_current_active_angle}</p>
                        <p><strong>Total power factor per phase:</strong> {results.total_powerfactor_active} </p>
                        {/* Add more balanced three-phase specific calculation results here */}
                    </>
                );
            } else if (InputDetails.isUnbalanced) {
                phaseOutput = (
                    <>
                        <p><strong>Total RMS current Phase L1:</strong> {results.total_current_phaseA} &lt; {results.total_current_active_angle_phaseA}</p>
                        <p><strong>Total power factor Phase L1:</strong> {results.total_powerfactor_active_phaseA}</p>
                        {/* Add more unbalanced three-phase specific calculation results here */}
                    </>
                );
            }
            break;
        default:
            phaseOutput = null; // Handle other cases if needed
    }

    return (
        <div className="results-container">
            <h2>Results</h2>
            {/* Display calculation results */}
            <div className="calculation-results">
            <p><strong>Total RMS current Phase L2:</strong> {results?.total_current_phaseB?.toFixed(3)} &lt; {results?.total_current_active_angle_phaseB?.toFixed(3)} A</p>
            <p><strong>Total power factor Phase L2:</strong> {results?.total_powerfactor_active_phaseB?.toFixed(3)}</p>
            <p><strong>Total harmonic distortion:</strong> {results?.total_harmonic_distortion_phaseB?.toFixed(3)} %</p>
            </div>
        </div>
    );
}

export default Results;
