import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import Styles from "./login.module.scss"; // Import your custom CSS file
// import Footer from "../HomePage/components/footer/footer.js";
import Header from "../HomePage/components/header/header.js";
import "firebase/firestore";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import image from "../HomePage/components/prizing/background1.jpg";
import logo from "../HomePage/assets/WiringMaster.svg";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const userCredential = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      const user = userCredential.user;

      // Check for existing active session
      const userRef = firebase.firestore().collection("users").doc(user.uid);
      const doc = await userRef.get();
      if (doc.exists && doc.data().sessionToken) {
        // Handle existing active session
        throw new Error(
          "An active session already exists. Please log out from other devices."
        );
      }

      // Update session token for this login
      const newSessionToken = generateUniqueToken();
      await userRef.update({ sessionToken: newSessionToken });
      localStorage.setItem("sessionToken", newSessionToken);

      navigate("/");
    } catch (error) {
      setError("Email or password don't match. Please try again or reset your password.");
    }
  };

  function generateUniqueToken() {
    return (
      Math.random().toString(36).substring(2) +
      new Date().getTime().toString(36)
    );
  }

  return (
    <>
        <Header />
      <div className={Styles.parentContainer}>
        <div className={Styles.imageContainer}>
          <img src={image} alt="hello" />
        </div>
        <Card sx={{ maxWidth: 345 }} className={Styles.card}>
          <CardMedia
            component="img"
            alt="green iguana"
            height="140"
            image={logo}
            onClick={()=>{navigate('/home')}}
          />
          <CardContent className={Styles.cardContent}>
            {/* Display error message if there is an error */}
            {error && (
              <Typography variant="body2" color="error" style={{ marginBottom: 16 }}>
                {error}
              </Typography>
            )}
            <Typography variant="body2" color="text.secondary">
              <div className={Styles.inputGroup}>
                <div>
                  <label htmlFor="email">Email:</label>
                  <input
                    id="email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="password">Password:</label>
                  <input
                    id="password"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
            </Typography>
            <div className={Styles.cardButton}>
              <Button className={Styles.loginButton} onClick={handleLogin} variant="contained">Login</Button>
            </div>
          </CardContent>
          <CardActions>
            <Typography variant="body2" color="text.secondary">
              Don't have an account?
              <Button size="large" variant="text" color="secondary" onClick={()=>{navigate('/register')}}>Sign Up</Button>
            </Typography>
          </CardActions>
          <CardActions>
            <Typography variant="body2" color="text.secondary">
              Forgot your password?
              <Button size="large" variant="text" color="secondary" onClick={()=>{navigate('/resetpassword')}}>Reset Password here</Button>
            </Typography>
          </CardActions>
        </Card>
      </div>
    </>





    /* <div className="Style">
        <h2>Login</h2>
        {error && <p className="error-message">{error}</p>}
        <div className="input-group">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input-group">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button className="login-button" onClick={handleLogin}>
          Login
        </button>
        <div className="register-link-container">
          <p>
            Don't have an account?{" "}
            <Link to="/register" className="register-link">
              Register for new account
              </Link>
              </p>
              </div>
            </div> */
  );
}

export default Login;
