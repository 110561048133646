import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "./Header.css";
import Styles from "./header.module.scss";
import image from "./WiringMaster.png";
import MobileHeader from "./headerMobile.jsx";
import Lottie from "react-lottie";
import profileicon from "../calculatorselector/lottie/profile.json";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import axios from "axios";

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isPaidUser, setIsPaidUser] = useState(false); // State to track if the user is a paid user
  const [user, setUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(false);
  const navigate = useNavigate();
  const divRef = useRef(null);
  const settings = [
    { name: "Profile", url: "/profile" },
   
  ];
  const mobileWidth = 768;
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const profileIcon = {
    loop: true,
    autoplay: true,
    animationData: profileicon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (currentUser) => {
        setIsLoggedIn(!!currentUser);
        setUser(currentUser);
        if (currentUser) {
          const userRef = firebase
            .firestore()
            .collection("users")
            .doc(currentUser.uid);
          const doc = await userRef.get();
          if (doc.exists) {
            setIsPaidUser(doc.data().isPaidUser);
            sessionStorage.setItem("isPaidUser", doc.data().isPaidUser);
            sessionStorage.setItem("isLogged", !!currentUser);
          }
        }
      });

    document.addEventListener("click", handleClickOutside);
    return () => {
      unsubscribe();
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    try {
      const user = firebase.auth().currentUser;
      if (user) {
        // Access Firestore and remove the session token
        const userRef = firebase.firestore().collection("users").doc(user.uid);
        await userRef.update({
          sessionToken: firebase.firestore.FieldValue.delete(),
        });

        // Remove the session token from localStorage
        localStorage.removeItem("sessionToken");
      }

      // Sign out the user from Firebase Authentication
      await firebase.auth().signOut();

      // Navigate to the home page
      navigate("/");
      handleClick("none"); // This function is used to handle UI, presumably closing a menu
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setAnchorEl(false);
    }
  };
  const handleClick = (url) => {
    setAnchorElUser(null);
    if (url !== "none") {
      navigate(url);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const user = firebase.auth().currentUser;
      if (user) {
        const db = firebase.firestore();
        const userRef = db.collection("users").doc(user.uid);
        const userData = await userRef.get();
        const { subscriptionId, customerId } = userData.data();

        // Call the cancel subscription endpoint with subscription ID and customer ID
        const response = await axios.post(
          "http://127.0.0.1:5000/cancel-subscription",
          {
            subscriptionId: subscriptionId,
            customerId: customerId,
          }
        );

        if (response.data.success) {
          // Update user status in Firebase to reflect cancellation
          await userRef.update({
            isPaidUser: false,
            subscriptionId: null,
            customerId: null,
          });
          console.log("Subscription cancelled successfully");
        } else {
          console.error("Failed to cancel subscription");
        }
      } else {
        console.error("User not logged in");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const userName =
    user &&
    user?.multiFactor &&
    user?.multiFactor?.user &&
    user?.multiFactor?.user?.displayName == null
      ? user?.multiFactor?.user?.email
      : user?.multiFactor?.user?.displayName;

  return (
    <>
      {mobileWidth < window.screen.width ? (
        <div className={Styles.mainContainer}>
          <div className={Styles.leftLinks}>
            {/* Left side links */}
            <Button className={Styles.homeButton}>
              <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                {/* <i className="fa fa-home"></i> */}
                <img src={image} alt="home" className={Styles.homeLogo} />
              </Link>
            </Button>
          </div>

          <div className={Styles.middleLinks}>
            {/* Middle links */}
            <Button className={Styles.cableSize}>
              <Link to="/cable-size-calculator">Cable Size Calc</Link>
            </Button>
            <Button className={Styles.maxDemand}>
              <Link to="/maxdemandcalculator">Max Demand Calc</Link>
            </Button>
            <Button className={Styles.maxDemand}>
              <Link to="/harmonic">Harmonic Calc</Link>
            </Button>
            <Button className={Styles.maxDemand}>
              <Link to="/ArcFlashCalculator">AC Arc Flash Calc</Link>
            </Button>
            <Button className={Styles.maxDemand}>
              <Link to="/ArcFlashCalculatorDC">DC Arc Flash Calc</Link>
            </Button>
            <Button className={Styles.aboutUs}>
              <Link to="/About-us">About Us</Link>
            </Button>
            {isLoggedIn && !isPaidUser && (
              <Button>
                <Link to="/stripepayment">Buy</Link>
              </Button>
            )}
          </div>

          <div className={Styles.rightLinks}>
            {/* Right side avatar and links */}
            {isLoggedIn ? (
              <div className={Styles.profileContainer}>
                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <span className={Styles.buttonTitle}>
                        <Lottie options={profileIcon} width={30} height={30} />
                      </span>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={() => handleClick("none")}
                  >
                    {settings.map((setting) => (
                      <MenuItem
                        key={setting.name}
                        onClick={() => handleClick(setting.url)}
                      >
                        <Typography textAlign="center">
                          {setting.name}
                        </Typography>
                      </MenuItem>
                    ))}
                    <MenuItem onClick={handleLogout}>
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleCancelSubscription}>
                    </MenuItem>
                  </Menu>
                </Box>
              </div>
            ) : (
              <Button
                className="w3-bar-item w3-button"
                style={{ fontSize: "16px", padding: "10px 20px" }}
              >
                <Link
                  to="/login"
                  style={{ textDecoration: "none", color: "#4c4c4c" }}
                >
                  Login
                </Link>
              </Button>
            )}
          </div>
        </div>
      ) : (
        <>
          <MobileHeader
            isLoggedIn={isLoggedIn}
            isPaidUser={isPaidUser}
            username={userName}
            handleLogout={handleLogout}
          />
        </>
      )}
    </>
  );
};

export default Header;
