import React, { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useNavigate } from "react-router-dom";
import Styles from "./register.module.scss";
import Header from "../HomePage/components/header/header.js";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import image from "../HomePage/components/prizing/background1.jpg";
import logo from "../HomePage/assets/WiringMaster.svg";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleRegister = async () => {
    try {
      // Create user with email and password
      await firebase.auth().createUserWithEmailAndPassword(email, password);

      // Get the currently signed-in user
      const user = firebase.auth().currentUser;

      // Update the user's profile with additional data
      const db = firebase.firestore();
      await db.collection("users").doc(user.uid).set({
        email: email,
        phoneNumber: phoneNumber,
        isPaidUser: false, // Initialize as false
      });

      // Navigate to the cable size calculator page upon successful registration
      navigate("/cable-size-calculator");
    } catch (error) {
      // Process the error message to remove "Firebase:" and the error code
      const errorMessage = error.message
        .replace("Firebase: ", "")
        .replace(/ \(.*\)/, "");
      setError(errorMessage);
    }
  };

  return (
    <>
      <Header />
      <div className={Styles.parentContainer}>
        <div className={Styles.imageContainer}>
          <img src={image} alt="hello" />
        </div>
        <Card sx={{ maxWidth: 345 }} className={Styles.card}>
          <CardMedia
            component="img"
            alt="green iguana"
            height="140"
            image={logo}
            onClick={() => {
              navigate("/");
            }}
          />
          <CardContent className={Styles.cardContent}>
            <Typography variant="body2" color="text.secondary">
              <div className={Styles.inputGroup}>
                <div>
                  <label htmlFor="email">Email:</label>
                  <input
                    id="email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="password">Password:</label>
                  <input
                    id="password"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="phoneNumber">Phone number:</label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
            </Typography>
            <div className={Styles.cardButton}>
              <Button
                className={Styles.loginButton}
                onClick={handleRegister}
                variant="contained"
              >
                Sign Up
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
}

export default Register;
