import React, { useState, useEffect } from 'react';
import { Paper, TextField, Typography, FormControl, Select, MenuItem, InputLabel } from '@mui/material';

function FaultCurrentComponent({ onChange, faultCurrentDetails1 }) {
  const [faultLevel, setFaultLevel] = useState(3);
  const [sourceImpedance, setSourceImpedance] = useState('Calculate');
  const [sourceImpedanceValue, setSourceImpedanceValue] = useState('0.01');
  const [voltageCorrectionFactor, setVoltageCorrectionFactor] = useState('1');


  useEffect(() => {
    if (faultCurrentDetails1) {
      setFaultLevel(faultCurrentDetails1.faultLevel ? faultCurrentDetails1.faultLevel : '3');
      setSourceImpedance(faultCurrentDetails1.sourceImpedance ? faultCurrentDetails1.sourceImpedance : 'Calculate');
      setSourceImpedanceValue(faultCurrentDetails1.sourceImpedanceValue ? faultCurrentDetails1.sourceImpedanceValue : '0.01');
    }
  }, [faultCurrentDetails1]);




  const handleFaultLevelChange = (event) => {
    let newFaultLevel = parseFloat(event.target.value);
    setFaultLevel(newFaultLevel);
    updateParent(newFaultLevel, sourceImpedance, sourceImpedanceValue,voltageCorrectionFactor);
  };


  const handleVoltageCorrectionFactor = (event) => {
    let newVoltageCorrectionFactor = parseFloat(event.target.value);
    setVoltageCorrectionFactor(newVoltageCorrectionFactor);
    updateParent(faultLevel, sourceImpedance, sourceImpedanceValue,newVoltageCorrectionFactor);
  };

  const handleSourceImpedanceChange = (event) => {
    const newSourceImpedance = event.target.value;
    setSourceImpedance(newSourceImpedance);
    updateParent(faultLevel, newSourceImpedance, sourceImpedanceValue,voltageCorrectionFactor);
  };

  const handleSourceImpedanceValueChange = (event) => {
    let newSourceImpedanceValue = parseFloat(event.target.value);
    setSourceImpedanceValue(newSourceImpedanceValue);
    updateParent(faultLevel, sourceImpedance, newSourceImpedanceValue,voltageCorrectionFactor);
  };

  const updateParent = (faultLevel, sourceImpedance, sourceImpedanceValue,voltageCorrectionFactor) => {
    onChange({ faultLevel, sourceImpedance, sourceImpedanceValue,voltageCorrectionFactor });
  };


  useEffect(() => {
    updateParent(faultLevel, sourceImpedance, sourceImpedanceValue,voltageCorrectionFactor);
  }, [faultLevel, sourceImpedance, sourceImpedanceValue,voltageCorrectionFactor]);

  return (
    <Paper style={{ padding: '20px', margin: '20px 0', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
      <Typography variant="h5" style={{ marginBottom: '20px' }}>FAULT CURRENT DETAILS</Typography>
      <FormControl fullWidth style={{ marginBottom: '10px' }}>
        <InputLabel>Source Impedance</InputLabel>
        <Select
          value={sourceImpedance}
          onChange={handleSourceImpedanceChange}
          label="Source Impedance"
        >
          <MenuItem value="Calculate">Calculate</MenuItem>
          <MenuItem value="Measured">Measured</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Fault Level (kA)"
        type="number"
        value={faultLevel}
        onChange={handleFaultLevelChange}
        variant="outlined"
        fullWidth
        disabled={sourceImpedance === 'Measured'}
      />
      {sourceImpedance === 'Measured' && (
        <TextField
          label="Source Impedance (ohms)"
          type="number"
          value={sourceImpedanceValue}
          onChange={handleSourceImpedanceValueChange}
          variant="outlined"
          fullWidth
          style={{ marginTop: '10px' }}
        />
      )}
            <TextField
        label="Voltage Correction Factor (c)"
        type="number"
        value={voltageCorrectionFactor}
        onChange={handleVoltageCorrectionFactor}
        variant="outlined"
        fullWidth
        style={{ marginTop: '10px' }}
      />
    </Paper>
  );
}

export default FaultCurrentComponent;
