import React, { useEffect, useState } from 'react';
import './BuildingInsightComponent.css'; // Import the CSS file
import math from 'mathjs'; // Import the math library for trigonometric functions
import html2canvas from 'html2canvas'; // Import html2canvas library



const MapComponent = ({latitude, longitude, rgbUrl, monthlyFluxUrl, dsmUrl, maskUrl, annualFluxUrl, building_insights,API_KEY,selectedLayer,selectedMonth,panel_bounds,pannelIndex,manualPlacementMode,financialanalysisdata,onImageUpdate}) => {
    const [map, setMap] = useState(null);
    const [overlays_auto, setOverlays_auto] = useState([]);
    const [overlays_mannual, setOverlays_mannual] = useState([]);
    const [overlays_layers, setOverlays_layers] = useState([]);
    const [overlays_segments, setOverlays_segments] = useState([]);
    const [manualPlacementDetails, setManualPlacementDetails] = useState([]);
    const [panelWidth, setPanelWidth] = useState(1); // Default panel width
    const [panelHeight, setPanelHeight] = useState(1); // Default panel height
    const [showHighestEnergySegments, setShowHighestEnergySegments] = useState(false); // State variable to track whether to show the highest energy roof segments
    const [numSegmentsToShow, setNumSegmentsToShow] = useState(3); // State variable to hold the number of segments to display
    const [pannelcapacity, setpannelcapacity] = useState(250);
    const [maxSunshineSegments, setMaxSunshineSegments] = useState([]); // State variable to hold information about segments with maximum sunshine
    const [energyYieldArray, setEnergyYieldArray] = useState([]); // State variable to store original yearly energy values
    const [totalenergymannual, settotalenergymannual] = useState();
    const [draggflag, setdraggflag] = useState(false);

    useEffect(() => {
        if (map) {
            // Use html2canvas to capture the map container with CORS enabled
            html2canvas(document.getElementById('map1'), {
                useCORS: true,
            }).then(canvas => {
                // Convert the canvas to a data URL
                const imageUrl = canvas.toDataURL('image/png');
                // Call the onImageUpdate function with the image URL
                onImageUpdate(imageUrl);
            });
        }
    }, [map, overlays_mannual, overlays_segments, overlays_layers]);



    useEffect(() => {
        let totalenergymannual = 0; // Initialize total energy yield
    
        // Calculate total energy yield by summing up yearly energy for each manually placed panel
        manualPlacementDetails.forEach((detail) => {
            totalenergymannual += parseFloat(detail.yearlyEnergyDcKwh);

        });
    
        // Update the state variable with the total energy yield
        settotalenergymannual(totalenergymannual);

        financialanalysisdata(manualPlacementDetails,pannelcapacity,totalenergymannual,maxSunshineSegments,panelHeight,panelWidth)


    }, [manualPlacementDetails,pannelcapacity,maxSunshineSegments,panelHeight,panelWidth ]);



    // Define handle panel capacity function
    const handlepannelcapacity = (event) => {
        const newPannelCapacity = parseFloat(event.target.value);
        const updatedManualPlacementDetails = manualPlacementDetails.map((detail, index) => {
            detail.yearlyEnergyDcKwh = energyYieldArray[index];
            const updatedYearlyEnergyDcKwh = ((detail.yearlyEnergyDcKwh * newPannelCapacity) / 250).toFixed(3);
            return { ...detail, yearlyEnergyDcKwh: updatedYearlyEnergyDcKwh };
        });
        setpannelcapacity(newPannelCapacity);
        console.log("newPannelCapacity",newPannelCapacity)
        setManualPlacementDetails(updatedManualPlacementDetails);
    };






        // Function to toggle the display of highest energy roof segments
    const toggleShowHighestEnergySegments = () => {
            setShowHighestEnergySegments(!showHighestEnergySegments);
        };


    // Function to handle changing the number of segments to display
    const handleNumSegmentsChange = (event) => {
        const value = parseInt(event.target.value);
        // Ensure the value is within the valid range
        const maxSegments = building_insights.solarPotential ? building_insights.solarPotential.roofSegmentStats.length : 0;
        if (!isNaN(value) && value >= 1 && value <= maxSegments) {
            setNumSegmentsToShow(value);
        }
    };


// Toggle manual placement mode    
// Function to handle deleting the last added panel
// Function to handle deleting the last added panel
const deleteLastPanel = () => {
    if (manualPlacementDetails.length > 0) {
        // Get the details of the last added panel
        const lastPanelDetails = manualPlacementDetails[manualPlacementDetails.length - 1];

        // Remove the rectangle overlay from the map
        lastPanelDetails.rectangle.setMap(null);

        // Remove the panel details from manualPlacementDetails
        const updatedDetails = [...manualPlacementDetails];
        updatedDetails.pop(); // Remove the last item
        setManualPlacementDetails(updatedDetails);
    }
};
    
        // Function to handle orientation change for a panel
        const handleOrientationChange = (index, orientation) => {
            const updatedDetails = manualPlacementDetails.map(detail => {
                if (detail.index === index) {
                    // Swap width and height if changing orientation from landscape to portrait or vice versa
                    const newWidth = orientation === 'Portrait' ? panelHeight : panelWidth;
                    const newHeight = orientation === 'Portrait' ? panelWidth : panelHeight;
        
                    // Calculate new bounding box corners using Haversine formula
                    const lat_offset = (newHeight / 2) / 111111; // 1 degree latitude is approximately 111111 meters
                    const lng_offset = (newWidth / 2) / (111111 * Math.cos(Math.radians(detail.latitude)));
        
                    const min_lat = detail.latitude - lat_offset;
                    const max_lat = detail.latitude + lat_offset;
                    const min_lng = detail.longitude - lng_offset;
                    const max_lng = detail.longitude + lng_offset;
        
                    // Update the rectangle's bounds with the new dimensions
                    detail.rectangle.setBounds(
                        new window.google.maps.LatLngBounds(
                            new window.google.maps.LatLng(min_lat, min_lng), // Southwest corner
                            new window.google.maps.LatLng(max_lat, max_lng)  // Northeast corner
                        )
                    );
        
                    return { ...detail, orientation: orientation };
                }
                return detail;
            });
            setManualPlacementDetails(updatedDetails);
        };
    
        // Function to handle panel width change
        const handlePanelWidthChange = (event) => {
            const width = parseFloat(event.target.value);
            setPanelWidth(width);
            setManualPlacementDetails([]);

        };
    
        // Function to handle panel height change
        const handlePanelHeightChange = (event) => {
            const height = parseFloat(event.target.value);
            setPanelHeight(height);
            setManualPlacementDetails([]);
           
        };


    Math.radians = function(degrees) {
        return degrees * Math.PI / 180;
      };


    // Function to clear existing overlays
const clearOverlays_auto = () => {
    // Loop through all existing overlays and set their map property to null
    for (const overlay of overlays_auto) {
        overlay.setMap(null);
    }
};

    // Function to clear existing overlays
    const clearOverlays_mannual = () => {
        // Loop through all existing overlays and set their map property to null
        for (const overlay of overlays_mannual) {
            overlay.setMap(null);
        }
    };


    // Function to clear existing overlays
    const clearOverlays_layers = () => {
        // Loop through all existing overlays and set their map property to null
        for (const overlay of overlays_layers) {
            overlay.setMap(null);
        }
    };


        // Function to clear existing overlays
        const clearOverlays_segments = () => {
            // Loop through all existing overlays and set their map property to null
            for (const overlay of overlays_segments) {
                overlay.setMap(null);
            }
        };



    useEffect(() => {
        // Load the Google Maps JavaScript API script
        

        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
        script.async = true;
        script.onload = initMap;
        document.body.appendChild(script);

        return () => {
            // Clean up: remove the script when the component unmounts
            document.body.removeChild(script);
        };
    },[panelWidth,panelHeight,building_insights]);

    const initMap = () => {
        // Initialize the map with the default center at latitude and longitude
        const map = new window.google.maps.Map(document.getElementById('map'), {
            center: { lat: latitude, lng: longitude },
            zoom: 60,
            mapTypeId: 'satellite', // Set default map type to satellite
            streetViewControl: false, // Hide street view control
            tilt: 0, // Set tilt to 0 for no tilt
            disableDefaultUI: true, // Disable default UI components
            minZoom: 1, // Set minimum zoom level
            maxZoom: 100, // Set maximum zoom level
        });
        
        // Save the map instance to state
        setMap(map);
        clearOverlays_auto();
        clearOverlays_mannual();
        setManualPlacementDetails([]);
    
        // Calculate the building bounds
        const buildingBounds = new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(building_insights.boundingBox.sw.latitude, building_insights.boundingBox.sw.longitude),
            new window.google.maps.LatLng(building_insights.boundingBox.ne.latitude, building_insights.boundingBox.ne.longitude)
        );
    
        // Create a rectangle overlay using the building bounds
        const buildingRectangle = new window.google.maps.Rectangle({
            bounds: buildingBounds,
            editable: false, // Set to true if you want users to be able to edit the rectangle
            draggable: false, // Set to true if you want users to be able to drag the rectangle
            strokeColor: '#FF0000', // Border color
            strokeOpacity: 0.8, // Border opacity
            strokeWeight: 5, // Border thickness
            clickable: false, // Make the rectangle non-clickable
  
            fillOpacity: 0, // Fill opacity
        });
    
        // Add the rectangle overlay to the map
        buildingRectangle.setMap(map);
    
        // Add click event listener for manual placement mode
        map.addListener('click', (event) => handleManualPlacement(event, map, panelWidth, panelHeight, pannelcapacity));
    };

    useEffect(() => {
        // Check if building_insights is empty before adding overlays
        if (Object.keys(building_insights).length && map) {
            // Add overlays based on the building insights bounding box
            const buildingBounds = new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(building_insights.boundingBox.sw.latitude, building_insights.boundingBox.sw.longitude),
                new window.google.maps.LatLng(building_insights.boundingBox.ne.latitude, building_insights.boundingBox.ne.longitude)
            );
    
            // Create an overlay for the RGB image
            const rgbOverlay = new window.google.maps.GroundOverlay(rgbUrl, buildingBounds);
           
            
            switch(selectedLayer) {
                case 'rgblayer':
                    // Add RGB overlay to the map
                    clearOverlays_layers();
                    break;
                case 'elevation':
                    // Create an overlay for the DSM image
                    const dsmOverlay = new window.google.maps.GroundOverlay(dsmUrl, buildingBounds);
                    // Add both RGB and DSM overlays to the map
                  
                    dsmOverlay.setMap(map);
                    overlays_layers.push(dsmOverlay);
                    break;
                case 'annualFlux':
                    // Create an overlay for the annual flux image
                    const annualFluxOverlay = new window.google.maps.GroundOverlay(annualFluxUrl, buildingBounds);
                    // Add both RGB and annual flux overlays to the map
                 
                    annualFluxOverlay.setMap(map);
                    overlays_layers.push(annualFluxOverlay);
                    break;
                    case 'monthlyFlux':
                        // Check if selectedMonth is a valid index
                        const monthIndex = parseInt(selectedMonth);
                        if (!isNaN(monthIndex) && monthIndex >= 0 && monthIndex < monthlyFluxUrl.length) {
                            // Create an overlay for the monthly flux image using the URL corresponding to the selected month index
                            const monthlyFluxOverlay = new window.google.maps.GroundOverlay(monthlyFluxUrl[monthIndex], buildingBounds);
                            // Add both RGB and monthly flux overlays to the map
                     
                            monthlyFluxOverlay.setMap(map);
                            overlays_layers.push(monthlyFluxOverlay);
                        }
                        break;
                default:
              
                    break;
            }
        }
    }, [selectedLayer, selectedMonth,building_insights.length,rgbUrl, monthlyFluxUrl, dsmUrl, maskUrl, annualFluxUrl,map]);

    useEffect(() => {

    if(manualPlacementMode) {
        clearOverlays_auto();
    }
    // Check if building_insights and panel_bounds are available before adding overlays
    if (Object.keys(building_insights).length && map && panel_bounds && !manualPlacementMode) {

        clearOverlays_auto();
        clearOverlays_mannual();
        setManualPlacementDetails([]);

        // Determine the number of panels to map based on the slider value
        const numPanelsToMap = building_insights.solarPotential.solarPanelConfigs[pannelIndex].panelsCount ;

        // Loop through the panels and add overlays for each one
        for (let i = 0; i < numPanelsToMap; i++) {
            const solarPanel = panel_bounds[i];
            const panelBounds = new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(solarPanel.min_lat, solarPanel.min_lng),
                new window.google.maps.LatLng(solarPanel.max_lat, solarPanel.max_lng)
            );
            const rectangle = new window.google.maps.Rectangle({
                bounds: panelBounds,
                strokeColor: '#FFFFFF', // White border color
                strokeOpacity: 1, // Full opacity for stroke
                strokeWeight: 0.6, // Increase stroke weight for visibility
                fillColor: '#1E2852', // Indigo fill color
                fillOpacity: 1, // Full opacity for fill
                editable: false,
                draggable: false,
                clickable: false,
                map: map
            });
            

                        // Add the rectangle overlay to the overlays array
            overlays_auto.push(rectangle);
        }
    }
    }, [selectedLayer, selectedMonth,building_insights.length,rgbUrl, monthlyFluxUrl, dsmUrl, maskUrl, annualFluxUrl,map,pannelIndex,manualPlacementMode]);




    useEffect(() => {
        // Check if building_insights and panel_bounds are available before adding overlays
        if (showHighestEnergySegments && Object.keys(building_insights).length && map && panel_bounds && building_insights.solarPotential && manualPlacementMode) {
            // Clear existing overlays
            clearOverlays_segments();
            setMaxSunshineSegments([]);

            // Sort the roof segments based on the last value in the sunshine quantity array
            const sortedSegments = building_insights.solarPotential.roofSegmentStats.sort((a, b) => {
                const aSunshine = a.stats.sunshineQuantiles[a.stats.sunshineQuantiles.length - 1];
                const bSunshine = b.stats.sunshineQuantiles[b.stats.sunshineQuantiles.length - 1];
                return bSunshine - aSunshine; // Descending order
            });

            // Define colors for roof segments
            const colors = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#00FFFF', '#FF00FF', '#FFA500', '#A52A2A', '#800080', '#008000', '#808080', '#800000', '#008080', '#800080', '#000080'];

            // Loop through the segments to display and create overlays for each one
            for (let i = 0; i < Math.min(numSegmentsToShow, sortedSegments.length); i++) {
                const segment = sortedSegments[i];

                const segmentBounds = new window.google.maps.LatLngBounds(
                    new window.google.maps.LatLng(segment.boundingBox.sw.latitude, segment.boundingBox.sw.longitude),
                    new window.google.maps.LatLng(segment.boundingBox.ne.latitude, segment.boundingBox.ne.longitude)
                );

                // Create overlay for each segment
                const overlay = new window.google.maps.Rectangle({
                    bounds: segmentBounds,
                    strokeColor: colors[i % colors.length],
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: colors[i % colors.length],
                    fillOpacity: 0.35,
                    editable: false,
                    draggable: false,
                    clickable: false,
                    map: map
                });

                // Add the overlay to the overlays array
                setOverlays_segments(prevOverlays => [...prevOverlays, overlay]);
                 // Update maxSunshineSegments state
                 setMaxSunshineSegments(prevSegments => [...prevSegments, { segment: overlay, maxSunshine: segment.stats.sunshineQuantiles[segment.stats.sunshineQuantiles.length - 1] }]);
            }
        } else {
            // If not showing highest energy segments or the necessary data is not available, clear the overlays
            clearOverlays_segments();
            setMaxSunshineSegments([]);
        }
    }, [showHighestEnergySegments, numSegmentsToShow, building_insights, map, panel_bounds,manualPlacementMode]);




  // Function to handle manual placement
const handleManualPlacement = (event, map, panelWidth, panelHeight,pannelcapacity) => {
    // Get latitude and longitude from the event
    const { latLng } = event;
    const latitude = latLng.lat();
    const longitude = latLng.lng();

    // Calculate bounding box corners using Haversine formula
    const lat_offset = (panelHeight / 2) / 111111; // 1 degree latitude is approximately 111111 meters
    const lng_offset = (panelWidth / 2) / (111111 * Math.cos(Math.radians(latitude)));

    const min_lat = latitude - lat_offset;
    const max_lat = latitude + lat_offset;
    const min_lng = longitude - lng_offset;
    const max_lng = longitude + lng_offset;

    // Create the bounding box for the rectangle overlay
    const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(min_lat, min_lng), // Southwest corner
        new window.google.maps.LatLng(max_lat, max_lng)  // Northeast corner
    );

    // Find the closest panel in the building insights' solar panel array
    let closestPanel = null;
    let minDistance = Infinity;
    building_insights.solarPotential.solarPanels.forEach((panel, index) => {
        const panelCenter = panel.center;
        const panelLat = panelCenter.latitude;
        const panelLng = panelCenter.longitude;
        const distance = Math.sqrt(Math.pow(panelLat - latitude, 2) + Math.pow(panelLng - longitude, 2));
        if (distance < minDistance) {
            closestPanel = { ...panel, index: index };
            minDistance = distance;
        }
    });

    if (closestPanel) {
        // Extract the yearlyEnergyDcKwh of the closest panel
        const yearlyEnergyDcKwh = (closestPanel.yearlyEnergyDcKwh);

        // Create a rectangle overlay for the placed solar cell
        const rectangle = new window.google.maps.Rectangle({
            bounds: bounds,
            strokeColor: '#FFFFFF', // White border color
            strokeOpacity: 1, // Full opacity for stroke
            strokeWeight: 0.6, // Increase stroke weight for visibility
            fillColor: '#1E2852', // Indigo fill color
            fillOpacity: 1, // Full opacity for fill
            editable: false, // Make the rectangle editable
            draggable: true, // Make the rectangle draggable
            clickable: true,
            map: map // Use the map parameter passed to the function
        });

        // Add a dragend event listener to update panel details when dragged
        rectangle.addListener('dragend', () => {
            // Update the latitude and longitude of the placed solar cell when dragged
            const newLatLng = rectangle.getBounds().getCenter();
            const newLatitude = newLatLng.lat();
            const newLongitude = newLatLng.lng();


            // Recalculate the yearlyEnergyDcKwh based on the new location
            const updatedYearlyEnergyDcKwh = (calculateYearlyEnergyDcKwh(newLatitude, newLongitude));
            console.log("pannelcapacity",pannelcapacity)
           

            // Update the manualPlacementDetails state with the new panel details
            setManualPlacementDetails(prevDetails => {
                // Map over the previous details and update the dragged panel's details
                return prevDetails.map((detail, index) => {
                    if (detail.rectangle === rectangle) {
                        energyYieldArray[index] = updatedYearlyEnergyDcKwh
                        return { ...detail, latitude: newLatitude, longitude: newLongitude, yearlyEnergyDcKwh: updatedYearlyEnergyDcKwh };
                    }
                    return detail;
                });
            });
        });

        overlays_mannual.push(rectangle);

        // Add the new panel details to manualPlacementDetails
        setManualPlacementDetails(prevDetails => {
            const newIndex = prevDetails.length + 1; // Calculate the new index
            energyYieldArray.push(yearlyEnergyDcKwh)
            return [...prevDetails, { index: newIndex, orientation: 'Landscape', latitude: latitude, longitude: longitude, rectangle: rectangle, yearlyEnergyDcKwh: yearlyEnergyDcKwh }];
        });
    }
};






// Function to calculate yearly energy based on latitude and longitude
const calculateYearlyEnergyDcKwh = (latitude, longitude) => {
    // Find the closest panel in the building insights' solar panel array
    let closestPanel = null;
    let minDistance = Infinity;
    building_insights.solarPotential.solarPanels.forEach((panel, index) => {
        const panelCenter = panel.center;
        const panelLat = panelCenter.latitude;
        const panelLng = panelCenter.longitude;
        const distance = Math.sqrt(Math.pow(panelLat - latitude, 2) + Math.pow(panelLng - longitude, 2));
        if (distance < minDistance) {
            closestPanel = { ...panel, index: index };
            minDistance = distance;
        }
    });

    if (closestPanel) {
        // Extract and return the yearlyEnergyDcKwh of the closest panel
        return (closestPanel.yearlyEnergyDcKwh);
    }

    return 0; // Return 0 if no closest panel found (should not happen)
};


    

    return (
        <div>

        <div id="map1">
        
        <div id="map" style={{ width: '100%', height: '800px' }}></div>
        </div>
        {manualPlacementMode && (
            <div>
                {/* Other manual placement mode UI elements */}
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={showHighestEnergySegments}
                            onChange={toggleShowHighestEnergySegments}
                        />
                        Show highest energy roof segments
                    </label>
                </div>
            </div>
        )}

{manualPlacementMode && (
            <div>
                {/* Other manual placement mode UI elements */}
                <div>
                    <label>
                        <input
                            type="number"
                            value={pannelcapacity}
                            onChange={handlepannelcapacity}
                        />
                        Pannel Capacity : 
                    </label>
                </div>
            </div>
        )}

{showHighestEnergySegments && manualPlacementMode &&  (
                        <div>
                            <label>
                                Number of Segments to Show:
                                <input
                                    type="number"
                                    value={numSegmentsToShow}
                                    onChange={handleNumSegmentsChange}
                                    min="1"
                                    max={building_insights.solarPotential ? building_insights.solarPotential.roofSegmentStats.length : 0}
                                />
                            </label>
                        </div>
                    )}


{showHighestEnergySegments && manualPlacementMode && (
                <div>
                    <h2>Segments with Maximum Sunshine Hours</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Segment</th>
                                <th>Maximum Sunshine Hours</th>
                            </tr>
                        </thead>
                        <tbody>
                            {maxSunshineSegments.map((segment, index) => (
                                <tr key={index}>
                                    <td><div style={{ width: '20px', height: '20px', backgroundColor: segment.segment.strokeColor }}></div></td>
                                    <td>{segment.maxSunshine} hours</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

        {manualPlacementMode && manualPlacementMode && (
            <div>
                <div className="panel-size-input">
                    <label htmlFor="panelWidth">Panel Width (meters):</label>
                    <input
                        type="number"
                        id="panelWidth"
                        value={panelWidth}
                        onChange={handlePanelWidthChange}
                    />
                    <label htmlFor="panelHeight">Panel Height (meters):</label>
                    <input
                        type="number"
                        id="panelHeight"
                        value={panelHeight}
                        onChange={handlePanelHeightChange}
                    />
                </div>

                
                
         
                
            {manualPlacementDetails && manualPlacementDetails.length === 0 && (
                <p style={{ color: 'red' }}>Click on roof location to add panels</p>

            )}

            {manualPlacementDetails && manualPlacementDetails?.length > 0 && (
                <div>
                  <button onClick={deleteLastPanel}>Delete Last Panel</button>
                          <div>
                          <h2>Total Annual Energy Yield for Manual Panels</h2>
                          <p>Total Energy Yield: {totalenergymannual} kWh</p>
                      </div>

                      </div>

            )}

            {manualPlacementDetails && manualPlacementDetails?.length > 0 && (
                

                <table className="panel-placement-table">
                    <thead>
                        <tr>
                            <th>Panel Index</th>
                            <th>Orientation</th>
                            <th>DC Kilowatt Energy Produced</th>
                        </tr>
                    </thead>
                    <tbody>
                        {manualPlacementDetails.map((detail, index) => (
                            <tr key={index}>
                                <td>{detail.index}</td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            name={`orientation_${index}`}
                                            value="Landscape"
                                            checked={detail.orientation === 'Landscape'}
                                            onChange={() => handleOrientationChange(detail.index, 'Landscape')}
                                        /> Landscape
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name={`orientation_${index}`}
                                            value="Portrait"
                                            checked={detail.orientation === 'Portrait'}
                                            onChange={() => handleOrientationChange(detail.index, 'Portrait')}
                                        /> Portrait
                                    </label>
                                </td>
                                <td>{detail.yearlyEnergyDcKwh} kWh</td> {/* Populate the yearly energy */}
                            </tr>
                        ))}
                    </tbody>
                </table>
                 )}
            </div>
        )}
        </div>
    );
};

export default MapComponent;
