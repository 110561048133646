import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/compat/app'; // Updated import path
import 'firebase/compat/auth'; // Updated import path




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


const firebaseConfig = {
  apiKey: "AIzaSyAszNn29pi1egYN33Hr9SA0KVQYW_wqYFw",
  authDomain: "cablesizecalculator.firebaseapp.com",
  projectId: "cablesizecalculator",
  storageBucket: "cablesizecalculator.appspot.com",
  messagingSenderId: "235004569461",
  appId: "1:235004569461:web:5d9be9714a90d5013fd53c",
  measurementId: "G-02C06BTJVH"
};

firebase.initializeApp(firebaseConfig);


