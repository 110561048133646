import React from "react";
import Header from "./components/header/header";
import { Helmet } from "react-helmet";
import CalculatorSelector from "./components/calculatorselector/calculatorselector.js";
import Footer from "./components/footer/footer.js";
import CompanyBanner from "./components/companybanner/companybanner.js";
import Prizing from "./components/prizing/prizing.js";

function HomePage() {
  return (
    <>
      <Helmet>
        <title>Wiringmaster</title>
        <meta name="description" content="Welcome to Wiringmaster, your premier online destination for comprehensive electrical calculations, meticulously designed to meet Australian and international standards. Our suite of advanced calculators empowers users to perform a range of critical calculations with ease and precision, while generating detailed reports for seamless project documentation.

Discover the power of our calculators:
- Cable Size Calculator: Ensure optimal cable sizing in compliance with AS3008 standards, maximizing efficiency and safety.
- Maximum Demand Calculation: Navigate complex electrical systems confidently, with calculations compliant with AS3000 standards and diversity factors.
- Arc Flash Calculator: Mitigate arc flash hazards effectively, utilizing calculations compliant with the IEEE-1584 standard for accurate risk assessment.
- Harmonics Calculator: Assess the impact of harmonics and sinusoidal waveform total harmonic distortion, optimizing system performance and stability.

At Wiringmaster, we understand the importance of regulatory compliance and project efficiency. That's why our tools are designed to streamline your workflow, providing precise results and detailed reports for informed decision-making. Elevate your electrical projects with Wiringmaster and experience the difference in efficiency and compliance." />
<meta name="description" content="Optimize your electrical installations with Wiringmaster's Cable Size Calculator, compliant with AS3008 standards. Accurately determine the appropriate cable size for your projects, ensuring safety and efficiency. Generate detailed reports for comprehensive documentation and regulatory compliance." />
<meta name="description" content="Navigate complex electrical systems confidently with Wiringmaster's Maximum Demand Calculation tool. Compliant with AS3000 standards and diversity factors, accurately assess maximum demand requirements for your projects. Streamline your workflow and ensure regulatory compliance with detailed reports and precise calculations." />
<meta name="description" content="Mitigate arc flash hazards effectively with Wiringmaster's Arc Flash Calculator. Utilizing calculations compliant with the IEEE-1584 standard, accurately assess arc flash risks and implement necessary safety measures. Ensure the safety of your personnel and equipment with detailed reports and informed decision-making." />
<meta name="description" content="Optimize system performance and stability with Wiringmaster's Harmonics Calculator. Analyze the effects of harmonics and sinusoidal waveform total harmonic distortion on your electrical installations. Generate detailed reports for comprehensive analysis and informed decision-making, ensuring the reliability and efficiency of your projects." />
<meta name="description" content="Perform accurate cable size calculations compliant with AS3008 standards and consider fault loop impedance for earth cable size calculation. Get detailed results with references to Australian standards AS3008 and AS3000. Generate comprehensive reports for your cable size calculations with Wiringmaster's Cable Size Calculator." />
<meta name="keywords" content="cable size calculation, AS3008, fault loop impedance, earth cable size, AS3000, detailed reports, Australian standards" />
<meta name="description" content="Arc Flash Calculation with IEEE 1584 2018. Perform hazard analysis based on IEEE 1584 2018. Exact PPE category calculation, enclosure size correction, and arcing current variation calculation based on IEEE 1584 2018. Generate detailed Arc Flash Assessment reports." />
<meta name="keywords" content="Arc Flash Calculation, IEEE 1584 2018, Arc Flash hazard analysis, PPE category calculation, enclosure size correction, arcing current variation, Arc Flash Assessment report" />
<meta name="description" content="Accurate maximum demand calculation based on AS3000 standard. Utilize diversity factors from AS3000 standard for load calculation. Calculate maximum demand for single domestic electrical installations or multiple living units per phase, ranging from 2 to over 21 units. Custom diversity factors and detailed maximum demand assessment reports included." />
<meta name="keywords" content="maximum demand calculation, AS3000 standard, diversity factors, load calculation, single domestic installation, living units per phase, custom diversity factor, detailed reports, maximum demand assessment" />
<meta name="description" content="Perform harmonic distortion calculations by adding multiple harmonic components and checking sinusoidal waveform distortion. Calculate total harmonic distortion (THD) and total RMS current. Generate detailed harmonics calculation reports with Wiringmaster's Harmonic Calculator." />
<meta name="keywords" content="harmonic distortion calculation, harmonic components, sinusoidal waveform distortion, total harmonic distortion, THD, RMS current, detailed harmonics calculation report" />


      </Helmet>
      <div>
        <Header />
        <CompanyBanner />
        <CalculatorSelector />
        <Prizing />
      </div>
      <Footer />
    </>
  );
}

export default HomePage;
