// PrivateRouteComponent/PrivateRoute.js

import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import Loading from '../Loading/loading';

import CableSizeCalculator from '../CableSizeCalculator/CableSizeCalculator';
import CableSizeCalculatorTrial from '../CableSizeCalculatortrial/CableSizeCalculator'; // Import the trial version

function PrivateRoute() {
  const [user, setUser] = useState(null);
  const [isPaidUser, setIsPaidUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (authUser) => {
      if (authUser) {
        const db = firebase.firestore();
        const userRef = db.collection('users').doc(authUser.uid);
        const doc = await userRef.get();
        if (doc.exists) {
          setUser(authUser);
          setIsPaidUser(doc.data().isPaidUser);
        }
      } else {
        setUser(null);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (!user) {
    return <CableSizeCalculator />;
  }

  if (isPaidUser) {
    return <CableSizeCalculator />; // Render full version for paid users
  } else {
    return <CableSizeCalculator />; // Render trial version for non-paid users
  }
}

export default PrivateRoute;
