import React, { useState,useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, FormGroup, FormControlLabel, TextField, Button, Paper, Typography } from '@mui/material';

function HarmonicCalculatorInput( {OnChange,InputDetails_1}) {
  const [phase, setPhase] = useState('Single-phase');
  const [isThreePhase, setIsThreePhase] = useState(false);
  const [isBalanced, setIsBalanced] = useState(false);
  const [isUnbalanced, setIsUnbalanced] = useState(false);
  const [phaseValues, setPhaseValues] = useState({
    1: { loadCurrent: 100, loadPowerFactor: 0.9 },
    2: { loadCurrent: 100, loadPowerFactor: 0.9 },
    3: { loadCurrent: 100, loadPowerFactor: 0.9 }
  });

  const handlePhaseChange = (event) => {
    setPhase(event.target.value);
    setIsThreePhase(event.target.value === 'Three-phase');
    // Reset balanced and unbalanced options
    setIsBalanced(true);
    setIsUnbalanced(false);
    // Reset phase values to defaults
    setPhaseValues({
      1: { loadCurrent: 100, loadPowerFactor: 1 },
      2: { loadCurrent: 100, loadPowerFactor: 1 },
      3: { loadCurrent: 100, loadPowerFactor: 1 }
    });
  };

  useEffect(() => {
    if (InputDetails_1.phase != null) {
      setPhase(InputDetails_1.phase);
      setIsThreePhase(InputDetails_1.isThreePhase);
      setIsBalanced(InputDetails_1.isBalanced);
      setIsUnbalanced(InputDetails_1.isUnbalanced);
      setPhaseValues(InputDetails_1.phaseValues);
    }
  }, [InputDetails_1]);

  const handleCheckboxChangeBalanced = (event) => {
    setIsBalanced(event.target.checked);
    if (event.target.checked) {
      setIsUnbalanced(false);
    }
  };

  const handleCheckboxChangeUnbalanced = (event) => {
    setIsUnbalanced(event.target.checked);
    if (event.target.checked) {
      setIsBalanced(false);
    }
  };

  const handlePhaseInputChange = (phase, value, type) => {
    setPhaseValues(prevValues => ({
      ...prevValues,
      [phase]: {
        ...prevValues[phase],
        [type]: value
      }
    }));
  };

  useEffect(() => {
    // Call OnChange with default values during initial render
    OnChange({ phase, isThreePhase, isBalanced, isUnbalanced, phaseValues });
  }, [phase,isThreePhase,isBalanced,isUnbalanced,phaseValues]);

  const handleSubmit = (event) => {
    event.preventDefault();
    OnChange ({phase , isThreePhase, isBalanced,isUnbalanced,phaseValues  });

  };

  // Render load current and power factor inputs for each phase if three-phase unbalanced is selected
  const renderPhaseInputs = () => {
    if (isThreePhase && isUnbalanced) {
      return (
        <>
          {[1, 2, 3].map((phase) => (
            <div key={`phase-${phase}`} style={{ display: 'flex', marginBottom: 16 }}>
              <TextField
                fullWidth
                type="number"
                label={`Load Current Phase L${phase}`}
                value={phaseValues[phase]?.loadCurrent || ''}
                onChange={(event) => handlePhaseInputChange(phase, event.target.value, 'loadCurrent')}
                sx={{ marginRight: 2 }}
              />
              <TextField
                fullWidth
                type="number"
                label={`Load Power Factor Phase L${phase}`}
                value={phaseValues[phase]?.loadPowerFactor || ''}
                onChange={(event) => handlePhaseInputChange(phase, event.target.value, 'loadPowerFactor')}
              />
            </div>
          ))}
        </>
      );
    } else {
      // Render single set of load current and power factor inputs
      return (
        <>
          <TextField
            fullWidth
            type="number"
            label="Load Current per Phase"
            value={phaseValues[1]?.loadCurrent || ''}
            onChange={(event) => handlePhaseInputChange(1, event.target.value, 'loadCurrent')}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            type="number"
            label="Load Power Factor"
            value={phaseValues[1]?.loadPowerFactor || ''}
            onChange={(event) => handlePhaseInputChange(1, event.target.value, 'loadPowerFactor')}
            sx={{ marginBottom: 2 }}
          />
        </>
      );
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom style={{  marginBottom: 16 }}>
        SUPPLY DETAILS
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="phase-label">Phase</InputLabel>
          <Select
            labelId="phase-label"
            id="phase"
            value={phase}
            onChange={handlePhaseChange}
            label="Phase"
          >
            <MenuItem value="Single-phase">Single-phase</MenuItem>
            <MenuItem value="Three-phase">Three-phase</MenuItem>
          </Select>
        </FormControl>

        {isThreePhase && (
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={isBalanced} onChange={handleCheckboxChangeBalanced} />}
              label="Balanced"
              sx={{ marginBottom: 2 }}
            />
            <FormControlLabel
              control={<Checkbox checked={isUnbalanced} onChange={handleCheckboxChangeUnbalanced} />}
              label="Unbalanced"
              sx={{ marginBottom: 4 }}
            />
          </FormGroup>
        )}

        {renderPhaseInputs()}

      </form>
    </Paper>
  );
}

export default HarmonicCalculatorInput;
