import React from 'react';
import './InputAddressComponent.css'; // Import CSS file for styling

const InputAddressComponent = ({ address, addressSuggestions, handleAddressChange, handleSelectAddress }) => {
    return (
        <div className="search-container">
            <input
                type="text"
                value={address}
                onChange={handleAddressChange}
                placeholder="Enter address"
                className="search-input"
            />
            <ul>
                {/* Display address suggestions as the user types */}
                {addressSuggestions.map((address) => (
                    <li key={address} onClick={() => handleSelectAddress(address)}>{address}</li>
                ))}
            </ul>
        </div>
    );
}

export default InputAddressComponent;









