import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

const comapnybanner = () => {

    
      
    return (
      <div class="w3-container w3-center w3-padding-48 w3-white">
        <div class="w3-xxlarge"><b>CABLE SIZE CALCULATOR  </b></div>
        <div style={{fontFamily: "Oswald", fontSize: "25px"}}>Automatic cable size calculation based on   <span class="w3-tag">AS3008.1.1</span></div>


      </div>
    )
  };
  
  export default comapnybanner;