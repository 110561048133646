// TermsOfUse.js
import React from 'react';
import { Typography, Container, Paper, Box } from '@mui/material';
import './TermsOfUse.css'; // Import your custom CSS
import Header from '../HomePage/components/header/header';
import Footer from '../HomePage/components/footer/footer';

function TermsOfUse() {
  return (

    <div>
      <Header/>
    <Container className="terms-container" maxWidth="md">
      
      <Paper elevation={3} className="terms-paper">
        <Box p={4}>
          <Typography variant="h4" gutterBottom>
            Terms of Use
          </Typography>
          
          <Typography variant="body1" paragraph>
            The Terms of Use includes all the content on this web page, including content under this heading and all subsequent headings.
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            Definitions
          </Typography>
          <Typography variant="body1" paragraph>
            The term “Services” refers to the software on the website, the software functions, the software calculations, the content of the software, any other information on the website, any reports generated by the software, and any communication to You, including writing, email, text, social media, telephone and verbal.
          </Typography>
          <Typography variant="body1" paragraph>
            The Services are provided by Wiringmaster with ABN number 35678732376 and domain name wiringmaster.org. From here on referred to as “Wiringmaster”, "Our", "We" or "Us".
          </Typography>
          <Typography variant="body1" paragraph>
            Wiringmaster will use other "Service Providers", including individuals, employees, trusts, organizations, contractors, partnerships, businesses, and companies to provide the Services. The Service Providers will be bound by their agreements, terms, and policies, as far as reasonably possible and practical, to comply with these Terms of Use.
          </Typography>
          <Typography variant="body1" paragraph>
            The term "You" or "Your" refers to You as an individual and to the business on whose behalf You are using the Services.
          </Typography>
          <Typography variant="body1" paragraph>
            The term “Privacy Policy” refers to the policy on how Wiringmaster manages Your personal information.
          </Typography>
          <Typography variant="body1" paragraph>
            The term "Stripe" refers to the Stripe.com payment system.
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            Acceptance of Terms of Use
          </Typography>
          <Typography variant="body1" paragraph>
            By accessing or using the Services, You acknowledge that You have read, understood, and agreed to the Terms of Use. And You agree to comply with the Terms of Use. If You do not agree, please do not access, or use, the Services.
          </Typography>
          <Typography variant="body1" paragraph>
            When You use the Services on behalf of a business, both You and that business accept the Terms of Use.
          </Typography>
          <Typography variant="body1" paragraph>
            Wiringmaster reserves the right to modify or update the Terms of Use at any time. It is Your responsibility to review the Terms of Use periodically. Continued use of the Services after changes imply acceptance by You of the modified Terms of Use.
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            Privacy and Data
          </Typography>
          <Typography variant="body1" paragraph>
            By using the Services, You agree that Wiringmaster can use Your personal information according to the Privacy Policy.
          </Typography>
          <Typography variant="body1" paragraph>
            Wiringmaster reserves the right to modify the Privacy Policy at any time. It is Your responsibility to review the Privacy Policy periodically. Continued use of the Services after changes imply acceptance by You of the modified Privacy Policy.
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            User Responsibilities
          </Typography>
          <Typography variant="body1" paragraph>
            You must maintain the security and confidentiality of Your account information.
          </Typography>
          <Typography variant="body1" paragraph>
            You may not share Your account login information with another person.
          </Typography>
          <Typography variant="body1" paragraph>
            You must keep Your personal and billing information on Your Wiringmaster account up to date.
          </Typography>
          <Typography variant="body1" paragraph>
            You must review the Terms of Use periodically.
          </Typography>
          <Typography variant="body1" paragraph>
            You must review the Privacy Policy periodically.
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            Using Our Services
          </Typography>
          <Typography variant="body1" paragraph>
            You must be appropriately qualified and skilled to use the Services.
          </Typography>
          <Typography variant="body1" paragraph>
            If You use the Services to provide services including design, installation, testing, consulting, inspection, verification, or training, You must be appropriately qualified and skilled to provide such services.
          </Typography>
          <Typography variant="body1" paragraph>
            You must use the Services in compliance with all applicable laws and regulations.
          </Typography>
          <Typography variant="body1" paragraph>
            You may not use the Services for any illegal or unauthorized purposes.
          </Typography>
          <Typography variant="body1" paragraph>
            You may not misuse the Services.
          </Typography>
          <Typography variant="body1" paragraph>
            You must use the Services fairly and reasonably.
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            Subscription and Payment
          </Typography>
          <Typography variant="body1" paragraph>
            Some of the premium Services require an active paid subscription to access and use.
          </Typography>
          <Typography variant="body1" paragraph>
            By purchasing a subscription, You agree to pay the specified fees according to the chosen subscription plan.
          </Typography>
          <Typography variant="body1" paragraph>
            The subscription will renew automatically at the end of the term, and You will be billed automatically.
          </Typography>
          <Typography variant="body1" paragraph>
            If the payment fails, for whatever reason, Your subscription will be terminated.
          </Typography>
          <Typography variant="body1" paragraph>
            It is Your responsibility to ensure the accuracy and completeness of Your billing information, and that You have sufficient funds in Your account to pay for the subscription renewal.
          </Typography>
          <Typography variant="body1" paragraph>
            You can update or modify Your billing details through Your account settings or by contacting Wiringmaster.
          </Typography>
          <Typography variant="body1" paragraph>
            Wiringmaster is not liable for any consequences arising from the inaccurate or outdated billing information provided by You.
          </Typography>
          <Typography variant="body1" paragraph>
            If You do not want Your subscription to renew automatically, You must cancel Your subscription. You can do this through Your account settings or by contacting Wiringmaster. In this case, You will have access to the premium Services until the subscription term expires.
          </Typography>
          <Typography variant="body1" paragraph>
            A subscription is limited to one person only. You may not share Your account login details with another person.
          </Typography>
          <Typography variant="body1" paragraph>
            If more than one person needs to use the Services, You will have to purchase more subscriptions.
          </Typography>
          <Typography variant="body1" paragraph>
            You may not use a generic login account that multiple users can access.
          </Typography>
          <Typography variant="body1" paragraph>
            Payments will be processed through the Stripe payment system, and You authorize Us to charge the applicable fees.
          </Typography>
          <Typography variant="body1" paragraph>
            Subscription fees are non-refundable, except as required by law.
          </Typography>
          <Typography variant="body1" paragraph>
            Wiringmaster reserves the right to terminate or suspend Your access to the software at any time, without prior notice, for any reason.
          </Typography>
          <Typography variant="body1" paragraph>
            Either party may terminate the subscription at any time, for any reason.
          </Typography>
          <Typography variant="body1" paragraph>
            When You terminate Your subscription, Your payment will not be refunded in full, in part, or as pro rata.
          </Typography>
          <Typography variant="body1" paragraph>
            When Wiringmaster terminates Your subscription, Your subscription may be refunded, depending on the reason for termination. This is entirely at Our discretion.
          </Typography>
          <Typography variant="body1" paragraph>
            Upon termination, Your rights to use the software will cease.
          </Typography>
          <Typography variant="body1" paragraph>
            Upon termination, Your access to the premium Services will be revoked. And You will not have access to any of Your information, including personal information, software application data, or generated reports. And Wiringmaster will not provide You with this information in any format whatsoever.
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            Intellectual Property
          </Typography>
          <Typography variant="body1" paragraph>
            All intellectual property rights, including copyrights and trademarks, related to the Services belongs to Wiringmaster.
          </Typography>
          <Typography variant="body1" paragraph>
            You may not distribute, sub-license, sell, or lease the Services.
          </Typography>
          <Typography variant="body1" paragraph>
            You may not modify, copy, reproduce, adapt, translate, or create derivative works of the Services.
          </Typography>
          <Typography variant="body1" paragraph>
            You may not reverse engineer, decompile, or disassemble the Services.
          </Typography>
          <Typography variant="body1" paragraph>
            You may not remove any proprietary notices or labels from the Services.
          </Typography>
          <Typography variant="body1" paragraph>
            You may not use the Software to infringe upon the intellectual property rights of others.
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            Updates to Services
          </Typography>
          <Typography variant="body1" paragraph>
            Wiringmaster strives to provide regular updates to improve the Services.
          </Typography>
          <Typography variant="body1" paragraph>
            Wiringmaster reserves the right to modify, add, suspend, and remove Services at any time.
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            Support
          </Typography>
          <Typography variant="body1" paragraph>
            Wiringmaster will provide reasonable support to users with a paid subscription.
          </Typography>
          <Typography variant="body1" paragraph>
            The support is limited to calculator functionality only, and not design support.
          </Typography>
          <Typography variant="body1" paragraph>
            Support is limited to email communication only.
          </Typography>
          <Typography variant="body1" paragraph>
            Support will be provided during normal business hours only.
          </Typography>
          <Typography variant="body1" paragraph>
            Wiringmaster may provide support to users without a paid subscription. This is entirely at Our discretion.
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            Limitation of Liability
          </Typography>
          <Typography variant="body1" paragraph>
            While Wiringmaster strives to provide accurate Services, the Services may contain errors or inaccuracies. Wiringmaster is not responsible for any damages or losses arising from reliance on the Services.
          </Typography>
          <Typography variant="body1" paragraph>
            Wiringmaster does not guarantee, or make any commitments, about the accuracy, reliability, availability, or completeness of the Services. Wiringmaster provides the Services “as is”.
          </Typography>
          <Typography variant="body1" paragraph>
            Wiringmaster shall not be held liable for any direct, indirect, incidental, or consequential damages arising from Your use, or inability to use, the Services directly, indirectly, or incidentally. You use the Services entirely at Your own risk.
          </Typography>
          <Typography variant="body1" paragraph>
            These terms explicitly exclude any warranty, condition or other undertaking implied at law or by custom.
          </Typography>
          <Typography variant="body1" paragraph>
            The software may include third-party content or links to external websites. Wiringmaster does not endorse or control these third-party resources and is not responsible for their content or actions.
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            Indemnification
          </Typography>
          <Typography variant="body1" paragraph>
            You agree to indemnify, defend, and hold Wiringmaster harmless from any claims, damages, losses, suits, judgments, litigation costs, or expenses (including legal fees) arising out of Your use of, or inability to use, the Services or any violation of the Terms of Use.
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            Jurisdiction
          </Typography>
          <Typography variant="body1" paragraph>
            The Terms and Conditions are governed by the laws of Western Australia.
          </Typography>
          <Typography variant="body1" paragraph>
            Any disputes arising from or related to the Services shall be subject to the exclusive jurisdiction of the courts in Western Australia.
          </Typography>
          <Typography variant="body1" paragraph>
            You cannot object to the jurisdiction of any of the courts in Western Australia on the ground that it is an inconvenient forum or that it does not have jurisdiction.
          </Typography>
          <Typography variant="body1" paragraph>
            Wiringmaster makes no representation that the Services are appropriate or available for use in particular locations and jurisdictions.
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            Communication
          </Typography>
          <Typography variant="body1" paragraph>
            Any communication from You, including comments, suggestions, and ideas for improvements are considered for improvements and updates to the Service. Wiringmaster is under no obligation to implement or act on Your comments, suggestions or ideas.
          </Typography>
          <Typography variant="body1" paragraph>
            Wiringmaster shall be free to use Your comments, suggestions, or ideas for any purpose whatsoever on an obligation-free, perpetual, royalty-free basis.
          </Typography>
          <Typography variant="body1" paragraph>
            Wiringmaster may send You emails about updates to the Services. This is entirely at Our discretion. You may opt out of some of these communications.
          </Typography>
          <Typography variant="body1" paragraph>
            Wiringmaster is under no obligation to send You emails about updates to the Services, Terms of Use or the Privacy Policy. It is Your responsibility to review these periodically.
          </Typography>
          <Typography variant="body1" paragraph>
            Wiringmaster may send You administrative emails about Your account, including billing information. This is entirely at Our discretion. You cannot opt out of these communications.
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            Entire Agreement
          </Typography>
          <Typography variant="body1" paragraph>
            These Terms of Use constitute the entire agreement between You and Wiringmaster, superseding any prior agreements, understandings, or assumptions, including written or oral relating to the Services.
          </Typography>
        </Box>
      </Paper>
    </Container>
    <Footer/>
    </div>
  );
}

export default TermsOfUse;
