import React from 'react';
import banner from './banner.png';
import mobileBanner from '../../assets/mobileBanner.png'
import Styles from './companybanner.module.scss';

const comapnybanner = () => {
const mobileWidth = 768;
    
      
    return (
      // <div class="w3-container w3-center w3-padding-48 w3-white">
      //   <div class="w3-xxxlarge"><b>WIRING MASTER</b></div>
      //   <div style={{fontFamily: "Oswald", fontSize: "20px"}}> Electrical calculations compliant with  <span class="w3-tag">Australian Standard</span></div>


      // </div>
      <div className={Styles.container}>
        <img src={mobileWidth < window.screen.width ? banner : mobileBanner} alt='banner' className='banner-image'/>
      </div>

    )
  };
  
  export default comapnybanner;