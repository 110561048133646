import React, { useEffect, useState } from 'react';
import './BuildingInsightComponent.css'; // Import the CSS file

const BuildingInsightComponent = ({ building_insights, updateindex,updatemanualPlacementMode }) => {
    const [selectedPanelCountIndex, setSelectedPanelCountIndex] = useState(0);
    const [panelCapacity, setPanelCapacity] = useState(250);
    const [manualPlacementMode, setManualPlacementMode] = useState(true);

    const toggleManualPlacementMode = () => {
        setManualPlacementMode(!manualPlacementMode);
        updatemanualPlacementMode(!manualPlacementMode);
    };

   

    // Function to handle slider change
    const handleSliderChange = (event) => {
        setSelectedPanelCountIndex(parseInt(event.target.value));
        updateindex(parseInt(event.target.value))
    };

    // Function to handle panel capacity input change
    const handlePanelCapacityChange = (event) => {
        setPanelCapacity(parseInt(event.target.value));
    };



    useEffect(() => {
        setSelectedPanelCountIndex(0);
        setPanelCapacity(250);
    }, [building_insights]);

    if (!building_insights || !building_insights.solarPotential || !building_insights.solarPotential.solarPanelConfigs) {
        return <div>Loading...</div>;
    }

    const {
        maxSunshineHoursPerYear,
        maxArrayPanelsCount,
        wholeRoofStats: { areaMeters2 },
        carbonOffsetFactorKgPerMwh,
        panelHeightMeters,
        panelWidthMeters
    } = building_insights.solarPotential;

    const solar_panel_configs = building_insights.solarPotential.solarPanelConfigs;

    return (
        <div className="building-insight-container">
            <h2>Building Insights</h2>
            <ul>
                <li>Annual Sunshine: {maxSunshineHoursPerYear} hours</li>
                <li>Maximum Array Panels Count: {maxArrayPanelsCount}</li>
                <li>Roof Area: {areaMeters2} square meters</li>
                <li>Carbon Saving: {carbonOffsetFactorKgPerMwh} kg per MWh</li>
            </ul>
            {!manualPlacementMode && (
                <div>
                    <h3>Solar Panel Configuration</h3>
                    <ul>
                        <li>Panel Height: {panelHeightMeters} meters</li>
                        <li>Panel Width: {panelWidthMeters} meters</li>
                    </ul>
                    <div className="panel-count-slider">
                        <input
                            type="range"
                            min={0}
                            max={solar_panel_configs.length - 1}
                            value={selectedPanelCountIndex}
                            onChange={handleSliderChange}
                        />
                        <span>Panel Count: {solar_panel_configs[selectedPanelCountIndex]?.panelsCount}</span>
                    </div>
                    <div className="selected-panel-count-info">
                        <p>Yearly Energy (DC): {(solar_panel_configs[selectedPanelCountIndex]?.yearlyEnergyDcKwh * (panelCapacity / 250)).toFixed(2)} kWh</p>
                    </div>
                    <div className="panel-capacity-input">
                        <label htmlFor="panelCapacity">Panel Capacity (Watts):</label>
                        <input
                            type="number"
                            id="panelCapacity"
                            value={panelCapacity}
                            onChange={handlePanelCapacityChange}
                        />
                    </div>
                </div>
            )}
            <button onClick={toggleManualPlacementMode}>
                {manualPlacementMode ? 'Disable Manual Placement Mode' : 'Enable Manual Placement Mode'}
            </button>
        </div>
    );
    
};

export default BuildingInsightComponent;
