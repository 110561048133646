import React, { useState, useEffect } from 'react';
import { Paper, FormControl, InputLabel, Select, MenuItem, Typography} from '@mui/material';
import cableData from "./Cable Details Data.json"; // Adjust the path to your JSON file
import cableDataTest from "./Cable Details Data test.json";

function CableDetailsComponent({ onChange , cableDetails1,supplyDetails }) {
  const [cableType, setCableType] = useState(Object.keys(cableDataTest[supplyDetails.phase])[0]);
  const [insulation, setInsulation] = useState('');
  const [conductor, setConductor] = useState('');
  
  useEffect(() => {

    setCableType(Object.keys(cableDataTest[supplyDetails.phase])[0])
  }, [supplyDetails]);



  useEffect(() => {
    if (cableDetails1) {
      setCableType(cableDetails1.cableType ? cableDetails1.cableType : Object.keys(cableDataTest[supplyDetails.phase])[0]);
      setInsulation(cableDetails1.insulation ? cableDetails1.insulation_display : '');
      setConductor(cableDetails1.conductor ? cableDetails1.conductor : '');
    }
  }, [cableDetails1]);

  const insulationToInstallationMapping = {
    "THERMOPLASTIC": ["THERMOPLASTIC"],
    "X-90": ["X-90", "X-HF-90", "R-EP-90", "R-CPE-90", "R-HF-90", "R-CSP-90"],
    "R-HF-110": ["R-HF-110", "R-E-110", "X-HF-110"]
  };

  const getMappedInsulation = (selectedInsulation) => {
    for (const key in insulationToInstallationMapping) {
      if (insulationToInstallationMapping[key].includes(selectedInsulation)) {
        return key; // Return the mapped key
      }
    }
    return selectedInsulation; // Return the selected insulation if no mapping is found
  };

  useEffect(() => {
  if (!cableDetails1.cableType) {
    const insulations = cableData[cableType] ? Object.keys(cableData[cableType]) : [];
    setInsulation(insulations[0] || '');
    setConductor(cableData[cableType][insulations[0]] ? cableData[cableType][insulations[0]][0] : '');

    // console.log("phase",Object.keys(phase?.cableDataTest[phase])[0])
    
  }
  }, [cableType]);

  const handleCableTypeChange = (event) => {
    const newCableType = event.target.value;
    setCableType(newCableType);
    updateParent(newCableType, getMappedInsulation(insulation), conductor,insulation);
  };

  const handleInsulationTypeChange = (event) => {
    const selectedInsulation = event.target.value;
    setInsulation(selectedInsulation); // Set the user's selection for UI display
    updateParent(cableType, getMappedInsulation(selectedInsulation), conductor,selectedInsulation);
  };

  const handleConductorTypeChange = (event) => {
    const newConductor = event.target.value;
    setConductor(newConductor);
    updateParent(cableType, getMappedInsulation(insulation), newConductor,insulation);
  };

  useEffect(() => {
    updateParent(cableType, getMappedInsulation(insulation), conductor, insulation );
  }, [cableType, insulation, conductor]);

  const updateParent = (cableType, insulation, conductor, insulation_display) => {
    onChange({ cableType, insulation, conductor, insulation_display });
  };


  // ... Handlers for onChange events

  const insulationOptions = cableData[cableType] ? Object.keys(cableData[cableType]) : [];
  const conductorOptions = insulation ? cableData[cableType][insulation] : [];

  return (
    <Paper style={{ padding: '20px', margin: '20px 0', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
      <Typography variant="h5" style={{ marginBottom: '20px' }}>CABLE DETAILS</Typography>
      <FormControl fullWidth style={{ marginBottom: '10px' }}>
        <InputLabel id="cable-type-label">Cable Type</InputLabel>
        <Select labelId="cable-type-label" value={cableType} onChange={handleCableTypeChange} label="Cable Type">
          {Object.keys(cableDataTest[supplyDetails.phase]).map((type) => (
            <MenuItem key={type} value={type}>{type}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth  style={{ marginBottom: '10px' }} disabled={!cableType}>
        <InputLabel>Insulation</InputLabel>
        <Select value={insulation} onChange={handleInsulationTypeChange} label="Insulation">
          {insulationOptions.map((insul) => (
            <MenuItem key={insul} value={insul}>{insul}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth  disabled={!insulation}>
        <InputLabel>Conductor</InputLabel>
        <Select value={conductor} onChange={handleConductorTypeChange} label="Conductor" >
          {conductorOptions.map((cond) => (
            <MenuItem key={cond} value={cond}>{cond}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Paper>
  );
}

export default CableDetailsComponent;
