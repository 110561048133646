import React, { useContext } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink,Image } from '@react-pdf/renderer';
import Button from '@mui/material/Button';
import ImageContext  from "../ImageContext/ImageContext"
// import ImageContext  from "src/components/CableSizeCalculator/components/ImageContext/ImageContext.js"

function ReportComponent ({activeResults, earthResults, loadDetails, supplyDetails, faultCurrentDetails, cableDetails, installationDetails, cableSize, protectiveDeviceDetails,isPaidUser}){

    const { editorImageUrl, setEditorImageUrl } = useContext(ImageContext);
    
    const earth_short_circuit_cap_check = earthResults.short_circuit_ok
    const earth_short_circuit_cap = Math.round(earthResults.short_circuit_cap* 100) / 100
    const Active_cable_size = activeResults.selectedCableSize
    const Number_of_active_cables = activeResults.number_of_circuits
    const Circuit_capacity_derated =  activeResults.currentCarryingCapacity.circuitRatingDerated
    const spare_capacity = activeResults.currentCarryingCapacity.spareCapacity

    const Voltage_drop =  Math.round(activeResults.voltageDrop.voltageDropCable * 100) / 100
    const voltage_drop_percent =  Math.round(activeResults.voltageDrop.voltageDropPercent * 100) / 100
    const max_length_cable =  Math.round(activeResults.voltageDrop.maxLengthOfCable * 100) / 100
    const resistance_active = activeResults.voltageDrop.resistancePerKm
    const reactance_active = activeResults.voltageDrop.reactancePerKm
    const operating_temp_calculation = Math.round(activeResults.voltageDrop.operatingTempCalculated * 100) / 100

    const short_circuit_soure = activeResults.shortCircuitCapacity.faultLevelSupply
    const short_circuit_load =  Math.round(activeResults.shortCircuitCapacity.faultLevelLoad * 100) / 100
    const K_value = activeResults.shortCircuitCapacity.KValue
    const source_impedance = activeResults.shortCircuitCapacity.sourceImpedance
    const total_impedance = activeResults.shortCircuitCapacity.totalImpedance
    const short_circuit_capacity = Math.round(activeResults.shortCircuitCapacity.ICalculation * 100) / 100

    const Earth_cable_size = earthResults.Earth_cable_size
    const number_of_earth_cable = earthResults.number_of_earth_circuits
    const resistance_earth = earthResults.resistance_per_km_earth
    const reactance_earth = earthResults.reactance_per_km_earth
    const Z_int =  Math.round(earthResults.Z_int * 100) / 100
    const Z_ext =  Math.round(earthResults.Z_ext * 100) / 100
    const loop_impedance =  Math.round(earthResults.Loop_impedance * 100) / 100
    const Max_loop_impedance =  Math.round(earthResults.Max_allowed_value * 100) / 100


    const loadCurrent = loadDetails.loadCurrent
    const powerFactor = loadDetails.powerFactor

    const phase  = supplyDetails.phase
    const voltage = supplyDetails.voltage
    const lengthOfRun = supplyDetails.lengthOfRun
    const maxVoltageDrop = supplyDetails.maxVoltageDrop

    const ambientTemperature  = installationDetails.ambientTemperature
    const finalDeratingFactor = installationDetails.finalDeratingFactor
    const selectedInstallation = installationDetails.selectedInstallation


    const faultLevel  = faultCurrentDetails.faultLevel
    const sourceImpedance = faultCurrentDetails.sourceImpedance
    const sourceImpedanceValue = faultCurrentDetails.sourceImpedanceValue

    const cableType  = cableDetails.cableType
    const conductor = cableDetails.conductor
    const insulation = cableDetails.insulation



    const is_current_capacity_ok = activeResults.currentCarryingCapacity.ok
    const is_voltage_drop_ok = activeResults.voltageDrop.ok
    const is_short_circuit_capacity_ok = activeResults.shortCircuitCapacity.ok
    const is_fault_loop_capacity_ok = earthResults.Loop_impedance_check

    const failStyle = { color: 'red' };



    const current_carrying_circuit_ref = activeResults.currentCarryingCapacity.current_capacity_ref
    const active_resistance_ref = activeResults.voltageDrop.resistance_per_km_ref
    const active_reactance_ref = activeResults.voltageDrop.reactance_per_km_ref
    const earth_resistance_ref = earthResults.resistance_per_km_earth_ref
    const earth_reactance_ref = earthResults.reactance_per_km_earth_ref
    const k_value_ref_active = activeResults.shortCircuitCapacity.KValue_ref


    const active_cablesize_mode = cableSize.activeSizeMode
    const earth_cablesize_mode = cableSize.earthSizeMode
    const protective_device_present = protectiveDeviceDetails.addUpstreamDevice



    const activeref = () => 
    active_cablesize_mode === "automatic" ? "Section 2.2 (AS3008)" : "Manual Selection";

    const earthref = () => 
    earth_cablesize_mode === "automatic" ? (protective_device_present ? "Section B4.4 (AS3000)" : "Table 5.1 (AS3000)")  : "Manual Selection";


    const deratingtrue = installationDetails.showDeratingOptions
    const deratingmainoption = installationDetails.deratingOptionsValues
    const deratingadditionaloptions = installationDetails.selectedAdditionalOptions
    const deratingadditionalvaluesair = installationDetails.deratingFactorair
    const deratingadditionalvaluessoil = installationDetails.deratingFactorsoil




    const Header = () => (
        <View style={styles.headerContainer}>
          <Text style={styles.headerLeftText}>Cable Size Report</Text>
          <Text style={styles.headerRightText}>WIRING MASTER</Text>
        </View>
      );
      
      const Footer = () => (
        <View style={styles.footerContainer}>
          <Text style={styles.footerText}>
          Disclaimer: This information does not constitute legal, professional or commercial advice. Wiring Master gives no guarantees, undertakings or warranties in
this regard, and does not accept any legal liability or responsibility for the content or the accuracy of the information so provided, or, for any loss or
damage caused arising directly or indirectly in connection with reliance on the use of such information.
          </Text>
        </View>
      );



    const styles = StyleSheet.create({
        heading: {
          fontFamily: 'Times-Roman',
          fontSize: 18,
          fontWeight: 16,
          marginBottom: 1,
        },
        normalText: {
          fontFamily: 'Times-Roman',
          fontSize: 12,
          marginBottom: 4,
        },
        row: {
          flexDirection: 'row',
          alignItems: 'stretch',
        },
        column: {
          flex: 1, // Adjust flex ratio based on your needs
          padding: 5, // Adjust padding as needed
        },
        superscript: {
            fontFamily: 'Times-Roman',
            fontSize: 8, // Smaller font size for superscript
            alignSelf: 'flex-start', // Align to top to simulate superscript position
            marginLeft: 2, // Space between main text and superscript
          },
          inlineContainer: {
            flexDirection: 'row', // Ensures inline layout
            alignItems: 'flex-start', // Aligns items to the start of the container
          },
          fullPageUnderline: {
            borderBottomWidth: 1,
            borderBottomColor: '#000',
            borderUpperColor: '#000',
            marginBottom: 1,
            marginUpper: 1,
              // Adjust as needed
          },
          image: {
            width: '100%', // Adjust as needed
            height: 'auto', // Adjust for aspect ratio
            marginVertical: 0,
            alignItems: "center"
          },
          headerContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottomWidth: 1,
            borderBottomColor: '#000',
            paddingBottom: 10, // Increase padding for more height
            paddingTop: 10, 
            paddingLeft:10,
            paddingRight: 10,   // Add top padding for symmetry
            width: '100%',     // Ensure full width
          },
          headerLeftText: {
            fontFamily: 'Times-Roman', // Match the font family
            fontSize: 18,              // Adjust font size as needed
            textAlign: 'left',
          },
          headerRightText: {
            fontFamily: 'Times-Roman', // Match the font family
            fontSize: 18,              // Adjust font size as needed
            textAlign: 'right',
          },
          footerContainer: {
            position: 'absolute', // Use absolute positioning
            bottom: 1,            // Pin to the bottom
            borderTopWidth: 1,
            borderTopColor: '#000',
            paddingTop: 4,
            width: '100%', // Ensure full width
          },
          footerText: {
            fontSize: 10,

            marginHorizontal: 12, // Adjust as needed for margins
          },
          watermark: {
            position: 'absolute',
            fontSize: 50,
            color: 'orange',
            opacity: 0.9,
            top: 170,
            left: 10,
            fontWeight: 'bold',
            transform: 'rotate(-45deg)',
          },
          watermark2: {
            position: 'absolute',
            fontSize: 50,
            color: 'orange',
            opacity: 0.9,
            top: 500,
            left: 10,
            fontWeight: 'bold',
            transform: 'rotate(-45deg)',
          },
        });


        // Additional styles as needed





    const MyDocument = () => (
            <Document>
            <Page > 
            <Header />
            {!isPaidUser ? (
          <>
            <Text style={styles.watermark}>FREE PREVIEW - NOT FOR COMMERCIAL USE</Text>
            <Text style={styles.watermark2}>PLEASE PURCHASE A SUBSCRIPTION TO REMOVE WATERMARK</Text>
          </>
        ) : null}
 
            <View style={{ padding: 20 }}>
              
                    <View>
                        <View>
                            {/* <Image style={styles.image} src={editorImageUrl}/> */}
                            <Image style={styles.image} src={editorImageUrl} />
                        </View>
                        <View >

                                <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>Cable Results</Text>
                                </View>
                                <View style={styles.row}>
                                        <View style={styles.column}>
                                            <Text style={styles.normalText}> Active Cable Size</Text>
                                            <Text style={styles.normalText}> Neutral Cable Size</Text>
                                            <Text style={styles.normalText}> Earth Cable Size</Text>
                                        </View>
                                        <View style={styles.column}>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{Number_of_active_cables} x {Active_cable_size} mm</Text>
                                            <Text style={styles.superscript}>2</Text>
                                            </View>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{Number_of_active_cables} x {Active_cable_size} mm</Text>
                                            <Text style={styles.superscript}>2</Text>
                                            </View>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{number_of_earth_cable} x {Earth_cable_size} mm</Text>
                                            <Text style={styles.superscript}>2</Text>
                                            </View>
                                        </View>
                                </View>
                        </View>

                        <View>

                                <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>Cable Details</Text>
                                </View>
                                        <View style={styles.row}>
                                                <View style={styles.column}>
                                                    <Text style={styles.normalText}>Cable Type:</Text>
                                                    <Text style={styles.normalText}>Conductor Material:</Text>
                                                    <Text style={styles.normalText}>Insulation:</Text>
                                                    <Text style={styles.normalText}>Ambient Temperature:</Text>
                                                </View>
                                                <View style={styles.column}>
                                                    <Text style={styles.normalText}>{cableType}</Text>
                                                    <Text style={styles.normalText}>{conductor}</Text>
                                                    <Text style={styles.normalText}>{insulation}</Text>
                                                    <View style={styles.inlineContainer}>
                                                        <Text style={styles.normalText}>{ambientTemperature}</Text>
                                                        <Text style={styles.superscript}>o</Text>
                                                        <Text style={styles.normalText}>C</Text>
                                                    </View>
                                                </View>
                                        </View>
                        </View>
                



                <View>
                <View style={styles.fullPageUnderline}>
                <Text style={styles.heading}>Current Carrying Capacity <Text style={is_current_capacity_ok ? {} : failStyle}>
                {is_current_capacity_ok ? "(Pass)" : "(Fail)"}
              </Text></Text>
                </View>
                        <View style={styles.row}>
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>Load Current:</Text>
                                        <Text style={styles.normalText}>Load Powerfactor:</Text>
                                        <Text style={styles.normalText}>Derating Factor:</Text>
                                    </View>
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>{loadCurrent} A</Text>
                                        <Text style={styles.normalText}>{powerFactor}</Text>
                                        <Text style={styles.normalText}>{finalDeratingFactor}</Text>
                                    </View>
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>Current Carrying Capacity (derated):</Text>
                                        <Text style={styles.normalText}>Spare Capacity:</Text>
                                        <Text style={styles.normalText}>Operating Temperature:</Text>
                                    </View>
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>{Circuit_capacity_derated} A {"\n"}{"\n"}</Text>
                                        <Text style={styles.normalText}>{spare_capacity} A</Text>
                                        <View style={styles.inlineContainer}>
                                                        <Text style={styles.normalText}>{operating_temp_calculation}</Text>
                                                        <Text style={styles.superscript}>o</Text>
                                                        <Text style={styles.normalText}>C</Text>
                                                    </View>
                                    </View>
                        </View>


                </View>

                <View>
                <View style={styles.fullPageUnderline}>
                <Text style={styles.heading}>Voltage Drop Calculation <Text style={is_voltage_drop_ok ? {} : failStyle}>
                {is_voltage_drop_ok ? "(Pass)" : "(Fail)"}
              </Text></Text>
                </View>
                        <View style={styles.row}>
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>Voltage:</Text>
                                        <Text style={styles.normalText}>Max Allowed Voltage Drop Limit:</Text>
                                        <Text style={styles.normalText}>Phase:</Text>
                                        <Text style={styles.normalText}>Length of Run:</Text>
                                    </View>
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>{voltage} V</Text>
                                    
                                        <Text style={styles.normalText}>{maxVoltageDrop} %{"\n"}{"\n"}</Text>
                                        <Text style={styles.normalText}>{phase}</Text>
                                        <Text style={styles.normalText}>{lengthOfRun} m</Text>
                                    </View>
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>Voltage Drop in Cable:</Text>
                                        <Text style={styles.normalText}>Percentage Voltage Drop:</Text>
                                        <Text style={styles.normalText}>Maximum Length of Run:</Text>
                                        <Text style={styles.normalText}>Active - Resistance:</Text>
                                        <Text style={styles.normalText}>Active - Reactance:</Text>
                                    </View>
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>{Voltage_drop} V</Text>
                                        <Text style={styles.normalText}>{voltage_drop_percent} %</Text>
                                        <Text style={styles.normalText}>{max_length_cable} m</Text>
                                        <Text style={styles.normalText}>{resistance_active} ohms/km</Text>
                                        <Text style={styles.normalText}>{reactance_active} ohm/km</Text>
                                    </View>
                         </View>


                </View>
                <View>
                <View style={styles.fullPageUnderline}>
                <Text style={styles.heading}>Short Circuit Calculation <Text style={is_short_circuit_capacity_ok ? {} : failStyle}>
                {is_short_circuit_capacity_ok ? "(Pass)" : "(Fail)"}
              </Text></Text>
                </View>
                        <View style={styles.row}>
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>Source Impedance Mode:</Text>
                                        <Text style={styles.normalText}>Fault Level at Supply:</Text>
                                        <Text style={styles.normalText}>Fault Level at Load:</Text>
                                    </View>
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>{sourceImpedance}</Text>
                                        <Text style={styles.normalText}>{faultLevel} kA</Text>
                                        <Text style={styles.normalText}>{short_circuit_load} A</Text>
                                    </View>
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>Short Circuit Capacity:</Text>
                                        <Text style={styles.normalText}>Max. Short Circuit Time:</Text>
                                        <Text style={styles.normalText}>K Value:</Text>
                                    </View>
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>{short_circuit_capacity} A</Text>
                                        <Text style={styles.normalText}>5 s</Text>
                                        <Text style={styles.normalText}> {K_value}</Text>
                                    </View>
                        </View>


                </View>
                </View>
                </View>
                <Footer />
                </Page>
                <Page >
                <Header />
                <View style={{ padding: 20 }}>
                <View>
                <View>
                <View style={styles.fullPageUnderline}>
                <Text style={styles.heading}>Earth Cable Calculation <Text style={!protective_device_present ? is_fault_loop_capacity_ok ? {} : failStyle : is_fault_loop_capacity_ok && earth_short_circuit_cap_check ? {}:failStyle }>
                {!protective_device_present ? is_fault_loop_capacity_ok ? "(Pass)" : "(Fail)" : is_fault_loop_capacity_ok && earth_short_circuit_cap_check  ? "(Pass)" : "(Fail)"}
              </Text></Text>
                </View>
                        <View style={styles.row}>
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>Earth Cable - Resistance:</Text>
                                        <Text style={styles.normalText}>Earth Cable - Reactance:</Text>
                                     
                                        {protective_device_present && (
                                        <Text style={styles.normalText}>Short Circuit Capacity:</Text>
                                        )}
                                    
                                    </View>
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>{resistance_earth} ohms/km</Text>
                                        <Text style={styles.normalText}>{reactance_earth} ohm/km</Text>
                                        {protective_device_present && (
                                        <Text style={styles.normalText}>{earth_short_circuit_cap} A</Text>
                                        )}
                                    </View>
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>Z Internal (Zint):</Text>
                                        <Text style={styles.normalText}>Z External (Zext):</Text>
                                        <Text style={styles.normalText}>Loop Impedance:</Text>
                                        <Text style={styles.normalText}>Max Loop Impedance:</Text>
                                    </View>
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>{Z_int} ohms</Text>
                                        <Text style={styles.normalText}>{Z_ext} ohms</Text>
                                        <Text style={styles.normalText}>{loop_impedance} ohms</Text>
                                        <Text style={styles.normalText}>{Max_loop_impedance} ohms</Text>
                                    </View>
                        </View>



                        <View style={styles.fullPageUnderline}>
                <Text style={styles.heading}>Derating Condtitons</Text>
                </View>
                        <View style={styles.row}>   
                        {!deratingtrue && (
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>Derating Factor Applied:</Text>

                                    </View>
                        )}
                        {!deratingtrue && (

                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>1</Text>
                                    </View>
                        )}
    



                             {installationDetails.deratingCategory === 'In Air' && deratingmainoption["INSTALLATION CONDITIONS"] === "IN TRAYS, RACKS, CLEATS OR OTHER SUPPORTS" && (
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>Type of Installation:</Text>
                                        <Text style={styles.normalText}>Installation:</Text>
                                        <Text style={styles.normalText}>Number of tiers or rows of cable supports:</Text>
                                        <Text style={styles.normalText}>Number of circuits per tier or row:</Text>
                                        <Text style={styles.normalText}>Air ambient temperature:</Text>
                                    </View>
                               )}
                             {installationDetails.deratingCategory === 'In Air' && deratingmainoption["INSTALLATION CONDITIONS"] === "IN TRAYS, RACKS, CLEATS OR OTHER SUPPORTS" && (
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>IN TRAYS, RACKS, CLEATS OR OTHER SUPPORTS</Text>
                                        <Text style={styles.normalText}>{deratingadditionaloptions["Installation"]}</Text>
                                        <Text style={styles.normalText}>{deratingadditionaloptions["Number of tiers or rows of cable supports"]}</Text>
                                        <Text style={styles.normalText}>{deratingadditionaloptions["Number of circuits per tier or row"]}</Text>
                                        <Text style={styles.normalText}>{deratingmainoption["AIR AMBIENT TEMPERATURES"]}</Text>
                                    </View>
                               )}
                             {installationDetails.deratingCategory === 'In Air' && deratingmainoption["INSTALLATION CONDITIONS"] === "IN TRAYS, RACKS, CLEATS OR OTHER SUPPORTS" && (
                                    <View style={styles.column}>

                                    <Text style={styles.normalText}>Derating Factor: {deratingadditionalvaluesair["trayInstallation"]}</Text>
                                    <Text style={styles.normalText}>Derating Factor: {deratingadditionalvaluesair["airTemperature"]}</Text>
                                    <Text style={styles.normalText}>Final Derating Factor: {installationDetails.finalDeratingFactor}</Text>
                                </View>
                               )} 

                            {installationDetails.deratingCategory === 'In Air' && deratingmainoption["INSTALLATION CONDITIONS"] === "None" && (
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>Air ambient temperature:</Text>
                                    </View>

                               )}
                             {installationDetails.deratingCategory === 'In Air' && deratingmainoption["INSTALLATION CONDITIONS"] === "None" && (
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>{deratingmainoption["AIR AMBIENT TEMPERATURES"]}</Text>
                                    </View>
                               )}
                             {installationDetails.deratingCategory === 'In Air' && deratingmainoption["INSTALLATION CONDITIONS"] === "None" && (
                                    <View style={styles.column}>
                                    <Text style={styles.normalText}>Derating Factor: {deratingadditionalvaluesair["airTemperature"]}</Text>
                                    <Text style={styles.normalText}>Final Derating Factor: {installationDetails.finalDeratingFactor}</Text>
                                </View>
                               )} 


                               {installationDetails.deratingCategory === 'In Air' && deratingmainoption["INSTALLATION CONDITIONS"] === "BUNCHED CIRCUITS" && (
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>Type of Installation:</Text>
                                        <Text style={styles.normalText}>Arrangement of cables</Text>
                                        <Text style={styles.normalText}>Number of circuits</Text>
                                        <Text style={styles.normalText}>Air ambient temperature:</Text>
                                    </View>
                               )}
                             {installationDetails.deratingCategory === 'In Air' && deratingmainoption["INSTALLATION CONDITIONS"] === "BUNCHED CIRCUITS" && (
                                    <View style={styles.column}>
                                    <Text style={styles.normalText}>BUNCHED CIRCUITS</Text>
                                    <Text style={styles.normalText}>{deratingadditionaloptions["Arrangement of cables"]}</Text>
                                    <Text style={styles.normalText}>{deratingadditionaloptions["Number of circuits"]}</Text>
                                    <Text style={styles.normalText}>{deratingmainoption["AIR AMBIENT TEMPERATURES"]}</Text>
                                </View>
                               )}
                             {installationDetails.deratingCategory === 'In Air' && deratingmainoption["INSTALLATION CONDITIONS"] === "BUNCHED CIRCUITS" && (
                                    <View style={styles.column}>
                                    <Text style={styles.normalText}>{"\n"}{"\n"}</Text>
                                    <Text style={styles.normalText}>Derating Factor: {deratingadditionalvaluesair["Bunchedcircuitinair"]}</Text>
                                    <Text style={styles.normalText}>Derating Factor:{deratingadditionalvaluesair["airTemperature"]}</Text>
                                    <Text style={styles.normalText}>Final Derating Factor: {installationDetails.finalDeratingFactor}</Text>
                                </View>
                               )}    


                              {installationDetails.deratingCategory === 'Buried' && deratingmainoption["NUMBER OF CIRCUITS"] === 1 && (
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>Number of circuits</Text>
                                        <Text style={styles.normalText}>Soil ambient temperature:</Text>
                                        <Text style={styles.normalText}>Depth of laying</Text>
                                        <Text style={styles.normalText}>Thermal resistivity of the soil</Text>
                                    </View>
                               )}
                             {installationDetails.deratingCategory === 'Buried' && deratingmainoption["NUMBER OF CIRCUITS"] === 1 && (
                                    <View style={styles.column}>
                                    <Text style={styles.normalText}>{deratingmainoption["NUMBER OF CIRCUITS"]}</Text>
                                    <Text style={styles.normalText}>{deratingmainoption["SOIL AMBIENT TEMPERATURE"]}</Text>
                                    <Text style={styles.normalText}>{deratingmainoption["DEPTH OF LAYING"]}</Text>
                                    <Text style={styles.normalText}>{deratingmainoption["THERMAL RESISTIVITY OF THE SOIL"]}</Text>
                                </View>
                               )}
                             {installationDetails.deratingCategory === 'Buried' && deratingmainoption["INSTALLATION CONDITIONS"]  === 1 && (
                                    <View style={styles.column}>
                                    <Text style={styles.normalText}>Derating Factor: {deratingadditionalvaluessoil["Numberofburiedcircuit"]}</Text>
                                    <Text style={styles.normalText}>Derating Factor: {deratingadditionalvaluessoil["Soiltemperature"]}</Text>
                                    <Text style={styles.normalText}>Derating Factor: {deratingadditionalvaluessoil["Depthoflaying"]}</Text>
                                    <Text style={styles.normalText}>Derating Factor: {deratingadditionalvaluessoil["Thermalresistivuty"]}</Text>
                                    <Text style={styles.normalText}>Final Derating Factor: {installationDetails.finalDeratingFactor}</Text>
                                </View>
                               )} 


                               {installationDetails.deratingCategory === 'Buried' && deratingmainoption["NUMBER OF CIRCUITS"] != 1 && (
                                <View style={styles.column}>
                                    <Text style={styles.normalText}>Number of circuits</Text>
                                    <Text style={styles.normalText}>Space between cable groups</Text>
                                    <Text style={styles.normalText}>Soil ambient temperature:</Text>
                                    <Text style={styles.normalText}>Depth of laying</Text>
                                    <Text style={styles.normalText}>Thermal resistivity of the soil</Text>
                                </View>
                               )}
                             {installationDetails.deratingCategory === 'Buried' && deratingmainoption["NUMBER OF CIRCUITS"] != 1 && (
                                    <View style={styles.column}>
                                    <Text style={styles.normalText}>{deratingmainoption["NUMBER OF CIRCUITS"]}</Text>
                                    <Text style={styles.normalText}>{deratingadditionaloptions["Space between cable groups"]}</Text>
                                    <Text style={styles.normalText}>{deratingmainoption["SOIL AMBIENT TEMPERATURE"]}</Text>
                                    <Text style={styles.normalText}>{deratingmainoption["DEPTH OF LAYING"]}</Text>
                                    <Text style={styles.normalText}>{deratingmainoption["THERMAL RESISTIVITY OF THE SOIL"]}</Text>
                                </View>
                               )}
                             {installationDetails.deratingCategory === 'Buried' && deratingmainoption["NUMBER OF CIRCUITS"] !=  1 && (
                                    <View style={styles.column}>
                                      <Text style={styles.normalText}>{"\n"}</Text>
                                    <Text style={styles.normalText}>Derating Factor: {deratingadditionalvaluessoil["Numberofburiedcircuit"]}</Text>
                                    <Text style={styles.normalText}>Derating Factor: {deratingadditionalvaluessoil["Soiltemperature"]}</Text>
                                    <Text style={styles.normalText}>Derating Factor: {deratingadditionalvaluessoil["Depthoflaying"]}</Text>
                                    <Text style={styles.normalText}>Derating Factor: {deratingadditionalvaluessoil["Thermalresistivuty"]}</Text>
                                    <Text style={styles.normalText}>Final Derating Factor: {installationDetails.finalDeratingFactor}</Text>
                                </View>
                               )}                              
                                
                                
                        </View>
  
           




                        <View style={styles.fullPageUnderline}>
                <Text style={styles.heading}>Standard References</Text>
                </View>
                        <View style={styles.row}>
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>Active - Cable Selection:</Text>
                                        <Text style={styles.normalText}>Active - Current Carrying Capacity:</Text>
                                        <Text style={styles.normalText}>Active - Resistance:</Text>
                                        <Text style={styles.normalText}>Active - Reactance:</Text>
                                        <Text style={styles.normalText}>Active - Voltage Drop Calculation:</Text>
                                        <Text style={styles.normalText}>Active - Operating Temperature Calculation:</Text>
                                        <Text style={styles.normalText}>Active - Short Circuit Capacity:</Text>
                                        <Text style={styles.normalText}>Active - K Value:</Text>
                                        <Text style={styles.normalText}>{"\n"}</Text>
                                        <Text style={styles.normalText}>{"\n"}</Text>
                                        <Text style={styles.normalText}>Earth - Cable Selection:</Text>
                                        <Text style={styles.normalText}>Earth - Resistance:</Text>
                                        <Text style={styles.normalText}>Earth - Reactance:</Text>
                                        <Text style={styles.normalText}>Earth Fault Loop Impedance:</Text>
                                        <Text style={styles.normalText}>Max Allowed Earth Fault Loop Impedance:</Text>
                                    </View>
                                    <View style={styles.column}>
                                        <Text style={styles.normalText}>{activeref()}</Text>
                                        <Text style={styles.normalText}>{current_carrying_circuit_ref} (AS3008.1.1)</Text>
                                        <Text style={styles.normalText}>{active_resistance_ref} (AS3008.1.1)</Text>
                                        <Text style={styles.normalText}>{active_reactance_ref} (AS3008.1.1)</Text>
                                        <Text style={styles.normalText}>Section 4.5 (AS3000)</Text>
                                        <Text style={styles.normalText}>Section 4.4 (AS3000)</Text>
                                        <Text style={styles.normalText}>Section 5.3 (AS3008)</Text>
                                        <Text style={styles.normalText}>Table 52, col 2 (AS3008)</Text>
                                        <Text style={styles.normalText}>{"\n"}</Text>
                                        <Text style={styles.normalText}>{"\n"}</Text>
                                        <Text style={styles.normalText}>{earthref()}</Text>
                                        <Text style={styles.normalText}>{earth_resistance_ref}</Text>
                                        <Text style={styles.normalText}>{earth_reactance_ref}</Text>
                                        <Text style={styles.normalText}>Section B4.4 (AS3000)</Text>
                                        <Text style={styles.normalText}>Section B4.5 (AS3000)</Text>
                                    </View>
                        </View>


                </View>

                
                
                </View>
                
                </View>
                <Footer />
            </Page>
            {/* More Pages as needed */}
            </Document>
            
            
      );

      return (
        <PDFDownloadLink
            document={<MyDocument />}
            fileName="Cable Report.pdf"
        >
            {({ blob, url, loading, error }) =>
                loading ? (
                    <Button variant="contained" color="primary" disabled>
                        Loading document...
                    </Button>
                ) : (
                    <Button variant="contained" color="primary">
                        Download Report
                    </Button>
                )
            }
        </PDFDownloadLink>
    );
}


export default ReportComponent;