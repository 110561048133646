import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import LoadComponent from "./components/LoadComponent/LoadComponent.js";
import SupplyDetailsComponent from "./components/SupplyDetailsComponent/SupplyDetailsComponent.js";
import FaultCurrentComponent from "./components/FaultCurrentComponennt/FaultCurrentComponennt.js";
import CableDetailsComponent from "./components/CableDetailsComponent/CableDetailsComponent.js";
import InstallationComponent from "./components/InstallationComponent/InstallationComponent.js";
import ResultsComponent from "./components/ResultsComponent/ResultsComponent.js";
import CableEditorComponent from "./components/CableEditorComponent/CableEditorComponent.js";
import ReportComponent from "./components/ReportComponent/ReportComponent.js";
import ImageContext from "./components/ImageContext/ImageContext.js";
import CableSizingComponent from "./components/CableSizeComponent/CableSizeComponet.js";
import ProtectionDeviceComponent from "./components/ProtectiveDeviceComponent/ProtectiveDeviceComponent.js";
import { Link } from "react-router-dom";
import Header from "../HomePage/components/header/header.js";
import Footer from "../HomePage/components/footer/footer.js";
import Companybanner from "./components/companybanner/companybanner.js";
import SaveCalculation from "./components/SaveCalculation/SaveCalculation.js";
import OpenCalculation from "./components/OpenCalculationComponent/OpenCalculation.js";
import Styles from "../CableSizeCalculatortrial/cableSizeCalculator.module.scss";
import firebase from 'firebase/compat/app';
import { ToastContainer, toast } from "react-toastify";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import CableSizeCalculatorhelp from './components/CableSizeHelpComponent/CableSizeHelpComponent.js'

function App() {
  const [loadDetails, setLoadDetails] = useState({});
  const [loadDetails1, setLoadDetails1] = useState({});
  const [supplyDetails, setSupplyDetails] = useState({});
  const [supplyDetails1, setSupplyDetails1] = useState({});
  const [faultCurrentDetails, setFaultCurrentDetails] = useState({});
  const [faultCurrentDetails1, setFaultCurrentDetails1] = useState({});
  const [cableDetails, setCableDetails] = useState({});
  const [cableDetails1, setCableDetails1] = useState({});
  const [installationDetails, setInstallationDetails] = useState({});
  const [installationDetails1, setInstallationDetails1] = useState({});
  const [results, setResults] = useState({
    active_Result: null,
    earth_Results: null,
  });
  const editorRef = useRef(null);
  const [editorSize, setEditorSize] = useState({ width: 0, height: 0 });
  const [editorImageUrl, setEditorImageUrl] = useState("");
  const [cableSize, setCableSize] = useState({});
  const [cableSize1, setCableSize1] = useState({});
  const [protectiveDeviceDetails, setprotectiveDeviceDetails] = useState({});
  const [protectiveDeviceDetails1, setprotectiveDeviceDetails1] = useState({});
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [user, setUser] = useState(null);
  const [isPaidUser, setIsPaidUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  // Add an event listener to handle window resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpenCalculation = (data) => {
    // Update your state with the loaded data
    setLoadDetails(data.loadDetails);
    setLoadDetails1(data.loadDetails);
    setSupplyDetails(data.supplyDetails);
    setSupplyDetails1(data.supplyDetails);
    setFaultCurrentDetails(data.faultCurrentDetails);
    setFaultCurrentDetails1(data.faultCurrentDetails);
    setCableDetails(data.cableDetails);
    setCableDetails1(data.cableDetails);
    setInstallationDetails(data.installationDetails);
    setInstallationDetails1(data.installationDetails);
    setCableSize(data.cableSize);
    setCableSize1(data.cableSize);
    setprotectiveDeviceDetails(data.protectiveDeviceDetails);
    setprotectiveDeviceDetails1(data.protectiveDeviceDetails);
  };

  console.log("loadDetails1:", loadDetails1);

  const handleEditorImageUpdate = (url) => {
    setEditorImageUrl(url);
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (authUser) => {
      if (authUser) {
        const db = firebase.firestore();
        const userRef = db.collection('users').doc(authUser.uid);
        const doc = await userRef.get();
        if (doc.exists) {
          setUser(authUser);
          setIsPaidUser(doc.data().isPaidUser);
        }
      } else {
        setUser(null);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Only proceed if all data is present
    if (
      Object.keys(loadDetails).length > 0 &&
      Object.keys(supplyDetails).length > 0 &&
      Object.keys(faultCurrentDetails).length > 0 &&
      Object.keys(cableDetails).length > 0 &&
      Object.keys(installationDetails).length > 0 &&
      Object.keys(cableSize).length > 0
    ) {
      const payload = {
        loadDetails,
        supplyDetails,
        faultCurrentDetails,
        cableDetails,
        installationDetails,
        cableSize,
        protectiveDeviceDetails,
      };

      axios
        .post(
          "https://app.wiringmaster.org/Calculate",
          payload
        )
        .then((response) => {
          setResults(response.data); // Set the results in state
          // Handle the response data here
        })
        .catch((error) => {
          console.error("There was an error!", error);
          setResults({ active_Result: null, earth_Results: null });
        });
    }
  }, [
    loadDetails,
    supplyDetails,
    faultCurrentDetails,
    cableDetails,
    installationDetails,
    cableSize,
    protectiveDeviceDetails,
  ]);

  const handleSubmit = () => {
    console.log("calculateResults called with:", {
      loadDetails,
      supplyDetails,
      faultCurrentDetails,
      cableDetails,
      installationDetails,
    });

    const payload = {
      loadDetails,
      supplyDetails,
      faultCurrentDetails,
      cableDetails,
      installationDetails,
      cableSize,
      protectiveDeviceDetails,
    };

    axios
      .post("http://127.0.0.1:5000/Calculate", payload)
      .then((response) => {
        setResults(response.data); // Set the results in state
        // Handle the response data here
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setResults({ active_Result: null, earth_Results: null });
      });
  };

  useEffect(() => {
    const updateSize = () => {
      if (editorRef.current) {
        setTimeout(() => {
          setEditorSize({
            width: editorRef.current.offsetWidth,
            height: editorRef.current.offsetHeight,
          });
        }, 0); // Delay of 100ms
      }
    };

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, [results]);

  const mobileScreenWidthThreshold = 1000;

  // Function to check if the screen is in mobile view
  const isMobileView = screenWidth < mobileScreenWidthThreshold;

  // Style object for the ResultsComponent
  const resultsComponentStyle = {
    overflowY: "auto",
    maxWidth: isMobileView ? screenWidth - 170 : "auto", // Adjust maxWidth for mobile view
  };

  return (
    <ImageContext.Provider value={{ editorImageUrl, setEditorImageUrl }}>
            <Helmet>
        <title>Cable Size Calculator Based on AS3008</title>
        <meta name="description" content="Perform accurate cable size calculations compliant with AS3008 standards and consider fault loop impedance for earth cable size calculation. Get detailed results with references to Australian standards AS3008 and AS3000. Generate comprehensive reports for your cable size calculations with Wiringmaster's Cable Size Calculator." />
<meta name="keywords" content="cable size calculation, AS3008, fault loop impedance, earth cable size, AS3000, detailed reports, Australian standards" />

      </Helmet>
      <div style={{ opacity: 10 }} className="w3-light-grey">
        <Header />
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
        <Companybanner />

        <div className={Styles.mainContainer}>
          {editorImageUrl && results.active_Result && (
            <div className={Styles.buttonContainer}>
              <div className={Styles.downloadButton}>
                <ReportComponent
                  activeResults={results.active_Result}
                  earthResults={results.earth_Results}
                  loadDetails={loadDetails}
                  supplyDetails={supplyDetails}
                  faultCurrentDetails={faultCurrentDetails}
                  cableDetails={cableDetails}
                  installationDetails={installationDetails}
                  cableSize={cableSize}
                  protectiveDeviceDetails={protectiveDeviceDetails}
                  image={editorImageUrl}
                  isPaidUser={isPaidUser}
                />
              </div>
              <div className={Styles.saveCalculationButton}>
              {isPaidUser ? (
                <SaveCalculation
                  loadDetails={loadDetails}
                  supplyDetails={supplyDetails}
                  faultCurrentDetails={faultCurrentDetails}
                  cableDetails={cableDetails}
                  installationDetails={installationDetails}
                  cableSize={cableSize}
                  protectiveDeviceDetails={protectiveDeviceDetails}
                />
              ) : (
                <div  onClick={() => {
                  toast.error("Please purchase a subscription to access this feature");
                }}>
                      <div style={{ pointerEvents: "none", opacity: 0.3 }}>
                      <SaveCalculation
                  loadDetails={loadDetails}
                  supplyDetails={supplyDetails}
                  faultCurrentDetails={faultCurrentDetails}
                  cableDetails={cableDetails}
                  installationDetails={installationDetails}
                  cableSize={cableSize}
                  protectiveDeviceDetails={protectiveDeviceDetails}
                />
                      </div>
      </div>
  )}
              </div>
              <div className={Styles.OpenCalculationButton}>
              {isPaidUser ? (
                <OpenCalculation onOpenCalculation={handleOpenCalculation} />
              ) : (
                <div  onClick={() => {
                  toast.error("Please purchase a subscription to access this feature");
                }}>
                                        <div style={{ pointerEvents: "none", opacity: 0.3 }}>
                                        <OpenCalculation onOpenCalculation={handleOpenCalculation} />
                                        </div>
      </div>
  )}

              </div>
            </div>
          )}

          <div className={Styles.calculationContainer}>
            {/* Cable Editor Component */}
<Grid container spacing={3}>
            <Grid
              item
              md={8}
              xs={0}
              ref={editorRef}
              className={Styles.editorContainer1}
            >
              {results.active_Result && results.earth_Results && (
                <CableEditorComponent
                  activeResults={results.active_Result}
                  earthResults={results.earth_Results}
                  loadDetails={loadDetails}
                  supplyDetails={supplyDetails}
                  faultCurrentDetails={faultCurrentDetails}
                  cableDetails={cableDetails}
                  installationDetails={installationDetails}
                  size={editorSize}
                  onImageUpdate={handleEditorImageUpdate}
                />
              )}
            </Grid>
            <Grid
              item
              md={4} >
            {/* Results Component */}
            <div className={Styles.resultContainer}>
              {results.active_Result && results.earth_Results && (
                <div className={Styles.resultTable}>
                  <ResultsComponent
                    activeResults={results.active_Result}
                    earthResults={results.earth_Results}
                    cableSize={cableSize}
                    protectiveDeviceDetails={protectiveDeviceDetails}
                  />
                </div>
              )}
            </div>
            </Grid>
            </Grid>
          </div>
          <div className={Styles.editorContainer}>
            <div className={Styles.loadContainer}>
              <LoadComponent
                onChange={setLoadDetails}
                loadDetails1={loadDetails1}
              />
            </div>
            <div className={Styles.supplyDetailsComponent}>
              <SupplyDetailsComponent
                onChange={setSupplyDetails}
                supplyDetails1={supplyDetails1}
              />
            </div>
            <div className={Styles.cableDetailsComponent}>
            {supplyDetails.phase && (
              <CableDetailsComponent
                onChange={setCableDetails}
                cableDetails1={cableDetails1}
                supplyDetails={supplyDetails}
              />
            )}
            </div>
          </div>
          <div className={Styles.editorContainer2}>
            <div className={Styles.installationComponent}>
              <InstallationComponent
                cableType={cableDetails.cableType}
                insulation={cableDetails.insulation}
                onChange={setInstallationDetails}
                installationDetails1={installationDetails1}
              />
            </div>
            <div className={Styles.faultCurrentContainer}>
              <FaultCurrentComponent
                onChange={setFaultCurrentDetails}
                faultCurrentDetails1={faultCurrentDetails1}
              />
            </div>
            <div className={Styles.protectionDeviceContainer}>
              <ProtectionDeviceComponent
                onChange={setprotectiveDeviceDetails}
                protectiveDeviceDetails1={protectiveDeviceDetails1}
                loadDetails={loadDetails}
              />
            </div>
            <div className={Styles.cableSizingContainer}>
              <CableSizingComponent
                cableDetails={cableDetails}
                installationDetails={installationDetails}
                onChange={setCableSize}
                cableSize1={cableSize1}
              />
            </div>
          </div>
        </div>
        <CableSizeCalculatorhelp/>
        <Footer />
      </div>
    </ImageContext.Provider>
  );
}

export default App;
