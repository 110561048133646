import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

const ResultComponent = ({ results }) => {
  const classes = useStyles();

  const getPPECategory = (incidentEnergy) => {
    if (incidentEnergy <= 1.2) {
      return "No PPE Required (Incident Energy < 1.2 cal/cm2";
    } else if (incidentEnergy <= 4) {
      return "Category 1";
    } else if (incidentEnergy <= 8) {
      return "Category 2";
    }else if (incidentEnergy <= 25) {
      return "Category 3";
    } else if (incidentEnergy <= 40) {
      return "Category 4";
    } else {
      return "No Category Applicable (Incident Energy > 40 cal/cm2";
    }
  };

  return (
    <div>
    <Paper style={{ padding: '20px', margin: '20px 0', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
      <Typography variant="h5" style={{ marginBottom: '20px' }} gutterBottom>
        CALCULATION RESULTS
      </Typography>
      <TableContainer component={Paper} style={{ maxHeight: '45vh'  }}> {/* Adjust maxHeight as needed */}
      <Table aria-label="simple table">
      <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em',backgroundColor: '#f5f5f5',color: '#333',  }}><Typography>Parameter</Typography></TableCell>
                            <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em',backgroundColor: '#f5f5f5',color: '#333',  }}><Typography>Value</Typography></TableCell>
                            <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em',backgroundColor: '#f5f5f5',color: '#333',  }}><Typography>Unit</Typography></TableCell>
                        </TableRow>
          </TableHead>
          <TableBody>
          <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Arcing Current :</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{Math.round(results.Iarc* 100)/100}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>kA<sup></sup></Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Incident Energy :</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{Math.round(results.Earc* 100)/100}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>J/cm<sup>2</sup></Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Incident Energy :</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{Math.round(results.Earc_cal* 100)/100}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>cal/cm<sup>2</sup></Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Arc Flash Boundary :</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{Math.round(results.AFB* 100)/100}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>mm<sup></sup></Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Arcing Current Reduced :</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{Math.round(results.Iarc_reduced* 100)/100}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>kA<sup></sup></Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Incident Energy Reduced :</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{Math.round(results.Earc_reduced* 100)/100}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>J/cm<sup>2</sup></Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Incident Energy Reduced :</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{Math.round(results.Earc_reduced_cal* 100)/100}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>cal/cm<sup>2</sup></Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Arc Flash Boundary Reduced :</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{Math.round(results.AFB_reduced* 100)/100}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>mm<sup></sup></Typography></TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>PPE Category :</Typography></TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{getPPECategory(Math.max(results.Earc_cal, results.Earc_reduced_cal))}</Typography></TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography></Typography></TableCell>
              </TableRow>
            

          </TableBody>
      </Table>
      </TableContainer>
      </Paper>
    </div>
    // <Paper className={classes.root}>
    //   <Typography variant="h6" gutterBottom>Results</Typography>
    //   <Typography variant="body1">Iarc: {results.Iarc} kA</Typography>
    //   <Typography variant="body1">Iarc Reduced: {results.Iarc_reduced} kA</Typography>
    //   <Typography variant="body1">Earc: {results.Earc} J/cm2</Typography>
    //   <Typography variant="body1">Earc Reduced: {results.Earc_reduced} J/cm2</Typography>
    //   <Typography variant="body1">Earc (Calculated): {results.Earc_cal} Cal/cm2</Typography>
    //   <Typography variant="body1">Earc Reduced (Calculated): {results.Earc_reduced_cal} Cal/cm2</Typography>
    //   <Typography variant="body1">AFB: {results.AFB} mm</Typography>
    //   <Typography variant="body1">AFB Reduced: {results.AFB_reduced} mm</Typography>
    //   {/* Add more result fields as needed */}
    // </Paper>
  );
};

export default ResultComponent;
