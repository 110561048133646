import React from 'react'
import Lottie from 'react-lottie'
import loading from '../HomePage/components/calculatorselector/lottie/Loading.json'

const Loading = () => {

    const LoadingJson = {
        loop: true,
        autoplay: true,
        animationData: loading,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      };
  return (
    <div>
        <Lottie options={LoadingJson} width={600} height={600}/>
    </div>
  )
}

export default Loading