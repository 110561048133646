import React, { useState } from 'react';
import { Paper, Typography, Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TeX from '@matejmazur/react-katex';
import 'katex/dist/katex.min.css'; // Import KaTeX CSS

const ArcFlashClculatorHelp = () => {
    return (
        <Paper style={{ padding: '20px', margin: '20px 0', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
            <Typography variant="h5" style={{ marginBottom: '20px' }}>HELP MENU</Typography>
            <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">INPUTS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">SUPPLY DETAILS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Supply Voltage (V)</Typography>
                    <Typography variant="body1" style={{ marginLeft:30 }}>Enter the 3 phase, line to line voltage in volts</Typography>
                    <Typography variant="h8" style={{ fontWeight: 'bold' }}>Bolted Fault Current (kA)</Typography>
                    <Typography variant="body1" style={{ marginLeft:30 }}>Enter the maximum short circuit current assuming zero impedance exists at the point of the fault.</Typography>
                    <Typography variant="h8" style={{ fontWeight: 'bold' }}>Working Distance (mm)</Typography>
                    <Typography variant="body1" style={{ marginLeft:30 }}>The distance between the potential arc source and the face and chest of the worker
performing the task.</Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">ENCLOSURE DETAILS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Enclosure Width (mm)</Typography>
                    <Typography variant="body1" style={{ marginLeft:30 }}>Enter the actual enclosure width in mm</Typography>
                    <Typography variant="h8" style={{ fontWeight: 'bold' }}>Enclosure Height (mm)</Typography>
                    <Typography variant="body1" style={{ marginLeft:30 }}>Enter the actual enclosure height in mm</Typography>
                    <Typography variant="h8" style={{ fontWeight: 'bold' }}>Enclosure Depth (mm)</Typography>
                    <Typography variant="body1" style={{ marginLeft:30 }}>Enter the actual enclosure depth in mm</Typography>
                    <Typography variant="h8" style={{ fontWeight: 'bold' }}>Electrode Configuration</Typography>
                    <Typography variant="body1" style={{ marginLeft:30 }}>The orientation and arrangement of the electrodes used</Typography>
                    <Typography variant="h8" style={{ fontWeight: 'bold' }}>Conductor Gap (mm)</Typography>
                    <Typography variant="body1" style={{ marginLeft:30 }}>The gap distance between conductors (electrodes) in mm</Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">PROTECTIVE DEVICE DETAILS</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography variant="h8" style={{ fontWeight: 'bold' }}>Arc Duration (ms)</Typography>
                    <Typography variant="body1" style={{ marginLeft:30 }}>The total time between the beginning of the arcing current and the final interruption of the
circuit at rated voltage in miliseconds.</Typography>
                    <Typography variant="h8" style={{ fontWeight: 'bold' }}>Arc Duration Reduced (ms)</Typography>
                    <Typography variant="body1" style={{ marginLeft:30 }}>The total time between the beginning of the reduced arcing current and the final interruption of the
circuit at rated voltage in miliseconds.</Typography>
                    </AccordionDetails>
                </Accordion>

                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5"> TYPICAL GAP AND ENCLOSURE SIZE</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <TableContainer component={Paper}>
    <Table style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
        <TableHead>
            <TableRow>
                <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px' }}>Equipment class</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px' }}>Typical bus gaps (mm)</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px' }}>Enclosure Size (H × W × D)</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>15 kV switchgear</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>152</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>1143 mm × 762 mm × 762 mm</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>15 kV MCC</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>152</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>914.4 mm × 914.4 mm × 914.4 mm</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>5 kV switchgear</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>104</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>914.4 mm × 914.4 mm × 914.4 mm</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>5 kV switchgear</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>104</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>1143 mm × 762 mm × 762 mm</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>5 kV MCC</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>104</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>660.4 mm × 660.4 mm × 660.4 mm</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>Low-voltage switchgear</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>32</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>508 mm × 508 mm × 508 mm</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>Shallow low-voltage MCCs and panelboards</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>25</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>355.6 mm × 304.8 mm × ≤203.2 mm</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>Deep low-voltage MCCs and panelboards</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>25</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>355.6 mm × 304.8 mm × > 203.2 mm</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>Cable junction box</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>13</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>355.6 mm × 304.8 mm × ≤203.2 mm or 355.6 mm × 304.8 mm × >203.2 mm</TableCell>
            </TableRow>
        </TableBody>
    </Table>
</TableContainer>


                    </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5"> TYPICAL WORKING DISTANCE</Typography>
                    </AccordionSummary>
                
                <AccordionDetails>
                <TableContainer component={Paper}>
    <Table style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
        <TableHead>
            <TableRow>
                <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px' }}>Equipment class</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px' }}>Working Distance (mm)</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>15 kV switchgear</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>914.4</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>15 kV MCC</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>914.4</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>5 kV switchgear</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>914.4</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>5 kV MCC</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>914.4</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>Low-voltage switchgear</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>609.6</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>Shallow low-voltage MCCs and panelboards</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>457.2</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>Deep low-voltage MCCs and panelboards</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>457.2</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>Cable junction box</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>457.2</TableCell>
            </TableRow>
        </TableBody>
    </Table>
</TableContainer>
                </AccordionDetails>
                
                </Accordion>

        
            </Paper>



)}

export default ArcFlashClculatorHelp;