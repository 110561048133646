// Function to calculate solar energy production
export const calculateSolarEnergyProduction = (
    monthlyBill,
    energyCostPerKwh,
    solarInsentives,
    installationCostPerWatt,
    dcToAcConversionFactor,
    panelEfficiencyDepreciationFactor,
    panelLifetime,
    energyCostPerYear,
    discountRatePerYear,
    financialanalysisdata
) => {
    // Calculate average monthly consumption
    const averageMonthlyConsumption = monthlyBill / energyCostPerKwh;


    // Calculate average yearly consumption
    const averageYearlyConsumption = averageMonthlyConsumption * 12;
 


    // Calculate total DC energy from panels
    const totalDCEnergyFromPanels = financialanalysisdata.totalenergymannual;


    // Calculate AC energy from panels for the first year
    const acEnergyFirstYear = totalDCEnergyFromPanels * dcToAcConversionFactor/100;


    const acEnergyPerYear = [0]; // Initialize with 0 energy for year 0
    let totalEnergyProducedIn20Years = 0; // Initialize total energy produced
    
    for (let i = 1; i <= panelLifetime; i++) {
        let acEnergyThisYear;
        if (i === 1) {
            acEnergyThisYear = acEnergyFirstYear; // Year 1 energy production
        } else {
            acEnergyThisYear = acEnergyPerYear[i - 1] * (1 - (panelEfficiencyDepreciationFactor/100)); // Calculate energy for subsequent years
        }
        
        acEnergyPerYear.push(acEnergyThisYear); // Push energy for current year to array
        totalEnergyProducedIn20Years += acEnergyThisYear; // Update total energy produced
    }
    




    // Calculate electricity bill for each year with solar
// Calculate electricity bill for each year with solar
const electricityBillWithSolarPerYear = [];
let totalBillWithSolar = 0;

for (let i = 0; i <= panelLifetime; i++) {
    let bill;

    if (i === 0) {
        bill = 0; // Year 0 has no energy production, so the bill is 0
    } else if (i === 1) {
        bill = (averageYearlyConsumption - acEnergyPerYear[i]) * energyCostPerKwh; // Year 1 bill calculation
    } else {
        bill = (averageYearlyConsumption - acEnergyPerYear[i]) * energyCostPerKwh * (Math.pow((1 + (energyCostPerYear / 100)), i-1)/Math.pow((1 + (discountRatePerYear / 100)), i-1)); // Remaining years bill calculation
    }

    electricityBillWithSolarPerYear.push(bill); // Push bill for current year to array
    totalBillWithSolar += bill; // Update total bill with solar
}




// Calculate electricity bill for each year without solar
const electricityBillWithoutSolarPerYear = [];
const electricityBillWithoutSolarPerYear_eachyear = [];
let totalBillWithoutSolar = 0;

for (let i = 0; i <= panelLifetime; i++) {
    let bill;
    let bill1

    if (i === 0) {
        bill = 0; // Year 0 has no energy production, so the bill is 0
        bill1 = 0;
    } else if (i === 1) {
        bill = averageYearlyConsumption * energyCostPerKwh; // Year 1 bill calculation
        bill1 = averageYearlyConsumption * energyCostPerKwh; // Year 1 bill calculation
    } else {
        bill = (averageYearlyConsumption * energyCostPerKwh * (Math.pow((1 + (energyCostPerYear / 100)), i-1)/Math.pow((1 + (discountRatePerYear / 100)), i-1))) + electricityBillWithoutSolarPerYear[i-1]; // Remaining years bill calculation
        bill1 = (averageYearlyConsumption * energyCostPerKwh * (Math.pow((1 + (energyCostPerYear / 100)), i-1)/Math.pow((1 + (discountRatePerYear / 100)), i-1)))
    }

    electricityBillWithoutSolarPerYear_eachyear.push(bill1)

    electricityBillWithoutSolarPerYear.push(bill); // Push bill for current year to array
  
}

totalBillWithoutSolar = electricityBillWithoutSolarPerYear[electricityBillWithoutSolarPerYear.length -1]


    // Calculate total panel installation cost
    const panelCount = financialanalysisdata.manualPlacementDetails.length;
    const totalPanelInstallationCost = (panelCount * financialanalysisdata.pannelcapacity) * installationCostPerWatt;

    // Calculate total investment from the user using solar panels each year
// Calculate total investment from the user using solar panels each year
const totalSolarInvestmentPerYear = [];


for (let i = 0; i <= panelLifetime; i++) {
    let investment;

    if (i === 0) {
        investment = 0; // Year 0 has no solar investment
    } else if (i === 1) {
        investment = totalPanelInstallationCost + electricityBillWithSolarPerYear[i] - solarInsentives; // Year 1 investment calculation

    } else {
        investment = totalSolarInvestmentPerYear[i - 1] + electricityBillWithSolarPerYear[i]; // Consecutive years investment calculation

    }


    totalSolarInvestmentPerYear.push(investment); // Push investment for current year to array
  
}

let totalSolarInvestment = totalSolarInvestmentPerYear[totalSolarInvestmentPerYear.length-1];

    // Calculate total savings by the user
    const totalSavings = totalBillWithoutSolar - totalSolarInvestment;

 

    // Return the result as a JSON object
    return {
        averageMonthlyConsumption,
        averageYearlyConsumption,
        acEnergyPerYear,
        electricityBillWithSolarPerYear,
        electricityBillWithoutSolarPerYear_eachyear,
        electricityBillWithoutSolarPerYear,
        totalEnergyProducedIn20Years,
        totalBillWithSolar,
        totalBillWithoutSolar,
        totalPanelInstallationCost,
        totalSolarInvestmentPerYear,
        totalSolarInvestment,
        totalSavings,

        // Other results...
    };
};
