import React from 'react';
import Button from '@mui/material/Button';

function OpenCalculation({ onOpenCalculation }) {
  const handleOpenFile = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.json';
    input.onchange = (event) => handleFileSelection(event.target.files[0]);
    input.click();
  };

  const handleFileSelection = (file) => {
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const jsonData = JSON.parse(event.target.result);
        onOpenCalculation(jsonData);
      } catch (error) {
        console.error('Error parsing JSON data:', error);
        // Handle invalid JSON data
      }
    };

    reader.readAsText(file);
  };

  return (
    <div>
            <Button variant="contained" color="primary" onClick={handleOpenFile}>
        OPEN CALCULATION
      </Button>
    </div>
  );
}

export default OpenCalculation;