import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cableData from './CableResistance.json'; // Ensure this path is correct
import { CloudUpload, Delete } from "@material-ui/icons";
import { BarChart, XAxis, YAxis, Tooltip, Bar, LineChart, Line } from 'recharts';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Papa from 'papaparse';
import {
  Paper,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  IconButton,
  Grid,
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const DCArcFlashCalculator = ({ onUpdate, formData1 }) => {
const classes = useStyles();
const [uploadedFiles, setUploadedFiles] = useState([]);
const [headers, setHeaders] = useState({}); // Store column headers for each file
const [selectedHeaders, setSelectedHeaders] = useState([]); // Store selected header per file
const [chartData, setChartData] = useState({}); // Store chart data for each file
const [numLoads, setNumLoads] = useState(4); // Default value for number of loads
const [numSimulations, setNumSimulations] = useState(5000); // Default value for number of simulations


const notifyParent = () => {
  // Prepare the selected profiles data to send to the parent
  const profilesData = uploadedFiles.map((file, index) => ({
    fileName: file.name,
    selectedHeader: selectedHeaders[index],
    data: chartData[file.name], // Corresponding data for the selected header
    numload: numLoads,
    numsim: numSimulations
  }));

  // Notify the parent with the profiles data
  onUpdate(profilesData); // Invoke the parent callback with the updated data
};

const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    setUploadedFiles((prevFiles) => [...prevFiles, file]);

    // Parse the CSV file to extract headers
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const headersFromFile = Object.keys(results.data[0]);
        setHeaders((prevHeaders) => ({
          ...prevHeaders,
          [file.name]: headersFromFile,
        }));
      },
    });
  }
};

const calculateStats = (data) => {
  if (!data || data.length === 0) return { max: 0, min: 0, mean: 0 };

  // Extract numerical values from the 'y' property
  const values = data.map((d) => parseFloat(d.y)).filter((v) => !isNaN(v));

  if (values.length === 0) return { max: 0, min: 0, mean: 0 }; // Handle empty or invalid data

  const max = Math.max(...values);
  const min = Math.min(...values);
  const mean = (values.reduce((sum, val) => sum + val, 0) / values.length).toFixed(2);

  return { max, min, mean };
};

const handleHeaderSelection = (fileIndex, header) => {
  const file = uploadedFiles[fileIndex];
  setSelectedHeaders((prev) => {
    const updated = [...prev];
    updated[fileIndex] = header;
    return updated;
  });

  // Parse the CSV file to extract column data
  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    complete: (results) => {
      const columnData = results.data
        .map((row, idx) => ({
          x: idx + 1, // Use the row index for x-axis (or timestamp if present)
          y: parseFloat(row[header]), // Ensure y is a number
        }))
        .filter((d) => !isNaN(d.y)); // Filter invalid numbers

      // Store data for Highcharts
      setChartData((prevData) => ({
        ...prevData,
        [file.name]: columnData,
      }));

    },
  });
};

useEffect(() => {
  notifyParent(); // Call notifyParent whenever chartData changes
}, [chartData,numSimulations,numLoads]); // Trigger whenever chartData changes


const handleRemoveFile = (indexToRemove) => {
  // Remove the file, header, and chart data by index
  setUploadedFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
  setSelectedHeaders((prevHeaders) => prevHeaders.filter((_, index) => index !== indexToRemove));
  setChartData((prevData) => {
    const newData = { ...prevData };
    delete newData[uploadedFiles[indexToRemove].name]; // Delete the corresponding chart data for the file being removed
    return newData;
  });
};

  return (
    <Paper className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
        <Paper
      style={{
        padding: "20px",
        margin: "20px 0",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
      }}
    >
      <Typography variant="h5" style={{ marginBottom: "20px" }}>
        LOAD PROFILES
      </Typography>

      <Grid container spacing={2} alignItems="center" style={{ marginBottom: "20px" }}>
  <Grid item xs={12} sm={6}>
    <TextField
      label="Number of Loads"
      type="number"
      value={numLoads}
      onChange={(e) => setNumLoads(e.target.value)}
      variant="outlined"
      fullWidth
      inputProps={{ min: 1 }}
    />
  </Grid>
  <Grid item xs={12} sm={6}>
    <TextField
      label="Number of Simulations"
      type="number"
      value={numSimulations}
      onChange={(e) => setNumSimulations(e.target.value)}
      variant="outlined"
      fullWidth
      inputProps={{ min: 1 }}
    />
  </Grid>
</Grid>

      {/* File Upload Section */}
      <Grid container spacing={2} alignItems="center" style={{ marginBottom: "20px" }}>
        <Grid item xs={12} sm={8}>
          <Button
            variant="contained"
            component="label"
            color="primary"
            startIcon={<CloudUpload />}
            fullWidth
          >
            Upload CSV
            <input
              type="file"
              accept=".csv"
              hidden
              onChange={handleFileUpload}
            />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Uploaded Files:</Typography>
          {uploadedFiles.length === 0 ? (
            <Typography variant="body2" color="textSecondary">
              No files uploaded yet.
            </Typography>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>File Name</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uploadedFiles.map((file, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{file.name}</TableCell>
                      <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => handleRemoveFile(index)}
                      >
                        <Delete />
                      </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>

    </Paper>
        </Grid>

        {uploadedFiles.map((file, index) => (
  <Grid item xs={12} md={4} key={index}>
    <Paper
      style={{
        padding: '20px',
        margin: '20px 0',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
      }}
    >
      <Typography variant="h5" style={{ marginBottom: '20px' }}>
        Load Profile {index + 1}: {file.name}
      </Typography>

      {/* Dropdown for Header Selection */}
      <TextField
        select
        label="Header Selection"
        name="headerSelection"
        value={selectedHeaders[index] || ''}
        onChange={(e) => handleHeaderSelection(index, e.target.value)}
        variant="outlined"
        style={{ marginBottom: '10px' }}
        fullWidth
      >
        {headers[file.name]?.map((header, idx) => (
          <MenuItem key={idx} value={header}>
            {header}
          </MenuItem>
        ))}
      </TextField>

      {/* Graph for the Selected Header */}
      {selectedHeaders[index] && chartData[file.name] && (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    {/* Highcharts Chart */}
    <div style={{ flex: 2, paddingRight: '20px' }}>
  <HighchartsReact
    highcharts={Highcharts}
    options={{
      chart: {
        type: 'spline',  // Smooth line chart
        height: 300,
        backgroundColor: '',  // Subtle background color
      },
      title: {
        text: `Load Profile: ${selectedHeaders[index]}`,
        style: { fontSize: '18px', fontWeight: 'bold', color: '#333' },
      },
      xAxis: {
        title: { text: 'Index', style: { fontSize: '14px' } },
        categories: chartData[file.name].map((point) => point.x), // Use x as categories
        labels: {
          style: { color: '#333', fontSize: '12px' },
        },
        gridLineWidth: 1,  // Light grid lines for better visibility
      },
      yAxis: {
        title: { text: 'Value', style: { fontSize: '14px' } },
        min: Math.min(...chartData[file.name].map((point) => point.y)) - 10,
        max: Math.max(...chartData[file.name].map((point) => point.y)) + 10,
        gridLineWidth: 1,
        labels: {
          style: { color: '#333', fontSize: '12px' },
        },
      },
      series: [
        {
          name: selectedHeaders[index],
          data: chartData[file.name].map((point) => point.y), // Use y as data
          color: '#0071A7',
          marker: {
            enabled: true,
            radius: 5,
            symbol: 'circle',  // Better markers for each data point
          },
          lineWidth: 3,  // Make the line thicker for better visibility
          states: {
            hover: {
              lineWidthPlus: 2,  // Increase line width on hover
            },
          },
        },
      ],
      tooltip: {
        shared: true,
        valueDecimals: 2,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',  // Darker tooltip for better contrast
        borderRadius: 5,
        style: { color: '#fff', fontSize: '14px' },
        headerFormat: '<b>{point.key}</b><br>',
        pointFormat: '{series.name}: {point.y}',
      },
      legend: {
        itemStyle: {
          fontSize: '14px',
          fontWeight: 'normal',
        },
        itemHoverStyle: {
          color: '#0071A7',  // Highlight series on hover
        },
      },
    }}
  />
</div>

    {/* Statistics Section */}
    <div
      style={{
        flex: 1,
        padding: '10px',
        border: '1px solid rgba(0,0,0,0.1)',
        borderRadius: '8px',
        background: '#f9f9f9',
      }}
    >
      {(() => {
        const stats = calculateStats(chartData[file.name]);
        return (
          <>
<Typography variant="body1">
  <strong>Max:</strong> {Number(stats.max).toFixed(2)}
</Typography>
<Typography variant="body1">
  <strong>Min:</strong> {Number(stats.min).toFixed(2)}
</Typography>
<Typography variant="body1">
  <strong>Mean:</strong> {Number(stats.mean).toFixed(2)}
</Typography>
          </>
        );
      })()}
    </div>
  </div>
)}
    </Paper>
  </Grid>
))}
      </Grid>
    </Paper>
  );
};

export default DCArcFlashCalculator;
