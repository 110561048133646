import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage/HomePage';
import AboutUsPage from "./components/AboutUsPage/AboutUsPage";
import Login from "./components/LoginComponent/Login";
import Logout from "./components/LogoutComponent/Logout";
import Register from "./components/RegisterComponent/Register";
import PrivateRoute from "./components/PrivateRouteComponent/PrivateRoute";
import Stripe from "./components/StripeComponent/StripeComponent";
import Termsofuse from "./components/TermsofuseComponent/TermsofuseComponent";
import PrivateRoutemaxdemand from "./components/PrivateRouteComponentMaxdemand/PrivateRoute";
import HarmonicCalculator from "./components/HarmonicComponent/HarmonicCalculator";
import MaxDemandCalculator from './components/MaxDemandComponent/MaxDemandComponent';
import PrivateRouteharmonic from"./components/PrivateRouteComponentHarmonicCalculator/PrivateRoute";
import Solarcalculator from "./components/SolarCalculator/SolarCalculator"
import Profile from './components/Profile/profile';
import ArcFlashCalculator from "./components/ArcFlashComponent/ArcFlashComponent"
import PasswordResetComponent from "./components/ResetPasswordComponent/ResetPasswordComponent"
import ArcFlashCalculatorDC from "./components/ArcFlashComponentDC/ArcFlashComponent"
import NetworkCalculator from "./components/NetworkCalculator/NetworkCalculator"
import SyntheticLoadProfileCalculator from "./components/SyntheticLoadProfileCalculator/SyntheticLoadProfileComponent"

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/About-us" element={<AboutUsPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/register" element={<Register />} />
        <Route path="/stripepayment" element={<Stripe />} />
        <Route path="/termsofuse" element={<Termsofuse />} />
        <Route path="/resetpassword" element={<PasswordResetComponent />} />
        <Route path="/harmonic" element={<HarmonicCalculator />} />
        <Route path="/Solarcalculator" element={<Solarcalculator />} />
        <Route path='/profile' element={<Profile />} />
        <Route path="/ArcFlashCalculator" element={<ArcFlashCalculator />} />
        <Route path="/maxdemandcalculator" element={<PrivateRoutemaxdemand />} />
        <Route path="/NetworkCalculator" element={<NetworkCalculator />} />
        <Route path="/SynthticLoadProfileCalculator" element={<SyntheticLoadProfileCalculator />} />
      
    
        {/* Use the PrivateRoute component to protect the route */}
        <Route path="/cable-size-calculator" element={<PrivateRoute />}/>
        <Route path="/ArcFlashCalculatorDC" element={<ArcFlashCalculatorDC />}/>
        

        
      </Routes>
    </Router>
  );
}

export default App;
