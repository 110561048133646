import React, { useState,useEffect } from 'react';
import { Paper, FormControl, InputLabel, Select, MenuItem, Typography, Checkbox, FormControlLabel, TextField,FormHelperText } from '@mui/material';
import protectiondeviceoptions from "./protectiondevicedetails.json";

function ProtectionDeviceComponent({ onChange , protectiveDeviceDetails1,loadDetails }) {
  const [addUpstreamDevice, setAddUpstreamDevice] = useState(false);
  const [deviceType, setDeviceType] = useState('MCB');
  const [mcbCurveType, setMcbCurveType] = useState('1');
  const [mcbRating, setMcbRating] = useState('125');
  const [tripCurrent, setTripCurrent] = useState(200);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  useEffect(() => {
    if (protectiveDeviceDetails1) {
      setAddUpstreamDevice(protectiveDeviceDetails1.addUpstreamDevice ? protectiveDeviceDetails1.addUpstreamDevice : false);
      setDeviceType(protectiveDeviceDetails1.deviceType ? protectiveDeviceDetails1.deviceType : 'MCB');
      setMcbCurveType(protectiveDeviceDetails1.mcbCurveType ? protectiveDeviceDetails1.mcbCurveType : '1');
      setMcbRating(protectiveDeviceDetails1.mcbRating ? protectiveDeviceDetails1.mcbRating : '125');
      setTripCurrent(protectiveDeviceDetails1.tripCurrent ? protectiveDeviceDetails1.tripCurrent : 200);
    }
  }, [protectiveDeviceDetails1]);



  const updateParent = () => {
    onChange({ 
      addUpstreamDevice, 
      deviceType, 
      mcbCurveType, 
      mcbRating, 
      tripCurrent
    });
  };

  useEffect(() => {
    updateParent();
  },  [addUpstreamDevice, deviceType, mcbCurveType, mcbRating, tripCurrent]);

  const handleCheckboxChange = (event) => {
    setAddUpstreamDevice(event.target.checked);
    // Reset device type if checkbox is unchecked
    if (!event.target.checked) {
      setDeviceType('MCB');
      onChange({ addUpstreamDevice: false, deviceType: 'MCB' });
    } else {
      onChange({ addUpstreamDevice: true, deviceType });
    }
  };

  useEffect(() => {
    if (loadDetails.loadCurrent && parseInt(loadDetails.loadCurrent) > parseInt(mcbRating)) {
      setError(true);
      setErrorMessage(`Load current (${loadDetails.loadcurrent} A) exceeds MCB Rating (${mcbRating} A)`);
    } else { 
      setError(false);
      setErrorMessage('');
    }
  }, [loadDetails.loadcurrent, mcbRating,addUpstreamDevice,deviceType,mcbCurveType,tripCurrent]);

  // Handle changes in the protective device type
  const handleDeviceTypeChange = (event) => {
    const newDeviceType = event.target.value;
    setDeviceType(newDeviceType);
    updateParent();
  };

  // Handle changes in the MCB Curve Type
  const handleMcbCurveTypeChange = (event) => {
    const newMcbCurveType = event.target.value;
    setMcbCurveType(newMcbCurveType);
    updateParent();
  };

  const handleMcbRatingChange = (event) => {
    const newMcbRating = event.target.value;
    setMcbRating(newMcbRating);
    updateParent();
  };

  useEffect(() => {
    const initialMcbCurveType = Object.values(protectiondeviceoptions.MCB["MCB Curve Type"])[0];
    setMcbCurveType(initialMcbCurveType);
  }, [protectiondeviceoptions]);


  const handleTripCurrentChange = (event) => {
    const generictripcurrent = event.target.value;
    setTripCurrent(generictripcurrent);
    updateParent();
  };


  return (
    <Paper style={{ padding: '20px', margin: '20px 0', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
      <Typography variant="h5" style={{ marginBottom: '20px' }}>PROTECTION</Typography>
      
      <FormControlLabel
        control={
          <Checkbox
            checked={addUpstreamDevice}
            onChange={handleCheckboxChange}
            color="primary"
          />
        }
        label="Add Upstream Protective Device"
      />
  
      {addUpstreamDevice && (
        <>
          <FormControl fullWidth style={{ marginTop: '10px' }}>
            <InputLabel id="protective-device-type-label">Protective Device Type</InputLabel>
            <Select labelId="protective-device-type-label" value={deviceType} onChange={handleDeviceTypeChange} label="Protective Device Type">
              {Object.keys(protectiondeviceoptions).map((type) => (
                <MenuItem key={type} value={type}>{type}</MenuItem>
              ))}
            </Select>
          </FormControl>
  
          {deviceType === 'MCB' && (
            <>
              <FormControl fullWidth style={{ marginTop: '10px' }}>
                <InputLabel>MCB Curve Type</InputLabel>
                <Select value={mcbCurveType} onChange={handleMcbCurveTypeChange} label="MCB Curve Type">
                  {Object.entries(protectiondeviceoptions.MCB["MCB Curve Type"]).map(([key, value]) => (
                    <MenuItem key={key} value={value}>{value}</MenuItem>
                  ))}
                </Select>
              </FormControl>
  
              <FormControl fullWidth style={{ marginTop: '10px', ...(error && { borderColor: 'red' }) }}>
                <InputLabel>MCB Rating (A)</InputLabel>
                <Select value={mcbRating} onChange={handleMcbRatingChange} label="MCB Rating (A)" error={error}>
                  {Object.entries(protectiondeviceoptions.MCB["MCB Rating (A)"]).map(([key, value]) => (
                    <MenuItem key={key} value={value}>{value}</MenuItem>
                  ))}
                </Select>
                {error && <FormHelperText error>{errorMessage}</FormHelperText>}
              </FormControl>
            </>
          )}
  
          {deviceType === 'Generic' && (
            <TextField 
              fullWidth
              style={{ marginTop: '10px' }}
              label="Trip Current (A)"
              type="number"
              value={tripCurrent}
              onChange={handleTripCurrentChange}
            />
          )}
        </>
      )}
  

  
      {/* Additional UI elements and logic */}
    </Paper>
  );
}

export default ProtectionDeviceComponent;
