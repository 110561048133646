import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import Button from '@mui/material/Button';
import Rules from '../InputValueComponent/Rules.json'

function ReportComponent({ Inputloads, LoadResults,isPaidUser }) {

  const styles = StyleSheet.create({
    heading: {
      fontFamily: 'Times-Roman',
      fontSize: 18,
      fontWeight: 16,
      marginBottom: 1,
    },
    normalText: {
      fontFamily: 'Times-Roman',
      fontSize: 12,
      marginBottom: 4,
    },
    row: {
      flexDirection: 'row',
      alignItems: 'stretch',
    },
    column: {
      flex: 1,
      padding: 5,
    },
    inlineContainer: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    fullPageUnderline: {
      borderBottomWidth: 1,
      borderBottomColor: '#000',
      marginBottom: 1,
    },
    headerContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomColor: '#000',
      paddingBottom: 10,
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      width: '100%',
    },
    headerLeftText: {
      fontFamily: 'Times-Roman',
      fontSize: 18,
      textAlign: 'left',
    },
    headerRightText: {
      fontFamily: 'Times-Roman',
      fontSize: 18,
      textAlign: 'right',
    },
    footerContainer: {
      position: 'absolute',
      bottom: 1,
      borderTopWidth: 1,
      borderTopColor: '#000',
      paddingTop: 4,
      width: '100%',
    },
    footerText: {
      fontSize: 10,
      marginHorizontal: 12,
    },
    table: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#000',
      marginBottom: 10,
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableCellfirst: {
      flex: 1,
      padding: 5,
      border: 1,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
    },
    tableCellsecond: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
    },
    tableCellthird: {
      flex: 0.6,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
    },
    tableCellforth: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
    },
    tableCellfifth: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
    },
    tableCellsixth: {
      flex: 0.3,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
    },
    tableCellseventh: {
      flex: 0.3,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
    },
    tableCelleight: {
      flex: 0.3,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
    },
    lastTableCell: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
    },
    watermark: {
      position: 'absolute',
      fontSize: 50,
      color: 'orange',
      opacity: 0.9,
      top: 130,
      left: 10,
      fontWeight: 'bold',
      transform: 'rotate(-30deg)',
    },
    watermark2: {
      position: 'absolute',
      fontSize: 50,
      color: 'orange',
      opacity: 0.9,
      top: 250,
      left: 10,
      fontWeight: 'bold',
      transform: 'rotate(-30deg)',
    },
  });

  const Max_demand_r_phase = LoadResults.Calculation_results.total_current.R;
  const Max_demand_w_phase = LoadResults.Calculation_results.total_current.W;
  const Max_demand_b_phase = LoadResults.Calculation_results.total_current.B;

  const Header = () => (
    <View style={styles.headerContainer}>
      <Text style={styles.headerLeftText}>Maximum Demand Report</Text>
      <Text style={styles.headerRightText}>WIRING MASTER</Text>
    </View>
  );

  const Footer = () => (
    <View style={styles.footerContainer}>
      <Text style={styles.footerText}>
        Disclaimer: This information does not constitute legal, professional or commercial advice. Wiring Master gives no guarantees, undertakings or warranties in
        this regard, and does not accept any legal liability or responsibility for the content or the accuracy of the information so provided, or, for any loss or
        damage caused arising directly or indirectly in connection with reliance on the use of such information.
      </Text>
    </View>
  );

  const MyDocument = () => {
    const itemsPerPage = 4; // Number of items per page
    const pageCount = Math.ceil(Inputloads.length / itemsPerPage); // Calculate total number of pages

    return (
      <Document>
        {[...Array(pageCount)].map((_, pageIndex) => (
          <Page key={`page_${pageIndex + 1}`} size="A4" orientation="landscape">
            <Header />
            <View style={{ padding: 20 }}>
            {!isPaidUser ? (
          <>
            <Text style={styles.watermark}>FREE PREVIEW - NOT FOR COMMERCIAL USE</Text>
            <Text style={styles.watermark2}>PLEASE PURCHASE A SUBSCRIPTION TO REMOVE WATERMARK</Text>
          </>
        ) : null}
              <View>
                <View>
                {pageIndex === 0 && 
                  <View style={styles.fullPageUnderline}>
                    <Text style={styles.heading}>Maximum Demand Results</Text>
                  </View>}
                  {pageIndex === 0 && 
                  <View style={styles.row}>
                    <View style={styles.column}>
                      <Text style={styles.normalText}> Maximum Demand R-Phase (A):</Text>
                      <Text style={styles.normalText}> Maximum Demand W-Phase (A):</Text>
                      <Text style={styles.normalText}> Maximum Demand B-Phase (A):</Text>
                    </View>
                    <View style={styles.column}>
                      <View style={styles.inlineContainer}>
                        <Text style={styles.normalText}>{Max_demand_r_phase} A</Text>
                      </View>
                      <View style={styles.inlineContainer}>
                        <Text style={styles.normalText}>{Max_demand_w_phase} A</Text>
                      </View>
                      <View style={styles.inlineContainer}>
                        <Text style={styles.normalText}>{Max_demand_b_phase} A</Text>
                      </View>
                    </View>
                  </View>}
                </View>

                <View>
                {pageIndex === 0 && 
                  <View style={styles.fullPageUnderline}>
                    <Text style={styles.heading}>Maximum Demand Loads</Text>
                  </View>
  }
                  <View style={styles.row}>
                  <View style={styles.tableCellfirst} >
                    <Text style={styles.normalText}>Description</Text>
                  </View>
                  <View style={styles.tableCellsecond} >
                    <Text style={styles.normalText}> Installation</Text>
                  </View>
                  <View style={styles.tableCellthird}>
                    <Text style={styles.normalText}> Load Group</Text>
                  </View>
                  <View style={styles.tableCellforth}>
                    <Text style={styles.normalText}> Load Subgroup</Text>
                  </View>
                  <View style={styles.tableCellfifth}>
                    <Text style={styles.normalText}> Additional Inputs</Text>
                  </View>
                  <View style={styles.tableCellsixth}>
                    <Text style={styles.normalText}>R(A)</Text>
                  </View>
                  <View style={styles.tableCellseventh}>
                    <Text style={styles.normalText}>W(A)</Text>
                  </View>
                  <View style={styles.tableCelleight}>
                    <Text style={styles.normalText}>B(A)</Text>
                  </View>
                  <View style={styles.lastTableCell}>
                    <Text style={styles.normalText}> Rule</Text>
                  </View>
                </View>


                  {Inputloads.slice(pageIndex * itemsPerPage, (pageIndex + 1) * itemsPerPage).map((load, index) => (
                    <View style={styles.row} key={`load_${pageIndex * itemsPerPage + index}`}>
                      <View style={styles.tableCellfirst}>
                        <Text style={styles.normalText}>{load.description}</Text>
                      </View>
                      <View style={styles.tableCellsecond}>
                        <Text style={styles.normalText}>{load.installation}</Text>
                      </View>
                      <View style={styles.tableCellthird}>
                        <Text style={styles.normalText}> {load.loadGroup}</Text>
                      </View>
                      <View style={styles.tableCellforth}>
                        <Text style={styles.normalText}> {load.loadSubgroup}</Text>
                      </View>
                      <View style={styles.tableCellfifth}>
                      {Object.entries(load.inputs).map(([key, value]) => (
                        
                          <Text style={styles.normalText}>{key} : {value}</Text>
                       
                      ))}
                       </View>
                 
                      <View style={styles.tableCellsixth}>
                        <Text style={styles.normalText}>{LoadResults.Calculation_results.results[pageIndex * itemsPerPage + index].R}</Text>
                      </View>
                      <View style={styles.tableCellseventh}>
                        <Text style={styles.normalText}>{LoadResults.Calculation_results.results[pageIndex * itemsPerPage + index].W}</Text>
                      </View>
                      <View style={styles.tableCelleight}>
                        <Text style={styles.normalText}>{LoadResults.Calculation_results.results[pageIndex * itemsPerPage + index].B}</Text>
                      </View>
                      <View style={styles.lastTableCell}>
                        <Text style={styles.normalText}>{Rules[load.installation][load.loadGroup][load.loadSubgroup]}</Text>
                      </View>
                    </View>
                  ))}
                </View>

              </View>
            </View>
             <Footer /> {/* Display footer only on the first page */}
          </Page>
        ))}
      </Document>
    );
  };

  return (
    <div>
        <PDFDownloadLink
            document={<MyDocument />}
            fileName="Maximum Demand Report.pdf"
        >
            {({ blob, url, loading, error }) =>
                loading ? (
                    <Button variant="contained" color="primary" disabled>
                        Loading document...
                    </Button>
                ) : (
                    <Button variant="contained" color="primary">
                        Download Report
                    </Button>
                )
            }
        </PDFDownloadLink>
    </div>
  );
}

export default ReportComponent;
