import React from 'react';
import { Paper, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TeX from '@matejmazur/react-katex';
import 'katex/dist/katex.min.css'; // Import KaTeX CSS

const ArcFlashCalculatorHelp = () => {
    return (
        <Paper style={{ padding: '20px', margin: '20px 0', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
            <Typography variant="h5" style={{ marginBottom: '20px' }}>HELP MENU</Typography>
            
            {/* Inputs Section */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">INPUTS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* Load Profile Inputs */}
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">LOAD PROFILE INPUTS</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="h8" style={{ fontWeight: 'bold' }}>Number of Loads:</Typography>
                            <Typography variant="body1" style={{ marginLeft: 30 }}>Specify the number of loads in the system for which load profiles are being generated. This is used to determine the data structure for simulations.</Typography>
                            
                            <Typography variant="h8" style={{ fontWeight: 'bold' }}>Number of Simulations:</Typography>
                            <Typography variant="body1" style={{ marginLeft: 30 }}>Set the number of Monte Carlo simulations to generate synthetic load profiles. A higher number increases accuracy but may require more computation time.</Typography>
                            
                            <Typography variant="h8" style={{ fontWeight: 'bold' }}>CSV File Upload:</Typography>
                            <Typography variant="body1" style={{ marginLeft: 30 }}>Upload a CSV file containing load data. Each file can include multiple columns representing different datasets.</Typography>

                            <Typography variant="h8" style={{ fontWeight: 'bold' }}>Header Selection:</Typography>
                            <Typography variant="body1" style={{ marginLeft: 30 }}>After uploading a file, select the column header representing the data to visualize and use for simulation. Only one column can be selected per file.</Typography>

                            <Typography variant="h8" style={{ fontWeight: 'bold' }}>Graphical Visualization:</Typography>
                            <Typography variant="body1" style={{ marginLeft: 30 }}>Once a header is selected, the corresponding data is displayed in a Highcharts spline chart. This allows for visualization of the load profile data.</Typography>

                            <Typography variant="h8" style={{ fontWeight: 'bold' }}>Statistical Analysis:</Typography>
                            <Typography variant="body1" style={{ marginLeft: 30 }}>The following statistics are calculated for the selected data:
                                <ul>
                                    <li><strong>Max:</strong> The maximum value in the dataset.</li>
                                    <li><strong>Min:</strong> The minimum value in the dataset.</li>
                                    <li><strong>Mean:</strong> The average value across the dataset, displayed to two decimal places.</li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                   
                </AccordionDetails>
            </Accordion>
        </Paper>
    );
};

export default ArcFlashCalculatorHelp;
