import React, { useEffect, useState } from "react";
import {
  Paper,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  InputLabel,
} from "@mui/material";
import Loadgroups from "./Inputvalues.json"; // Ensure Loadgroups is the correct import path
import { Grid } from "@mui/material";
import Rules from "./Rules.json";
import Module from "./inputValueComponent.module.scss";

function InputArrayComponent({ onChange, LoadResults, Inputloads1, Styles }) {
  const mobileScreen = 768;

  const initializeNewLoad = () => {
    const firstInstallation = Object.keys(Loadgroups)[0];
    const firstLoadGroup = Object.keys(Loadgroups[firstInstallation])[0];
    const firstLoadSubgroup = Object.keys(
      Loadgroups[firstInstallation][firstLoadGroup]
    )[0];
    const firstInputs = Loadgroups[firstInstallation][firstLoadGroup][
      firstLoadSubgroup
    ].reduce((acc, curr) => {
      // Set initial values for specific fields
      if (curr === "Number of phases") {
        acc[curr] = "1 - Phase";
      } else if (curr === "Phase") {
        acc[curr] = "R";
      } else {
        acc[curr] = "10"; // Initialize other input fields with an empty string
      }
      return acc;
    }, {});

    const description = "";

    return {
      installation: firstInstallation,
      loadGroup: firstLoadGroup,
      loadSubgroup: firstLoadSubgroup,
      inputs: firstInputs,
      description,
    };
  };

  const [loads, setLoads] = useState([initializeNewLoad()]);

  const handleDeleteLoad = (index) => {
    const newLoads = loads.filter((_, i) => i !== index);
    setLoads(newLoads);
  };

  useEffect(() => {
    if (Inputloads1 != 0) {
      setLoads(Inputloads1);
    }
  }, [Inputloads1]);

  const handleLoadChange = (index, field, value) => {
    let updatedLoad = { ...loads[index], [field]: value };

    if (field === "description") {
      updatedLoad[field] = value;
    } else {
      updatedLoad = { ...updatedLoad, [field]: value };
    }

    if (field === "loadGroup") {
      // Reset load subgroup and inputs when load group changes
      const firstLoadSubgroup = Object.keys(
        Loadgroups[loads[index].installation][value]
      )[0];
      const newInputs = Loadgroups[loads[index].installation][value][
        firstLoadSubgroup
      ].reduce((acc, curr) => {
        if (curr === "Number of phases") acc[curr] = "1 - Phase";
        else if (curr === "Phase") acc[curr] = "R";
        else acc[curr] = "10";
        return acc;
      }, {});

      updatedLoad.loadSubgroup = firstLoadSubgroup;
      updatedLoad.inputs = newInputs;
    }

    if (field === "loadSubgroup") {
      // Reset inputs when load subgroup changes
      const newInputs = Loadgroups[loads[index].installation][
        loads[index].loadGroup
      ][value].reduce((acc, curr) => {
        if (curr === "Number of phases") acc[curr] = "1 - Phase";
        else if (curr === "Phase") acc[curr] = "R";
        else acc[curr] = "10";
        return acc;
      }, {});

      updatedLoad.loadSubgroup = value;
      updatedLoad.inputs = newInputs;
    }

    const updatedLoads = loads.map((load, i) =>
      i === index ? updatedLoad : load
    );
    setLoads(updatedLoads);
  };

  const addLoad = () => {
    setLoads([...loads, initializeNewLoad()]);
  };

  const renderLoadInputs = (load, index) => {
    const inputFields =
      Loadgroups[load.installation]?.[load.loadGroup]?.[load.loadSubgroup];

    return (
      <div key={index}>
        {/* Delete Icon */}
        <Grid container spacing={2}>
          <Grid item md="0.2">
            <i
              className="fa fa-trash"
              style={{ cursor: "pointer", fontSize: "20px" }}
              onClick={() => handleDeleteLoad(index)}
            />
          </Grid>
          <>
            <Grid item md="5.8">
              <Grid container direction={"column"}>
                {/* Installation Dropdown */}
                <Grid item md="5.8">
                  <Grid container spacing={2}>
                    <Grid item md="5">
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Installation</InputLabel>
                        <Select
                          value={load.installation}
                          onChange={(e) =>
                            handleLoadChange(
                              index,
                              "installation",
                              e.target.value
                            )
                          }
                          label="Installation"
                          style={
                            mobileScreen > window.screen.width
                              ? { wordBreak: "break-all", width: "90vw" }
                              : { width: "100%" }
                          }
                        >
                          {Object.keys(Loadgroups).map((installation, i) => (
                            <MenuItem key={i} value={installation}>
                              {installation}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item md="3">
                      {/* Load Group Dropdown */}
                      {load.installation && (
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Load Group</InputLabel>
                          <Select
                            value={load.loadGroup || ""}
                            onChange={(e) =>
                              handleLoadChange(
                                index,
                                "loadGroup",
                                e.target.value
                              )
                            }
                            label="Load Group"
                          >
                            {Object.keys(
                              Loadgroups[load.installation] || {}
                            ).map((group, i) => (
                              <MenuItem key={i} value={group}>
                                {group}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>

                    <Grid item md="4">
                      {/* Load Subgroup Dropdown */}
                      {load.loadGroup && (
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Load Subgroup</InputLabel>
                          <Select
                            value={load.loadSubgroup || ""}
                            onChange={(e) =>
                              handleLoadChange(
                                index,
                                "loadSubgroup",
                                e.target.value
                              )
                            }
                            label="Load Subgroup"
                          >
                            {Object.keys(
                              Loadgroups[load.installation][load.loadGroup] ||
                                {}
                            ).map((subgroup, i) => (
                              <MenuItem key={i} value={subgroup}>
                                {subgroup}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md="5.8">
                  <Grid container>
                    <Grid item md="12">
                      {/* Description Input Field */}
                      <TextField
                        label="Description"
                        value={load.description || ""}
                        onChange={(e) =>
                          handleLoadChange(index, "description", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md="1">
              {load.loadGroup &&
                inputFields &&
                inputFields.map((field, fieldIndex) => {
                  // Render Phase Dropdown
                  if (field === "Number of phases") {
                    return (
                      <FormControl fullWidth margin="normal" key={fieldIndex}>
                        <InputLabel>{field}</InputLabel>
                        <Select
                          value={load.inputs[field] || ""}
                          onChange={(e) => {
                            const newInputs = {
                              ...load.inputs,
                              [field]: e.target.value,
                            };
                            handleLoadChange(index, "inputs", newInputs);
                          }}
                          label={field}
                        >
                          <MenuItem value="1 - Phase">1 - Phase</MenuItem>
                          <MenuItem value="3 - Phase">3 - Phase</MenuItem>
                        </Select>
                      </FormControl>
                    );
                  }

                  // Phase Color Dropdown for 1 Phase
                  if (
                    field === "Phase" &&
                    load.inputs["Number of phases"] === "1 - Phase"
                  ) {
                    return (
                      <FormControl fullWidth margin="normal" key={fieldIndex}>
                        <InputLabel>{field}</InputLabel>
                        <Select
                          value={load.inputs[field] || ""}
                          onChange={(e) => {
                            const newInputs = {
                              ...load.inputs,
                              [field]: e.target.value,
                            };
                            handleLoadChange(index, "inputs", newInputs);
                          }}
                          label={field}
                        >
                          <MenuItem value="R">R</MenuItem>
                          <MenuItem value="W">W</MenuItem>
                          <MenuItem value="B">B</MenuItem>
                        </Select>
                      </FormControl>
                    );
                  }

                  // Render other input fields
                  if (field !== "Number of phases" && field !== "Phase") {
                    return (
                      <TextField
                        key={fieldIndex}
                        label={field}
                        type="number"
                        value={load.inputs[field] || "10"}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Ensure the value is a number
                          if (!isNaN(value) && value.trim() !== "") {
                            const newInputs = {
                              ...load.inputs,
                              [field]: value,
                            };
                            handleLoadChange(index, "inputs", newInputs);
                          }
                        }}
                        fullWidth
                        margin="normal"
                      />
                    );
                  }
                  return null; // Return null for unmatched conditions
                })}
            </Grid>
            <Grid item md="4.5" className={Styles.mainfirstContainer}>
              {}
              {/* Display Results for Each Load */}
              {/* Display Results for Each Load */}
              {LoadResults &&
                LoadResults.Calculation_results &&
                LoadResults.Calculation_results.results[index] && (
                  <div
                    style={{ marginTop: "10px" }}
                    className={Styles.mainResultContainer}
                  >
                    {mobileScreen < window.screen.width ? (
                      <Grid container spacing={2}>
                        <Grid
                          item
                          md={1.5}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body1">
                            {LoadResults.Calculation_results.results[index].R} A
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={1.5}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body1">
                            {LoadResults.Calculation_results.results[index].W} A
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={1.5}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body1">
                            {LoadResults.Calculation_results.results[index].B} A
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={4}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body1">
                            {
                              Rules[load.installation][load.loadGroup][
                                load.loadSubgroup
                              ]
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <div className={Styles.resultContainer}>
                        <div className={Styles.outputContainer}>
                          <div className={Styles.outputContainerHeading}>Output</div>
                          <div className={Styles.outputContainerBody}>
                          <div className={Styles.outputData}>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                md={1.5}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                R :{" "}
                                <Typography variant="body1" className={Styles.outputResultData}>
                                  
                                  {
                                    LoadResults.Calculation_results.results[
                                      index
                                    ].R
                                  }{" "}
                                  A
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                md={1.5}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                 W :{" "}
                                <Typography variant="body1" className={Styles.outputResultData}>
                                 
                                  {
                                    LoadResults.Calculation_results.results[
                                      index
                                    ].W
                                  }{" "}
                                  A
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                md={1.5}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                B :{" "}
                                <Typography variant="body1" className={Styles.outputResultData}>
                                  
                                  {
                                    LoadResults.Calculation_results.results[
                                      index
                                    ].B
                                  }{" "}
                                  A
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                md={4}
                                className={Styles.ruleContainer}
                              >
                                 <div className={Styles.headingContainer}>Rule</div>
                                <Typography variant="body1">
                                  {
                                    Rules[load.installation][load.loadGroup][
                                      load.loadSubgroup
                                    ]
                                  }
                                </Typography>
                              </Grid>
                            </Grid>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
            </Grid>
          </>
        </Grid>
      </div>
    );
  };

  useEffect(() => {
    onChange(loads);
  }, [loads, onChange]);

  return (
    <div className={Module.headingMainContainer}>
      {mobileScreen > window.screen.width ? (
        <div className={Module.mobileHeadingContainer}>
          <h2>Load</h2>
        </div>
      ) : (
        <Paper style={{ padding: "20px", backgroundColor: "#55ACEE" }}>
          <Grid container spacing={2}>
            <Grid
              item
              md={7}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  font: "Copperplate",
                  fontWeight: "bold",
                  fontSize: "2rem",
                }}
              >
                Loads
              </div>
            </Grid>
            <Grid item md="4.5">
              <Grid container direction="column" spacing={2}>
                <Grid
                  item
                  md="4.5"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></Grid>
                <Grid item md="4.5">
                  <Grid container spacing={2}>
                    <Grid
                      item
                      md="1.5"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          font: "Copperplate",
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                        }}
                      >
                        R
                      </div>
                    </Grid>
                    <Grid
                      item
                      md="1.5"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          font: "Copperplate",
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                        }}
                      >
                        W
                      </div>
                    </Grid>
                    <Grid
                      item
                      md="1.5"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          font: "Copperplate",
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                        }}
                      >
                        B
                      </div>
                    </Grid>
                    <Grid
                      item
                      md="4"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          font: "Copperplate",
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                        }}
                      >
                        Rule
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
      {loads.map((load, index) => (
        <div>
          {mobileScreen < window.screen.width ? (
            <Paper style={{ padding: "10px", margin: "10px 0" }} key={index}>
              {renderLoadInputs(load, index)}
            </Paper>
          ) : (
            <div key={index} className={Module.dataContainer}>
              {renderLoadInputs(load, index)}
            </div>
          )}
        </div>
      ))}

      <div className={Module.addLoadButton}>
        <Button onClick={addLoad} variant="contained">
          Add Load
        </Button>
      </div>
    </div>
  );
}

export default InputArrayComponent;
