import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from '@mui/material/Button';
import List from "@mui/material/List";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { useNavigate } from "react-router-dom";
import Styles from "./header.module.scss";
import Lottie from "react-lottie";
import HamburgerIcon from "../calculatorselector/lottie/hamburger.json";
import profileicon from "../calculatorselector/lottie/profile.json";
import Logo from "../../assets/WiringMaster.svg";

import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import axios from "axios";

export default function HeaderMobile(props) {

  const settings = [{name:'Profile', url:'/profile'}];

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const isLoggedIn = props.isLoggedIn;
  const isPaidUser = props.isPaidUser;
  const userName = props.username;
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const navigateToUrl = (url) => {
    navigate(url);
  };

  const HamburgerMenu = {
    loop: true,
    autoplay: true,
    animationData: HamburgerIcon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const profileIcon = {
    loop: true,
    autoplay: true,
    animationData: profileicon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  console.log(userName);
  const data = [
    {
      componentName: "Home",
      url: "/",
    },
    {
      componentName: "Cable Size Calc",
      url: "/cable-size-calculator",
    },
    {
      componentName: "Max Demand Calc",
      url: "/maxdemandcalculator",
    },
    {
      componentName: "AC Arc Flash Calc",
      url: "/ArcFlashCalculator",
    },
    {
      componentName: "DC Arc Flash Calc",
      url: "/ArcFlashCalculatorDC",
    },
    {
      componentName: "Harmonic Calc",
      url: "/harmonic",
    },
    {
      componentName: "About Us",
      url: "/About-us",
    },
  ];
  const handleClick = (url) => {
    setAnchorElUser(null);
    if(url !== 'none'){
      navigate(url)
    }
  };

  // const handleClose = () => {
  //   setAnchorEl(false);
  // };

  const handleCancelSubscription = async () => {
    try {
      const user = firebase.auth().currentUser;
      if (user) {
        const db = firebase.firestore();
        const userRef = db.collection('users').doc(user.uid);
        const userData = await userRef.get();
        const { subscriptionId, customerId } = userData.data();
  
        // Call the cancel subscription endpoint with subscription ID and customer ID
        const response = await axios.post('http://firstdeployment-dev.ap-southeast-2.elasticbeanstalk.com/cancel-subscription', {
          subscriptionId: subscriptionId,
          customerId: customerId
        });
  
        if (response.data.success) {
          // Update user status in Firebase to reflect cancellation
          await userRef.update({ isPaidUser: false, subscriptionId: null, customerId: null });
          console.log('Subscription cancelled successfully');
        } else {
          console.error('Failed to cancel subscription');
        }
      } else {
        console.error('User not logged in');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleLogout = async () => {
    try {
      const user = firebase.auth().currentUser;
      if (user) {
        // Access Firestore and remove the session token
        const userRef = firebase.firestore().collection("users").doc(user.uid);
        await userRef.update({
          sessionToken: firebase.firestore.FieldValue.delete(),
        });

        // Remove the session token from localStorage
        localStorage.removeItem("sessionToken");
      }

      // Sign out the user from Firebase Authentication
      await firebase.auth().signOut();

      // Navigate to the home page
      navigate("/");
      handleClick('none') // This function is used to handle UI, presumably closing a menu
      sessionStorage.clear('isLogged')
      sessionStorage.clear('isPaidUser')
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <div className={Styles.mobileHeaderContainer}>
        <div className={Styles.mobileFirstContainer}>
          <List>
            {data.map((text, index) => (
              <ListItem key={text.componentName} disablePadding>
                <ListItemButton onClick={() => navigateToUrl(text.url)}>
                  {/* <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon> */}
                  <ListItemText primary={text.componentName} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </div>
        {/* <Divider /> */}
        {isLoggedIn && !isPaidUser && (
          <div className={Styles.mobileBottomContainer}>
            <Button className={Styles.buyButton} href="/stripepayment">Buy</Button>
          </div>
        )}
      </div>
    </Box>
  );

  return (
    <div className={Styles.mobileHeaderMainContainer}>
      <div className={Styles.hamburgerMenu}>
        <div onClick={toggleDrawer(true)}>
          <span>
            <Lottie options={HamburgerMenu} height={40} width={40} />
          </span>
        </div>
        <Drawer open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>
      </div>
      <div className={Styles.mobileLogo} onClick={()=>{navigateToUrl('/')}}>
        <img src={Logo} alt="logo" />
      </div>
      <div className={Styles.rightLinks}>
        {isLoggedIn ? (
          <div>
           <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <span className={Styles.buttonTitle}>
              <Lottie options={profileIcon} width={30} height={30} />
            </span>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={()=>handleClick('none')}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.name} onClick={()=>handleClick(setting.url)}>
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
               <MenuItem  onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
                {/* <MenuItem onClick={handleCancelSubscription}>
                <Typography textAlign="center">Cancel Subscription</Typography></MenuItem> */}
            </Menu>
          </Box>
          </div>
        ) : (
          <Button className={Styles.LoginButton} href="/login">
            Login
          </Button>
        )}
      </div>
    </div>
  );
}
