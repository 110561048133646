import React, { useContext } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink,Image } from '@react-pdf/renderer';
import Button from '@mui/material/Button';
import ImageContext from '../ImageContext/ImageContext'
// import ImageContext  from "src/components/CableSizeCalculator/components/ImageContext/ImageContext.js"

function ReportComponent ({formData,results,isPaidUser}){

    const { editorImageUrl, setEditorImageUrl } = useContext(ImageContext);

    const total_incident_Calcm2_maxdemand = results?.incident_result?.total_incident_Calcm2_maxdemand
    const total_incident_Calcm2_oppenlander = results?.incident_result?.total_incident_Calcm2_oppenlander
    const total_incident_Calcm2_paukerts = results?.incident_result?.total_incident_Calcm2_paukerts
    const total_incident_Jmm2_maxdemand = results?.incident_result?.total_incident_Jmm2_maxdemand
    const total_incident_Jmm2_oppenlander = results?.incident_result?.total_incident_Jmm2_oppenlander
    const total_incident_Jmm2_paukerts = results?.incident_result?.total_incident_Jmm2_paukerts
    const arc_boundary_maxdemand = results?.incident_result?.total_incident_Calcm2_maxdemand
    const arc_boundary_oppenlander = results?.incident_result?.total_incident_Calcm2_maxdemand
    const arc_boundary_paukerts = results?.incident_result?.total_incident_Calcm2_maxdemand



    const getPPECategory = (incidentEnergy) => {
      if (incidentEnergy <= 1.2) {
        return "No PPE Required (Incident Energy < 1.2 cal/cm2)";
      } else if (incidentEnergy <= 4) {
        return "Category 1";
      } else if (incidentEnergy <= 8) {
        return "Category 2";
      }else if (incidentEnergy <= 25) {
        return "Category 3";
      } else if (incidentEnergy <= 40) {
        return "Category 4";
      } else {
        return "No Category Applicable (Incident Energy > 40 cal/cm2";
      }
    };

    const ppe_maxdemand = getPPECategory(total_incident_Calcm2_maxdemand)
    const ppe_oppenlander = getPPECategory(total_incident_Calcm2_oppenlander)
    const ppe_paukerts = getPPECategory(total_incident_Calcm2_paukerts)
    

    let totalArcDuration = 0;
    let totalArcDuration1 = 0;
    let totalArcDuration2 = 0;




    const Header = () => (
        <View style={styles.headerContainer}>
          <Text style={styles.headerLeftText}>DC Arc Flash Assessment Report</Text>
          <Text style={styles.headerRightText}>WIRING MASTER</Text>
        </View>
      );
      
      const Footer = () => (
        <View style={styles.footerContainer}>
          <Text style={styles.footerText}>
          Disclaimer: This information does not constitute legal, professional or commercial advice. Wiring Master gives no guarantees, undertakings or warranties in
this regard, and does not accept any legal liability or responsibility for the content or the accuracy of the information so provided, or, for any loss or
damage caused arising directly or indirectly in connection with reliance on the use of such information.
          </Text>
        </View>
      );



    const styles = StyleSheet.create({
        heading: {
          fontFamily: 'Times-Roman',
          fontSize: 18,
          fontWeight: 16,
          marginBottom: 1,
        },
        normalText: {
          fontFamily: 'Times-Roman',
          fontSize: 12,
          marginBottom: 4,
        },
        row: {
          flexDirection: 'row',
          alignItems: 'stretch',
        },
        column: {
          flex: 1, // Adjust flex ratio based on your needs
          padding: 5, // Adjust padding as needed
        },
        superscript: {
            fontFamily: 'Times-Roman',
            fontSize: 8, // Smaller font size for superscript
            alignSelf: 'flex-start', // Align to top to simulate superscript position
            marginLeft: 2, // Space between main text and superscript
          },
          inlineContainer: {
            flexDirection: 'row', // Ensures inline layout
            alignItems: 'flex-start', // Aligns items to the start of the container
          },
          fullPageUnderline: {
            borderBottomWidth: 1,
            borderBottomColor: '#000',
            borderUpperColor: '#000',
            marginBottom: 1,
            marginUpper: 1,
              // Adjust as needed
          },
          image: {
            width: '100%', // Adjust as needed
            height: 'auto', // Adjust for aspect ratio
            marginVertical: 0,
            alignItems: "center",
            marginBottom: 20
          },
          headerContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottomWidth: 1,
            borderBottomColor: '#000',
            paddingBottom: 10, // Increase padding for more height
            paddingTop: 10, 
            paddingLeft:10,
            paddingRight: 10,   // Add top padding for symmetry
            width: '100%',     // Ensure full width
          },
          headerLeftText: {
            fontFamily: 'Times-Roman', // Match the font family
            fontSize: 18,              // Adjust font size as needed
            textAlign: 'left',
          },
          headerRightText: {
            fontFamily: 'Times-Roman', // Match the font family
            fontSize: 18,              // Adjust font size as needed
            textAlign: 'right',
          },
          footerContainer: {
            position: 'absolute', // Use absolute positioning
            bottom: 1,            // Pin to the bottom
            borderTopWidth: 1,
            borderTopColor: '#000',
            paddingTop: 4,
            width: '100%', // Ensure full width
          },
          footerText: {
            fontSize: 10,

            marginHorizontal: 12, // Adjust as needed for margins
          },
          watermark: {
            position: 'absolute',
            fontSize: 50,
            color: 'orange',
            opacity: 0.9,
            top: 170,
            left: 10,
            fontWeight: 'bold',
            transform: 'rotate(-45deg)',
          },
          watermark2: {
            position: 'absolute',
            fontSize: 50,
            color: 'orange',
            opacity: 0.9,
            top: 500,
            left: 10,
            fontWeight: 'bold',
            transform: 'rotate(-45deg)',
          },
        });


        // Additional styles as needed





    const MyDocument = () => (
            <Document>
            <Page > 
            <Header />
            {!isPaidUser ? (
          <>
            <Text style={styles.watermark}>FREE PREVIEW - NOT FOR COMMERCIAL USE</Text>
            <Text style={styles.watermark2}>PLEASE PURCHASE A SUBSCRIPTION TO REMOVE WATERMARK</Text>
          </>
        ) : null}
            <View style={{ padding: 20 }}>
                    <View>
                        <View>
                            {/* <Image style={styles.image} src={editorImageUrl}/> */}
                            <Image style={styles.image} src={editorImageUrl} />
                        </View>
                       </View>

                       <View>
                       <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>SYSTEM DETAILS  </Text>
                                </View>

                       </View>

                       <View style={styles.row}>
    <View style={styles.column}>
        <Text style={styles.normalText}> System Voltage: </Text>
        <Text style={styles.normalText}> Number of Battery Strings: </Text>
        <Text style={styles.normalText}> Working Distance: </Text>
        <Text style={styles.normalText}> Enclosure Type: </Text>
        <Text style={styles.normalText}> Conductor Gap: </Text>
        <Text style={styles.normalText}> Fault Location: </Text>
        {formData.global_values.faultLocation === "battery terminals" && (
            <Text style={styles.normalText}> Faulted String: </Text>
        )}
    </View>
    <View style={styles.column}>
        <Text style={styles.normalText}> {formData.global_values.systemVoltage} V </Text>
        <Text style={styles.normalText}> {formData.global_values.numStrings} </Text>
        <Text style={styles.normalText}> {formData.global_values.workingDistance} mm</Text>
        <Text style={styles.normalText}> {formData.global_values.enclosureType} </Text>
        <Text style={styles.normalText}> {formData.global_values.conductorGap} mm</Text>
        <Text style={styles.normalText}> {formData.global_values.faultLocation} </Text>
        {formData.global_values.faultLocation === "battery terminals" && (
            <Text style={styles.normalText}> String {formData.global_values.faultedString} </Text>
        )}
    </View>
</View>
                       

                       <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>MAXIMUM POWER METHOD</Text>
                                </View>

                                <View style={styles.row}>
                                        <View style={styles.column}>
                                            <Text style={styles.normalText}> Incident Energy at working distance:</Text>
                                            <Text style={styles.normalText}> PPE Catergory:</Text>
                                            <Text style={styles.normalText}> Working Distance:</Text>
                                            
                                </View>
                                <View style={styles.column}>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{total_incident_Calcm2_maxdemand.toFixed(3)} cal/cm</Text>
                                            <Text style={styles.superscript}>2</Text>
                                            </View>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{ppe_maxdemand}</Text>
                                            
                                            
                                            </View>
                                            <Text style={styles.normalText}>{formData.global_values.workingDistance} mm</Text>
                                </View>                                


                        </View>



                                <View style={styles.row}>
                                        <View style={styles.column}>
                                            
                                            <Text style={styles.normalText}> Incident Energy:</Text>
                                            <Text style={styles.normalText}> Arc Flash Boundary:</Text>
                                            <Text style={styles.normalText}> Arcing Current:</Text>
                                </View>
                                <View style={styles.column}>


                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{results.incident_result.total_incident_Jmm2_maxdemand.toFixed(3)} J/mm</Text>
                                            <Text style={styles.superscript}>2</Text>
                                            </View>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{results.incident_result.arc_boundary_maxdemand.toFixed(3)} mm</Text>
                                            <Text style={styles.superscript}></Text>
                                            </View>
                                            {results.arcing_current_results.map((result, index) => {
                            totalArcDuration += result.arc_duration;

                            return (
                                <View style={styles.inlineContainer} key={index}>
                                    <Text style={styles.normalText}>{result.Maximum_demand_fault.toFixed(3)} kA @ {totalArcDuration} s</Text>
                                </View>
                            );
                        })}
                                </View>   
                                                   


                        </View>


                       

                        
                        </View>
                        <Footer/>
                       
            </Page>

            <Page>
            <Header />
            {!isPaidUser ? (
          <>
            <Text style={styles.watermark}>FREE PREVIEW - NOT FOR COMMERCIAL USE</Text>
            <Text style={styles.watermark2}>PLEASE PURCHASE A SUBSCRIPTION TO REMOVE WATERMARK</Text>
          </>
        ) : null}
            <View style={{ padding: 20 }}>
                    <View>

                       </View>

                       <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>STOKES/OPPENLANDER METHOD</Text>
                                </View>

                                <View style={styles.row}>
                                        <View style={styles.column}>
                                            <Text style={styles.normalText}> Incident Energy at working distance:</Text>
                                            <Text style={styles.normalText}> PPE Catergory:</Text>
                                            <Text style={styles.normalText}> Working Distance:</Text>
                                            
                                </View>
                                <View style={styles.column}>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{total_incident_Calcm2_oppenlander.toFixed(3)} cal/cm</Text>
                                            <Text style={styles.superscript}>2</Text>
                                            </View>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{ppe_oppenlander}</Text>
                                            
                                            
                                            </View>
                                            <Text style={styles.normalText}>{formData.global_values.workingDistance} mm</Text>
                                </View>                                


                        </View>



                                <View style={styles.row}>
                                        <View style={styles.column}>
                                            
                                            <Text style={styles.normalText}> Incident Energy:</Text>
                                            <Text style={styles.normalText}> Arc Flash Boundary:</Text>
                                            <Text style={styles.normalText}> Arcing Current:</Text>
                                </View>
                                <View style={styles.column}>


                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{results.incident_result.total_incident_Jmm2_oppenlander.toFixed(3)} J/mm</Text>
                                            <Text style={styles.superscript}>2</Text>
                                            </View>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{results.incident_result.arc_boundary_oppenlander.toFixed(3)} mm</Text>
                                            <Text style={styles.superscript}></Text>
                                            </View>
                                            {results.arcing_current_results.map((result, index) => {
                            totalArcDuration1 += result.arc_duration;

                            return (
                                <View style={styles.inlineContainer} key={index}>
                                    <Text style={styles.normalText}>{result.arc_resistance_oppenlander.toFixed(3)} kA @ {totalArcDuration1} s</Text>
                                </View>
                            );
                        })}
                                </View>   
                                                   


                        </View>
                       

                       <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>PAUKERT METHOD</Text>
                                </View>

                                <View style={styles.row}>
                                        <View style={styles.column}>
                                            <Text style={styles.normalText}> Incident Energy at working distance:</Text>
                                            <Text style={styles.normalText}> PPE Catergory:</Text>
                                            <Text style={styles.normalText}> Working Distance:</Text>
                                            
                                </View>
                                <View style={styles.column}>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{total_incident_Calcm2_paukerts.toFixed(3)} cal/cm</Text>
                                            <Text style={styles.superscript}>2</Text>
                                            </View>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{ppe_paukerts}</Text>
                                            
                                            
                                            </View>
                                            <Text style={styles.normalText}>{formData.global_values.workingDistance} mm</Text>
                                </View>                                


                        </View>



                                <View style={styles.row}>
                                        <View style={styles.column}>
                                            
                                            <Text style={styles.normalText}> Incident Energy:</Text>
                                            <Text style={styles.normalText}> Arc Flash Boundary:</Text>
                                            <Text style={styles.normalText}> Arcing Current:</Text>
                                </View>
                                <View style={styles.column}>


                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{results.incident_result.total_incident_Jmm2_paukerts.toFixed(3)} J/mm</Text>
                                            <Text style={styles.superscript}>2</Text>
                                            </View>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{results.incident_result.arc_boundary_paukerts.toFixed(3)} mm</Text>
                                            <Text style={styles.superscript}></Text>
                                            </View>
                                            {results.arcing_current_results.map((result, index) => {
                            totalArcDuration2 += result.arc_duration;

                            return (
                                <View style={styles.inlineContainer} key={index}>
                                    <Text style={styles.normalText}>{result.paukerts_fault_current.toFixed(3)} kA @ {totalArcDuration2} s</Text>
                                </View>
                            );
                        })}
                                </View>   
                                                   


                        </View>


               

                        
                        </View>
                        <Footer/>
                        </Page>

                        <Page>
            <Header />
            {!isPaidUser ? (
          <>
            <Text style={styles.watermark}>FREE PREVIEW - NOT FOR COMMERCIAL USE</Text>
            <Text style={styles.watermark2}>PLEASE PURCHASE A SUBSCRIPTION TO REMOVE WATERMARK</Text>
          </>
        ) : null}
            <View style={{ padding: 20 }}>
            <View>
                              {formData.stringData.map((result1, index) => (
                                <View key={index}>
                                  <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>STRING {index + 1} DETAILS</Text>
                                  </View>

                                  <View style={styles.row}>
                                    <View style={styles.column}>
                                      <Text style={styles.normalText}>Fault Current Calculation Method:</Text>
                                      {formData?.result1?.faultMethod === "Battery Short Circuit Input" ? (
            <Text style={styles.normalText}>Battery Short Circuit:</Text>
        ):(
<Text style={styles.normalText}>Battery Internal Resistance:</Text>
        )}

                                      <Text style={styles.normalText}>Connecting Cable Size:</Text>
                                      <Text style={styles.normalText}>Connecting Cable Length:</Text>
                                      <Text style={styles.normalText}>Connecting Cable Material:</Text>
                                      <Text style={styles.normalText}>Short Circuit Current At Fault Location:</Text>
                                    </View>
                                    <View style={styles.column}>
                                    <Text style={styles.normalText}>{result1.faultMethod} </Text>
                                    {formData?.result1?.faultMethod === "Battery Short Circuit Input" ? (
            <Text style={styles.normalText}>{result1.batShortCct} A</Text>
        ):(
<Text style={styles.normalText}>{result1.batInternalres} mohm</Text>
        )}

                                    <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{result1.cableSize} mm</Text>
                                            <Text style={styles.superscript}>2</Text>
                                            </View>
                                    <Text style={styles.normalText}>{result1.cableLength} m</Text>
                                    <Text style={styles.normalText}>{result1.cableMaterial} </Text>
                                    <Text style={styles.normalText}>{results?.fault_current_results[index]?.faultcurrent.toFixed(3)} A</Text>
                                    </View>
                                  </View>
                                </View>
                              ))}
                            </View>
                            </View>
                        <Footer/>
                        </Page>


                       

                       

            {/* More Pages as needed */}
            </Document>
            
            
      );

      return (
        <div>
        <PDFDownloadLink
            document={<MyDocument />}
            fileName="Arc Flash Report.pdf"
        >
            {({ blob, url, loading, error }) =>
                loading ? (
                    <Button variant="contained" color="primary" disabled>
                        Loading document...
                    </Button>
                ) : (
                    <Button variant="contained" color="primary">
                        Download Report
                    </Button>
                )
            }
        </PDFDownloadLink>
        </div>
    );
}


export default ReportComponent;