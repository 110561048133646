
// import { Card, CardContent, Typography, Button, Grid } from '@mui/material';
import Styles from  './PricingPage.module.css'; // Make sure to create this CSS file for custom styles
import PrizingCard from '../PrizingCard/card';
import React, { useEffect, useState, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";


function PricingPage() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isPaidUser, setIsPaidUser] = useState(false);
  const plans = [
    {
      title: "Basic",
      price: "Free",
      features: [
        "Access to trial version of our calculators",
        "Limited features",
      ],
      disabled : isLoggedIn ? true: false,
      text : isLoggedIn ? isPaidUser ? '--' : 'Current Plan' : 'Log In'
    },
    {
      title: "Pro",
      price: ["$15/month"],
      features: [
        "Full access to our calculators",
        "Save and open calculations",
        "Priority customer support",
        "Watermark free report genration"
        // Add more features here
      ],
      disabled : isLoggedIn ? isPaidUser ? true: false: false,
      text : isLoggedIn ? isPaidUser ? 'Current Plan': 'Upgrade' : 'Log In'
    },
    // {
    //   title: "Enterprise",
    //   price: "Contact Us",
    //   features: [
    //     "Multipe users at once",
    //     "Full access to our calculators",
    //     "Save and open calculations",
    //     "Priority customer support",
    //     "Protective device selection",
    //     "Mannual cable selection",
    //     "Detailed Report Genration"
    //     // Add more features here
    //   ],
    // },
  ];


  useEffect(() => {
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (currentUser) => {
        setIsLoggedIn(!!currentUser);
        //setUser(currentUser);
        if (currentUser) {
          const userRef = firebase
            .firestore()
            .collection("users")
            .doc(currentUser.uid);
          const doc = await userRef.get();
          if (doc.exists) {
            setIsPaidUser(doc.data().isPaidUser);
            // console.log(doc.data());
            //sessionStorage.setItem("isPaidUser", doc.data().isPaidUser);
            //sessionStorage.setItem("isLogged", !!currentUser);
          }
        }
      });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className={Styles.pricingContainer}>
      <div className={Styles.card}>
      <PrizingCard props={plans} />
      </div>
    </div>
  );
}

export default PricingPage;
