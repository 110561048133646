import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Rect, Line, Text, Arc, Shape } from 'react-konva';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import html2canvas from 'html2canvas';
 

const ArcFlashRepresentation = ({ size,formData,results, onImageUpdate }) => {
    const stageWidth = 1492;
    const stageHeight = 598;
    const stageRef = useRef();
    const [selectedMethod, setSelectedMethod] = useState('maxdemand');
  

 


    useEffect(() => {

    if(stageRef.current){
      // Generate the canvas image URL
    const url = stageRef.current.toDataURL();
      // Update the parent component with the image URL
    onImageUpdate(url);
  
    }

  }, [ size,formData, results, onImageUpdate]);




  let Earc_cal = 0;
  let AFB = 0;

  // Extract data based on the selected method
  if (results) {
    switch (selectedMethod) {
      case 'maxdemand':
        Earc_cal = results.incident_result.total_incident_Calcm2_maxdemand;
        AFB = results.incident_result.arc_boundary_maxdemand;
        break;
      case 'oppenlander':
        Earc_cal = results.incident_result.total_incident_Calcm2_oppenlander;
        AFB = results.incident_result.arc_boundary_oppenlander;
        break;
      case 'paukerts':
        Earc_cal = results.incident_result.total_incident_Calcm2_paukerts;
        AFB = results.incident_result.arc_boundary_paukerts;
        break;
      default:
        break;
    }
  }

  AFB = AFB.toFixed(2);

    const Earc_list_array = results?.Earc_list_array



    const tolerance = 0.01;

    // Function to find distance for incident energy
    const findDistanceForIncidentEnergy = (incidentEnergy) => {
      const tolerance = 1;

      let incidentEnergies = [];

      switch (selectedMethod) {
        case 'maxdemand':
           incidentEnergies = Earc_list_array.map(obj => obj.total_incident_Calcm2_maxdemand);
          break;
        case 'oppenlander':
           incidentEnergies = Earc_list_array.map(obj => obj.total_incident_Calcm2_oppenlander);
          break;
        case 'paukerts':
           incidentEnergies = Earc_list_array.map(obj => obj.total_incident_Calcm2_paukerts);
          break;
        default:
          break;
      }
      
      // Extract the relevant values from each JSON object


      // Find the index in the array
      const index = incidentEnergies.findIndex(energy => energy <= incidentEnergy);
  

      return index !== -1 ? index + 1 : null;
    };

    const distance40 = findDistanceForIncidentEnergy(40);
    const distance25 = findDistanceForIncidentEnergy(25);
    const distance8 = findDistanceForIncidentEnergy(8);
    const distance4 = findDistanceForIncidentEnergy(4);








 

    // Switchboard line dimensions and positions
    const switchboardLength =  stageWidth <= 1450 ? stageWidth*0.055172414 : 80 ;
    const switchboardStrokeWidth = stageWidth <= 1450 ? 0.002758621*stageWidth : 4  ;
    const switchboard1Position = { x: (stageWidth / 2) - (stageWidth / 3.5) , y: stageHeight / 2 - switchboardLength / 2 };
    const switchboard2Position = { x: stageWidth / 2 + (stageWidth / 3.5), y: stageHeight / 2 - switchboardLength / 2 };
        // Dotted box dimensions and positions
    const boxPadding = stageWidth <= 1450 ? stageWidth * 0.020689655 : 30;
    const boxWidth = switchboardStrokeWidth + boxPadding * (stageWidth <= 1450 ? 0.006996552 * stageWidth : 10) + 20;
    const boxHeight = switchboardLength + boxPadding * (stageWidth <= 1450 ? 0.004137931 * stageWidth : 6);
    const fontsize = stageWidth <= 1450 ? 0.017241379*stageWidth : 25;

      // Wire positions (connecting the two switchboard lines)
  const wirePoints = [
    switchboard1Position.x, switchboard1Position.y + switchboardLength / 2 ,
    switchboard2Position.x + switchboardLength + switchboardLength + switchboardLength  , switchboard2Position.y + switchboardLength / 2
  ];

    // Arc properties for each level
    const arc1Props = { x: stageWidth / 10, y: stageHeight / 2, radius: stageHeight / 1.5, startAngle: 315, endAngle: 90 };
    const arc2Props = { x: stageWidth / 10 + (0.75 * stageWidth * 0.1814), y: stageHeight / 2, radius: stageHeight / 1.5, startAngle: 315, endAngle: 90 };
    const arc3Props = { x: stageWidth / 10 + (2 * 0.75 * stageWidth * 0.1814), y: stageHeight / 2, radius: stageHeight / 1.5, startAngle: 315, endAngle: 90 };
    const arc4Props = { x: stageWidth / 10 + (3 * 0.75 * stageWidth * 0.1814), y: stageHeight / 2, radius: stageHeight / 1.5, startAngle: 315, endAngle: 90 };
    const arc5Props = { x: stageWidth / 10 + (4 * 0.75 * stageWidth * 0.1814), y: stageHeight / 2, radius: stageHeight / 1.5, startAngle: 315, endAngle: 90 };



        // Determine the working distance
        const workingDistance = formData.global_values.workingDistance;
        let workingDistanceX = 0;
    
        if (workingDistance < distance40 ) {
            workingDistanceX = arc1Props.x +  (arc1Props.radius/1.5);
        } else if ((workingDistance <= distance25) && (workingDistance > distance40)) {
            workingDistanceX = arc1Props.x + arc1Props.radius + (((arc2Props.x + arc2Props.radius)-(arc1Props.x + arc1Props.radius))/2)
        } else if ((workingDistance <= distance8) && (workingDistance > distance25)) {
          workingDistanceX = arc2Props.x + arc2Props.radius + (((arc3Props.x + arc3Props.radius)-(arc2Props.x + arc2Props.radius))/2)
        } else if ((workingDistance <= distance4) && (workingDistance > distance8)) {
          workingDistanceX = arc3Props.x + arc3Props.radius + (((arc4Props.x + arc4Props.radius)-(arc3Props.x + arc3Props.radius))/2)
        } else if ((workingDistance <= parseFloat(AFB)) && (workingDistance > distance4)) {
          workingDistanceX = arc4Props.x + arc4Props.radius + (((arc5Props.x + arc5Props.radius)-(arc4Props.x + arc4Props.radius))/2 )
        }else if (workingDistance > parseFloat(AFB)) {
            workingDistanceX = arc5Props.x + arc5Props.radius + 20;
        }

 


  return (
    <div>
    <Paper style={{ overflowX: 'auto',overflowY: 'auto',maxHeight: '60vh',padding: '20px', margin: '20px 0', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)'}} >

    <div style={{ position: 'relative' }}>
          {/* Dropdown for method selection */}
          <Select
            value={selectedMethod}
            onChange={(e) => setSelectedMethod(e.target.value)}
            style={{ position: 'absolute', top: 10, left: 10, zIndex: 1000 }}
          >
            <MenuItem value="maxdemand">Maximum Power Method</MenuItem>
            <MenuItem value="oppenlander">Stokes/Oppenlander Method</MenuItem>
            <MenuItem value="paukerts">Paukert Method</MenuItem>
          </Select>

          </div>

      <div>
    <Stage   width={stageWidth} height={stageHeight} ref={stageRef} >

    <Layer>
    
    <Arc
                  x={arc5Props .x}
                  y={arc5Props .y}
                  innerRadius={0 }
                  outerRadius={arc5Props .radius}
                  angle={arc1Props .endAngle + 40}
                  stroke="LawnGreen"
                  fill='LawnGreen'
                  strokeWidth={0}
                  dash={[4, 4]}
                  rotation={arc1Props .startAngle - 20}
            
                />
    <Arc
                  x={arc4Props .x}
                  y={arc4Props .y}
                  innerRadius={0 }
                  outerRadius={arc4Props .radius}
                  angle={arc1Props .endAngle + 40}
                  stroke="yellow"
                  fill='yellow'
                  strokeWidth={0}
                  dash={[4, 4]}
                  rotation={arc1Props .startAngle - 20}
            
                />

    <Arc
                  x={arc3Props .x}
                  y={arc3Props .y}
                  innerRadius={0}
                  outerRadius={arc3Props .radius}
                  angle={arc3Props .endAngle + 40}
                  stroke="orange"
                  fill='orange'
                  strokeWidth={0}
                  dash={[4, 4]}
                  rotation={arc3Props .startAngle - 20}
            
                />

                    <Arc
                  x={arc2Props .x}
                  y={arc2Props .y}
                  innerRadius={0}
                  outerRadius={arc2Props .radius}
                  angle={arc2Props .endAngle + 40}
                  stroke="FireBrick"
                  fill='FireBrick'
                  strokeWidth={0}
                  dash={[4, 4]}
                  rotation={arc2Props .startAngle - 20}
            
                />
                    <Arc
                  x={arc1Props .x}
                  y={arc1Props .y}
                  innerRadius={0 }
                  outerRadius={arc1Props .radius}
                  angle={arc1Props .endAngle + 40}
                  stroke="RED"
                  fill="RED"
                  strokeWidth={0}
                  dash={[4, 4]}
                  rotation={arc1Props .startAngle - 20}
            
                />
                                <Line
          points={[workingDistanceX, switchboard1Position.y - 70, workingDistanceX, switchboard1Position.y + switchboardLength ]}
          stroke="black"
          strokeWidth={1}
          dash={[4, 4]}
        />

        <Text
          x={workingDistanceX - 40 } // Adjust as needed
          y={switchboard1Position.y - 130} // Adjust as needed
          text={`${workingDistance} mm`}
          fontSize={fontsize-4}
          fontFamily="Times New Roman"
          fill="black"
        />
                <Text
          x={workingDistanceX - 60 } // Adjust as needed
          y={switchboard1Position.y - 100} // Adjust as needed
          text={`${Earc_cal.toFixed(3)} cal/cm`} 
          fontSize={fontsize-4}
          fontFamily="Times New Roman"
          fill="black"
        />

<Text
  x={workingDistanceX - 59 + measureText(`${Earc_cal.toFixed(3)} cal/cm`, fontsize - 4, "Times New Roman")} // Adjust as needed
  y={switchboard1Position.y - 103} // Adjust as needed
  text={"2"}
  fontSize={fontsize - 8}
  fontFamily="Times New Roman"
  fill="black"
/>
 







                


        </Layer>
        <Layer>
          {/* Position the switchboard at the center of the stage */}
          <Line
          points={[switchboard1Position.x, switchboard1Position.y, switchboard1Position.x, switchboard1Position.y + switchboardLength]}
          stroke="black"
          strokeWidth={switchboardStrokeWidth}
        />

        {/* Dotted Box for Switchboard 1 */}
        <Rect
          x={switchboard1Position.x - boxWidth + boxPadding}
          y={switchboard1Position.y + (switchboardLength/2) - (boxHeight/2)}
          width={boxWidth}
          height={boxHeight}
          stroke="black"
          fill='white'
          strokeWidth={1}
          dash={[4, 4]}
          strokeEnabled
        />

<Text
          x={switchboard1Position.x - boxWidth + boxPadding + 10} // Adjust as needed
          y={switchboard1Position.y + (switchboardLength / 2) - (boxHeight / 2) + 10} // Adjust as needed
          text={`Voc: ${formData.global_values.systemVoltage} V`}
          fontSize={fontsize}
          fontFamily="Times New Roman"
          fill="black"
        />

<Text
          x={switchboard1Position.x - boxWidth + boxPadding + 10} // Adjust as needed
          y={switchboard1Position.y + (switchboardLength / 2) - (boxHeight / 2) + 40} // Adjust as needed
          text={`Ibf: ${(results?.merged_events)[0]?.faultcurrent.toFixed(2)} A`}
          fontSize={fontsize}
          fontFamily="Times New Roman"
          fill="black"
        />




<Text
          x={switchboard1Position.x - boxWidth + boxPadding + 10} // Adjust as needed
          y={switchboard1Position.y + (switchboardLength / 2) - (boxHeight / 2) + 70} // Adjust as needed
          text={`Enclosure: ${formData.global_values.enclosureType}`}
          fontSize={fontsize}
          fontFamily="Times New Roman"
          fill="black"
        />

<Text
          x={switchboard1Position.x - boxWidth + boxPadding + 10} // Adjust as needed
          y={switchboard1Position.y + (switchboardLength / 2) - (boxHeight / 2) + 100} // Adjust as needed
          text={`Electrode Gap: ${formData.global_values.conductorGap} mm`}
          fontSize={fontsize}
          fontFamily="Times New Roman"
          fill="black"
        />

<Line
          points={wirePoints}
          stroke="black"
          strokeWidth={1}
          lineCap="round"
          lineJoin="round"
          dash={[8, 8]}
        />


                <Text
                  x={switchboard1Position.x - boxWidth + boxPadding + boxWidth +10  }
                  y={arc1Props .y + 30}
                  text={`Incident Energy`}
                  fontSize={fontsize-4}
                  fontFamily="Times New Roman"
                  fill="black"
                  align="right"
                />
               <Text
                  x={arc1Props .x + arc1Props .radius + 10 }
                  y={arc1Props .y - 25}
                  text={`${distance40} mm`}
                  fontSize={fontsize-4}
                  fontFamily="Times New Roman"
                  fill="black"
                  align="right"
                />
                <Text
                  x={switchboard1Position.x - boxWidth + boxPadding + boxWidth +10  }
                  y={arc1Props .y + 60}
                  text={`> 40 cal/cm`}
                  fontSize={fontsize-3}
                  fontFamily="Times New Roman"
                  fill="black"
                  align="right"
                />
                <Text
  x={switchboard1Position.x - boxWidth + boxPadding + boxWidth +10 + measureText(`> 40 cal/cm`,fontsize-3,"Times New Roman")} // Adjust as needed
  y={arc1Props .y + 60} // Adjust as needed
  text={"2"}
  fontSize={fontsize - 8}
  fontFamily="Times New Roman"
  fill="black"
/>
                <Arc
                  x={arc1Props .x}
                  y={arc1Props .y}
                  innerRadius={arc1Props .radius}
                  outerRadius={arc1Props .radius}
                  angle={arc1Props .endAngle}
                  stroke="black"
                  strokeWidth={1}
                  rotation={arc1Props .startAngle}
                  dash={[4, 4]} 
                />


<Text
                  x={arc1Props .x + arc1Props .radius + 10 }
                  y={arc2Props .y + 30}
                  text={`Category 4`}
                  fontSize={fontsize-4}
                  fontFamily="Times New Roman"
                  fill="black"
                  align="right"
                />
                               <Text
                  x={arc2Props .x + arc2Props .radius + 10 }
                  y={arc2Props .y - 25}
                  text={`${distance25} mm`}
                  fontSize={fontsize-4}
                  fontFamily="Times New Roman"
                  fill="black"
                  align="right"
                />

                <Text
                  x={arc1Props .x + arc1Props .radius + 10 }
                  y={arc1Props .y + 60}
                  text={'40 cal/cm'}
                  fontSize={fontsize-3}
                  fontFamily="Times New Roman"
                  fill="black"
                  align="right"
                />
                                <Text
  x={arc1Props .x + arc1Props .radius + 10 + measureText(`40 cal/cm`,fontsize-3,"Times New Roman")} // Adjust as needed
  y={arc1Props .y + 60} // Adjust as needed
  text={"2"}
  fontSize={fontsize - 8}
  fontFamily="Times New Roman"
  fill="black"
/>
                <Arc
                  x={arc2Props .x}
                  y={arc2Props .y}
                  innerRadius={arc2Props .radius}
                  outerRadius={arc2Props .radius}
                  angle={arc2Props .endAngle}
                  stroke="black"
                  strokeWidth={1}
                  rotation={arc2Props .startAngle}
                  dash={[4, 4]} 
                />

<Text
                  x={arc2Props .x + arc2Props .radius + 10   }
                  y={arc3Props .y + 30}
                  text={`Category 3`}
                  fontSize={fontsize-4}
                  fontFamily="Times New Roman"
                  fill="black"
                  align="right"
                />
                <Text
                  x={arc2Props .x + arc2Props .radius + 10   }
                  y={arc3Props .y + 60}
                  text={'25 cal/cm'}
                  fontSize={fontsize-3}
                  fontFamily="Times New Roman"
                  fill="black"
                  align="right"
                />
                                                <Text
  x={arc2Props .x + arc2Props .radius + 10 + measureText(`25 cal/cm`,fontsize-3,"Times New Roman")} // Adjust as needed
  y={arc1Props .y + 60} // Adjust as needed
  text={"2"}
  fontSize={fontsize - 8}
  fontFamily="Times New Roman"
  fill="black"
/>
                                <Text
                  x={arc3Props .x + arc3Props .radius + 10 }
                  y={arc3Props .y - 25}
                  text={`${distance8} mm`}
                  fontSize={fontsize-4}
                  fontFamily="Times New Roman"
                  fill="black"
                  align="right"
                />
                <Arc
                  x={arc3Props .x}
                  y={arc3Props .y}
                  innerRadius={arc3Props .radius}
                  outerRadius={arc3Props .radius}
                  angle={arc3Props .endAngle}
                  stroke="black"
                  strokeWidth={1}
                  rotation={arc3Props .startAngle}
                  dash={[4, 4]} 
                />

<Text
                  x={arc3Props .x + arc3Props .radius + 10 }
                  y={arc4Props .y + 30}
                  text={`Category 2`}
                  fontSize={fontsize-4}
                  fontFamily="Times New Roman"
                  fill="black"
                  align="right"
                />
                <Text
                  x={arc3Props .x + arc3Props .radius + 10 }
                  y={arc4Props .y + 60}
                  text={'8 cal/cm'}
                  fontSize={fontsize-3}
                  fontFamily="Times New Roman"
                  fill="black"
                  align="right"
                />

<Text
  x={arc3Props .x + arc2Props .radius + 10 + measureText(`8 cal/cm`,fontsize-3,"Times New Roman")} // Adjust as needed
  y={arc1Props .y + 60} // Adjust as needed
  text={"2"}
  fontSize={fontsize - 8}
  fontFamily="Times New Roman"
  fill="black"
/>
                                                <Text
                  x={arc4Props .x + arc4Props .radius + 10 }
                  y={arc4Props .y - 25}
                  text={`${distance4} mm`}
                  fontSize={fontsize-4}
                  fontFamily="Times New Roman"
                  fill="black"
                  align="right"
                />
                <Arc
                  x={arc4Props .x}
                  y={arc4Props .y}
                  innerRadius={arc4Props .radius}
                  outerRadius={arc4Props .radius}
                  angle={arc4Props .endAngle}
                  stroke="black"
                  strokeWidth={1}
                  rotation={arc4Props .startAngle}
                  dash={[4, 4]} 
                />

<Text
                  x={arc4Props .x + arc4Props .radius + 10 }
                  y={arc5Props .y + 30}
                  text={`Category 1`}
                  fontSize={fontsize-4}
                  fontFamily="Times New Roman"
                  fill="black"
                  align="right"
                />
                <Text
                  x={arc4Props .x + arc4Props .radius + 10 }
                  y={arc5Props .y + 60}
                  text={'4 cal/cm'}
                  fontSize={fontsize-3}
                  fontFamily="Times New Roman"
                  fill="black"
                  align="right"
                />
                <Text
  x={arc4Props .x + arc2Props .radius + 10 + measureText(`4 cal/cm`,fontsize-3,"Times New Roman")} // Adjust as needed
  y={arc1Props .y + 60} // Adjust as needed
  text={"2"}
  fontSize={fontsize - 8}
  fontFamily="Times New Roman"
  fill="black"
/>
                <Arc
                  x={arc5Props .x}
                  y={arc5Props .y}
                  innerRadius={arc5Props .radius}
                  outerRadius={arc5Props .radius}
                  angle={arc5Props .endAngle}
                  stroke="black"
                  strokeWidth={1}
                  rotation={arc5Props .startAngle}
                  dash={[4, 4]} 
                />
                                                                <Text
                  x={arc5Props .x + arc5Props .radius + 10 }
                  y={arc5Props .y - 25}
                  text={`${AFB} mm`}
                  fontSize={fontsize-4}
                  fontFamily="Times New Roman"
                  fill="black"
                  align="right"
                />
                                                                                <Text
                  x={arc5Props .x + arc5Props .radius + 10 }
                  y={arc5Props .y + 30}
                  text={`AFB`}
                  fontSize={fontsize-4}
                  fontFamily="Times New Roman"
                  fill="black"
                  align="right"
                />
                  <Text
                  x={arc5Props .x + arc5Props .radius + 10 }
                  y={arc5Props .y + 60}
                  text={`1.2 cal/cm`}
                  fontSize={fontsize-4}
                  fontFamily="Times New Roman"
                  fill="black"
                  align="right"
                />
                                <Text
  x={arc5Props .x + arc2Props .radius + 10 + measureText(`1.2 cal/cm`,fontsize-3,"Times New Roman")} // Adjust as needed
  y={arc1Props .y + 60} // Adjust as needed
  text={"2"}
  fontSize={fontsize - 8}
  fontFamily="Times New Roman"
  fill="black"
/>



        </Layer>

      </Stage>
      </div>
      </Paper>
    </div>
  );
};

const measureText = (text, fontSize, fontFamily) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = `${fontSize}px ${fontFamily}`;
  return context.measureText(text).width;
};

export default ArcFlashRepresentation;
