import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./YourCheckoutForm.css"; // Import your custom CSS file
import firebase from "firebase/compat/app"; // Updated import path
import "firebase/compat/auth"; // Updated import path
import Header from "../HomePage/components/header/header";
import Lottie from 'react-lottie';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import buttonLoader from './buttonLoader.json';

const YourCheckoutForm = () => {
  const stripeLogoPath = process.env.PUBLIC_URL + "stripe/Stripe.png"; // Path to the image in the public folder
  const stripe = useStripe();
  const elements = useElements();
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(null); // State to track payment success
  const notify = (props) => toast.success(props);
  const notifyError = (props) => toast.error(props);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const loader = {
    loop: true,
    autoplay: true,
    animationData: buttonLoader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet; make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      console.error("[error]", error);
      setPaymentSuccess(false);
    } else {
      setPaymentMethodId(paymentMethod.id); // Set the payment method ID in the state
      await createSubscription(paymentMethod.id); // Call createSubscription with the payment method ID
    }
  };

  const createSubscription = async (paymentMethodId) => {
    try {
      setLoading(true);
      const user = firebase.auth().currentUser;
      const userEmail = user ? user.email : null;
      const response = await axios.post(
        "https://app.wiringmaster.org/create-subscription",
        {
          planId: "price_1Ozq2DCV8H3kriX2D1wZKnz0",
          customerId: userEmail,
          paymentMethodId: paymentMethodId, // Pass payment method ID to the backend
        }
      );

      if (response.data.success) {
        const { subscriptionid, customerid } = response.data;
        setPaymentSuccess(true); // Set payment success state
        notify("Subscription Successful");
        // setTimeout(navigate('/'), 2200)
        await updateUserStatus(subscriptionid, customerid);
      } else {
        setPaymentSuccess(false); // Set payment failure state
        notifyError("Could not complete Subscription");
      }
    } catch (error) {
      setPaymentSuccess(false);

      // Handle error
    } finally {
      setLoading(false);
    }
  };

  const updateUserStatus = async (subscriptionId, customerId) => {
    const user = firebase.auth().currentUser;
    if (user) {
      const db = firebase.firestore();
      const userRef = db.collection("users").doc(user.uid);
      await userRef.update({
        isPaidUser: true,
        subscriptionId: subscriptionId, // Add subscription ID
        customerId: customerId, // Add customer ID
      });
    }
  };

  const descriptionStyle = {
    textAlign: "center",
    margin: "20px 0",
    padding: "10px",
    backgroundColor: "#f9f9f9",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
  };

  const headingStyle = {
    fontSize: "1.25rem",
    fontWeight: "bold",
    marginBottom: "1rem",
  };

  const paragraphStyle = {
    fontSize: "1rem",
    lineHeight: "1.5",
    margin: "0.5rem 0",
  };

  const cardStyle = {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        "::placeholder": {
          color: "#aab7c4",
        },
        iconColor: "#c4f0ff",
        ":-webkit-autofill": {
          color: "#fce883",
        },
      },
      invalid: {
        color: "#9e2146",
        iconColor: "#9e2146",
      },
      successAnimation: {
        color: "green", // Change color as needed
        fontSize: "24px", // Change font size as needed
      },
      failureAnimation: {
        color: "red", // Change color as needed
        fontSize: "24px", // Change font size as needed
      },

    },
  };

  return (
    <div>
      <Header />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="overall-container">
        <div className="checkout-form-container">
          <form onSubmit={handleSubmit} className="checkout-form">
            <h3 className="checkout-title">Card Information</h3>
            <div className="payment-details">
              <label>
                Card Details
                <CardElement options={cardStyle} />
              </label>
            </div>
            {/* Placeholder for displaying any payment related messages */}
            <div className="payment-messages">
              {/* Render animation based on payment success state */}
              {paymentSuccess === true &&
                setTimeout(() => {
                  navigate("/");
                }, 5000)}
            </div>
            <button
              type="submit"
              disabled={!stripe || loading}
              className="pay-button"
            >
             {loading?<Lottie options={loader} height={25} width={100} /> : `Subscribe`}
            </button>
            <h2>
              <span>
                <img src={stripeLogoPath} alt="Stripe Logo" />
              </span>
            </h2>
          </form>
          <div style={descriptionStyle}>
        <h3 style={headingStyle}>How secure is my credit card payment information?</h3>
        <p style={paragraphStyle}>
          Wiring Master uses <a href="https://stripe.com/au" target="_blank" rel="noopener noreferrer">Stripe.com</a> to securely collect, transmit, process and store your credit card information.
        </p>
        <p style={paragraphStyle}>
          <a href="https://stripe.com/au" target="_blank" rel="noopener noreferrer">Stripe.com</a> is a level 1 <a href="https://www.pcisecuritystandards.org/about_us/" target="_blank" rel="noopener noreferrer">PCI certified</a> payment service provider. And it is one of the top 5 payment service providers in the world.
        </p>
        <p style={paragraphStyle}>
          Wiring Master does not collect, transmit, process or store credit card information on the Wiring Master server.
        </p>
        <p style={paragraphStyle}>
          The credit card input form on this page is loaded directly from the Stripe server. And when you click "Pay", your information is submitted directly to Stripe over a secure SSL connection.
        </p>
        <p style={paragraphStyle}>
          On the Stripe system, only the last 4 digits of your card number and expiry date are visible to a Stripe administrator.
        </p>
        <p style={paragraphStyle}>
          For more information, see the <Link to="/termsofuse">Privacy Policy</Link>.
        </p>
        <p style={paragraphStyle}>
          Please let me know if you need more information. I don't mind.
        </p>
      </div>
        </div>
      

      </div>

    </div>
  );
};

export default YourCheckoutForm;
