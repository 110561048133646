import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import html2canvas from 'html2canvas'; // Import html2canvas library

function HarmonicWaveform({ HarmonicOrderinput,InputDetails,onImageUpdate }) {
  const chartRef = useRef(null);


  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const numberOfPoints = 500; // Adjust the number of points as needed
    const durationInSeconds = 0.03;
    const time = Array.from({ length: numberOfPoints }, (_, i) => i / (numberOfPoints - 1) * durationInSeconds);
    // Calculate the power factor angle and adjust the fundamental waveform
    const fundamentalpf = parseFloat(InputDetails.phaseValues[2].loadPowerFactor);
    const angle = Math.acos(fundamentalpf); // Calculate the angle in radians
    const originalData = time.map((t) => Math.sin(2 * Math.PI * 50 * t - angle)); // Subtract angle in radians

    // Generate harmonic data for each order and add them together
    const totalData = originalData.map((_, index) => {
      let sum = originalData[index]; // Initialize sum with the original data at the corresponding index
      HarmonicOrderinput.slice(1).forEach(order => { // Start iteration from index 1 to skip the first harmonic
        const { order: harmonicOrder, percentage } = order;
        sum += (percentage / 100) * Math.sin(2 * Math.PI * 50 * harmonicOrder * time[index]);
      });
      return sum;
    });

    const chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: time.map((t) => t.toFixed(4) + 's'),
        datasets: [
          {
            label: 'Original Sine Wave',
            data: originalData,
            borderColor: 'rgba(75, 192, 192, 1)',
            tension: 0.1,
          },
          {
            label: 'With Harmonic Component',
            data: totalData,
            borderColor: 'rgba(255, 99, 132, 1)',
            tension: 0.1,
          },
        ],
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Time (s)',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Amplitude (pu)',
            },
          },
        },
        animation: {
          onComplete: function () {
            // Capture screenshot of the chart canvas after the animation is complete
            html2canvas(chartRef.current).then(canvas => {
              onImageUpdate(canvas.toDataURL()); // Convert canvas to base64 image and set as state
            });
          }
        }
      },
    });

    return () => {
      chart.destroy();
    };
  }, [HarmonicOrderinput,InputDetails]);

  return (
    <div>
      <canvas ref={chartRef} width="400" height="200"></canvas>
    </div>
  );
}

export default HarmonicWaveform;
