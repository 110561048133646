import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from "@mui/material/Grid";
import Typography from '@material-ui/core/Typography';
import cableData from './CableResistance.json'; // Ensure this path is correct

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const DCArcFlashCalculator = ({ onUpdate, formData1 }) => {
  const classes = useStyles();
  const [numStrings, setNumStrings] = useState(2);
  const [systemVoltage, setSystemVoltage] = useState(240);
  const [faultLocation, setFaultLocation] = useState('Combiner Terminals');
  const [faultedString, setFaultedString] = useState('');
  const [conductorGap, setConductorGap] = useState(20);
  const [enclosureType, setEnclosureType] = useState('Open Air');
  const [workingDistance, setWorkingDistance] = useState(450);
  const [stringData, setStringData] = useState([
    {
      arcDuration: 2,
      faultMethod: 'Battery Short Circuit Input',
      batShortCct: 2000,
      batInternalres: 0.02,
      cableMaterial: 'Copper solid/stranded',
      cableSize: '1',
      cableLength: 10
    },
    {
      arcDuration: 2,
      faultMethod: 'Battery Short Circuit Input',
      batShortCct: 2000,
      batInternalres: 0.02,
      cableMaterial: 'Copper solid/stranded',
      cableSize: '1',
      cableLength: 10
    }
  ]);
  const [cableSizeOptions, setCableSizeOptions] = useState({});
  const [defaultCableSize, setDefaultCableSize] = useState('');

  useEffect(() => {
    if (formData1) {
      const { global_values, stringData } = formData1;
      setNumStrings(global_values?.numStrings || 2);
      setSystemVoltage(global_values?.systemVoltage || 240);
      setFaultLocation(global_values?.faultLocation || 'Combiner Terminals');
      setFaultedString(global_values?.faultedString || '');
      setConductorGap(global_values?.conductorGap || 20);
      setEnclosureType(global_values?.enclosureType || 'Open Air');
      setWorkingDistance(global_values?.workingDistance || 450);
      setStringData(stringData || [
        {
          arcDuration: 2,
          faultMethod: 'Battery Short Circuit Input',
          batShortCct: 2000,
          batInternalres: 0.02,
          cableMaterial: 'Copper solid/stranded',
          cableSize: '1',
          cableLength: 10
        },
        {
          arcDuration: 2,
          faultMethod: 'Battery Short Circuit Input',
          batShortCct: 2000,
          batInternalres: 0.02,
          cableMaterial: 'Copper solid/stranded',
          cableSize: '1',
          cableLength: 10
        }
      ]);
    }
  }, [formData1]);



  useEffect(() => {
    // Initialize cable size options and set the default cable size
    const initialMaterial = 'Copper solid/stranded';
    const initialSizes = cableData[initialMaterial] || [];
    setCableSizeOptions({ [initialMaterial]: initialSizes });

    // Set the first size as the default if available
    if (initialSizes.length > 0) {
      setDefaultCableSize(initialSizes[0]);
      setStringData(prevStringData => 
        prevStringData.map(data => ({ ...data, cableSize: initialSizes[0],cableMaterial: initialMaterial  }))
      );
    }
  }, [numStrings]);

  useEffect(() => {
    // Update the stringData when numStrings changes
    setStringData(prev => {
      const updatedData = [...prev];
      while (updatedData.length < numStrings) {
        updatedData.push({
          arcDuration: 2,
          faultMethod: 'Battery Short Circuit Input',
          batShortCct: 2000,
          batInternalres: 0.02,
          cableMaterial: 'Copper solid/stranded',
          cableSize: defaultCableSize,
          cableLength: 10
        });
      }
      while (updatedData.length > numStrings) {
        updatedData.pop();
      }
      return updatedData;
    });
  }, [numStrings, defaultCableSize]);

  useEffect(() => {
    // Notify parent component of changes to stringData or global data
    onUpdate({
      global_values: {
        numStrings: numStrings,
        systemVoltage: systemVoltage,
        faultLocation: faultLocation,
        faultedString: faultedString,
        conductorGap:conductorGap,
        workingDistance:workingDistance,
        enclosureType: enclosureType,
        
      },
      stringData: stringData
    });
  }, [numStrings, systemVoltage, faultLocation, faultedString, stringData,conductorGap,workingDistance,enclosureType]);

  const handleStringChange = (index, event) => {
    const { name, value } = event.target;
    const newStringData = [...stringData];
    newStringData[index] = { ...newStringData[index], [name]: value };
    setStringData(newStringData);
  };

  const handleNumStringsChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setNumStrings(value);
  };

  const handleWorkingDistanceChange = (event) => {
    setWorkingDistance(event.target.value);
  };

  const handleSystemVoltageChange = (event) => {
    setSystemVoltage(event.target.value);
  };

  const handleconductorGap = (event) => {
    setConductorGap(event.target.value);
  };

  const handleEnclosureType = (event) => {
    setEnclosureType(event.target.value);
  };



  const handleFaultLocation = (event) => {
    const value = event.target.value;
    setFaultLocation(value);
    if (value !== 'Battery Terminals') {
      setFaultedString('');
    } else if (value === 'Battery Terminals' && !faultedString) {
      setFaultedString('1');
    }
  };

  const handleFaultedStringChange = (event) => {
    setFaultedString(event.target.value);
  };

  const handleCableMaterialChange = (index, event) => {
    const material = event.target.value;
    const sizes = cableData[material] || [];
    setCableSizeOptions(prevOptions => ({
      ...prevOptions,
      [material]: sizes
    }));

    const newStringData = [...stringData];
    newStringData[index] = { ...newStringData[index], cableMaterial: material };

    // Set the first size as the default if available
    if (sizes.length > 0) {
      newStringData[index] = { ...newStringData[index], cableSize: sizes[0] };
    } else {
      newStringData[index] = { ...newStringData[index], cableSize: '' };
    }

    setStringData(newStringData);
  };

  const handleCableSizeChange = (index, event) => {
    const size = event.target.value;
    const newStringData = [...stringData];
    newStringData[index] = { ...newStringData[index], cableSize: size };
    setStringData(newStringData);
  };

  return (
    <Paper className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: '20px', margin: '20px 0', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
            <Typography variant="h5" style={{ marginBottom: '20px' }}>SYSTEM DETAILS</Typography>
            <TextField
              select
              label="Number of Battery Strings"
              name="numberOfStrings"
              value={numStrings}
              onChange={handleNumStringsChange}
              variant="outlined"
              style={{ marginBottom: '10px' }}
              fullWidth
            >
              {[1, 2, 3, 4, 5].map((num) => (
                <MenuItem key={num} value={num}>{num}</MenuItem>
              ))}
            </TextField>

            <TextField
              type="number"
              label="System Voltage (VDC)"
              name="systemVoltage"
              value={systemVoltage}
              onChange={handleSystemVoltageChange}
              variant="outlined"
              style={{ marginBottom: '10px' }}
              fullWidth
            />

<TextField
              select
              label="Enclosure Type"
              name="enclosureType"
              value={enclosureType}
              onChange={handleEnclosureType}
              variant="outlined"
              style={{ marginBottom: '10px' }}
              fullWidth
            >
              {["Open Air", "Pannelboard", "LV Switchboard", "MV Switchboard"].map((num) => (
                <MenuItem key={num} value={num}>{num}</MenuItem>
              ))}
            </TextField>

            <TextField
              select
              label="Fault Location"
              name="faultLocation"
              value={faultLocation}
              onChange={handleFaultLocation}
              variant="outlined"
              style={{ marginBottom: '10px' }}
              fullWidth
            >
              {["Battery Terminals", "Combiner Terminals"].map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </TextField>

            <TextField
              type="number"
              label="Conductor Gap (mm)"
              name="conductorGap"
              value={conductorGap}
              onChange={handleconductorGap}
              variant="outlined"
              style={{ marginBottom: '10px' }}
              fullWidth
            />

<TextField
              type="number"
              label="Working Distance (mm)"
              name="workingDistance"
              value={workingDistance}
              onChange={handleWorkingDistanceChange}
              variant="outlined"
              style={{ marginBottom: '10px' }}
              fullWidth
            />

            

            {faultLocation === 'Battery Terminals' && (
              <TextField
                select
                label="Faulted String"
                name="faultedString"
                value={faultedString}
                onChange={handleFaultedStringChange}
                variant="outlined"
                style={{ marginBottom: '10px' }}
                fullWidth
              >
                {[...Array(numStrings).keys()].map((index) => (
                  <MenuItem key={index} value={index + 1}>{`String ${index + 1}`}</MenuItem>
                ))}
              </TextField>
            )}
          </Paper>
        </Grid>

        {[...Array(numStrings).keys()].map((index) => (
          <Grid item xs={12} md={4} key={index}>
            <Paper style={{ padding: '20px', margin: '20px 0', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
              <Typography variant="h5" style={{ marginBottom: '20px' }}>BATTERY STRING {index + 1}</Typography>

              <TextField
                type="number"
                label="Fault Clearing Time (s)"
                name="arcDuration"
                value={stringData[index]?.arcDuration}
                onChange={(e) => handleStringChange(index, e)}
                variant="outlined"
                style={{ marginBottom: '10px' }}
                fullWidth
              />

              <TextField
                select
                label="Fault Method"
                name="faultMethod"
                value={stringData[index]?.faultMethod || 'Battery Short Circuit Input'}
                onChange={(e) => handleStringChange(index, e)}
                variant="outlined"
                style={{ marginBottom: '10px' }}
                fullWidth
              >
                <MenuItem value="Battery Short Circuit Input">Battery Short Circuit Input</MenuItem>
                <MenuItem value="Battery Internal Ressitance">Battery Internal Ressitance</MenuItem>
                {/* Add more options if necessary */}
              </TextField>
              {[stringData[index]?.faultMethod] == 'Battery Short Circuit Input' && (
              <TextField
                type="number"
                label="Battery Short Circuit Current (A)"
                name="batShortCct"
                value={stringData[index]?.batShortCct}
                onChange={(e) => handleStringChange(index, e)}
                variant="outlined"
                style={{ marginBottom: '10px' }}
                fullWidth
              />
              )}

              {[stringData[index]?.faultMethod] == 'Battery Internal Ressitance' && (


              <TextField
                type="number"
                label="Battery Internal Resistance (mΩ)"
                name="batInternalres"
                value={stringData[index]?.batInternalres}
                onChange={(e) => handleStringChange(index, e)}
                variant="outlined"
                style={{ marginBottom: '10px' }}
                fullWidth
              />
            )}

              <TextField
                select
                label="Cable Material"
                name="cableMaterial"
                value={stringData[index]?.cableMaterial || 'Copper solid/stranded'}
                onChange={(e) => handleCableMaterialChange(index, e)}
                variant="outlined"
                style={{ marginBottom: '10px' }}
                fullWidth
              >
                {Object.keys(cableData).map((material) => (
                  <MenuItem key={material} value={material}>{material}</MenuItem>
                ))}
              </TextField>

              
                <TextField
                  select
                  label="Cable Size (mm2)"
                  name="cableSize"
                  value={stringData[index]?.cableSize || '1'}
                  onChange={(e) => handleCableSizeChange(index, e)}
                  variant="outlined"
                  style={{ marginBottom: '10px' }}
                  fullWidth
                >
                  {cableSizeOptions[stringData[index]?.cableMaterial]?.map((size) => (
                    <MenuItem key={size} value={size}>{size}</MenuItem>
                  ))}
                </TextField>
              

              <TextField
                type="number"
                label="Cable Length (m)"
                name="cableLength"
                value={stringData[index]?.cableLength}
                onChange={(e) => handleStringChange(index, e)}
                variant="outlined"
                style={{ marginBottom: '10px' }}
                fullWidth
              />
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default DCArcFlashCalculator;
