import React, { useState, useEffect } from "react";
import { Paper, Typography, Grid } from "@mui/material";
import InputArrayComponent from "./components/InputValueComponent/Inputarraycomponent";
import axios from "axios";
import Header from "../HomePage/components/header/header";
import Footer from "../HomePage/components/footer/footer";
import Banner from "./components/companybanner/companybanner";
import SaveCalculation from "./components/SaveCalculation/SaveCalculation";
import OpenCalculation from "./components/OpenCalculationComponent/OpenCalculation";
import ReportComponent from "./components/ReportComponent/ReportComponent";
import Styles from "./maxDemandComponent.module.scss";
import firebase from 'firebase/compat/app';
import { ToastContainer, toast } from "react-toastify";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { Helmet } from "react-helmet";

function MaxDemandCalculator() {
  const [Inputloads, setInputloads] = useState([]);
  const [Inputloads1, setInputloads1] = useState([]);
  const [LoadResults, setLoadResults] = useState({});
  const [user, setUser] = useState(null);
  const [isPaidUser, setIsPaidUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // Function to submit loads to the backend server
  const submitLoads = async () => {
    try {
      const response = await axios.post(
        "https://app.wiringmaster.org/MaxDemandCalculate",
        { Inputloads }
      );
      setLoadResults(response.data);
      console.log("LoadResults:", LoadResults);
      console.log("Calculation_results:", LoadResults.Calculation_results);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (authUser) => {
      if (authUser) {
        const db = firebase.firestore();
        const userRef = db.collection('users').doc(authUser.uid);
        const doc = await userRef.get();
        if (doc.exists) {
          setUser(authUser);
          setIsPaidUser(doc.data().isPaidUser);
        }
      } else {
        setUser(null);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Call submitLoads whenever there's a change in the load details
  useEffect(() => {
    submitLoads();
  }, [Inputloads]);

  const handleOpenCalculation = (data) => {
    // Update your state with the loaded data
    setInputloads(data.Inputloads);
    setInputloads1(data.Inputloads);
  };

  return (
    <div>
<Helmet>
  <title>Maximum Demand Calculator Based on AS3000</title>
  <meta name="description" content="Accurate maximum demand calculation based on AS3000 standard. Utilize diversity factors from AS3000 standard for load calculation. Calculate maximum demand for single domestic electrical installations or multiple living units per phase, ranging from 2 to over 21 units. Custom diversity factors and detailed maximum demand assessment reports included." />
  <meta name="keywords" content="maximum demand calculation, AS3000 standard, diversity factors, load calculation, single domestic installation, living units per phase, custom diversity factor, detailed reports, maximum demand assessment" />
</Helmet>
      <Header />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Banner />
      <div className={Styles.mainContainer}>
        <div className={Styles.saveCalculationsContainer}>
        {isPaidUser ? (
          <SaveCalculation Inputloads={Inputloads} />
        ) : (
          <div  onClick={() => {
            toast.error("Please purchase a subscription to access this feature");
          }}>  
          <div style={{ pointerEvents: "none", opacity: 0.3 }}>  
          <SaveCalculation Inputloads={Inputloads} />   
          </div>
            </div>
  )}   
        </div>
        <div className={Styles.openCalculationContainer}>
        {isPaidUser ? (
          <OpenCalculation onOpenCalculation={handleOpenCalculation} />
        ) : (
          <div  onClick={() => {
            toast.error("Please purchase a subscription to access this feature");
          }}>
            <div style={{ pointerEvents: "none", opacity: 0.3 }}>
            <OpenCalculation onOpenCalculation={handleOpenCalculation} />
            </div>
            </div>
  )}
        </div>
        {Inputloads.length > 0 &&
          LoadResults.Calculation_results &&
          LoadResults.Calculation_results.total_current &&
          LoadResults.Calculation_results.total_current.R &&
          LoadResults.Calculation_results.results &&
          LoadResults.Calculation_results.results.length > 0 &&
          LoadResults.Calculation_results.results.length ===
            Inputloads.length &&
          LoadResults.Calculation_results.results.every((load) => load) && (
            <div className={Styles.reportComponentContainer}>
              <ReportComponent
                Inputloads={Inputloads}
                LoadResults={LoadResults}
                isPaidUser={isPaidUser}
              
              />
            </div>
          )}
      </div>
      {LoadResults.Calculation_results &&
        LoadResults.Calculation_results.total_current != 0 && (
          <div className={Styles.cardContainer}>
            <div className={Styles.totalDemandRedCard}>
              <Typography variant="h5" align="center">
                Total Demand Red-Phase
              </Typography>
              <Typography variant="h4" align="center">
                {LoadResults.Calculation_results.total_current.R} A
              </Typography>
            </div>
            <div className={Styles.totalDemandWhiteCard}>
              <Typography variant="h5" align="center">
                Total Demand White-Phase
              </Typography>
              <Typography variant="h4" align="center">
                {LoadResults.Calculation_results.total_current.W} A
              </Typography>
            </div>
            <div className={Styles.totalDemandBlueCard} item xs={4}>
              <Typography variant="h5" align="center">
                Total Demand Blue-Phase
              </Typography>
              <Typography variant="h4" align="center">
                {LoadResults.Calculation_results.total_current.B} A
              </Typography>
            </div>
          </div>
        )}

      <div className={Styles.mainContainer1}>
        <InputArrayComponent
          onChange={setInputloads}
          LoadResults={LoadResults}
          Inputloads1={Inputloads1}
          Styles={Styles}
        />
      </div>

      <Footer />
    </div>
  );
}

export default MaxDemandCalculator;
