import React, { useState } from 'react';
import { Paper, Typography, Accordion, AccordionSummary, AccordionDetails, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TeX from '@matejmazur/react-katex';
import 'katex/dist/katex.min.css'; // Import KaTeX CSS

const CableSizeCalculatorHelp = () => {
    const [inputDetails, setInputDetails] = useState(''); // State to store selected input details
    const [calculationDetails, setCalculationDetails] = useState(''); // State to store selected calculation details


    return (
        <Paper style={{ padding: '20px', margin: '20px 0', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
            <Typography variant="h5" style={{ marginBottom: '20px' }}>HELP MENU</Typography>

            {/* INPUTS Dropdown */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">INPUTS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">LOAD DETAILS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Load Current (A)</Typography>
                    <Typography variant="body1" style={{  }}>Load current per phase in ampere</Typography>

                    <Typography variant="h8" style={{ fontWeight: 'bold' }}>Power Factor</Typography>
                    <Typography variant="body1" style={{ fontStyle:  '' }}>Load power factor</Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">SUPPLY DETAILS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Phase</Typography>
                <div style={{marginLeft:30}}>
                    <Typography variant="body1" style={{ fontStyle:  ''}}>Select the supply phase</Typography>
                    </div>
                    <Typography variant="h8" style={{ fontWeight: 'bold' }}>Voltage(V)</Typography>
                    <div style={{marginLeft:30}}>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>Select the supply voltage value</Typography>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>For 1-Phase, 2-Phase, DC enter line to neutral voltage</Typography>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>For 3-Phase, enter line-to-line voltage</Typography>
                    </div>
                    <Typography variant="h8" style={{ fontWeight: 'bold' }}>Max Voltage Drop (%)</Typography>
                    <div style={{marginLeft:30}}>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>The maximum allowable voltage drop in the cable expressed as a percentage of the supply voltage.</Typography>
                    </div>
                    <Typography variant="h8" style={{ fontWeight: 'bold' }}>Length of Run (m)</Typography>
                    <div style={{marginLeft:30}}>
                    <Typography variant="body1" style={{ fontStyle:  '' }}>The total length of the cable in meters</Typography>
                    </div>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">CABLE DETAILS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Cable Type</Typography>
                <div style={{marginLeft:30}}>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>Select the desired cable type from the dropdown (all cable types from AS3008 has been included)</Typography>
                    </div>
                    <Typography variant="h8" style={{ fontWeight: 'bold' }}>Insulation</Typography>
                    <div style={{marginLeft:30}}>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>Select the desired Insulation type from the dropdown (all insulation types from AS3008 has been included)</Typography>
                    </div>
                    <Typography variant="h8" style={{ fontWeight: 'bold' }}>Conductor</Typography>
                    <div style={{marginLeft:30}}>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>Select the desired Conductor from the dropdown (all conductor types from AS3008 has been included)</Typography>
                    </div>


                    <Typography variant="h8" style={{ fontWeight: 'bold' }}></Typography>
                    <Typography variant="body1" style={{ fontStyle: 'italic' }}></Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">INSTALLATION DETAILS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Installation Method</Typography>
                <div style={{marginLeft:30}}>
                    <Typography variant="body1" style={{ fontStyle: '' }}>Select the cable installation method from the dropdown menu. All cable installation methods specified in AS/NZS 3008 are included for the selected cable type.</Typography>
                    </div>
                    <Typography variant="h8" style={{ fontWeight: 'bold' }}>Show Derating Options</Typography>
                <div style={{marginLeft:30}}>
                    <Typography variant="body1" style={{ fontStyle: '' }}>Select the option to use the derating wizard to apply appropriate derating factors from AS/NZS 3008 based on the selected cable type and installation method.</Typography>
                    </div>
                    <Typography variant="body1" style={{ fontStyle: 'italic' }}></Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">FAULT CURRENT DETAILS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Source Impedance</Typography>
                <div style={{marginLeft:30}}>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>Select the prefered method for source impedance calculation</Typography>
                    <div style={{marginLeft:30}}>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>Calculate : The source impedance for calculation is calculated from the entered supply fault level</Typography>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>Measured : The source impedance for calculation is directly taken from the input</Typography>
                    </div>
                    </div>
                    
                    <Typography variant="h8" style={{ fontWeight: 'bold' }}>Fault Level (kA)</Typography>
                    <div style={{marginLeft:30}}>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>Enter the fault current at supply in kA</Typography>
                    </div>
                    <Typography variant="h8" style={{ fontWeight: 'bold' }}>Source Impedance (Ohms)</Typography>
                    <div style={{marginLeft:30}}>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>Enter the source impedance measured at the supply in ohms</Typography>
                    </div>
                    <Typography variant="h8" style={{ fontWeight: 'bold' }}>Voltage Correction Factor (c)</Typography>
                    <div style={{marginLeft:30}}>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>The voltage factor c is used to scale the equivalent voltage source in the calculations to account for variations in the system voltage</Typography>
                    </div>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">PROTECTION</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <div style={{marginLeft:30}}>
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Protection Device Type</Typography>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>Select the type of protective device from the dropdown, currently</Typography>
                    <div style={{marginLeft:30}}>
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>MCB (Miniature Circuit Breaker)</Typography>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>MCB Curve Type - select the appropriate trip characteristics </Typography>
                    <div style={{marginLeft:30}}>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>B type - Instantaneous trip is 4 times the rated current</Typography>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>C type - Instantaneous trip is 7.5 times the rated current</Typography>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>D type - Instantaneous trip is 12 times the rated current</Typography>
                    </div>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>MCB Rating (A)</Typography>
                    <div style={{marginLeft:30}}>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>Select the appropriate rating for the Miniature Circuit Breaker</Typography>
                    </div>
                    </div>
                    </div>
                    <Typography variant="h8" style={{ fontWeight: 'bold' }}></Typography>
                    <Typography variant="body1" style={{ fontStyle: 'italic' }}></Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">CABLE SIZING</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Active Cable Size</Typography>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>Select between automatic and mannual cable sizing</Typography>
                    <div style={{marginLeft:30}}>
    
                    </div>
                    <div style={{marginLeft:30}}>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>Automatic Cable Sizing : Minimum required active cable size results will be generated based on the inputs</Typography>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>Mannual Cable Sizing : Cable results for the selected active cable will be generated, warnings will be displayed if any conditions are violated</Typography>
                    </div>

                    <Typography variant="h8" style={{ fontWeight: 'bold' }}>Earth Cable Size</Typography>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>Select between automatic and mannual earth cable sizing</Typography>
                    <div style={{marginLeft:30}}>

                    </div>
                    <div style={{marginLeft:30}}>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>Automatic Cable Sizing : Minimum required earth cable size results will be generated based on the table 5.1 from AS3000 if protective device is not selected and based on the fault loop impedance when protective device is selected</Typography>
                    <Typography variant="body1" style={{ fontStyle: ' ' }}>Mannual Cable Sizing : Cable results for the selected earth cable will be generated, warnings will be displayed if any conditions are violated</Typography>
                    </div>
                </AccordionDetails>
                </Accordion>
                    </AccordionDetails>

            </Accordion>


                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">ACTIVE CABLE SIZE</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <div style={{marginLeft:30}}>
                <Typography variant="body1" style={{  }}>The minimum active size selection of active cable size is dependent on,</Typography>

                <div style={{marginLeft:30}}>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Current-carrying capacity</Typography>
                </AccordionSummary>
                <div style={{marginLeft:30}}>
                <Typography variant="body1" style={{ marginBottom:10 }}>The load current should be less than the continuous derated current-carrying capacity of the cable</Typography>
                <TeX >{'\\text{Current Carrying Capacity of Cable} \\times \\text{Derating Factor} \\geq \\text{Load Current}'}</TeX>
                </div>
                </Accordion>
                </div>
                <div style={{marginLeft:30}}>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Voltage drop</Typography>
                </AccordionSummary>
                <div style={{marginLeft:30}}>
                <Typography variant="body1" style={{  }}>The Cable size is selected such that percentage voltage drop due to the impedance of the cable is less than the max voltage drop limit specified </Typography>
                <Typography variant="body1" style={{  marginBottom:10 }}>The voltage drop in the cable is calculated according to section 4.5 of AS3008, the determination of voltage drop from the load power factor.  </Typography>
                <Typography variant="body1" ><TeX >{'I (R_c \\cos \\theta + X_c \\sin \\theta) \\, \\text{lagging power factor}'}</TeX>  </Typography>
                <Typography variant="body1" style={{  marginTop:10 }}>The total voltage drop in the cable run is given by,</Typography>
                <div style={{marginLeft:30}}>
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Single phase, DC supply</Typography>
                <div style={{marginLeft:30}}>
                <Typography variant="body1" style={{  marginBottom:10 }}>The total voltage drop is the sum of the voltage drop of the active and neutral cables. </Typography>
                <div style={{marginLeft:30}}>
                <TeX >{'\\text{Total voltage drop} = 2 \\times \\text{Single phase voltage drop} '}</TeX>
                </div>
                </div>

                </div>
                <div style={{marginLeft:30}}>
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Three phase supply</Typography>
                <div style={{marginLeft:30}}>
                <Typography variant="body1" style={{  marginBottom:10 }}>The total voltage drop is given by </Typography>
                <div style={{marginLeft:30}}>
                <TeX >{'\\text{Total voltage drop} = 0.866 \\times \\text{Single phase voltage drop} '}</TeX>
                </div>
                </div>

                </div>
                <div style={{marginLeft:30}}>
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Two phase supply (120 degree)</Typography>
                <div style={{marginLeft:30}}>
                <Typography variant="body1" style={{  marginBottom:10 }}>The total voltage drop is given by </Typography>
                <div style={{marginLeft:30}}>
                <TeX >{'\\text{Total voltage drop} = 0.75 \\times \\text{Single phase voltage drop} '}</TeX>
                </div>
                </div>

                </div>
                
                </div>
               </Accordion>
                </div>
                <div style={{marginLeft:30}}>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Short-circuit temperature rise</Typography>
               </AccordionSummary>
                <div style={{marginLeft:30}}>
                <Typography variant="body1" style={{ marginBottom:10 }}>The short circuit capacity of the cable is selected based on the maximum allowed short-circuit temperatures of the current-carrying capacity limited by the adjacent material of the cables. The maximum temperature rise allowed for the current-carrying conductor based on adjacent material is given in Table 53 of AS3008. The temperatures in Table 53 are valid for a short circuit duration of 5 seconds. The maximum short circuit current capacity of cables is calculated by</Typography>
                <TeX >{'\\text{Short-Circuit Capacity} = \\sqrt{K^{2}\\text{Cable Size}^{2}/\\text{Short-Circuit Duration}}'}</TeX>
                <Typography variant="body1" style={{ marginTop:10 }}>Where K is determined from table 52 of AS3008</Typography>
                </div>
                </Accordion>
                </div>
                </div>

                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">EARTH CABLE SIZE</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography variant="body1" style={{  }}>The minimum earth cable size is selected in the following methods based on the availability of upstream protective device</Typography>
                <div style={{marginLeft:30}}>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Table 5.1 from AS3000 (when upstream protection device is not selected)</Typography>
                </AccordionSummary>
                
                <div style={{marginLeft:30}}>
                <AccordionDetails>
                <Typography variant="body1" style={{  }}>When upstream protective device is not selected, the earth cable size is selected from table 5.1 (AS3000) based on the active cable size selected</Typography>
                </AccordionDetails>
                </div>
                </Accordion>

                </div>
                <div style={{marginLeft:30}}>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>To satisfy earth fault loop impedance (when upstream protection device is selected)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <div style={{marginLeft:30}}>
                <Typography variant="body1" style={{  }}>When an upstream protective device is selected, the earth cable size is selected such that the total earth fault loop impedance is lower than the allowed maximum value based on the instantaneous trip current of the upstream breaker. </Typography>

                <Typography variant="body1" style={{ marginBottom:10 }}>The maximum allowed fault loop impedance is given by,</Typography>
                <div style={{marginLeft:30 }}>
                <TeX >{'\\text{Maximum allowed fault loop impedance} = \\frac{\\text{Phase Voltage}}{\\text{Instantaneous trip current of the protective device}}'}</TeX>
                </div>

                <Typography variant="body1" style={{ marginTop:10,marginBottom:10 }}>Total fault loop impedance is given by,</Typography>
                <div style={{marginLeft:30 }}>
                <TeX >{'\\text{Loop impedance} = \\text{Z}_{\\text{internal}} + \\text{Z}_{\\text{external}}'}</TeX>
                <div style={{ marginTop:10,marginBottom:10 }}>
                <TeX >{'\\text{Z}_{\\text{internal}} = \\text{Impedance of active cable} + \\text{Impedance of earth cable}'}</TeX>
                </div>
                <div style={{ marginTop:10,marginBottom:10 }}>
                <TeX >{'\\text{Z}_{\\text{external}} = \\text{20\\% of the total fault loop impedance (according to section B5.2.1)}'}</TeX>
                </div>
                </div>
                <Typography variant="body1" style={{ marginTop:10,marginBottom:10 }}>The selected earth cable size such that</Typography>
                <div style={{marginLeft:30 }}>
                <TeX >{'\\text{Loop impedance} \\leq \\text{Maximum allowed fault loop impedance}  '}</TeX>
                </div>
                </div>
                </AccordionDetails>
                </Accordion>
                </div>



                    
                </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">CABLE OPERATING TEMPERATURE</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <div style={{marginLeft:30 }}>
                    <Typography variant="body1" style={{marginBottom:10  }}>The operating temperature of cables based on its loading is calculated by,</Typography>
                    <div style={{marginLeft:30 }}>
                        <div style={{marginBottom:20  }}>
                    <TeX >{'\\theta_{0} = ((\\frac{I_{0}}{I_{R}})^{2}*(\\theta_{R} - \\theta_{A})) + \\theta_{A}'}</TeX>
                    </div>
                    <div style={{marginLeft:30,marginTop:10,marginBottom:10 }}>
                    <TeX >{'\\theta_{0} = \\text{operating temperature of cable when carrying} \\ \\text{I}_{0}, \\text{in degrees Celsius}'}</TeX>
                    </div>
                    <div style={{marginLeft:30,marginTop:10,marginBottom:10 }}>
                    <TeX >{'\\theta_{R} = \\text{operating temperature of the cable when carrying} \\ \\text{I}_{R}, \\text{in degrees Celsius}'}</TeX>
                    </div>
                    <div style={{marginLeft:30,marginTop:10,marginBottom:10 }}>
                    <TeX >{'\\theta_{A} = \\text{ambient air or soil temperature, in degrees Celsius}'}</TeX>
                    </div>
                    <div style={{marginLeft:30,marginTop:10,marginBottom:10 }}>
                    <TeX >{'\\text{I}_{0} = \\text{ambient air or soil temperature, in degrees Celsius}'}</TeX>
                    </div>
                    </div>
                    <Typography variant="body1" style={{marginBottom:10  }}>The calculated operating temperature (0) is then raised to the nearest temperature 45°C,
60°C, 75°C, 80°C, 90°C or 110°C for use with Tables 34 to 50 to determine the cable a.c.
resistance and three-phase voltage drop.</Typography>
                    </div>
                    </AccordionDetails>
                </Accordion>
        </Paper>
    );
};

export default CableSizeCalculatorHelp;
