import React, { useState, useEffect } from "react";
import InputValues from "./Components/Inputvalues/InputValuesComponent";
import HarmonicOrder1 from "./Components/harmonicorder1/HarmonicOrder";
import HarmonicOrder2 from "./Components/harmonicorder2/HarmonicOrder";
import HarmonicOrder3 from "./Components/harmonicorder3/HarmonicOrder";
import Chart from "./Components/Chart/Chartdesign";
import Chart1 from "./Components/Chart1/Chartdesign";
import Chart2 from "./Components/Chart2/Chartdesign";
import Header from "../HomePage/components/header/header";
import Footer from "../HomePage/components/footer/footer";
import Grid from "@mui/material/Grid";
import Results from "./Components/results/results";
import Results1 from "./Components/results1/results";
import Results2 from "./Components/results2/results";
import Resultsmain from "./Components/results_main/results";
import SaveCalculation from "./Components/SaveCalculation/SaveCalculation";
import OpenCalculation from "./Components/OpenCalculationComponent/OpenCalculation";
import ReportComponent from "./Components/ReportComponent/ReportComponent";
import axios from "axios";
import ImageContext from "./Components/ImageContext/ImageContext";
import { Paper } from "@mui/material";
import Styles from "./harmonicCalculator.module.scss";
import firebase from 'firebase/compat/app';
import { ToastContainer, toast } from "react-toastify";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { Helmet } from "react-helmet";

function HarmonicCalculator() {
  const [InputDetails, setInputDetails] = useState({});
  const [InputDetails_1, setInputDetails_1] = useState({});
  const [HarmonicOrderinput, setHarmonicOrder] = useState({});
  const [HarmonicOrderinput_1, setHarmonicOrder_1] = useState({});
  const [HarmonicOrderinput1, setHarmonicOrder1] = useState({});
  const [HarmonicOrderinput1_1, setHarmonicOrder1_1] = useState({});
  const [HarmonicOrderinput2, setHarmonicOrder2] = useState({});
  const [HarmonicOrderinput2_1, setHarmonicOrder2_1] = useState({});
  const [results, setResults] = useState({});
  const [editorImageUrl, setEditorImageUrl] = useState("");
  const [editorImageUrl1, setEditorImageUrl1] = useState("");
  const [editorImageUrl2, setEditorImageUrl2] = useState("");
  const [user, setUser] = useState(null);
  const [isPaidUser, setIsPaidUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (authUser) => {
      if (authUser) {
        const db = firebase.firestore();
        const userRef = db.collection('users').doc(authUser.uid);
        const doc = await userRef.get();
        if (doc.exists) {
          setUser(authUser);
          setIsPaidUser(doc.data().isPaidUser);
        }
      } else {
        setUser(null);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const payload = {
      InputDetails,
      HarmonicOrderinput,
      HarmonicOrderinput1,
      HarmonicOrderinput2,
    };

    axios
      .post(
        "https://app.wiringmaster.org/harmoniccalculate",
        payload
      )
      .then((response) => {
        setResults(response.data);
        console.log(
          "results.total_current_active",
          results.Calculation_results
        ); // Set the results in state
        // Handle the response data here
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setResults({ Calculation_results: null });
      });
  }, [
    InputDetails,
    HarmonicOrderinput,
    HarmonicOrderinput1,
    HarmonicOrderinput2,
  ]); // Trigger the effect whenever InputDetails state changes

  const handleInputChange = (data) => {
    setInputDetails(data); // Update the InputDetails state
  };

  const handleharmonicorderChange = (data) => {
    setHarmonicOrder(data); // Update the InputDetails state
  };

  const handleharmonicorderChange1 = (data) => {
    setHarmonicOrder1(data); // Update the InputDetails state
  };

  const handleharmonicorderChange2 = (data) => {
    setHarmonicOrder2(data); // Update the InputDetails state
  };

  const handleEditorImageUpdate = (url) => {
    setEditorImageUrl(url);
  };

  const handleEditorImageUpdate1 = (url) => {
    setEditorImageUrl1(url);
  };

  const handleEditorImageUpdate2 = (url) => {
    setEditorImageUrl2(url);
  };

  const handleOpenCalculation = (data) => {
    // Update your state with the loaded data
    setInputDetails(data.InputDetails);
    setInputDetails_1(data.InputDetails);
    setHarmonicOrder(data.HarmonicOrderinput);
    setHarmonicOrder_1(data.HarmonicOrderinput);
    setHarmonicOrder1(data.HarmonicOrderinput1);
    setHarmonicOrder1_1(data.HarmonicOrderinput1);
    setHarmonicOrder2(data.HarmonicOrderinput2);
    setHarmonicOrder2_1(data.HarmonicOrderinput2);
  };

  return (
    <>
<Helmet>
  <title>Harmonic Calculator for Accurate THD Analysis</title>
  <meta name="description" content="Perform harmonic distortion calculations by adding multiple harmonic components and checking sinusoidal waveform distortion. Calculate total harmonic distortion (THD) and total RMS current. Generate detailed harmonics calculation reports with Wiringmaster's Harmonic Calculator." />
  <meta name="keywords" content="harmonic distortion calculation, harmonic components, sinusoidal waveform distortion, total harmonic distortion, THD, RMS current, detailed harmonics calculation report" />
</Helmet>
      <Header />
      <ImageContext.Provider
        value={{
          editorImageUrl,
          setEditorImageUrl,
          editorImageUrl1,
          setEditorImageUrl1,
          editorImageUrl2,
          setEditorImageUrl2,
        }}
      >
              <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      
        <div className={Styles.mainContainer}>
          <Grid container spacing={5} direction={"column"}>
            <Grid item md={12} style={{ maxWidth: "100%" }}>
              <Grid container spacing={2}>
                <Grid item>
                  
                  {results.Calculation_results && (
                    <ReportComponent
                      InputDetails={InputDetails}
                      HarmonicOrderinput={HarmonicOrderinput}
                      HarmonicOrderinput1={HarmonicOrderinput1}
                      HarmonicOrderinput2={HarmonicOrderinput2}
                      results={results.Calculation_results}
                      isPaidUser={isPaidUser}
                    />
                  )}
                </Grid>
                <Grid item>
                {isPaidUser ? (
                  <SaveCalculation
                    InputDetails={InputDetails}
                    HarmonicOrderinput={HarmonicOrderinput}
                    HarmonicOrderinput1={HarmonicOrderinput1}
                    HarmonicOrderinput2={HarmonicOrderinput2}
                  />
                ) : (
                  <div  onClick={() => {
                    toast.error("Please purchase a subscription to access this feature");
                  }}>
                        <div style={{ pointerEvents: "none", opacity: 0.3 }}>
                        <SaveCalculation
                    InputDetails={InputDetails}
                    HarmonicOrderinput={HarmonicOrderinput}
                    HarmonicOrderinput1={HarmonicOrderinput1}
                    HarmonicOrderinput2={HarmonicOrderinput2}
                  />
                        </div>
                        </div>
                          )}
                </Grid>

                <Grid item>
                {isPaidUser ? (
                  <OpenCalculation onOpenCalculation={handleOpenCalculation} />
                ) : (
                  <div  onClick={() => {
                    toast.error("Please purchase a subscription to access this feature");
                  }}>
                     <div style={{ pointerEvents: "none", opacity: 0.3 }}>
                     <OpenCalculation onOpenCalculation={handleOpenCalculation} />
                     </div>
                     </div>
                )}

                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} style={{ maxWidth: "100%" }}>
              <Grid container spacing={2}>
                <Grid item md={8}>
                  <InputValues
                    OnChange={handleInputChange}
                    InputDetails_1={InputDetails_1}
                  />
                </Grid>
                <Grid item md={4}>
                  <Paper elevation={2}>
                    {results.Calculation_results && (
                      <Resultsmain
                        InputDetails={InputDetails}
                        results={results.Calculation_results}
                      />
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} style={{ maxWidth: "100%" }}>
              <Grid
                container
                spacing={2}
                direction="row"
                style={{ marginTop: 5 }}
              >
                <Grid item md={4}>
                  <Paper
                    elevation={3}
                    style={{
                      overflowY: "auto",
                      height: "50vh",
                      padding: 20,
                    }}
                  >
                    {InputDetails.phaseValues &&
                      InputDetails.phaseValues[1]?.loadCurrent !== 0 && (
                        <HarmonicOrder1
                          InputDetails={InputDetails}
                          OnChange={handleharmonicorderChange}
                          HarmonicOrderinput_1={HarmonicOrderinput_1}
                          Styles={Styles}
                        />
                      )}
                  </Paper>
                </Grid>
                <Grid item md={5}>
                  <Paper elevation={3} className={Styles.resultChartContainer}>
                    {HarmonicOrderinput.length > 0 && (
                      <Chart
                        HarmonicOrderinput={HarmonicOrderinput}
                        InputDetails={InputDetails}
                        onImageUpdate={handleEditorImageUpdate}
                      />
                    )}
                  </Paper>
                </Grid>
                <Grid item md={3}>
                  <Paper elevation={3} className={Styles.resultChartContainer}>
                    {results.Calculation_results && (
                      <Results
                        InputDetails={InputDetails}
                        results={results.Calculation_results}
                      />
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            {InputDetails.phase == "Three-phase" &&
              InputDetails.isUnbalanced == true && (
                <Grid item md={12} style={{ maxWidth: "100%" }}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    style={{ marginTop: 5 }}
                  >
                    {/* Render HarmonicOrder2 and Chart1 only if loadPowerFactor is available */}

                    <Grid item md={4}>
                      <Paper
                        elevation={3}
                        style={{
                          overflowY: "auto",
                          height: "50vh",
                          padding: 20,
                        }}
                      >
                        {InputDetails.phaseValues &&
                          InputDetails.phaseValues[2]?.loadCurrent !== 0 && (
                            <HarmonicOrder2
                              InputDetails={InputDetails}
                              OnChange={handleharmonicorderChange1}
                              HarmonicOrderinput1_1={HarmonicOrderinput1_1}
                              Styles={Styles}
                            />
                          )}
                      </Paper>
                    </Grid>
                    <Grid item md={5}>
                      <Paper elevation={3} className={Styles.resultChartContainer}>
                        {HarmonicOrderinput1.length > 0 &&
                          InputDetails.phaseValues[2]?.loadPowerFactor && (
                            <Chart1
                              HarmonicOrderinput={HarmonicOrderinput1}
                              InputDetails={InputDetails}
                              onImageUpdate={handleEditorImageUpdate1}
                            />
                            
                          )}
                      </Paper>
                    </Grid>

                    <Grid item md={3}>
                      <Paper elevation={3} className={Styles.resultChartContainer}>
                        {results.Calculation_results && (
                          <Results1
                            InputDetails={InputDetails}
                            results={results.Calculation_results}
                          />
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              )}

            {InputDetails.phase == "Three-phase" &&
              InputDetails.isUnbalanced == true && (
                <Grid item md={12} style={{ maxWidth: "100%" }}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    style={{ marginTop: 5 }}
                  >
                    <Grid item md={4}>
                      <Paper
                        elevation={3}
                        style={{
                          overflowY: "auto",
                          height: "50vh",
                          padding: 20,
                        }}
                      >
                        {InputDetails.phaseValues &&
                          InputDetails.phaseValues[3]?.loadCurrent !== 0 && (
                            <HarmonicOrder3
                              InputDetails={InputDetails}
                              OnChange={handleharmonicorderChange2}
                              HarmonicOrderinput2_1={HarmonicOrderinput2_1}
                              Styles={Styles}
                            />
                          )}
                      </Paper>
                    </Grid>
                    <Grid item md={5}>
                      <Paper elevation={3} className={Styles.resultChartContainer}>
                        {HarmonicOrderinput2.length > 0 &&
                          InputDetails.phaseValues[3]?.loadPowerFactor && (
                            <Chart2
                              HarmonicOrderinput={HarmonicOrderinput2}
                              InputDetails={InputDetails}
                              onImageUpdate={handleEditorImageUpdate2}
                            />
                          )}
                      </Paper>
                    </Grid>

                    <Grid item md={3}>
                      <Paper elevation={3} className={Styles.resultChartContainer}>
                        {results.Calculation_results && (
                          <Results2
                            InputDetails={InputDetails}
                            results={results.Calculation_results}
                          />
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              )}
          </Grid>
        </div>
       
      </ImageContext.Provider>
      <Footer />
    </>
  );
}

export default HarmonicCalculator;
