import React, { useContext } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink,Image } from '@react-pdf/renderer';
import Button from '@mui/material/Button';
import ImageContext from '../ImageContext/ImageContext'
// import ImageContext  from "src/components/CableSizeCalculator/components/ImageContext/ImageContext.js"

function ReportComponent ({formData,results,isPaidUser}){

    const { editorImageUrl, setEditorImageUrl } = useContext(ImageContext);

    const Earc_cal = Math.max(results?.Earc_cal, results?.Earc_reduced_cal);
    const AFB = Math.max(results?.AFB,results?.AFB_reduced).toFixed(2)


    const getPPECategory = (incidentEnergy) => {
      if (incidentEnergy <= 1.2) {
        return "No PPE Required (Incident Energy < 1.2 cal/cm2";
      } else if (incidentEnergy <= 4) {
        return "Category 1";
      } else if (incidentEnergy <= 8) {
        return "Category 2";
      }else if (incidentEnergy <= 25) {
        return "Category 3";
      } else if (incidentEnergy <= 40) {
        return "Category 4";
      } else {
        return "No Category Applicable (Incident Energy > 40 cal/cm2";
      }
    };

    const ppe = getPPECategory(Earc_cal)
    

   




    const Header = () => (
        <View style={styles.headerContainer}>
          <Text style={styles.headerLeftText}>Arc Flash Assessment Report</Text>
          <Text style={styles.headerRightText}>WIRING MASTER</Text>
        </View>
      );
      
      const Footer = () => (
        <View style={styles.footerContainer}>
          <Text style={styles.footerText}>
          Disclaimer: This information does not constitute legal, professional or commercial advice. Wiring Master gives no guarantees, undertakings or warranties in
this regard, and does not accept any legal liability or responsibility for the content or the accuracy of the information so provided, or, for any loss or
damage caused arising directly or indirectly in connection with reliance on the use of such information.
          </Text>
        </View>
      );



    const styles = StyleSheet.create({
        heading: {
          fontFamily: 'Times-Roman',
          fontSize: 18,
          fontWeight: 16,
          marginBottom: 1,
        },
        normalText: {
          fontFamily: 'Times-Roman',
          fontSize: 12,
          marginBottom: 4,
        },
        row: {
          flexDirection: 'row',
          alignItems: 'stretch',
        },
        column: {
          flex: 1, // Adjust flex ratio based on your needs
          padding: 5, // Adjust padding as needed
        },
        superscript: {
            fontFamily: 'Times-Roman',
            fontSize: 8, // Smaller font size for superscript
            alignSelf: 'flex-start', // Align to top to simulate superscript position
            marginLeft: 2, // Space between main text and superscript
          },
          inlineContainer: {
            flexDirection: 'row', // Ensures inline layout
            alignItems: 'flex-start', // Aligns items to the start of the container
          },
          fullPageUnderline: {
            borderBottomWidth: 1,
            borderBottomColor: '#000',
            borderUpperColor: '#000',
            marginBottom: 1,
            marginUpper: 1,
              // Adjust as needed
          },
          image: {
            width: '100%', // Adjust as needed
            height: 'auto', // Adjust for aspect ratio
            marginVertical: 0,
            alignItems: "center",
            marginBottom: 20
          },
          headerContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottomWidth: 1,
            borderBottomColor: '#000',
            paddingBottom: 10, // Increase padding for more height
            paddingTop: 10, 
            paddingLeft:10,
            paddingRight: 10,   // Add top padding for symmetry
            width: '100%',     // Ensure full width
          },
          headerLeftText: {
            fontFamily: 'Times-Roman', // Match the font family
            fontSize: 18,              // Adjust font size as needed
            textAlign: 'left',
          },
          headerRightText: {
            fontFamily: 'Times-Roman', // Match the font family
            fontSize: 18,              // Adjust font size as needed
            textAlign: 'right',
          },
          footerContainer: {
            position: 'absolute', // Use absolute positioning
            bottom: 1,            // Pin to the bottom
            borderTopWidth: 1,
            borderTopColor: '#000',
            paddingTop: 4,
            width: '100%', // Ensure full width
          },
          footerText: {
            fontSize: 10,

            marginHorizontal: 12, // Adjust as needed for margins
          },
          watermark: {
            position: 'absolute',
            fontSize: 50,
            color: 'orange',
            opacity: 0.9,
            top: 170,
            left: 10,
            fontWeight: 'bold',
            transform: 'rotate(-45deg)',
          },
          watermark2: {
            position: 'absolute',
            fontSize: 50,
            color: 'orange',
            opacity: 0.9,
            top: 500,
            left: 10,
            fontWeight: 'bold',
            transform: 'rotate(-45deg)',
          },
        });


        // Additional styles as needed





    const MyDocument = () => (
            <Document>
            <Page > 
            <Header />
            {!isPaidUser ? (
          <>
            <Text style={styles.watermark}>FREE PREVIEW - NOT FOR COMMERCIAL USE</Text>
            <Text style={styles.watermark2}>PLEASE PURCHASE A SUBSCRIPTION TO REMOVE WATERMARK</Text>
          </>
        ) : null}
            <View style={{ padding: 20 }}>
                    <View>
                        <View>
                            {/* <Image style={styles.image} src={editorImageUrl}/> */}
                            <Image style={styles.image} src={editorImageUrl} />
                        </View>
                       </View>
                       

                       <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>Incident Energy Results</Text>
                                </View>

                                <View style={styles.row}>
                                        <View style={styles.column}>
                                            <Text style={styles.normalText}> Incident Energy at working distance:</Text>
                                            <Text style={styles.normalText}> PPE Catergory:</Text>
                                            <Text style={styles.normalText}> Working Distance:</Text>
                                            
                                </View>
                                <View style={styles.column}>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{Earc_cal.toFixed(3)} cal/cm</Text>
                                            <Text style={styles.superscript}>2</Text>
                                            </View>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{ppe}</Text>
                                            
                                            
                                            </View>
                                            <Text style={styles.normalText}>{formData.workingDistance} mm</Text>
                                </View>                                


                        </View>
                        <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>Detailed Results</Text>
                                </View>


                                <View style={styles.row}>
                                        <View style={styles.column}>
                                            <Text style={styles.normalText}> Arcing Current:</Text>
                                            <Text style={styles.normalText}> Incident Energy:</Text>
                                            <Text style={styles.normalText}> Incident Energy:</Text>
                                            <Text style={styles.normalText}> Arc Flash Boundary:</Text>
                                            
                                </View>
                                <View style={styles.column}>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{results.Iarc.toFixed(2)} kA</Text>
                                            <Text style={styles.superscript}></Text>
                                            </View>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{results.Earc_cal.toFixed(2)} cal/cm</Text>
                                            <Text style={styles.superscript}>2</Text>
                                            </View>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{results.Earc.toFixed(2)} J/cm</Text>
                                            <Text style={styles.superscript}>2</Text>
                                            </View>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{results.AFB.toFixed(2)} mm</Text>
                                            <Text style={styles.superscript}></Text>
                                            </View>
                                </View>   
                                <View style={styles.column}>
                                            <Text style={styles.normalText}> Arcing Current Reduced:</Text>
                                            <Text style={styles.normalText}> Incident Energy Reduced:</Text>
                                            <Text style={styles.normalText}> Incident Energy Reduced:</Text>
                                            <Text style={styles.normalText}> AFB Reduced:</Text>
                                            
                                </View>
                                <View style={styles.column}>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{results.Iarc_reduced.toFixed(2)} kA</Text>
                                            <Text style={styles.superscript}></Text>
                                            </View>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{results.Earc_reduced_cal.toFixed(2)} cal/cm</Text>
                                            <Text style={styles.superscript}>2</Text>
                                            </View>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{results.Earc_reduced.toFixed(2)} J/cm</Text>
                                            <Text style={styles.superscript}>2</Text>
                                            </View>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{results.AFB_reduced.toFixed(2)} mm</Text>
                                            <Text style={styles.superscript}></Text>
                                            </View>
                                </View>                               


                        </View>

                        <View style={styles.row}>
                      
 
                                        <View style={styles.column}>
                                        <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>Supply Details</Text>
                                </View>
                                <View style={styles.row}>
                                <View style={styles.column}>
                                <Text style={styles.normalText}>Supply Voltage:</Text>
                                <Text style={styles.normalText}>Bolted Fault Current:</Text>
                                <Text style={styles.normalText}>Working Distance:</Text>
                                </View>
                                <View style={styles.column}>
                                <Text style={styles.normalText}>{formData.supplyVoltage} V</Text>
                                <Text style={styles.normalText}>{formData.boltedFaultCurrent} kA</Text>
                                <Text style={styles.normalText}>{formData.workingDistance} mm</Text>
                                </View>
                                </View>


                               
                                            
                                </View>
                                <View style={styles.column}>
                                <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>Enclosure Details</Text>
                                </View>
                                <View style={styles.row}>
                                <View style={styles.column}>
                                <Text style={styles.normalText}>Electrode Configuration:</Text>
                                <Text style={styles.normalText}>Enclosure Width:</Text>
                                <Text style={styles.normalText}>Enclosure Height:</Text>
                                <Text style={styles.normalText}>Enclosure Depth:</Text>
                                <Text style={styles.normalText}>Conductor Gap:</Text>
                                </View>
                                <View style={styles.column}>
                                <Text style={styles.normalText}>{formData.electrodeConfiguration}</Text>
                                <Text style={styles.normalText}>{formData.enclosureWidth} mm</Text>
                                <Text style={styles.normalText}>{formData.enclosureHeight} mm</Text>
                                <Text style={styles.normalText}>{formData.enclosureDepth} mm</Text>
                                <Text style={styles.normalText}>{formData.conductorGap} mm</Text>
                                </View>
                                </View>
                                
                                </View>   

                                </View>
                                <View>
                                <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>Protection Settings</Text>
                                </View>

                                <View style={styles.row}>
                                        <View style={styles.column}>
                                            <Text style={styles.normalText}>Fault clearing time for arcing current:</Text>
                                            <Text style={styles.normalText}>Fault clearing time for reduced arcing current:</Text>
                                            
                                </View>
                                <View style={styles.column}>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{formData.arcDuration} ms</Text>
                                            <Text style={styles.superscript}></Text>
                                            </View>
                                            <View style={styles.inlineContainer}>
                                            <Text style={styles.normalText}>{formData.arcDurationReduced} ms</Text>
                                            
                                            </View>
                                </View>     
                                </View>                             


                        </View>
                              


                        
                        </View>
                        <Footer/>
                       
            </Page>
            {/* More Pages as needed */}
            </Document>
            
            
      );

      return (
        <div>
        <PDFDownloadLink
            document={<MyDocument />}
            fileName="Arc Flash Report.pdf"
        >
            {({ blob, url, loading, error }) =>
                loading ? (
                    <Button variant="contained" color="primary" disabled>
                        Loading document...
                    </Button>
                ) : (
                    <Button variant="contained" color="primary">
                        Download Report
                    </Button>
                )
            }
        </PDFDownloadLink>
        </div>
    );
}


export default ReportComponent;