import React, { useState, useEffect } from 'react';
import { Paper, TextField, Typography } from '@mui/material';

function LoadComponent({ onChange,loadDetails1}) {
  const [loadCurrent, setLoadCurrent] = useState(50); // Default value 50
  const [powerFactor, setPowerFactor] = useState(0.9); // Default value 0.9

    // Use useEffect to update the state when loadDetails prop changes

    useEffect(() => {
      if (loadDetails1 ) {
        setLoadCurrent((loadDetails1.loadCurrent ? loadDetails1.loadCurrent:50 ))
        setPowerFactor(((loadDetails1.powerFactor ? loadDetails1.powerFactor:0.9 )))
      }
    }, [loadDetails1]);



  const handleLoadCurrentChange = (event) => {
    let newLoadCurrent = parseFloat(event.target.value);

    // Check if the value is NaN (not a number) or less than or equal to zero
    if (isNaN(newLoadCurrent) || newLoadCurrent <= 0) {
        newLoadCurrent = 1; // Set to a default value like 1 to avoid zero or negative values
    }

    setLoadCurrent(newLoadCurrent);
    updateParent(newLoadCurrent, powerFactor);
};

const handlePowerFactorChange = (event) => {
  let newPowerFactor = parseFloat(event.target.value);

  // Check if the value is NaN (not a number), less than 0, or greater than 1
  if (isNaN(newPowerFactor) || newPowerFactor < 0 || newPowerFactor > 1) {
      newPowerFactor = 0.9; // Set to a default value like 0.9
  }

  setPowerFactor(newPowerFactor);
  updateParent(loadCurrent, newPowerFactor);
};

  useEffect(() => {
    updateParent(loadCurrent, powerFactor);
  }, [loadCurrent, powerFactor]);

  const updateParent = (loadCurrent, powerFactor) => {
    onChange({ loadCurrent, powerFactor });
  };

  return (
    <Paper style={{ padding: '20px', margin: '20px 0', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
      <Typography variant="h5" style={{ marginBottom: '20px' }}>LOAD DETAILS</Typography>
      <TextField
        label="Load Current (A)"
        type="number"
        value={loadCurrent}
        onChange={handleLoadCurrentChange}
        variant="outlined"
        style={{ marginBottom: '10px',  }}
        fullWidth
        
      />
      <TextField
        label="Power Factor"
        type="number"
        value={powerFactor}
        onChange={handlePowerFactorChange}
        variant="outlined"
        fullWidth
      />
    </Paper>
  );
}

export default LoadComponent;
