import React, { useState,useEffect } from 'react';
import axios from 'axios';
import InputAddressComponent from './Components/InputAddressComponent/InputAddressComponent';
import MapComponent from './Components/MapComponent/MapComponent';
import MapLayerControlCompoent from './Components/MapLayerControlCompoent/MapLayerControlCompoent';
import MapLayerInformationComponent from './Components/MapLayerInformationComponent/MapLayerInformationComponent';
import BuildinInsightComponent from './Components/BuildingInsightComponent/BuildingInsightComponent'
import FinancialAnalysisComponent from './Components/FinancialcalculationComponent/FinacialcalculationComponent'
import ReportComponent from './Components/ReportComponent/ReportComponent'
import  ImageContext  from "./Components/ImageContext/ImageContext"


const API_KEY = 'AIzaSyAszNn29pi1egYN33Hr9SA0KVQYW_wqYFw'; // Replace 'YOUR_API_KEY' with your actual API key

const MyComponent = () => {
    const [address, setAddress] = useState('');
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [map, setMap] = useState(null);
    const [dsmUrl, setDsmUrl] = useState(null);
    const [rgbUrl, setRgbUrl] = useState(null);
    const [maskUrl, setMaskUrl] = useState(null);
    const [annualFluxUrl, setAnnualFluxUrl] = useState(null);
    const [monthlyFluxUrl, setMonthlyFluxUrl] = useState(null);
    const [hourlyShadeUrls, setHourlyShadeUrls] = useState([]);
    const [addressSuggestions, setAddressSuggestions] = useState([]);
    const [Colorbar, setColorbar] = useState({});
    const [building_insights, setBuildingInsights] = useState({});
    const [pannel, setpannel] = useState({});
    const [selectedLayer, setSelectedLayer] = useState('rgblayer');
    const [selectedMonth, setSelectedMonth] = useState('0');
    const [pannelIndex, setpannelIndex] = useState(0);
    const [manualPlacementMode, setManualPlacementMode] = useState(true);
    const [financialanalysisdata, setfinancialanalysisdata] = useState({});
    const [financialanalysisresults, setfinancialanalysisresults] = useState({});
    const [mapImageUrl, setMapImageUrl] = useState('');
    const [financialImageUrl, setFinancialImageUrl] = useState('');



    const handlefinancialanalysisdata = (manualPlacementDetails,pannelcapacity,totalenergymannual,maxSunshineSegments,panelHeight,panelWidth) => {
        setfinancialanalysisdata({"manualPlacementDetails": manualPlacementDetails, "pannelcapacity": pannelcapacity, "totalenergymannual":totalenergymannual, "maxSunshineSegments":maxSunshineSegments,"panelHeight":panelHeight,"panelWidth":panelWidth });
        // Update map overlays based on selectedLayer and selectedMonth
    };


    const handlefinancialanalysisresults = (solarAnalysisResult,crossoverYear,monthlyBill,energyCostPerKwh,solarInsentives,installationCostPerWatt,dcToAcConversionFactor,panelEfficiencyDepreciationFactor,panelLifetime,energyCostPerYear,discountRatePerYear) => {
        setfinancialanalysisresults({"solarAnalysisResult":solarAnalysisResult,"crossoverYear":crossoverYear, "monthlyBill":monthlyBill, "energyCostPerKwh":energyCostPerKwh,"solarInsentives":solarInsentives,"installationCostPerWatt":installationCostPerWatt,"dcToAcConversionFactor":dcToAcConversionFactor,"panelEfficiencyDepreciationFactor":panelEfficiencyDepreciationFactor,"panelLifetime":panelLifetime,"energyCostPerYear":energyCostPerYear,"discountRatePerYear":discountRatePerYear });
        console.log("financialanalysisresults",financialanalysisresults)
        // Update map overlays based on selectedLayer and selectedMonth
    };


    const handleLayerChange = (layer, month) => {
        setSelectedLayer(layer);
        setSelectedMonth(month);
        // Update map overlays based on selectedLayer and selectedMonth
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
        // Call Google Places Autocomplete service to get address suggestions
        fetchAddressSuggestions(e.target.value);
    };
  



    const handleUpdateIndex = (e) => {
        setpannelIndex(e);
    };

    const handleupdatemanualPlacementMode = (e) => {
        setManualPlacementMode(e);
    };


    const handleEditorImageUpdate = (url) => {
        setMapImageUrl(url);
      };

      const handlefinancialImageUpdate = (url) => {
        setFinancialImageUrl(url);
      };


  
    const fetchAddressSuggestions = async (input) => {
        try {
            const response = await axios.get(`https://app.wiringmaster.org/autocomplete?input=${encodeURIComponent(input)}`);
            setAddressSuggestions(response.data.predictions.map(prediction => prediction.description));
        } catch (error) {
            console.error('Error fetching address suggestions:', error);
        }
    };

    const handleSelectAddress = async (selectedAddress) => {
        setAddress(selectedAddress);
        setAddressSuggestions([])
        try {
            const response = await axios.post('https://app.wiringmaster.org/processaddress', { address: selectedAddress });
            const { lat, lng,dsmUrl, rgbUrl,monthly_flux_url,roof_mask_url,annual_flux_url, building_insight_data,panel_bounds} = response.data;

            setLatitude(lat);
            setLongitude(lng);
            setRgbUrl(rgbUrl);
            setDsmUrl(dsmUrl);
            setMonthlyFluxUrl(monthly_flux_url);
            setAnnualFluxUrl(annual_flux_url);
            setMaskUrl(roof_mask_url);
            setpannel(panel_bounds);
            setBuildingInsights(building_insight_data);
            console.log("building_insight_data",building_insight_data)
            

        } catch (error) {
            console.error('Error processing address:', error);
        }
    };




 
    return (
        <ImageContext.Provider value={{ mapImageUrl, setMapImageUrl,financialImageUrl, setFinancialImageUrl }}>
        <div>
            <InputAddressComponent
                address={address}
                addressSuggestions={addressSuggestions}
                handleAddressChange={handleAddressChange}
                handleSelectAddress={handleSelectAddress}
            />
            {Object.keys(building_insights).length > 0 && (
                <MapComponent
                    latitude={latitude}
                    longitude={longitude}
                    rgbUrl={rgbUrl}
                    monthlyFluxUrl={monthlyFluxUrl}
                    dsmUrl={dsmUrl}
                    maskUrl={maskUrl}
                    annualFluxUrl={annualFluxUrl}
                    building_insights={building_insights}
                    API_KEY={API_KEY}
                    selectedLayer={selectedLayer}
                    selectedMonth={selectedMonth}
                    panel_bounds={pannel}
                    pannelIndex={pannelIndex}
                    manualPlacementMode={manualPlacementMode}
                    financialanalysisdata={handlefinancialanalysisdata}
                    onImageUpdate={handleEditorImageUpdate}
                />
            )}
            {Object.keys(building_insights).length > 0 && (
            <MapLayerControlCompoent onLayerChange={handleLayerChange} />
            )}
            {Object.keys(building_insights).length > 0 && (
            <BuildinInsightComponent building_insights={building_insights} 
            updateindex={handleUpdateIndex} 
            updatemanualPlacementMode={handleupdatemanualPlacementMode}
             />
            )}

           {financialanalysisdata.manualPlacementDetails && financialanalysisdata?.manualPlacementDetails?.length > 0 && (
            <FinancialAnalysisComponent financialanalysisdata={financialanalysisdata} onImageUpdate={handlefinancialImageUpdate} financialanalysisresults={handlefinancialanalysisresults}  />
            )}

            {financialanalysisdata.manualPlacementDetails && financialanalysisdata?.manualPlacementDetails?.length > 0 && financialanalysisdata && financialanalysisresults && (
            <ReportComponent address={address} 
            financialanalysisdata ={financialanalysisdata} 
            financialanalysisresults={financialanalysisresults}/>
            )}

        </div>
        </ImageContext.Provider>
    );
};

export default MyComponent;