import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import InputComponent from './InputComponent/InputComponent'
import ResultComponent from './ResultComponent/ResultComponent'
import ArcFlashRepresentation from './ArcFlashEditor/ArcFlashEditor'
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import SaveCalculation from './SaveCalculation/SaveCalculation'
import OpenCalculation from './OpenCalculationComponent/OpenCalculation'
import ReportComponent from './ReportComponent/ReportComponent'
import ImageContext from './ImageContext/ImageContext'
import Header from '../HomePage/components/header/header';
import Footer from '../HomePage/components/footer/footer';
import firebase from 'firebase/compat/app';
import { ToastContainer, toast } from "react-toastify";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import LatexComponent from './LatexComponent/LatexComponent';
import ArcFlashClculatorHelp from './ArcFlashCalculatorHelp/ArcFlashCalculatorHelp';
import { Helmet } from "react-helmet";

const MyComponent = () => {
  const [formData, setFormData] = useState(null);
  const [formData1, setFormData1] = useState(null);
  const [results, setResults] = useState(null);
  const editorRef = useRef(null);
  const [editorImageUrl, setEditorImageUrl] = useState("");
  const [editorSize, setEditorSize] = useState({ width: 0, height: 0 });
  const [user, setUser] = useState(null);
  const [isPaidUser, setIsPaidUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
 

  useEffect(() => {
    // You can use formData here to send it to your backend via Axios
    if (formData) {
      axios.post('https://app.wiringmaster.org/arcFlashCalculate', formData)
        .then(response => {
          setResults(response.data); 
        })
        .catch(error => {
          setResults(null); 
        });
    }
    else{
      setResults(null);
    }
  }, [formData]);




  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (authUser) => {
      if (authUser) {
        const db = firebase.firestore();
        const userRef = db.collection('users').doc(authUser.uid);
        const doc = await userRef.get();
        if (doc.exists) {
          setUser(authUser);
          setIsPaidUser(doc.data().isPaidUser);
        }
      } else {
        setUser(null);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleUpdate = (data) => {
    setFormData(data);
  };



  const handleOpenCalculation = (data) => {
    // Update your state with the loaded data
    setFormData(data);
    setFormData1(data);

  };

  const handleEditorImageUpdate = (url) => {
    setEditorImageUrl(url);
  };




    useEffect(() => {
      const updateSize = () => {
        if (editorRef.current) {
          setTimeout(() => {
            setEditorSize({
              width: editorRef.current.offsetWidth,
              height: editorRef.current.offsetHeight,
            });
          }, 0); // Delay of 100ms
        }
      };
  
      window.addEventListener("resize", updateSize);
      updateSize();
  
      return () => {
        window.removeEventListener("resize", updateSize);
      };
    }, [results]);

  
  return (
    <ImageContext.Provider value={{ editorImageUrl, setEditorImageUrl}}>
<Helmet>
  <title>Arc Flash Calculator Based on IEEE-1584 2018</title>
  <meta name="description" content="Arc Flash Calculation with IEEE 1584 2018. Perform hazard analysis based on IEEE 1584 2018. Exact PPE category calculation, enclosure size correction, and arcing current variation calculation based on IEEE 1584 2018. Generate detailed Arc Flash Assessment reports." />
  <meta name="keywords" content="Arc Flash Calculation, IEEE 1584 2018, Arc Flash hazard analysis, PPE category calculation, enclosure size correction, arcing current variation, Arc Flash Assessment report" />
</Helmet>
      <Header/>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Paper elevation={5} style={{  margin: 20, padding: 10 }}>
    <div style={{  margin: 'auto', marginTop:20 }}>
      <Grid container spacing={3}>
      <Grid item>
  {isPaidUser ? (
    <SaveCalculation formData={formData} />
  ) : (
    <div  onClick={() => {
      toast.error("Please purchase a subscription to access this feature");
    }}>
    <div style={{ pointerEvents: "none", opacity: 0.3 }}>
      <SaveCalculation formData={formData}  />
      </div>
      </div>
  )}
</Grid>
<Grid item>
  {isPaidUser ? (
    <OpenCalculation onOpenCalculation={handleOpenCalculation} />
  ) : (
    <div onClick={() => {
      toast.error("Please purchase a subscription to access this feature");
    }}>
      <div style={{ pointerEvents: "none", opacity: 0.3 }}>
        <OpenCalculation onOpenCalculation={handleOpenCalculation} />
      </div>
    </div>
  )}
</Grid>
        <Grid item>
          { results && formData &&
        <ReportComponent
formData = {formData}
results = {results}
isPaidUser = {isPaidUser}

                />}
                </Grid>
      </Grid>
      <Grid container  direction="column" spacing={0}>
      <Grid item>
      <Grid container spacing={3}>
      <Grid item md={8} xs={12} ref={editorRef}> {/* Ref on this Grid item */}
      { results && formData && editorSize.width > 70 && editorSize.height > 100  &&  <ArcFlashRepresentation  size={editorSize} formData={formData} results={results} onImageUpdate={handleEditorImageUpdate} />} 

      </Grid>  

      <Grid item md={4} xs ={12}>
      {results && <ResultComponent results={results} />}
      </Grid>
      </Grid>
      </Grid>
      <Grid item>
      <InputComponent onUpdate={handleUpdate} formData1={formData1}  />
      </Grid>
      

      </Grid>


    </div>
    </Paper>
<ArcFlashClculatorHelp/>

    <Footer/>
    </ImageContext.Provider>
    
  );
};

export default MyComponent;
