// ImageContext.js
import React from 'react';

const ImageContext = React.createContext({
  editorImageUrl: '',
  setEditorImageUrl: () => {},
  editorImageUrl1: '',
  setEditorImageUrl1: () => {},
  editorImageUrl2: '',
  setEditorImageUrl2: () => {}
});

export default ImageContext;