import React, { useState } from 'react';
import { Paper, FormControl, InputLabel, Select, MenuItem,Typography} from '@mui/material';

const LayerControlComponent = ({ onLayerChange }) => {
    const [selectedLayer, setSelectedLayer] = useState('rgblayer');
    const [selectedMonth, setSelectedMonth] = useState('0');

    const handleLayerChange = (event) => {
        const layer = event.target.value;
        setSelectedLayer(layer);
        onLayerChange(layer, selectedMonth); // Pass updated layer directly
    };
    
    const handleMonthChange = (event) => {
        const month = event.target.value;
        setSelectedMonth(month);
        onLayerChange(selectedLayer, month); // Pass updated month directly
    };

    return (
        <Paper style={{ padding: '20px', margin: '20px 0', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
            <Typography variant="h5" style={{ marginBottom: '20px' }}>MAP SETTINGS</Typography>
            <FormControl fullWidth style={{ marginBottom: '30px' }}>
            <InputLabel id="layer-select-label" >Map Overlays</InputLabel>
            <Select
                labelId="layer-select-label"
                value={selectedLayer}
                onChange={handleLayerChange}
                label="Map Overlays"
            >
                <MenuItem value="rgblayer">No Layer</MenuItem>
                <MenuItem value="elevation">Elevation Data</MenuItem>
                <MenuItem value="monthlyFlux">Monthly Flux Data</MenuItem>
                <MenuItem value="annualFlux">Annual Flux Data</MenuItem>
            </Select>
        </FormControl>
            {selectedLayer === 'monthlyFlux' && (
                <FormControl fullWidth>
                    <InputLabel id="month-select-label">Month</InputLabel>
                    <Select
                        labelId="month-select-label"
                        value={selectedMonth}
                        onChange={handleMonthChange}
                        label="Month"
                    >
                        <MenuItem value="0">January</MenuItem>
                        <MenuItem value="1">February</MenuItem>
                        <MenuItem value="2">March</MenuItem>
                        <MenuItem value="3">April</MenuItem>
                        <MenuItem value="4">May</MenuItem>
                        <MenuItem value="5">June</MenuItem>
                        <MenuItem value="6">July</MenuItem>
                        <MenuItem value="7">August</MenuItem>
                        <MenuItem value="8">September</MenuItem>
                        <MenuItem value="9">October</MenuItem>
                        <MenuItem value="10">November</MenuItem>
                        <MenuItem value="11">December</MenuItem>
                    </Select>
                </FormControl>
            )}
        </Paper>
    );
};

export default LayerControlComponent;
