import React from 'react';
import { Typography, Grid, Paper, Box,Container,Link } from '@mui/material';
import Header from "../HomePage/components/header/header";
import Footer from "../HomePage/components/footer/footer";

function AboutUsPage() {
  const paperStyle = {
    padding: '20px',
    margin: '20px 0',
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />

      <Box style={{ padding: '20px', flexGrow: 1 }}>
        {/* Company Overview */}
        <Paper style={paperStyle}>
        <Container maxWidth="md" style={{ paddingTop: '20px' }}>
      <Typography variant="h4" align="center" gutterBottom>
        About Wiring Master
      </Typography>
      <Typography variant="body1" paragraph>
        Wiring Master was born out of a passion for electrical engineering. Our journey began with a deep love for
        electrical systems and a desire to make the field more accessible and efficient for engineers worldwide. Over
        time, we developed our skills in web development to create powerful web applications tailored specifically for
        electrical engineers.
      </Typography>
      <Typography variant="body1" paragraph>
        Today, Wiring Master proudly offers a range of calculators designed to assist electrical engineers in their
        daily tasks. Our calculators simplify complex tasks such as cable sizing calculations, max demand calculations, harmonic calculations and arc flash
        calculations, all based on relevent standards. We believe that by providing these tools, we can contribute to
        the success and productivity of electrical engineers across the globe.
      </Typography>
      <Typography variant="body1" paragraph>
        At Wiring Master, we are dedicated to innovation, accuracy, and user-friendliness. Our mission is to continue
        developing high-quality web applications that empower electrical engineers, making their work more efficient and
        effective.
      </Typography>
    </Container>
        </Paper>

        <Container maxWidth="md" style={{ paddingTop: '20px' }}>
      <Typography variant="h4" align="center" gutterBottom>
        About Abraham George
      </Typography>
      <Typography variant="body1" paragraph>
        Hi, I'm Abraham George, an Electrical Engineer, and the developer behind Wiring Master.
      </Typography>
      <Typography variant="body1" paragraph>
        With a background in electrical design, I decided to combine my engineering expertise with my love for web applications
        development and I founded Wiring Master to bridge the gap between
        electrical engineering and user-friendly web applications.
      </Typography>
      <Typography variant="body1" paragraph>
        I'm dedicated to providing engineers around the world with tools that simplify their daily tasks. Your feedback
        matters to me, so please don't hesitate to reach out if you find any bugs in our calculators or if you have
        suggestions for new features. You can contact me at{' '}
        <Link href="mailto:abrahamgeorge@wiringmaster.org">abrahamgeorge@wiringmaster.org</Link> or connect with me on{' '}
        <Link href="https://www.linkedin.com/in/abraham-george-14a1131b9" target="_blank" rel="noopener noreferrer">
          LinkedIn
        </Link>.
      </Typography>
      <Typography variant="body1" paragraph>
        At Wiring Master, my mission is to empower electrical engineers with efficient and accurate tools, and I'm
        committed to continual improvement and innovation.
      </Typography>
    </Container>

        {/* Any other sections you'd like to include */}
      </Box>

      <Footer />
    </div>
  );
}

export default AboutUsPage;
