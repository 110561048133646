import React, { useEffect, useState } from "react";

import Styles from "./profile.module.scss";
import Header from "../HomePage/components/header/header";
import Footer from "../HomePage/components/footer/footer";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import axios from "axios";
import Button from "@mui/material/Button";
import profileImage from "./Avatar.jpg";
import buttonLoader from '../StripeComponent/buttonLoader.json';
import Lottie from 'react-lottie';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";


const Profile = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isPaidUser, setIsPaidUser] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const notify = (props) => toast.success(props);
  const notifyError = (props) => toast.error(props);

  const loader = {
    loop: true,
    autoplay: true,
    animationData: buttonLoader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };




 

  

  useEffect(() => {
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (currentUser) => {
        setIsLoggedIn(!!currentUser);
        setUser(currentUser);
        if (currentUser) {
          const userRef = firebase
            .firestore()
            .collection("users")
            .doc(currentUser.uid);
          const doc = await userRef.get();
          if (doc.exists) {
            setIsPaidUser(doc.data().isPaidUser);
            // console.log(doc.data());
            sessionStorage.setItem("isPaidUser", doc.data().isPaidUser);
            sessionStorage.setItem("isLogged", !!currentUser);
          }
        }
      });
    return () => {
      unsubscribe();
    };
  }, []);



  const handleCancelSubscription = async () => {
    try {
      setLoading(true)
      const user = firebase.auth().currentUser;
      if (user) {
        const db = firebase.firestore();
        const userRef = db.collection("users").doc(user.uid);
        const userData = await userRef.get();
        const { subscriptionId, customerId } = userData.data();

        // Call the cancel subscription endpoint with subscription ID and customer ID
        const response = await axios.post(
          "https://app.wiringmaster.org/cancel-subscription",
          {
            subscriptionId: subscriptionId,
            customerId: customerId,
          }
        );
        if (response.data.success) {
          // Update user status in Firebase to reflect cancellation
          await userRef.update({
            isPaidUser: false,
            subscriptionId: null,
            customerId: null,
          });
          setIsPaidUser(false)

          console.log("Subscription cancelled successfully");
          notify("Subscription cancelled successfully");
        } else {
          console.log("Failed to cancel subscription");
          notifyError("Failed to cancel subscription");
        }
      } else {
        console.error("User not logged in");
        notifyError("User not logged in");
      }
    } catch (error) {
      console.error("Error:", error);
      notifyError("Some error occurred, try again after some time");
    }finally{
      setLoading(false);
    }
  };

  const email =
    user &&
    user.multiFactor &&
    user.multiFactor.user &&
    user.multiFactor.user.email;
  const name =
    user &&
    user.multiFactor &&
    user.multiFactor.user &&
    user.multiFactor.user.displayName;
  return (
    <>
      <Header />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className={Styles.profileContainer}>
        <div className={Styles.profileCardContainer}>
          <div className={Styles.profileImage}>
            <div className={Styles.imageContainer}>
              <img src={profileImage} alt="profile Avatar" />
            </div>
          </div>
          <div className={Styles.profileData}>
            {/* <div className={Styles.profileName}>
              {name ? name : "User Name"}
            </div> */}
            <div className={Styles.profileEmail}>
              {email ? email : "User Email"}
            </div>
          </div>
          <div className={Styles.profileSubscription}>
            <div className={Styles.profileSubscriptionHeading}>
              Subscription
            </div>
            {isPaidUser ? (
          <div className={Styles.profileSubscriptionDetails}>
            <span className={Styles.subscriptionName}>Pro</span>
            <span className={Styles.subscriptionPrice}>25$/Month</span>
            <Button
              className={Styles.cancelSubscriptionButton}
              onClick={() => handleCancelSubscription()}
              variant="contained"
              disabled={loading}
            >
              {loading ? (
                <Lottie options={loader} height={25} width={100} />
              ) : (
                `Cancel Subscription`
              )}
            </Button>
          </div>
        ) : (
          <div className={Styles.profileSubscriptionDetails}>
            <span className={Styles.subscriptionName}>Free</span>
            <span className={Styles.subscriptionPrice}>No subscription</span>
            <Button
              className={Styles.cancelSubscriptionButton}
              
              variant="contained"
              disabled={loading}
            >
              {loading ? (
                <Lottie options={loader} height={25} width={100} />
              ) : (
                <Link to="/stripepayment" style={{ textDecoration: 'none', color: 'inherit' }}  >
                Purchase Subscription (25$/Month)
              </Link>
              )}
            </Button>
          </div>
          
        )}

            <Button
              className={Styles.cancelSubscriptionButton}
              
              variant="contained"
              disabled={loading}
            >
              <Link to="/resetpassword" style={{ textDecoration: 'none', color: 'inherit' }} >Reset Password</Link>
              </Button>
          </div>
        </div>
      </div>
      <div className={Styles.footerContainer}>
        <Footer />
      </div>
    </>
  );
};

export default Profile;
