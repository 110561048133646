// src/components/StripeComponent/StripeComponent.js
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import stripePromise from '../../stripe'; // Importing stripePromise by going one level up
import YourCheckoutForm from './CheckoutComponent'; // Adjust the path as necessary for YourCheckoutForm

const StripeComponent = () => {
  return (
    <Elements stripe={stripePromise}>
      <YourCheckoutForm />
      
    </Elements>

  );
};

export default StripeComponent;
