import React, { useState } from 'react';
import { Paper, Typography, Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TeX from '@matejmazur/react-katex';
import 'katex/dist/katex.min.css'; // Import KaTeX CSS

const ArcFlashClculatorHelp = () => {
    return (
        <Paper style={{ padding: '20px', margin: '20px 0', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
            <Typography variant="h5" style={{ marginBottom: '20px' }}>HELP MENU</Typography>
            <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">INPUTS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">SUPPLY DETAILS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                            <Typography variant="h8" style={{ fontWeight: 'bold' }}>Number of Strings:</Typography>
                            <Typography variant="body1" style={{ marginLeft: 30 }}>Enter the number of parallel strings in the system. This impacts the overall fault current calculation.</Typography>
                            <Typography variant="h8" style={{ fontWeight: 'bold' }}>System Voltage (V):</Typography>
                            <Typography variant="body1" style={{ marginLeft: 30 }}>Enter the nominal DC system voltage in volts. This is the voltage across each string of the system.</Typography>
                            <Typography variant="h8" style={{ fontWeight: 'bold' }}>Fault Location</Typography>
                            <Typography variant="body1" style={{ marginLeft: 30 }}>Select the location of the fault, which affects the arc flash calculation due to variations in system impedance and configuration.</Typography>
                            <Typography variant="body1" style={{ marginLeft: 30 }}>Select between two options:</Typography>
                            <Typography variant="body1" style={{ marginLeft: 60, fontStyle: 'italic' }}>"Battery Terminals":</Typography>
                            <Typography variant="body1" style={{ marginLeft: 90 }}>This option assumes the fault occurs at the battery terminals.</Typography>
                            <Typography variant="body1" style={{ marginLeft: 60, fontStyle: 'italic' }}>"Combiner Terminals":</Typography>
                            <Typography variant="body1" style={{ marginLeft: 90 }}>This option assumes the fault occurs at the combiner terminals where all the battery strings are paralleled.</Typography>
                            <Typography variant="h8" style={{ fontWeight: 'bold' }}>Faulted String:</Typography>
                            <Typography variant="body1" style={{ marginLeft: 30 }}>Specify the battery string where the battery terminal is faulted, if applicable. </Typography>

                            <Typography variant="h8" style={{ fontWeight: 'bold' }}>Conductor Gap (mm)</Typography>
<Typography variant="body1" style={{ marginLeft: 30 }}>The distance between the conductors (electrodes) where the fault occurs. The conductor gap directly influences the arc resistance and, consequently, the arc flash energy. A smaller gap typically results in a higher arc flash energy, while a larger gap can reduce the energy by increasing the arc resistance.</Typography>

<Typography variant="h8" style={{ fontWeight: 'bold' }}>Working Distance (mm)</Typography>
<Typography variant="body1" style={{ marginLeft: 30 }}>The distance between the personnel and the source of the potential arc flash. </Typography>
                        </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">BATTERY STRING DETAILS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Fault Clearing Time (s):</Typography>
                <Typography variant="body1" style={{ marginLeft:30 }}>
                    The duration of the arc fault event for the string in seconds. This value determines how long the arc flash energy is released, directly impacting the overall incident energy calculation.
                </Typography>
                
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Fault Method</Typography>
                <Typography variant="body1" style={{ marginLeft:30 }}>
                    Choose the method for determining the fault in the string. Options include using the battery's short circuit input or calculating based on the battery's internal resistance.
                </Typography>
                
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Battery Short Circuit Current (A)</Typography>
                <Typography variant="body1" style={{ marginLeft:30 }}>
                    Enter the maximum short circuit current of the battery in amperes. This value is crucial for evaluating the potential severity of an arc flash event.
                </Typography>
                
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Battery Internal Resistance (mΩ)</Typography>
                <Typography variant="body1" style={{ marginLeft:30 }}>
                    Enter the internal resistance of the battery in milliohms. This parameter affects the calculation of the short circuit current and, consequently, the potential arc flash energy.
                </Typography>
                
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Cable Material</Typography>
                <Typography variant="body1" style={{ marginLeft:30 }}>
                    Select the material of the cables used to connect the battery string to the combiner terminal. The material, whether copper or aluminum, affects the conductivity and the resulting fault current in the system.
                </Typography>
                
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Cable Size (mm²)</Typography>
                <Typography variant="body1" style={{ marginLeft:30 }}>
                    Enter the cross-sectional area of the cable in square millimeters. The size of the cable influences the resistance and, thus, the calculated fault current.
                </Typography>
                
                <Typography variant="h8" style={{ fontWeight: 'bold' }}>Cable Length (m)</Typography>
                <Typography variant="body1" style={{ marginLeft:30 }}>
                    Enter the length of the cable in meters. Longer cables introduce more resistance, reducing the fault current and impacting the arc flash calculation.
                </Typography>
                </AccordionDetails>
                </Accordion>


                </AccordionDetails>
            </Accordion>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5"> TYPICAL WORKING DISTANCE</Typography>
                    </AccordionSummary>
                
                <AccordionDetails>
                <TableContainer component={Paper}>
    <Table style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
        <TableHead>
            <TableRow>
                <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px' }}>Equipment class</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px' }}>Working Distance (mm)</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>15 kV switchgear</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>914.4</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>15 kV MCC</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>914.4</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>5 kV switchgear</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>914.4</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>5 kV MCC</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>914.4</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>Low-voltage switchgear</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>609.6</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>Shallow low-voltage MCCs and panelboards</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>457.2</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>Deep low-voltage MCCs and panelboards</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>457.2</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>Cable junction box</TableCell>
                <TableCell align="center" style={{ fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)' }}>457.2</TableCell>
            </TableRow>
        </TableBody>
    </Table>
</TableContainer>
                </AccordionDetails>
                
                </Accordion>

        
            </Paper>



)}

export default ArcFlashClculatorHelp;