import React, { useEffect, useState } from "react";
import Styles from "./footer.module.scss";
import Logo from "../../assets/WiringMaster.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import { Link } from "@mui/material";
// import { Grid, Typography, Link, Box } from '@mui/material';

function Footer() {
  const [isMobile, setIsMobile] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [expanded1, setExpanded1] = useState(false)
  const mobileWidth = 769;

  const handleExpansion = (num) => {
    if(num === 1){
      setExpanded((prevExpanded) => !prevExpanded);
    }else{
      setExpanded1((prevExpanded) => !prevExpanded);
    }
  };

  useEffect(() => {
    mobileWidth > window.screen.width ? setIsMobile(true) : setIsMobile(false);
  }, []);
  return (
    <>
      <div className={Styles.footerContainer}>
        <div className={Styles.footerLinks}>
          {isMobile ? (
            <Accordion
              expanded={expanded}
              onChange={()=>handleExpansion(1)}
              slots={{ transition: Fade }}
              slotProps={{ transition: { timeout: 400 } }}
              sx={{
                "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
                "& .MuiAccordionDetails-root": {
                  display: expanded ? "block" : "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>Quick Links</Typography>
              </AccordionSummary>
              <AccordionDetails className={Styles.footerAccordianLinks}>
                <div>
                  <Link href="/termsofuse">Terms of Use</Link>
                </div>
                <div>
                  <Link href="/calculator">Our Calculators</Link>
                </div>
                <div>
                  <Link href="/about">About Us</Link>
                </div>
                <div>
                  <Link href="/harmonic">Harmonic</Link>
                </div>
              </AccordionDetails>
            </Accordion>
          ) : (
            <div className={Styles.footerLeftLinks}>
              <div className={Styles.leftLinksHeading}>Quick Links</div>
              <div>
                <Link href="/termsofuse">Terms of Use</Link>
              </div>

              <div>
                <Link href="/about">About Us</Link>
              </div>
              {/* <div>
                <Link href="/Solarcalculator">Solar</Link>
              </div>
              <div>
                <Link href="/ArcFlashCalculatorDC">ArcFlashCalculatorDC</Link>
              </div> */}
            </div>
          )}

          <div className={Styles.footerRightLinks}>
            {isMobile ? (
              <Accordion
                expanded={expanded1}
                onChange={()=>handleExpansion(2)}
                slots={{ transition: Fade }}
                slotProps={{ transition: { timeout: 400 } }}
                sx={{
                  "& .MuiAccordion-region": { height: expanded1 ? "auto" : 0 },
                  "& .MuiAccordionDetails-root": {
                    display: expanded1 ? "block" : "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>Contact Us </Typography>
                </AccordionSummary>
                <AccordionDetails className={Styles.footerContactUsAccordion}>
                  <div>
                    <Link
                      to={
                        "mailto:abrahamgeorge@wiringmaster.org?body=Hi I would like to know more about wiring master"
                      }
                    >
                      abrahamgeorge@wiringmaster.org
                    </Link>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : (
              <div className={Styles.footerRightTop}>
                <div className={Styles.rightLinksHeading}>Contact Us</div>
                <div>
                  <Link
                    to={
                      "mailto:abrahamgeorge@wiringmaster.org?body=Hi I would like to know more about wiring master"
                    }
                  >
                    abrahamgeorge@wiringmaster.org
                  </Link>
                </div>
              </div>
            )}
            <div className={Styles.footerBottom}>
              <div className={Styles.footerIcon}>
                <img src={Logo} alt="logo" />
              </div>
              <div className={Styles.footerTrademark}>
                © {new Date().getFullYear()} Wiring Master. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
