import React from 'react';
import { saveAs } from 'file-saver';
import Button from '@mui/material/Button';

const SaveCalculation = ({ formData }) => {
  const handleSaveCalculation = () => {
    const calculationData = { formData };
    const calculationDataJSON = JSON.stringify(calculationData);

    const blob = new Blob([calculationDataJSON], { type: 'application/json' });

    // Ask the user for the desired file name
    const fileName = prompt('Enter the file name:', 'calculation.json');

    // Check if the user provided a file name
    if (fileName) {
      // Use FileSaver.js to save the file with the specified name
      saveAs(blob, fileName);
    }
  };

  return (
    <div>
        <Button variant="contained" color="primary" onClick={handleSaveCalculation}>
        Save Calculation
      </Button>
    </div>
  );
};

export default SaveCalculation;
