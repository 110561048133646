import React from 'react';
import * as d3 from 'd3'; // Import d3.js for color interpolation

const MapOverlayInfo = ({Colorbar}) => {

  const max_value = Colorbar.max_dsm
  const min_value = Colorbar.min_dsm
  const colorArray = Colorbar.dsm_colors


  return (
    <div className="map-overlay-info">
 <div className="color-bar">
                {colorArray.map((color, index) => (
                    <div key={index} style={{ backgroundColor: color, width: '10px', height: '20px' }}></div>
                ))}
      </div>
      <p>Min Value: {min_value}</p>
      <p>Max Value: {max_value}</p>
    </div>
  );
};

export default MapOverlayInfo;