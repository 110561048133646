import React from 'react';
import firebase from 'firebase/compat/app'; // Updated import path
import 'firebase/compat/auth'; // Updated import path

function Logout() {
  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      // User is logged out.
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2>Logout</h2>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
}

export default Logout;
