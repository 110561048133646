import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import InputComponent from './InputComponent/InputComponent'
import ResultComponent from './ResultComponent/ResultComponent'
import ArcFlashRepresentation from './ArcFlashEditor/ArcFlashEditor'
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Button from '@mui/material/Button';
import SaveCalculation from './SaveCalculation/SaveCalculation'
import OpenCalculation from './OpenCalculationComponent/OpenCalculation'
import ReportComponent from './ReportComponent/ReportComponent'
import ImageContext from './ImageContext/ImageContext'
import Header from '../HomePage/components/header/header';
import Footer from '../HomePage/components/footer/footer';
import firebase from 'firebase/compat/app';
import { ToastContainer, toast } from "react-toastify";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import LatexComponent from './LatexComponent/LatexComponent';
import ArcFlashClculatorHelp from './ArcFlashCalculatorHelp/ArcFlashCalculatorHelp';
import { Helmet } from "react-helmet";
import useSimulation from "./CalculationComponent/CalculationComponent"
import pako from 'pako';

const MyComponent = () => {
  const [formData, setFormData] = useState(null);
  const [formData1, setFormData1] = useState(null);
  const [results, setResults] = useState(null);
  const editorRef = useRef(null);
  const [editorImageUrl, setEditorImageUrl] = useState("");
  const [editorSize, setEditorSize] = useState({ width: 0, height: 0 });
  const [user, setUser] = useState(null);
  const [isPaidUser, setIsPaidUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { runSimulation, simulationResults} = useSimulation();




  const handleCalculate = () => {
    console.log("form",formData)
    if (!formData) {
      toast.error("Please fill in the form before calculating.");
      return;
    }
  
    try {

      const loadProfiles = formData.map((profile) => {
      const { data, selectedHeader, fileName } = profile;
      const profileValues = data.map((entry) => entry.y); // Extract only the `y` values

      return {
        fileName, // Keep the file name for reference
        profileValues, // Extracted values
      };
    });

    const numSimulations = formData[0]?.numsim || 0; // Get from the first item
    const numLoads = formData[0]?.numload || 0; // Get from the first item

      // Run the simulation with extracted data
      runSimulation({
        loadProfiles, // Assuming these are arrays of load profile data
        numLoads: parseInt(numLoads, 10),
        numSimulations: parseInt(numSimulations, 10),
      });
  
      toast.success("Simulation started successfully!");
    } catch (error) {
      console.error("Error during calculation:", error);
      toast.error("An error occurred while processing the calculation.");
    }
  };
  




  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (authUser) => {
      if (authUser) {
        const db = firebase.firestore();
        const userRef = db.collection('users').doc(authUser.uid);
        const doc = await userRef.get();
        if (doc.exists) {
          setUser(authUser);
          setIsPaidUser(doc.data().isPaidUser);
        }
      } else {
        setUser(null);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleUpdate = (data) => {
   
    setFormData(data);
    console.log("data",data)
  };



  const handleOpenCalculation = (data) => {
    // Update your state with the loaded data
    setFormData(data);
    setFormData1(data);

  };

  const handleEditorImageUpdate = (url) => {
    setEditorImageUrl(url);
  };


  useEffect(() => {
    if (simulationResults) {
      console.log('Simulation results:', simulationResults);
      // Add more logic here if needed
    }
  }, [simulationResults]);




    useEffect(() => {
      const updateSize = () => {
        if (editorRef.current) {
          setTimeout(() => {
            setEditorSize({
              width: editorRef.current.offsetWidth,
              height: editorRef.current.offsetHeight,
            });
          }, 0); // Delay of 100ms
        }
      };
  
      window.addEventListener("resize", updateSize);
      updateSize();
  
      return () => {
        window.removeEventListener("resize", updateSize);
      };
    }, [results]);

  
  return (
    <ImageContext.Provider value={{ editorImageUrl, setEditorImageUrl}}>
<Helmet>
  <title>Monte Carlo Simulation Calculator</title>
  <meta name="description" content="Perform advanced Monte Carlo simulations to analyze and predict outcomes. Generate synthetic load profiles and detailed statistical reports for accurate decision-making." />
  <meta name="keywords" content="Monte Carlo Simulation, Load Profile Generator, Statistical Analysis, Simulation Calculator, Synthetic Load Profiles, Monte Carlo Method, Simulation Report" />
</Helmet>
      <Header/>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Paper elevation={5} style={{  margin: 20, padding: 10 }}>
    <div style={{  margin: 'auto', marginTop:20 }}>
    <Grid container spacing={2} alignItems="center">

  <Grid item>
    {/* {results && formData && (
      <ReportComponent
        formData={formData}
        results={results}
        isPaidUser={isPaidUser}
      />
    )} */}
  </Grid>
  <Grid item>
  <Button variant="contained" color="primary" onClick={handleCalculate}>
        CALCULATE
      </Button>

  </Grid>
</Grid>
      <Grid container  direction="column" spacing={0}>
      <Grid item>
      <Grid container spacing={3}>
      <Grid item md={12} xs ={12}>
      {simulationResults && simulationResults.stats && <ResultComponent results={simulationResults.stats} />}
      </Grid>
      </Grid>
      </Grid>
      <Grid item>
      <InputComponent onUpdate={handleUpdate} formData1={formData1}  />
      </Grid>
      

      </Grid>


    </div>
    </Paper>
<ArcFlashClculatorHelp/>

    <Footer/>
    </ImageContext.Provider>
    
  );
};

export default MyComponent;
