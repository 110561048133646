import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import Styles from "./login.module.scss"; // Import your custom CSS file
// import Footer from "../HomePage/components/footer/footer.js";
import Header from "../HomePage/components/header/header.js";
import "firebase/firestore";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import image from "../HomePage/components/prizing/background1.jpg";
import logo from "../HomePage/assets/WiringMaster.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const notify = (props) => toast.success(props);
  const notifyError = (props) => toast.error(props);

  const handleReset = async () => {
    try {
        await firebase.auth().sendPasswordResetEmail(email);
        // Password reset email sent successfully
        console.log("Password reset email sent successfully");
        notify("Password reset email send successfully ");
      

      } catch (error) {
        // Handle errors
        console.error("Error sending password reset email:", error.message);
        notifyError("Error resetting password");
      }
  };



  return (
    <>
        <Header />
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className={Styles.parentContainer}>
        <div className={Styles.imageContainer}>
          <img src={image} alt="hello" />
        </div>
        <Card sx={{ maxWidth: 345 }} className={Styles.card}>
          <CardMedia
            component="img"
            alt="green iguana"
            height="140"
            image={logo}
            onClick={()=>{navigate('/home')}}
          />
          <CardContent className={Styles.cardContent}>
            {/* <Typography gutterBottom variant="h5" component="div">
              Login
            </Typography> */}
            <Typography variant="body2" color="text.secondary">
              <div className={Styles.inputGroup}>
                <div>
                  <label htmlFor="email">Email:</label>
                  <input
                    id="email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
            </Typography>
            <div className={Styles.cardButton}>
              <Button className={Styles.loginButton} onClick={handleReset} variant="contained">Reset Password</Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </>





    /* <div className="Style">
        <h2>Login</h2>
        {error && <p className="error-message">{error}</p>}
        <div className="input-group">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input-group">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button className="login-button" onClick={handleLogin}>
          Login
        </button>
        <div className="register-link-container">
          <p>
            Don't have an account?{" "}
            <Link to="/register" className="register-link">
              Register for new account
              </Link>
              </p>
              </div>
            </div> */
  );
}

export default Login;
