import { useState } from "react";
import pako from 'pako'; // For compression

const useSimulation = () => {
  const [simulationResults, setSimulationResults] = useState(null);

  const runSimulation = ({ loadProfiles, numLoads, numSimulations }) => {
    if (!loadProfiles || !numLoads || !numSimulations) {
      console.error("Invalid inputs for simulation.");
      return;
    }

    // Equalize load profiles
    const equalizeProfiles = (profiles) => {
      const maxLength = Math.max(...profiles.map((profile) => profile.profileValues.length));

      return profiles.map((profile) =>
        Array.from({ length: maxLength }, (_, i) => profile.profileValues[i] || 0)
      );
    };

    // Perform simulation
    const performSimulation = (equalizedProfiles) => {
      const results = [];
      const maxLength = equalizedProfiles[0].length;

      // Loop through the number of simulations
      for (let i = 0; i < numSimulations; i++) {
        const totalLoad = Array(maxLength).fill(0);

        // Loop through the number of loads in each simulation
        for (let j = 0; j < numLoads; j++) {
          const profileIndex = Math.floor(Math.random() * equalizedProfiles.length);
          const randomProfile = equalizedProfiles[profileIndex];
          const offset = Math.floor(Math.random() * (maxLength - 1)) + 1;

          // Add load from the random profile to the totalLoad array
          for (let k = 0; k < maxLength; k++) {
            totalLoad[k] += randomProfile[(k + offset) % maxLength];
          }
        }

        // Store the results
        results.push(totalLoad);
      }

      return results;
    };

    // Calculate peaks, means, RMS, etc.
    const calculateStatistics = (data) => {
      const peaks = data.map(row => Math.max(...row));
      // const means = data.map(row => row.reduce((sum, value) => sum + value, 0) / row.length);
      // const rmsValues = data.map(row => Math.sqrt(row.reduce((sum, value) => sum + Math.pow(value, 2), 0) / row.length));

      // Find max/min for each
      const maxPeakIndex = peaks.indexOf(Math.max(...peaks));
      const minPeakIndex = peaks.indexOf(Math.min(...peaks));
      // const maxMeanIndex = means.indexOf(Math.max(...means));
      // const minMeanIndex = means.indexOf(Math.min(...means));
      // const maxRmsIndex = rmsValues.indexOf(Math.max(...rmsValues));
      // const minRmsIndex = rmsValues.indexOf(Math.min(...rmsValues));

      return {
        peaks,
        // means,
        // rmsValues,
        maxPeak: { profile: data[maxPeakIndex], value: peaks[maxPeakIndex] },
        minPeak: { profile: data[minPeakIndex], value: peaks[minPeakIndex] },
        // maxMean: { profile: data[maxMeanIndex], value: means[maxMeanIndex] },
        // minMean: { profile: data[minMeanIndex], value: means[minMeanIndex] },
        // maxRMS: { profile: data[maxRmsIndex], value: rmsValues[maxRmsIndex] },
        // minRMS: { profile: data[minRmsIndex], value: rmsValues[minRmsIndex] },
      };
    };

    const equalizedProfiles = equalizeProfiles(loadProfiles);
    const results = performSimulation(equalizedProfiles);

    const stats = calculateStatistics(results);
    console.log("stats",stats)
    setSimulationResults({ results, stats });

    return { results, stats };
  };

  return { runSimulation, simulationResults };
};

export default useSimulation;
