
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image, Table, TableHeader, TableCell, TableBody } from '@react-pdf/renderer';
import Button from '@mui/material/Button';
import React, { useContext, useEffect, useState, useRef } from 'react';
import ImageContext  from "../ImageContext/ImageContext"
;



function ReportComponent({address,financialanalysisdata,financialanalysisresults}) {

    const { mapImageUrl, setMapImageUrl,financialImageUrl, setFinancialImageUrl } = useContext(ImageContext);

    const solarAnalysisResult = financialanalysisresults?.solarAnalysisResult || {};
    const electricityBillWithoutSolarPerYear_eachyear = solarAnalysisResult?.electricityBillWithoutSolarPerYear_eachyear || [];
    const acenergyproducedeachyear = solarAnalysisResult?.acEnergyPerYear || [];
    const electricityBillWithSolarPerYear = solarAnalysisResult?.electricityBillWithSolarPerYear || [];
    const totalPanelInstallationCost = solarAnalysisResult?.totalPanelInstallationCost || 0;
  
    const solarInsentives = financialanalysisresults?.solarInsentives || 0;
    const solarlifetime = financialanalysisresults?.panelLifetime || 0;



  const styles = StyleSheet.create({
    heading: {
      fontFamily: 'Times-Roman',
      fontSize: 18,
      fontWeight: 16,
      marginBottom: 1,
    },
    normalText: {
      fontFamily: 'Times-Roman',
      fontSize: 12,
      marginBottom: 4,
    },
    image: {
      width: '300%', // Adjust as needed
      height: 'auto', // Adjust for aspect ratio
      marginVertical: 0,
      alignItems: "center"},

    row: {
      flexDirection: 'row',
      alignItems: 'stretch',
    },
    column: {
      flex: 1,
      padding: 5,
    },
    inlineContainer: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    fullPageUnderline: {
      borderBottomWidth: 1,
      borderBottomColor: '#000',
      marginBottom: 1,
    },
    headerContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomColor: '#000',
      paddingBottom: 10,
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      width: '100%',
    },
    headerLeftText: {
      fontFamily: 'Times-Roman',
      fontSize: 18,
      textAlign: 'left',
    },
    headerRightText: {
      fontFamily: 'Times-Roman',
      fontSize: 18,
      textAlign: 'right',
    },
    footerContainer: {
      position: 'absolute',
      bottom: 1,
      borderTopWidth: 1,
      borderTopColor: '#000',
      paddingTop: 4,
      width: '100%',
    },
    footerText: {
      fontSize: 10,
      marginHorizontal: 12,
    },
    table: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#000',
      marginBottom: 10,
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableCellfirst: {
      flex: 1,
      padding: 5,
      border: 1,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
      alignItems: "center"
    },
    tableCellsecond: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
      alignItems: "center"
    },
    tableCellthird: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
      alignItems: "center"
    },
    tableCellforth: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
      alignItems: "center"
    },
    tableCellfifth: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
    },
    tableCellsixth: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
    },
    tableCellseventh: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
    },
    tableCelleight: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
    },
    lastTableCell: {
      flex: 1,
      padding: 5,
      border: 1,
      borderLeftWidth: 0.5,
      borderRightColor: '#000',
      justifyContent: 'center',
      alignItems: "center"
    },
    centeredText: {
      flex: 1,
      marginTop: 120,
      justifyContent: 'center',
      alignItems: 'center',
    },
    redText: {
      color: 'red',
      fontSize: 14,
    },
    plotContainer: {
      width: '100%',
      height: 400,
      marginBottom: 20
  }
    
  });



  const Header = () => (
    <View style={styles.headerContainer}>
      <Text style={styles.headerLeftText}>Solar Estimate Calculator Result</Text>
      <Text style={styles.headerRightText}>WIRING MASTER</Text>
    </View>
  );

  const Footer = () => (
    <View style={styles.footerContainer}>
      <Text style={styles.footerText}>
        Disclaimer: This information does not constitute legal, professional or commercial advice. Wiring Master gives no guarantees, undertakings or warranties in
        this regard, and does not accept any legal liability or responsibility for the content or the accuracy of the information so provided, or, for any loss or
        damage caused arising directly or indirectly in connection with reliance on the use of such information.
      </Text>
    </View>
  );

  const MyDocument = () => {

    const itemsPerPage = 20; // Number of items per page
    const pageCount = Math.ceil(financialanalysisdata.manualPlacementDetails.length / itemsPerPage); // Calculate total number of pages

    const itemsPerPage1 = 20; // Number of items per page
    const pageCount1 = Math.ceil(solarlifetime / itemsPerPage); // Calculate total number of pages
   

    return (
      <Document>

<Page > 
           <Header/>
            <View style={{ padding: 20 }}>
                    <View>

                        <View>
                        </View>
                        <View >
                        <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>Input Details</Text>
                                </View>

                                <View style={styles.row}>
                                        <View style={styles.column}>                 
                                          <View>
                                            <Text style={styles.normalText}>Address:</Text>
                                          </View>                                           
                                        </View>
                                        <View style={styles.column}>                                       
                                          <View>
                                          <Text style={styles.normalText}>{address}</Text>
                                          </View>
                                            
                                        </View>
                                </View>

                                <View>
                            <Image style={styles.image} src={mapImageUrl}/>
                        </View>



                               
                        </View>
                        

                        </View>

                        </View>
                        <Footer />
                        </Page>
                        {[...Array(pageCount)].map((_, pageIndex) => (
                        <Page key={`page_${pageIndex + 1}`} size="A4" >
                            <Header/>
                            <View style={{ padding: 20 }}>
                            {pageIndex === 0 && 
                            <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>Pannel Placement Details</Text>
                                </View>
                                 }
                                 {pageIndex === 0 && 
                                <View style={styles.row}>
                                        <View style={styles.column}>
                                          <View>
                                          <Text style={styles.normalText}>Total DC Energy Yield:</Text>
                                            <Text style={styles.normalText}>Pannel Capacity:</Text>
                                            <Text style={styles.normalText}>Panel Width:</Text>
                                            <Text style={styles.normalText}>Panel Height:</Text>
                                 
                                          </View>
                                            
                                        </View>
                                        <View style={styles.column}>
                                        <View>
                                        <Text style={styles.normalText}>{financialanalysisdata?.totalenergymannual.toFixed(3)} kWh</Text>
                                            <Text style={styles.normalText}>{financialanalysisdata?.pannelcapacity} W</Text>
                                            <Text style={styles.normalText}>{financialanalysisdata?.panelHeight} m</Text>
                                            <Text style={styles.normalText}>{financialanalysisdata?.panelWidth} m</Text>
                                          </View>
                                    
                                        </View>

                                        </View>
  }
                        <View style={styles.row}>
                          <View style={styles.tableCellfirst} >
                            <Text style={styles.normalText}>Index</Text>
                          </View>
                          <View style={styles.tableCellsecond} >
                            <Text style={styles.normalText}> Orientation</Text>
                          </View>
                          <View style={styles.lastTableCell}>
                            <Text style={styles.normalText}> Energy Yield (DC)</Text>
                          </View>
                        </View>

                        {financialanalysisdata.manualPlacementDetails.slice(pageIndex * itemsPerPage, (pageIndex + 1) * itemsPerPage).map((item, index1) => (
                        <View style={styles.row} key={`load_${pageIndex * itemsPerPage + index1}`}>
                          <View style={styles.tableCellfirst} >
                            <Text style={styles.normalText}>{item.index}</Text>
                          </View>
                          <View style={styles.tableCellsecond} >
                            <Text style={styles.normalText}>{item.orientation}</Text>
                          </View>
                          <View style={styles.lastTableCell}>
                            <Text style={styles.normalText}>{item.yearlyEnergyDcKwh} kWh</Text>
                          </View>
                        </View>
                        ))}
                        
                     
                    
                        </View>
                            <Footer/>
                        </Page>
                           ))}
                        <Page>
                        <Header/>
                            <View style={{ padding: 20 }}>
                            <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>Financial Analysis</Text>
                                </View>

                                <View>
                            <Image style={styles.image} src={financialImageUrl}/>
                        </View>

                        <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>Cost Analysis</Text>
                                </View>

                                <View style={styles.row}>
                                        <View style={styles.column}>
                                          <View>
                                          <Text style={styles.normalText}>Total Energy Produced In 20 Years (AC):</Text>
                                            <Text style={styles.normalText}>Total Bill Without Solar In 20 Years:</Text>
                                            <Text style={styles.normalText}>Total Solar Investment In 20 Years:</Text>
                                            <Text style={styles.normalText}>Total Savings In 20 Years:</Text>      
                                            <Text style={styles.normalText}>Break Even Period:</Text>                               
                                          </View> 
                                        </View>
                                        <View style={styles.column}>
                                        <View>
                                        <Text style={styles.normalText}>{financialanalysisresults?.solarAnalysisResult?.totalEnergyProducedIn20Years.toFixed(3)} kWh</Text>
                                        <Text style={styles.normalText}>{financialanalysisresults?.solarAnalysisResult?.totalBillWithoutSolar.toFixed(3)} $</Text>
                                        <Text style={styles.normalText}>{financialanalysisresults?.solarAnalysisResult?.totalSolarInvestment.toFixed(3)} $</Text>
                                        <Text style={styles.normalText}>{financialanalysisresults?.solarAnalysisResult?.totalSavings.toFixed(3)} $</Text>
                                        <Text style={styles.normalText}>{financialanalysisresults?.crossoverYear} Years</Text>
                                          </View>
                                    
                                        </View>

                                        </View>

                                        <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>Input Parameters</Text>
                                </View>

                                <View style={styles.row}>
                                        <View style={styles.column}>
                                          <View>
                                          <Text style={styles.normalText}>Monthly Average Bill</Text>
                                            <Text style={styles.normalText}>Enegry Cost Per KWh</Text>
                                            <Text style={styles.normalText}>Solar Insentives</Text>
                                            <Text style={styles.normalText}>Energy Cost Increase per Year</Text>      
                                            <Text style={styles.normalText}>Cash Discount Rate per Year</Text>   
                                            <Text style={styles.normalText}>Solar Pannel Installation Cost Per Watt</Text>
                                            <Text style={styles.normalText}>DC to AC converstion Percentage</Text>
                                            <Text style={styles.normalText}>Pannel Efficiency Depreciation Percentage</Text>      
                                            <Text style={styles.normalText}>Pannel lifetime</Text>                             
                                          </View> 
                                        </View>
                                        <View style={styles.column}>
                                        <View>
                                        <Text style={styles.normalText}>{financialanalysisresults?.monthlyBill} $</Text>
                                        <Text style={styles.normalText}>{financialanalysisresults?.energyCostPerKwh} $</Text>
                                        <Text style={styles.normalText}>{financialanalysisresults?.solarInsentives} $</Text>
                                        <Text style={styles.normalText}>{financialanalysisresults?.energyCostPerYear} %</Text>
                                        <Text style={styles.normalText}>{financialanalysisresults?.discountRatePerYear} %</Text>
                                        <Text style={styles.normalText}>{financialanalysisresults?.installationCostPerWatt} $</Text>
                                        <Text style={styles.normalText}>{financialanalysisresults?.dcToAcConversionFactor} %</Text>
                                        <Text style={styles.normalText}>{financialanalysisresults?.panelEfficiencyDepreciationFactor} %</Text>
                                        <Text style={styles.normalText}>{financialanalysisresults?.panelLifetime} Years</Text>
                                          </View>
                                          
                                    
                                        </View>

   

                                        </View>


                                
                            
                            </View>                       
                        </Page>
                        

                        {[...Array(pageCount1)].map((_, pageIndex) => (
                        <Page key={`page_${pageIndex + 1}`} size="A4" >
                            <Header/>
                            <View style={{ padding: 20 }}>
                            {pageIndex === 0 && 
                            <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>Yearly Cost analysis</Text>
                                </View>
                                 }

                                 <View style={{marginTop: 20 }}>

                        <View style={styles.row}>
                          <View style={styles.tableCellfirst} >
                            <Text style={styles.normalText}>Year</Text>
                          </View>
                          <View style={styles.lastTableCell}>
                            <Text style={styles.normalText}>Cost Without Solar</Text>
                          </View>
                          <View style={styles.lastTableCell}>
                            <Text style={styles.normalText}>Cost With Solar</Text>
                          </View>
                        </View>

                        {electricityBillWithoutSolarPerYear_eachyear?.slice(pageIndex * itemsPerPage, (pageIndex + 1) * itemsPerPage).map((item, index1) => (
  index1 > 0 && (
    <View style={styles.row} key={`load_${pageIndex * itemsPerPage + index1}`}>
      <View style={styles.tableCellfirst} >
        <Text style={styles.normalText}>{index1}</Text>
      </View>
      <View style={styles.tableCellsecond} >
        <Text style={styles.normalText}>{item.toFixed(3)} $</Text>
      </View>
      {index1 === 1 ? (
        <View style={styles.lastTableCell}>
          <Text style={styles.normalText}>{(electricityBillWithSolarPerYear[index1] - solarInsentives + totalPanelInstallationCost).toFixed(3)} $ (Including installation cost and solar insentives)</Text>
        </View>
      ) : (
        <View style={styles.lastTableCell}>
          <Text style={styles.normalText}>{electricityBillWithSolarPerYear[index1].toFixed(3)} $</Text>
        </View>
      )}
    </View>
  )
))}

                        
                     
</View>
                        </View>
                            <Footer/>
                        </Page>
                           ))}



                           
                        {[...Array(pageCount1)].map((_, pageIndex) => (
                        <Page key={`page_${pageIndex + 1}`} size="A4" >
                            <Header/>
                            <View style={{ padding: 20 }}>
                            {pageIndex === 0 && 
                            <View style={styles.fullPageUnderline}>
                                    <Text style={styles.heading}>Yearly Energy Output</Text>
                                </View>
                                 }

                                 <View style={{marginTop: 20 }}>

                        <View style={styles.row}>
                          <View style={styles.tableCellfirst} >
                            <Text style={styles.normalText}>Year</Text>
                          </View>
                          <View style={styles.lastTableCell}>
                            <Text style={styles.normalText}>Yearly Energy Output (AC)</Text>
                          </View>

                        </View>

                        {acenergyproducedeachyear?.slice(pageIndex * itemsPerPage, (pageIndex + 1) * itemsPerPage).map((item, index1) => (
  index1 > 0 && (
    <View style={styles.row} key={`load_${pageIndex * itemsPerPage + index1}`}>
      <View style={styles.tableCellfirst} >
        <Text style={styles.normalText}>{index1}</Text>
      </View>
      <View style={styles.lastTableCell} >
        <Text style={styles.normalText}>{item.toFixed(3)} kWh</Text>
      </View>

    </View>
  )
))}

                        
                     
</View>
                        </View>
                            <Footer/>
                        </Page>
                           ))}
                        

                       
                        
      </Document>
    );
  };

  return (
    <div>
      <PDFDownloadLink
        document={<MyDocument />}
        fileName="Solar Estimate Report"
      >
        {({ blob, url, loading, error }) =>
          loading || error ? (
            <Button variant="contained" color="primary" disabled>
              Loading document...
            </Button>
          ) : (
            <Button variant="contained" color="primary" href={url} download>
              Download Report
            </Button>
          )
        }
      </PDFDownloadLink>
    </div>
  );
}

export default ReportComponent;
