import React, { useState,useEffect } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, TextField, IconButton, Paper, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

function HarmonicOrder({ InputDetails, OnChange, HarmonicOrderinput1_1, Styles }) {
    console.log("InputDetails from harmonicorder",InputDetails)
  const [orders, setOrders] = useState([{ order: 1, frequency: 50, current: InputDetails.phaseValues[2]?.loadCurrent, percentage: 100 }]);

  const handleAddOrder = () => {
    const newOrder = {
      order: '0',
      frequency: '0',
      current: '0',
      percentage: '0'
    };
    setOrders([...orders, newOrder]);
  };

  const handleDeleteOrder = (index) => {
    const updatedOrders = [...orders];
    updatedOrders.splice(index, 1);
    setOrders(updatedOrders);
  };

  const handleOrderChange = (index, field, value) => {
    const updatedOrders = [...orders];
    updatedOrders[index][field] = value;
    if (field === 'order') {
        updatedOrders[index]['frequency'] = calculateFrequency(value);
    }
    setOrders(updatedOrders);
  };

  const calculateFrequency = (order) => {
    return order * 50;
  };

  const calculatePercentage = (current) => {
    const loadCurrent = InputDetails.phaseValues[2]?.loadCurrent || 0;
    return ((parseFloat(current) / loadCurrent) * 100).toFixed(2);
  };
  
  const calculateCurrent = (percentage) => {
    const loadCurrent = InputDetails.phaseValues[2]?.loadCurrent || 0;
    return ((parseFloat(percentage) / 100) * loadCurrent).toFixed(2);
  };

  useEffect(() => {
    OnChange(orders);
  }, [orders]);


  useEffect(() => {
    if (HarmonicOrderinput1_1.length > 0) {
      setOrders(HarmonicOrderinput1_1)
    }
  }, [HarmonicOrderinput1_1]);

  return (
    <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#f0f0f0', marginBottom: 2 }}>
      <h2 className={Styles.harmonicHeader}>HARMONIC DATA PHASE L2</h2>
      <Table>
        <TableHead sx={{ backgroundColor: '#a0a0a0' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Order</TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Frequency (Hz)</TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Current (A)</TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Percentage (%)</TableCell>
            <TableCell></TableCell> {/* Empty cell for delete button */}
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order, index) => (
            <TableRow key={index}>
              <TableCell>
                {index === 0 ? (
                  <TextField
                    value={order.order}
                    disabled
                  />
                ) : (
                    <TextField
                    value={order.order}
                    onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                        handleOrderChange(index, 'order', value);
                    }}
                    />
                )}
              </TableCell>
              <TableCell>{order.frequency}</TableCell>
              <TableCell>
                {index === 0 ? (
                  <TextField
                    value={InputDetails.phaseValues[2]?.loadCurrent}
                    disabled
                  />
                ) : (
                <TextField
                value={order.current}
                onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                    const current = value;
                    const percentage = calculatePercentage(current);
                    handleOrderChange(index, 'current', value);
                    handleOrderChange(index, 'percentage', percentage);
                }}
                />
                )}
              </TableCell>
              <TableCell>
  {index === 0 ? (
    <TextField
      value={order.percentage}
      disabled
    />
  ) : (
    <TextField
    value={order.percentage}
    onChange={(e) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        const percentage = value;
        const current = calculateCurrent(percentage);
        handleOrderChange(index, 'percentage', value);
        handleOrderChange(index, 'current', current);
    }}
    />
  )}
</TableCell>
              <TableCell>
                {index !== 0 && (
                  <IconButton onClick={() => handleDeleteOrder(index)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <IconButton onClick={handleAddOrder}>
        <AddIcon />
      </IconButton>
    </Paper>
  );
}

export default HarmonicOrder;
