import React, { useState, useEffect } from 'react';
import { Paper, TextField, Typography, FormControl, Select, MenuItem, InputLabel } from '@mui/material';

function SupplyDetailsComponent({ onChange, supplyDetails1 }) {
  const [phase, setPhase] = useState('1 - Phase AC');
  const [voltage, setVoltage] = useState(230);
  const [maxVoltageDrop, setMaxVoltageDrop] = useState(3);
  const [lengthOfRun, setLengthOfRun] = useState(40);


  useEffect(() => {
    if (supplyDetails1) {
      setPhase(supplyDetails1.phase ? supplyDetails1.phase : '1 - Phase AC');
      setVoltage(supplyDetails1.voltage ? supplyDetails1.voltage : 230);
      setMaxVoltageDrop(supplyDetails1.maxVoltageDrop ? supplyDetails1.maxVoltageDrop : 3);
      setLengthOfRun(supplyDetails1.lengthOfRun ? supplyDetails1.lengthOfRun : 40);
    }
  }, [supplyDetails1]);


  useEffect(() => {
    // Update the voltage based on phase selection
    switch (phase) {
      case '1 - Phase AC':
        setVoltage(230);
        break;
      case '3 - Phase AC':
        setVoltage(400);
        break;
      case '2 - Phase AC':
        setVoltage(480);
        break;
      case 'DC':
        setVoltage(230);
        break;
      default:
        setVoltage(230);
    }
  }, [phase]);

  const handlePhaseChange = (event) => {
    const newPhase = event.target.value;
    setPhase(newPhase);
    updateParent(newPhase, voltage, maxVoltageDrop, lengthOfRun);
  };

  const handleVoltageChange = (event) => {
    let newVoltage = parseInt(event.target.value, 10);
    if (isNaN(newVoltage) || newVoltage <= 0) {
      newVoltage = 1; // Default value or last valid value
    }
    setVoltage(newVoltage);
    updateParent(phase, newVoltage, maxVoltageDrop, lengthOfRun);
  };

  const handleMaxVoltageDropChange = (event) => {
    let newMaxVoltageDrop = parseFloat(event.target.value);
    if (isNaN(newMaxVoltageDrop) || newMaxVoltageDrop <= 0) {
      newMaxVoltageDrop = 1; // Default value or last valid value
    }
    setMaxVoltageDrop(newMaxVoltageDrop);
    updateParent(phase, voltage, newMaxVoltageDrop, lengthOfRun);
  };

  const handleLengthOfRunChange = (event) => {
    let newLengthOfRun = parseInt(event.target.value, 10);
    if (isNaN(newLengthOfRun) || newLengthOfRun <= 0) {
      newLengthOfRun = 1; // Default value or last valid value
    }
    setLengthOfRun(newLengthOfRun);
    updateParent(phase, voltage, maxVoltageDrop, newLengthOfRun);
  };

  const updateParent = (phase, voltage, maxVoltageDrop, lengthOfRun) => {
    onChange({ phase, voltage, maxVoltageDrop, lengthOfRun });
  };


  useEffect(() => {
    updateParent(phase, voltage, maxVoltageDrop, lengthOfRun);
  }, [phase, voltage, maxVoltageDrop, lengthOfRun]);

  return (
    <Paper style={{ padding: '20px', margin: '20px 0', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
      <Typography variant="h5" style={{ marginBottom: '20px' }}>SUPPLY DETAILS</Typography>
      <FormControl fullWidth style={{ marginBottom: '10px' }}>
        <InputLabel>Phase</InputLabel>
        <Select
          value={phase}
          onChange={handlePhaseChange}
          label="Phase"
        >
          <MenuItem value="1 - Phase AC">1 - Phase AC</MenuItem>
          <MenuItem value="3 - Phase AC">3 - Phase AC</MenuItem>
          <MenuItem value="2 - Phase AC">2 - Phase AC</MenuItem>
          <MenuItem value="DC">DC</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Voltage (V)"
        type="number"
        value={voltage}
        onChange={handleVoltageChange}
        variant="outlined"
        fullWidth
        style={{ marginBottom: '10px' }}
      />
      <TextField
        label="Max Voltage Drop (%)"
        type="number"
        value={maxVoltageDrop}
        onChange={handleMaxVoltageDropChange}
        variant="outlined"
        fullWidth
        style={{ marginBottom: '10px' }}
      />
      <TextField
        label="Length of Run (m)"
        type="number"
        value={lengthOfRun}
        onChange={handleLengthOfRunChange}
        variant="outlined"
        fullWidth
      />
    </Paper>
  );
}

export default SupplyDetailsComponent;
