import React, { useRef, useEffect, useState } from 'react';
import { Stage, Layer, Line, Rect,Text } from 'react-konva';
import Paper from '@mui/material/Paper';

function CableEditorComponent({activeResults, earthResults, loadDetails, supplyDetails, faultCurrentDetails, cableDetails, installationDetails, size,onImageUpdate}) {
    const stageWidth = 1602;
    const stageHeight = 546;
    const stageRef = useRef();

    useEffect(() => {
        if (stageRef.current) {
          console.log('StageWidth', stageWidth);
          console.log('stageHeight', stageHeight);
            // Generate the canvas image URL
          const url = stageRef.current.toDataURL();
            // Update the parent component with the image URL
            onImageUpdate(url);
        }
    }, [activeResults, earthResults, loadDetails, supplyDetails, faultCurrentDetails, cableDetails, installationDetails, size, onImageUpdate]);


  // Switchboard line dimensions and positions
  const switchboardLength =  stageWidth <= 1450 ? stageWidth*0.055172414 : 80 ;
  const switchboardStrokeWidth = stageWidth <= 1450 ? 0.002758621*stageWidth : 4  ;
  const switchboard1Position = { x: (stageWidth / 2) - (stageWidth / 3.5) , y: stageHeight / 2.9 - switchboardLength / 2 };
  const switchboard2Position = { x: stageWidth / 2 + (stageWidth / 3.5), y: stageHeight / 2.9 - switchboardLength / 2 };
  
  const phase = supplyDetails ? supplyDetails.phase : "Not specified";
  const voltage = supplyDetails ? supplyDetails.voltage : "Not specified";
  const FL_supply = activeResults ? activeResults.shortCircuitCapacity.faultLevelSupply : "Not specified";
  const load = loadDetails ? loadDetails.loadCurrent : "Not specified";
  const load_pf = loadDetails ? loadDetails.powerFactor : "Not specified";
  const FL_load = activeResults ? Math.round(activeResults.shortCircuitCapacity.faultLevelLoad * 100) / 100 : "Not specified";
  const active_size = activeResults ? activeResults.selectedCableSize : "Not specified";
  const earth_size = earthResults ? earthResults.Earth_cable_size : "Not specified";
  const voltage_drop = activeResults ? Math.round(activeResults.voltageDrop.voltageDropPercent * 100) / 100 : "Not specified";
  const cable_type = cableDetails ? cableDetails.cableType : "Not specified";
  const conductor = cableDetails ? cableDetails.conductor : "Not specified";
  const insulation = cableDetails ? cableDetails.insulation_display : "Not specified";
  const installation = installationDetails ? installationDetails.selectedInstallation : "Not specified";
  const number_of_active_circuits = activeResults ? activeResults.number_of_circuits : "Not specified";
  const number_of_earth_circuits = earthResults ? earthResults.number_of_earth_circuits : "Not specified";
  const fontsize = stageWidth <= 1450 ? 0.017241379*stageWidth : 25;
  const superscriptsize = stageWidth <= 1450 ? 0.011724138*stageWidth : 17;




  // Wire positions (connecting the two switchboard lines)
  const wirePoints = [
    switchboard1Position.x, switchboard1Position.y + switchboardLength / 2,
    switchboard2Position.x, switchboard2Position.y + switchboardLength / 2
  ];

  // Dotted box dimensions and positions
  const boxPadding = stageWidth <= 1450 ? stageWidth * 0.020689655 : 30;
  const boxWidth = switchboardStrokeWidth + boxPadding * (stageWidth <= 1450 ? 0.006996552 * stageWidth : 10) + 20;
  const boxHeight = switchboardLength + boxPadding * (stageWidth <= 1450 ? 0.004137931 * stageWidth : 6);
  

  return (
    <Paper style={{ overflowX: 'auto',overflowY: 'auto',maxHeight: '50vh'}}>

    <Stage   width={stageWidth} height={stageHeight} ref={stageRef} >
      <Layer>
        {/* Draw Vertical Switchboard Line 1 */}
        <Line
          points={[switchboard1Position.x, switchboard1Position.y, switchboard1Position.x, switchboard1Position.y + switchboardLength]}
          stroke="black"
          strokeWidth={switchboardStrokeWidth}
        />

        {/* Dotted Box for Switchboard 1 */}
        <Rect
          x={switchboard1Position.x - boxWidth + boxPadding}
          y={switchboard1Position.y + (switchboardLength/2) - (boxHeight/2)}
          width={boxWidth}
          height={boxHeight}
          stroke="black"
          strokeWidth={1}
          dash={[4, 4]}
          strokeEnabled
        />


        {/* Draw Vertical Switchboard Line 2 */}
        <Line
          points={[switchboard2Position.x, switchboard2Position.y, switchboard2Position.x, switchboard2Position.y + switchboardLength]}
          stroke="black"
          strokeWidth={switchboardStrokeWidth}
        />

        {/* Dotted Box for Switchboard 2 */}
        <Rect
          x={switchboard2Position.x - boxPadding}
          y={switchboard2Position.y + (switchboardLength/2) - (boxHeight/2)}
          width={boxWidth}
          height={boxHeight}
          stroke="black"
          strokeWidth={1}
          dash={[4, 4]}
          strokeEnabled
        />

        {/* Draw Wire (Black) */}
        <Line
          points={wirePoints}
          stroke="black"
          strokeWidth={2}
          lineCap="round"
          lineJoin="round"
        />

        {/* Text for Switchboard 1 */}
        <Text
          x={switchboard1Position.x - boxWidth + boxPadding + 10} // Adjust as needed
          y={switchboard1Position.y + (switchboardLength / 2) - (boxHeight / 2) + 10} // Adjust as needed
          text={`Phase: ${phase}`}
          fontSize={fontsize}
          fontFamily="Times New Roman"
          fill="black"
        />
        <Text
          x={switchboard1Position.x - boxWidth + boxPadding + 10} // Adjust as needed
          y={switchboard1Position.y + (switchboardLength / 2) - (boxHeight / 2) + 40} // Adjust as needed
          text={`Voltage: ${voltage} V`}
          fontSize={fontsize}
          fontFamily="Times New Roman"
          fill="black"
        />
        <Text
          x={switchboard1Position.x - boxWidth + boxPadding + 10} // Adjust as needed
          y={switchboard1Position.y + (switchboardLength / 2) - (boxHeight / 2) + 70} // Adjust as needed
          text={`FL: ${FL_supply} A`}
          fontSize={fontsize}
          fontFamily="Times New Roman"
          fill="black"
        />
        <Text
          x={switchboard2Position.x + 10} // Adjust as needed
          y={switchboard1Position.y + (switchboardLength / 2) - (boxHeight / 2) + 10} // Adjust as needed
          text={`Load: ${load} A`}
          fontSize={fontsize}
          fontFamily="Times New Roman"
          fill="black"
        />
        <Text
          x={switchboard2Position.x + 10} // Adjust as needed
          y={switchboard1Position.y + (switchboardLength / 2) - (boxHeight / 2) + 40} // Adjust as needed
          text={`Load powerfactor: ${load_pf} `}
          fontSize={fontsize}
          fontFamily="Times New Roman"
          fill="black"
        />
        <Text
          x={switchboard2Position.x + 10} // Adjust as needed
          y={switchboard1Position.y + (switchboardLength / 2) - (boxHeight / 2) + 70} // Adjust as needed
          text={`FL: ${FL_load} A`}
          fontSize={fontsize}
          fontFamily="Times New Roman"
          fill="black"
        />
        <Text
          x={(stageWidth / 2)-((measureText(`Active: ${number_of_active_circuits} X ${active_size} mm`, fontsize, "Times New Roman").width)/2)} // Adjust as needed
          y={stageHeight / 2.9+10} // Adjust as needed
          text={`Active: ${number_of_active_circuits} x ${active_size} mm`}
          fontSize={fontsize}
          fontFamily="Times New Roman"
          fill="black"
        />

        <Text
          x={(stageWidth / 2)+((measureText(`Active: ${number_of_active_circuits} X ${active_size} mm`, fontsize, "Times New Roman").width)/2)} // Adjust as needed
          y={stageHeight / 2.9+8} // Adjust as needed
          text={`2`}
          fontSize={superscriptsize}
          fontFamily="Times New Roman"
          fill="black"
        />
        <Text
          x={(stageWidth / 2)-((measureText(`Active: ${number_of_active_circuits} X ${active_size} mm`, fontsize, "Times New Roman").width)/2)} // Adjust as needed
          y={stageHeight / 2.9+40} // Adjust as needed
          text={`Earth: ${number_of_earth_circuits} x ${earth_size} mm`}
          fontSize={fontsize}
          fontFamily="Times New Roman"
          fill="black"
        />
        <Text
          x={(stageWidth / 2)-((measureText(`Active: ${number_of_active_circuits} X ${active_size} mm`, fontsize, "Times New Roman").width)/2)+(measureText(`Earth: ${number_of_earth_circuits} x ${earth_size} mm`, fontsize, "Times New Roman").width)} // Adjust as needed
          y={stageHeight / 2.9+38} // Adjust as needed
          text={`2`}
          fontSize={superscriptsize}
          fontFamily="Times New Roman"
          fill="black"
        />
        <Text
          x={(stageWidth / 2)-((measureText(`Active: ${number_of_active_circuits} X ${active_size} mm`, fontsize, "Times New Roman").width)/2)} // Adjust as needed
          y={stageHeight / 2.9+70} // Adjust as needed
          text={`Voltage drop: ${voltage_drop} %`}
          fontSize={fontsize}
          fontFamily="Times New Roman"
          fill="black"
        />
        <Text
          x={(stageWidth / 2)-((measureText(`${cable_type} | ${conductor} | ${insulation}`, fontsize, "Times New Roman").width)/2)} // Adjust as needed
          y={stageHeight / 2.9-60} // Adjust as needed
          text={`${cable_type} | ${conductor} | ${insulation}`}
          fontSize={fontsize}
          fontFamily="Times New Roman"
          fill="black"
        />
        <Text
          x={(stageWidth / 2)-((measureText(`Installation: ${installation}`, fontsize, "Times New Roman").width)/2)} // Adjust as needed
          y={stageHeight / 2.9-30} // Adjust as needed
          text={`Installation: ${installation}`}
          fontSize={fontsize}
          fontFamily="Times New Roman"
          fill="black"
        />


      </Layer>
    </Stage>

    </Paper>
  );
}


function measureText(text, fontSize, fontFamily) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = `${fontSize}px ${fontFamily}`;
    return context.measureText(text);
  }

export default CableEditorComponent;