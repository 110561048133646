import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from "@mui/material/Grid";
import Typography from '@material-ui/core/Typography'; // Import Typography

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const MyComponent = ({ onUpdate,formData1 }) => {
  const classes = useStyles();
  const [inputs, setInputs] = useState({
    supplyVoltage: '480',
    boltedFaultCurrent: '20',
    workingDistance: '450',
    enclosureWidth: '508',
    enclosureHeight: '508',
    enclosureDepth: '508',
    electrodeConfiguration: 'VCB',
    conductorGap: '32',
    arcDuration: '100',
    arcDurationReduced: '100',
  });
  const [voltageError, setVoltageError] = useState(false); 
  const [currentError, setCurrentError] = useState(false);
  const [conductorGapError, setConductorGapError] = useState(false);
  const [workingDistanceError, setWorkingDistanceError] = useState(false);
  const [enclosureWidthError, setEnclosureWidthError] = useState(false); 
  const [enclosureHeightError, setEnclosureHeightError] = useState(false); 
 


  useEffect(() => {
    
    if (formData1 ) {
      const {
        supplyVoltage,
        boltedFaultCurrent,
        workingDistance,
        enclosureWidth,
        enclosureHeight,
        enclosureDepth,
        electrodeConfiguration,
        conductorGap,
        arcDuration,
        arcDurationReduced,
      } = formData1;

      setInputs({
        supplyVoltage: supplyVoltage || '46',
        boltedFaultCurrent: boltedFaultCurrent || '20',
        workingDistance: workingDistance || '450',
        enclosureWidth: enclosureWidth || '508',
        enclosureHeight: enclosureHeight || '508',
        enclosureDepth: enclosureDepth || '508',
        electrodeConfiguration: electrodeConfiguration || 'VCB',
        conductorGap: conductorGap || '32',
        arcDuration: arcDuration || '100',
        arcDurationReduced: arcDurationReduced || '100',
      });
    }
  }, [formData1]);

  useEffect(() => {
  
    onUpdate(inputs);
  }, [inputs, onUpdate]);






  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'supplyVoltage') {
      const voltage = parseFloat(value);
      if (voltage < 208 || voltage > 15000) {
        setVoltageError(true); // Set error flag if voltage is outside range
      } else {
        setVoltageError(false); // Clear error flag if voltage is within range
      }
    }
    if (name === 'boltedFaultCurrent') {
      const voltage = parseFloat(inputs.supplyVoltage);
      const current = parseFloat(value);
      if ((voltage >= 208 && voltage <= 600 && (current < 0.500 || current > 106.000)) ||
          (voltage > 600 && voltage <= 15000 && (current < 0.200 || current > 65.000))) {
        setCurrentError(true); // Set error flag if current is outside range based on voltage
      } else {
        setCurrentError(false); // Clear error flag if current is within range
      }
    }

    if (name === 'conductorGap') {
      const voltage = parseFloat(inputs.supplyVoltage);
      const gap = parseFloat(value);
      if ((voltage >= 208 && voltage <= 600 && (gap < 6.35 || gap > 76.2)) ||
          (voltage > 600 && voltage <= 15000 && (gap < 19.05 || gap > 254))) {
        setConductorGapError(true); // Set error flag if conductor gap is outside range based on voltage
      } else {
        setConductorGapError(false); // Clear error flag if conductor gap is within range
      }
    }
    if (name === 'workingDistance') {
      const distance = parseFloat(value);
      if (distance < 305) {
        setWorkingDistanceError(true); // Set error flag if working distance is less than 305 mm
      } else {
        setWorkingDistanceError(false); // Clear error flag if working distance is within range
      }
    }
    if (name === 'enclosureWidth') {
      const width = parseFloat(value);
      const gap = parseFloat(inputs.conductorGap);

      if (width > 1244.6||width < 4 * gap) { // Check if width exceeds maximum allowable value
        setEnclosureWidthError(true); // Set error flag if width exceeds maximum
      } else {
        setEnclosureWidthError(false); // Clear error flag if width is within maximum
      }
    }
    if (name === 'enclosureHeight') {
      const height = parseFloat(value);
      if (height > 1244.6) { // Check if height exceeds maximum allowable value
        setEnclosureHeightError(true); // Set error flag if height exceeds maximum
      } else {
        setEnclosureHeightError(false); // Clear error flag if height is within maximum
      }
    }

    
   
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
    
  };



  return (
    <Paper>
    <Grid container spacing={3}>
    <Grid item xs={12} md={4}>
    <Paper style={{ padding: '20px', margin: '20px 0', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
    <Typography variant="h5" style={{ marginBottom: '20px' }}>SUPPLY DETAILS</Typography>
    <TextField
        type="number"
        label="Supply Voltage (V)"
        name="supplyVoltage"
        value={inputs.supplyVoltage}
        onChange={handleChange}
        variant="outlined"
        style={{ marginBottom: '10px',  }}
        error={voltageError}
        helperText={voltageError ? "The entered voltage is outside the allowable range of 208 V to 15000 V." : null}
        fullWidth
      />
      <TextField
        type="number"
        label="Bolted Fault Current (kA)"
        name="boltedFaultCurrent"
        value={inputs.boltedFaultCurrent}
        onChange={handleChange}
        variant="outlined"
        style={{ marginBottom: '10px',  }}
        fullWidth
        error={currentError} // Apply error style if current is outside range based on voltage
        helperText={currentError ? "The entered bolted fault current is outside the allowable range." : null}
      />
      <TextField
        type="number"
        label="Working Distance (mm)"
        name="workingDistance"
        value={inputs.workingDistance}
        onChange={handleChange}
        variant="outlined"
        style={{ marginBottom: '10px',  }}
        fullWidth
        error={workingDistanceError} // Apply error style if working distance is less than 305 mm
        helperText={workingDistanceError ? "The entered working distance is less than the allowable minimum (305 mm)." : null}
      />

      
    </Paper>
    </Grid>

    <Grid item xs={12} md={4}>
    <Paper style={{ padding: '20px', margin: '20px 0', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
    <Typography variant="h5" style={{ marginBottom: '20px' }}>ENCLOSURE DETAILS</Typography>

      <TextField
        type="number"
        label="Enclosure Width (mm)"
        name="enclosureWidth"
        value={inputs.enclosureWidth}
        onChange={handleChange}
        variant="outlined"
        style={{ marginBottom: '10px',  }}
        fullWidth
        error={enclosureWidthError}
        helperText={  enclosureWidthError 
          ? (inputs.enclosureWidth < 1244.6 
            ? "Width should be larger than four times the gap between conductors" 
            : "The entered width exceeds the maximum allowable value (1244.6 mm).") 
          : null}
      />
      <TextField
        type="number"
        label="Enclosure Height (mm)"
        name="enclosureHeight"
        value={inputs.enclosureHeight}
        onChange={handleChange}
        variant="outlined"
        style={{ marginBottom: '10px',  }}
        fullWidth
        error={enclosureHeightError}
        helperText={enclosureHeightError ? "The entered height exceeds the maximum allowable value (1244.6 mm)." : null}
      />
      <TextField
        type="number"
        label="Enclosure Depth (mm)"
        name="enclosureDepth"
        value={inputs.enclosureDepth}
        variant="outlined"
        style={{ marginBottom: '10px',  }}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        select
        label="Electrode Configuration"
        name="electrodeConfiguration"
        value={inputs.electrodeConfiguration}
        onChange={handleChange}
        variant="outlined"
        style={{ marginBottom: '10px',  }}
        fullWidth
      >
        <MenuItem value="VCB">Vertical in Box - VCB</MenuItem>
        <MenuItem value="VCBB">Vertical in Box with Barrier - VCBB</MenuItem>
        <MenuItem value="HCB">Horizontal in Box - HCB</MenuItem>
        <MenuItem value="VOA">Vertical in Open Air - VOA</MenuItem>
        <MenuItem value="HOA">Horizontal Open Air - HOA</MenuItem>
      </TextField>
      <TextField
        type="number"
        label="Conductor Gap (mm)"
        name="conductorGap"
        value={inputs.conductorGap}
        onChange={handleChange}
        variant="outlined"
        style={{ marginBottom: '10px',  }}
        fullWidth
        error={conductorGapError} // Apply error style if conductor gap is outside range based on voltage
        helperText={conductorGapError ? "The entered conductor gap is outside the allowable range." : null}
      />
    </Paper>
    </Grid>

    <Grid item xs={12} md={4}>
    <Paper style={{ padding: '20px', margin: '20px 0', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
    <Typography variant="h5" style={{ marginBottom: '20px' }}>PROTECTIVE DEVICE DETAILS</Typography>
    <TextField
        type="number"
        label="Arc Duration (ms)"
        name="arcDuration"
        value={inputs.arcDuration}
        onChange={handleChange}
        variant="outlined"
        style={{ marginBottom: '10px',  }}
        fullWidth
      />

      <TextField
        type="number"
        label="Arc Duration Reduced (ms)"
        name="arcDurationReduced"
        value={inputs.arcDurationReduced}
        onChange={handleChange}
        variant="outlined"
        style={{ marginBottom: '10px',  }}
        fullWidth
      />
    </Paper>
    </Grid>




    </Grid>
    </Paper>



  );
};

export default MyComponent;
