import React, { useState, useEffect } from 'react';
import { calculateSolarEnergyProduction } from './calculations';
import Plot from 'react-plotly.js';
import html2canvas from 'html2canvas'; // Import html2canvas library



const FinancialAnalysisComponent = ({ financialanalysisdata, onImageUpdate, financialanalysisresults }) => {
    const [monthlyBill, setMonthlyBill] = useState('300');
    const [energyCostPerKwh, setEnergyCostPerKwh] = useState('2.00');
    const [solarInsentives, setSolarInsentives] = useState('6995.00');
    const [installationCostPerWatt, setInstallationCostPerWatt] = useState('24.00');
    const [dcToAcConversionFactor, setDcToAcConversionFactor] = useState('85');
    const [panelEfficiencyDepreciationFactor, setPanelEfficiencyDepreciationFactor] = useState('0.5');
    const [panelLifetime, setPanelLifetime] = useState('20');
    const [energyCostPerYear, setEnergyCostPerYear] = useState('2.2');
    const [discountRatePerYear, setDiscountRatePerYear] = useState('4');
    const [solarAnalysisResult, setSolarAnalysisResult] = useState(null);
    const [selectedYear, setSelectedYear] = useState(1);



    function findCrossoverYear(data1, data2) {
        for (let i = 1; i < data1.length - 1; i++) {
            if ((data1[i] <= data2[i] && data1[i + 1] > data2[i + 1]) ||
                (data1[i] >= data2[i] && data1[i + 1] < data2[i + 1])) {
                // Crossover detected
                // Interpolate to find the exact crossover year
                const slope1 = (data1[i + 1] - data1[i]) / (i + 1 - i);
                const slope2 = (data2[i + 1] - data2[i]) / (i + 1 - i);
                const year = i + ((data2[i] - data1[i]) / (slope1 - slope2));
                return Math.round(year);
            }
        }
        // No crossover found
        return null;
    }

    


    const takeScreenshot = () => {
        // Use html2canvas to capture the plot container
        html2canvas(document.getElementById('financial-analysis-plot')).then(canvas => {
            // Convert the canvas to a data URL
            const imageUrl = canvas.toDataURL('image/png');
            // Call the onImageUpdate function with the image URL
            onImageUpdate(imageUrl);
        });
    };

    useEffect(() => {
        // Take a screenshot with a delay when the component mounts or when any dependency changes
        const timeoutId = setTimeout(() => {
            takeScreenshot();
        }, 500); // Adjust the delay time as needed (in milliseconds)
    
        return () => clearTimeout(timeoutId); // Clear the timeout on component unmount or dependency change
    }, [financialanalysisdata, solarAnalysisResult, monthlyBill, energyCostPerKwh, solarInsentives, installationCostPerWatt, dcToAcConversionFactor, panelEfficiencyDepreciationFactor, panelLifetime, energyCostPerYear, discountRatePerYear, selectedYear]);

        // Function to handle slider change
        const handleYearChange = (event) => {
            setSelectedYear(parseInt(event.target.value));
        };

    const handleSubmit = () => {
        // Perform any necessary action on form submission
        console.log("Financial Analysis Data:", financialanalysisdata);
    };

    useEffect(() => {
        const result = calculateSolarEnergyProduction(
            parseFloat(monthlyBill),
            parseFloat(energyCostPerKwh),
            parseFloat(solarInsentives),
            parseFloat(installationCostPerWatt),
            parseFloat(dcToAcConversionFactor),
            parseFloat(panelEfficiencyDepreciationFactor),
            parseFloat(panelLifetime),
            parseFloat(energyCostPerYear),
            parseFloat(discountRatePerYear),
            financialanalysisdata
        );
        setSolarAnalysisResult(result);
    }, [monthlyBill, energyCostPerKwh, solarInsentives, installationCostPerWatt, dcToAcConversionFactor, panelEfficiencyDepreciationFactor, panelLifetime, energyCostPerYear, discountRatePerYear, financialanalysisdata]);

    let crossoverYear = null;
    if (solarAnalysisResult) {
        crossoverYear = findCrossoverYear(solarAnalysisResult.electricityBillWithoutSolarPerYear, solarAnalysisResult.totalSolarInvestmentPerYear);
    }

    useEffect(() => {
        financialanalysisresults(solarAnalysisResult,crossoverYear,monthlyBill,energyCostPerKwh,solarInsentives,installationCostPerWatt,dcToAcConversionFactor,panelEfficiencyDepreciationFactor,panelLifetime,energyCostPerYear,discountRatePerYear)
    }, [solarAnalysisResult,crossoverYear,monthlyBill,energyCostPerKwh,solarInsentives,installationCostPerWatt,dcToAcConversionFactor,panelEfficiencyDepreciationFactor,panelLifetime,energyCostPerYear,discountRatePerYear]);





    return (
        <div>
            <h2>Financial Analysis</h2>
            <div>
                <label htmlFor="monthlyBill">Monthly Average Bill:</label>
                <input
                    type="number"
                    id="monthlyBill"
                    value={monthlyBill}
                    onChange={(e) => setMonthlyBill(e.target.value)}
                    required
                />
            </div>
            <div>
                <label htmlFor="energyCostPerKwh">Energy Cost per kWh:</label>
                <input
                    type="number"
                    id="energyCostPerKwh"
                    value={energyCostPerKwh}
                    onChange={(e) => setEnergyCostPerKwh(e.target.value)}
                    required
                />
            </div>
            <div>
                <label htmlFor="solarIntensity">Solar Intensity:</label>
                <input
                    type="number"
                    id="solarIntensity"
                    value={solarInsentives}
                    onChange={(e) => setSolarInsentives(e.target.value)}
                    required
                />
            </div>
            <div>
                <label htmlFor="installationCostPerWatt">Installation Cost per Watt:</label>
                <input
                    type="number"
                    id="installationCostPerWatt"
                    value={installationCostPerWatt}
                    onChange={(e) => setInstallationCostPerWatt(e.target.value)}
                    required
                />
            </div>
            <div>
                <label htmlFor="dcToAcConversionFactor">DC to AC Conversion Factor:</label>
                <input
                    type="number"
                    id="dcToAcConversionFactor"
                    value={dcToAcConversionFactor}
                    onChange={(e) => setDcToAcConversionFactor(e.target.value)}
                    required
                />
            </div>
            <div>
                <label htmlFor="panelEfficiencyDepreciationFactor">Panel Efficiency Depreciation Factor (%):</label>
                <input
                    type="number"
                    id="panelEfficiencyDepreciationFactor"
                    value={panelEfficiencyDepreciationFactor}
                    onChange={(e) => setPanelEfficiencyDepreciationFactor(e.target.value)}
                    required
                />
            </div>
            <div>
                <label htmlFor="panelLifetime">Panel Lifetime (years):</label>
                <input
                    type="number"
                    id="panelLifetime"
                    value={panelLifetime}
                    onChange={(e) => setPanelLifetime(e.target.value)}
                    required
                />
            </div>
            <div>
                <label htmlFor="energyCostPerYear">Energy Cost per Year (%):</label>
                <input
                    type="number"
                    id="energyCostPerYear"
                    value={energyCostPerYear}
                    onChange={(e) => setEnergyCostPerYear(e.target.value)}
                    required
                />
            </div>
            <div>
                <label htmlFor="discountRatePerYear">Discount Rate per Year (%):</label>
                <input
                    type="number"
                    id="discountRatePerYear"
                    value={discountRatePerYear}
                    onChange={(e) => setDiscountRatePerYear(e.target.value)}
                    required
                />
            </div>
            <button onClick={handleSubmit}>Submit</button>

            {/* Display Output Fields */}
            <h3>Output</h3>
            <p>Total Energy Produced in 20 Years: {solarAnalysisResult?.totalEnergyProducedIn20Years.toFixed(3)} kWh</p>
            <p>Total Bill Without Solar: {solarAnalysisResult?.totalBillWithoutSolar.toFixed(3)} $</p>
            <p>Total Solar Investment: {solarAnalysisResult?.totalSolarInvestment.toFixed(3)} $</p>
            <p>Total Savings: {solarAnalysisResult?.totalSavings.toFixed(3)} $</p>

            {/* Plot */}
            <h3>Plot</h3>
            <div id="financial-analysis-plot">
            <Plot
                data={[
                    {
                        x: Array.from({ length: solarAnalysisResult?.electricityBillWithoutSolarPerYear?.length }, (_, i) => i),
                        y: solarAnalysisResult?.electricityBillWithoutSolarPerYear,
                        type: 'scatter',
                        mode: 'lines+markers',
                        name: 'Electricity Bill Without Solar'
                    },
                    {
                        x: Array.from({ length: solarAnalysisResult?.totalSolarInvestmentPerYear?.length }, (_, i) => i),
                        y: solarAnalysisResult?.totalSolarInvestmentPerYear,
                        type: 'scatter',
                        mode: 'lines+markers',
                        name: 'Total Solar Investment'
                    }
                ]}
                layout={{ width: 800, height: 400, title: 'Electricity Bill Without Solar vs Total Solar Investment',        xaxis: {
                    title: {
                        text: 'Years'
                    }
                },
                yaxis: {
                    title: {
                        text: 'Cost'
                    }
                } }}
            />
          
            </div>
            <div>
                {/* Display Output Fields */}
                <h3>Output for each year</h3>
                <p>Total Energy Produced in {selectedYear} Year: {solarAnalysisResult?.acEnergyPerYear[selectedYear].toFixed(3)} kWh</p>
                <p>Annual Energy Consumption: {solarAnalysisResult?.averageYearlyConsumption.toFixed(3)} kWh</p>
                <p>Cost With Solar: {selectedYear === 1 ? solarAnalysisResult?.electricityBillWithSolarPerYear[selectedYear] + solarAnalysisResult?.totalPanelInstallationCost  - parseFloat(solarInsentives) : solarAnalysisResult?.electricityBillWithSolarPerYear[selectedYear]} $</p>
                <p>Cost Without Solar: {solarAnalysisResult?.electricityBillWithoutSolarPerYear_eachyear[selectedYear]} $</p>

                {/* Slider for selecting year */}
                <input
                    type="range"
                    min={1}
                    max={panelLifetime}
                    value={selectedYear}
                    onChange={handleYearChange}
                />

                {/* Bar chart for comparing costs */}
                <h3>Cost Comparison for Year {selectedYear}</h3>
                <Plot
                    data={[
                        {
                            x: [selectedYear === 1 ?'With Solar Considering installation cost and solar insentives':'With Solar', 'Without Solar'],
                            y: [
                                selectedYear === 1 ? solarAnalysisResult?.electricityBillWithSolarPerYear[selectedYear] + solarAnalysisResult?.totalPanelInstallationCost  - parseFloat(solarInsentives) : solarAnalysisResult?.electricityBillWithSolarPerYear[selectedYear],
                                solarAnalysisResult?.electricityBillWithoutSolarPerYear_eachyear[selectedYear]
                            ],
                            type: 'bar',
                            marker: {
                                color: ['#1f77b4', '#ff7f0e']
                            }
                        }
                    ]}
                    layout={{ width: 600, height: 400, title: `Cost Comparison for Year ${selectedYear}` }}
                />

                {/* Bar chart for comparing energy produced */}
                <h3>Energy Production Comparison for Year {selectedYear}</h3>
                <Plot
                    data={[
                        {
                            x: ['Energy Produced By Solar', 'Energy Consumption'],
                            y: [solarAnalysisResult?.acEnergyPerYear[selectedYear], solarAnalysisResult?.averageYearlyConsumption],
                            type: 'bar',
                            marker: { color: ['#1f77b4', '#ff7f0e'] }
                        }
                    ]}
                    layout={{ width: 600, height: 400, title: `Energy Production Comparison for Year ${selectedYear}` }}
                />
            </div>
        </div>
        
    );
};

export default FinancialAnalysisComponent;
