import React, { useState, useEffect } from 'react';
import { Paper, Typography, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, Select, MenuItem, InputLabel, TextField } from '@mui/material';
import cablesizes from "./Current carrying capacity table.json";

function CableSizingComponent({ cableDetails, installationDetails, onChange, cableSize1 }) {
    const [activeSizeMode, setActiveSizeMode] = useState('automatic');
    const [earthSizeMode, setEarthSizeMode] = useState('automatic');
    const [selectedActiveSize, setSelectedActiveSize] = useState('');
    const [selectedEarthSize, setSelectedEarthSize] = useState('');
    const [numberOfActiveCircuits, setNumberOfActiveCircuits] = useState(1);
    const [numberOfEarthCircuits, setNumberOfEarthCircuits] = useState(1);


    useEffect(() => {
        if (cableSize1) {
            setActiveSizeMode(cableSize1.activeSizeMode ? cableSize1.activeSizeMode : 'automatic');
            setEarthSizeMode(cableSize1.earthSizeMode ? cableSize1.earthSizeMode : 'automatic');
            setSelectedActiveSize(cableSize1.selectedActiveSize ? cableSize1.selectedActiveSize : '');
            setSelectedEarthSize(cableSize1.selectedEarthSize ? cableSize1.selectedEarthSize : '');
            setNumberOfActiveCircuits(cableSize1.numberOfActiveCircuits ? cableSize1.numberOfActiveCircuits : 1);
            setNumberOfEarthCircuits(cableSize1.numberOfEarthCircuits ? cableSize1.numberOfEarthCircuits : 1);
        }
      }, [cableSize1]);

    // Function to handle size mode change
    const handleActiveSizeModeChange = (event) => {
        const newSizeMode = event.target.value;
        setActiveSizeMode(newSizeMode);
        onChange({ activeSizeMode, selectedActiveSize, numberOfActiveCircuits, earthSizeMode, selectedEarthSize, numberOfEarthCircuits });
    };
    
    // Function to handle earth size mode change
    const handleEarthSizeModeChange = (event) => {
        const newSizeMode = event.target.value;
        setEarthSizeMode(newSizeMode);
    };

    // Function to handle manual size selection
    const handleActiveSizeChange = (event) => {
        const newSize = event.target.value;
        setSelectedActiveSize(newSize);
    };
    
    const handleEarthSizeChange = (event) => {
        const newSize = event.target.value;
        setSelectedEarthSize(newSize);
    };
    
    const handleNumberOfActiveCircuitsChange = (event) => {
        let number = parseInt(event.target.value, 10);
        if (isNaN(number) || number <= 0) {
          number = 1; // Default to 1 if the input is invalid or zero
        }
        setNumberOfActiveCircuits(number);
      };
    
      const handleNumberOfEarthCircuitsChange = (event) => {
        let number = parseInt(event.target.value, 10);
        if (isNaN(number) || number <= 0) {
          number = 1; // Default to 1 if the input is invalid or zero
        }
        setNumberOfEarthCircuits(number);
      };

    // Get cable sizes for the current cable type and insulation
    const currentCableSizes = cableDetails && cableDetails.cableType && cableDetails.insulation && cableDetails.conductor && installationDetails && installationDetails.selectedInstallation
    ? Object.keys(cablesizes[cableDetails.cableType][cableDetails.insulation][cableDetails.conductor][installationDetails.selectedInstallation])
    .sort((a, b) => parseFloat(a) - parseFloat(b))
    : [];


    // Update selected sizes when cable details change
    useEffect(() => {
        if (!cableSize1.activeSizeMode) {
        if (currentCableSizes.length > 0) {
            setSelectedActiveSize(currentCableSizes[0]);
            setSelectedEarthSize(currentCableSizes[0]);
        }
    }
    }, [activeSizeMode,earthSizeMode]);


    useEffect(() => {
        onChange({
            activeSizeMode,
            selectedActiveSize,
            numberOfActiveCircuits,
            earthSizeMode,
            selectedEarthSize,
            numberOfEarthCircuits
        });
    }, [activeSizeMode, selectedActiveSize, numberOfActiveCircuits, earthSizeMode, selectedEarthSize, numberOfEarthCircuits, onChange]);

    return (
        <Paper style={{ padding: '20px', margin: '20px 0', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
        <Typography variant="h5" style={{ marginBottom: '20px' }}>CABLE SIZING</Typography>

        {/* Active Cable Size */}
        <div>
            <FormControl component="fieldset" style={{ marginBottom: '20px' }}>
                <FormLabel component="legend">Active Cable Size</FormLabel>
                <RadioGroup
                    row
                    aria-label="active-cable-size"
                    name="active-cable-size"
                    value={activeSizeMode}
                    onChange={handleActiveSizeModeChange}
                >
                    <FormControlLabel value="automatic" control={<Radio />} label="Automatic" />
                    <FormControlLabel value="manual" control={<Radio />} label="Manual" />
                </RadioGroup>
            </FormControl>
        </div>


        {activeSizeMode === 'manual' && (
                <>
                    <FormControl fullWidth style={{ marginBottom: '20px' }}>
                        <InputLabel id="select-active-cable-size-label">Select Active Cable Size</InputLabel>
                        <Select
                            labelId="select-active-cable-size-label"
                            value={selectedActiveSize}
                            onChange={handleActiveSizeChange}
                            label="Select Active Cable Size"
                        >
                            {currentCableSizes.map(size => (
                                <MenuItem key={size} value={size}>{size}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField 
                        label="Number of Active Circuits" 
                        type="number" 
                        inputProps={{ min: 1, max: 200 }} 
                        value={numberOfActiveCircuits} 
                        onChange={handleNumberOfActiveCircuitsChange}
                        fullWidth 
                        style={{ marginBottom: '20px' }}
                    />
                </>
            )}

        {/* Earth Cable Size */}
        <div>
            <FormControl component="fieldset" style={{ marginBottom: '20px' }}>
                <FormLabel component="legend">Earth Cable Size</FormLabel>
                <RadioGroup
                    row
                    aria-label="earth-cable-size"
                    name="earth-cable-size"
                    value={earthSizeMode}
                    onChange={handleEarthSizeModeChange}
                >
                    <FormControlLabel value="automatic" control={<Radio />} label="Automatic" />
                    <FormControlLabel value="manual" control={<Radio />} label="Manual" />
                </RadioGroup>
            </FormControl>
        </div>








            {earthSizeMode === 'manual' && (
                <>
                    <FormControl fullWidth style={{ marginBottom: '20px' }}>
                        <InputLabel id="select-earth-cable-size-label">Select Earth Cable Size</InputLabel>
                        <Select
                            labelId="select-earth-cable-size-label"
                            value={selectedEarthSize}
                            onChange={handleEarthSizeChange}
                            label="Select Earth Cable Size"
                        >
                            {currentCableSizes.map(size => (
                                <MenuItem key={size} value={size}>{size}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField 
                        label="Number of Earth Circuits" 
                        type="number" 
                        inputProps={{ min: 1, max: 200 }} 
                        value={numberOfEarthCircuits} 
                        onChange={handleNumberOfEarthCircuitsChange}
                        fullWidth 
                        style={{ marginBottom: '20px' }}
                    />
                </>
            )}
        </Paper>
    );
}

export default CableSizingComponent;
