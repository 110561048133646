import React, { useState, useEffect } from 'react';
import { Paper, Typography, Modal, List, ListItem, ListItemText, ListItemAvatar, Avatar, Box, TextField, InputAdornment, Select, MenuItem, FormControl, InputLabel, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import installationData from "./installationpopupmapping.json";
import deratingOptions from "./deratingFactorsMainOptions.json";
import deratingOptionAdditionalair from "./deratingFactorAdditionalOptions.json";
import deratingOptionAdditional_buried_numberofcircuits from "./deratingFactorAdditionalOptions_Buried_numberofcircuits.json"
import deratingOptionAdditional_buried_seperateenclosure from "./deratingFactorAdditionalOptions_Buried_numberofcircuits_seperateenclosure.json"
import deratingOptionAdditional_buried_combined from "./deratingFactorAdditionalOptions_Buried_numberofcircuits_combinedenclosure.json"
import deratingFactorValuesForTrays from "./deratingFactorValuesForTrays.json"
import deratingFactorValuesForAirAmbientTemp from "./deratingfactorforairambienttemp.json"
import deratingFactorValuesForBunchedCircuit from "./Deratingfactorforbunchedcircuits.json"
import deratingFactorValuesForBuriedCircuit from "./deratingFactorAdditionalvalues_buriednoofcircuits.json"
import deratingFactorValuesForBuriedCircuitspecialenclosure from "./deratingFactorAdditionalvalues_buriednoofcircuits_specialenclosure.json"
import deratingFactorValuesForSoilAmbientTemp from "./deratingfactorforsoilambienttemp.json"
import deratingFactorValuesForResistivity from "./deratingFactorAdditionalvalues_resistivity.json"
import deratingFactorValuesFordepth from"./deratingFactorAdditionalvalues_depthoflaying.json"

const insulationToInstallationMapping = {
    "THERMOPLASTIC": ["THERMOPLASTIC"],
    "X-90": ["X-90", "X-HF-90", "R-EP-90", "R-CPE-90", "R-HF-90", "R-CSP-90"],
    "R-HF-110": ["R-HF-110", "R-E-110", "X-HF-110"]
  };

const deratingOptionSelector = {
    "In Air" : ["Unenclosed - Spaced","Unenclosed - Spaced from surface","Unenclosed - Touching","Unenclosed - Exposed to sun","Enclosed - Wiring enclosure in air","Thermal insulation - Partially surrounded by thermal insulation","Thermal insulation - Completely surrounded by thermal insulation","Thermal insulation - Partially surrounded by thermal insulation, unenclosed","Thermal insulation - Partially surrounded by thermal insulation, in a wiring enclosure","Thermal insulation - Completely surrounded by thermal insulation, unenclosed","Thermal insulation - Completely surrounded by thermal insulation, in a wiring enclosure"],
    "Buried" : ["Buried direct","Underground wiring enclosure","Underground wiring seperate enclosure"]
}

const initialDeratingValues = {
    "INSTALLATION CONDITIONS": "1", // Initial value is at key 1
    "AIR AMBIENT TEMPERATURES": "6", // Initial value is at key 6
    // ... define initial values for other subcategories if needed
    "NUMBER OF CIRCUITS" : "1",
    "SOIL AMBIENT TEMPERATURE" : "4",
    "DEPTH OF LAYING" : "1",
    "THERMAL RESISTIVITY OF THE SOIL" : "4"

};



function InstallationComponent({ cableType, insulation, onChange, installationDetails1 }) {
    const [open, setOpen] = useState(false);
    const [selectedInstallation, setSelectedInstallation] = useState('');
    const [showDeratingOptions, setShowDeratingOptions] = useState(false);
    const [deratingCategory, setDeratingCategory] = useState('');
    const [deratingOption, setDeratingOption] = useState('');
    const [deratingSubCategory, setDeratingSubCategory] = useState('');
    const [deratingOptionsValues, setDeratingOptionsValues] = useState({});
    const [additionalOptions, setAdditionalOptions] = useState({});
    const [selectedAdditionalOptions, setSelectedAdditionalOptions] = useState({});
    const [deratingFactorair, setderatingFactorair] = useState({
        trayInstallation: 1,
        airTemperature: 1,
        Bunchedcircuitinair: 1,
    });
    const [deratingFactorsoil, setderatingFactorsoil] = useState({
        Numberofburiedcircuit: 1,
        Soiltemperature : 1,
        Depthoflaying : 1,
        Thermalresistivuty :1
    });

    const installationMethods = cableType && insulation ? 
    installationData[cableType][Object.keys(insulationToInstallationMapping).find(key => 
      insulationToInstallationMapping[key].includes(insulation)) || insulation] : {};
      


      useEffect(() => {
        if (installationDetails1) {
            setOpen(installationDetails1.open ? installationDetails1.open : false);
            setSelectedInstallation(installationDetails1.selectedInstallation ? installationDetails1.selectedInstallation : '');
            setShowDeratingOptions(installationDetails1.showDeratingOptions ? installationDetails1.showDeratingOptions : false);
            setDeratingOptionsValues(installationDetails1.deratingOptionsValues ? installationDetails1.deratingOptionsValues : {});
            setAdditionalOptions(installationDetails1.additionalOptions ? installationDetails1.additionalOptions : {});
            setSelectedAdditionalOptions(installationDetails1.selectedAdditionalOptions ? installationDetails1.selectedAdditionalOptions : {});
            setderatingFactorair(installationDetails1.deratingFactorair ? installationDetails1.deratingFactorair : {
                trayInstallation: 1,
                airTemperature: 1,
                Bunchedcircuitinair: 1,
            });
            setderatingFactorsoil(installationDetails1.deratingFactorsoil ? installationDetails1.deratingFactorsoil : {
                Numberofburiedcircuit: 1,
                Soiltemperature : 1,
                Depthoflaying : 1,
                Thermalresistivuty :1
            });

        }
      }, [installationDetails1]);


    

  
    useEffect(() => {

        if (!installationDetails1.selectedInstallation) {
      const firstInstallationMethod = Object.keys(installationData[cableType]?.[insulation] || {})[0];
      setSelectedInstallation(firstInstallationMethod);
        }
    }, [cableType, insulation]);

    useEffect(() => {
        if (!installationDetails1.selectedInstallation) {
        // Find the correct key for installation methods
        const installationKey = Object.keys(insulationToInstallationMapping).find(key => 
          insulationToInstallationMapping[key].includes(insulation)) || insulation;
  
        // Set the initial selected installation
        const firstInstallationMethod = Object.keys(installationData[cableType]?.[installationKey] || {})[0];
        setSelectedInstallation(firstInstallationMethod);
        }
      }, [cableType, insulation]);


      useEffect(() => {

        let finalDeratingFactor = 1;
        let ambientTemperature = 0;
    
        if (showDeratingOptions) {
            if (deratingCategory === 'In Air') {
                finalDeratingFactor = deratingFactorair.trayInstallation * deratingFactorair.airTemperature * deratingFactorair.Bunchedcircuitinair;
                ambientTemperature = deratingOptionsValues['AIR AMBIENT TEMPERATURES'] || 40;
            } else if (deratingCategory === 'Buried') {
                finalDeratingFactor = deratingFactorsoil.Numberofburiedcircuit * deratingFactorsoil.Soiltemperature * deratingFactorsoil.Thermalresistivuty * deratingFactorsoil.Depthoflaying;
                ambientTemperature = deratingOptionsValues['SOIL AMBIENT TEMPERATURE'] || 25;
                
            }
        } else {
            // Default values if derating options are not shown
            finalDeratingFactor = 1;
            ambientTemperature = deratingCategory === 'In Air' ? 40 : 25;
        }
    
        onChange({ finalDeratingFactor, ambientTemperature, selectedInstallation,showDeratingOptions,deratingCategory,deratingOptionsValues,additionalOptions,selectedAdditionalOptions,deratingFactorair,deratingFactorsoil,open });

    }, [showDeratingOptions, deratingFactorair, deratingFactorsoil, deratingOptionsValues, deratingCategory, onChange,selectedInstallation]);





      useEffect(() => {

        if (!installationDetails1.selectedInstallation) {


        const category = Object.keys(deratingOptionSelector)
          .find(key => deratingOptionSelector[key].includes(selectedInstallation));
        
        setDeratingCategory(category);
    
        if (category && showDeratingOptions) {
            const initialValues = {};
            Object.keys(deratingOptions[category] || {}).forEach(subcategory => {
                const initialKey = initialDeratingValues[subcategory];
                const options = deratingOptions[category][subcategory] || {};
                initialValues[subcategory] = options[initialKey] || Object.values(options)[0];
            });
            setDeratingOptionsValues(initialValues);
        } else {
            setDeratingOptionsValues({});
        }
    }

    }, [selectedInstallation, showDeratingOptions, deratingOptions, insulation]);








    useEffect(() => {

        if (!installationDetails1.selectedInstallation) {

 

        if (deratingCategory === 'In Air') {
            const selectedCondition = deratingOptionsValues['INSTALLATION CONDITIONS'];
            const optionsForCondition = deratingOptionAdditionalair[cableType]?.[selectedCondition] || {};
            setAdditionalOptions(optionsForCondition);

            const initialAdditionalValues = {};
            Object.keys(optionsForCondition).forEach(optionCategory => {
                const firstKey = Object.values(optionsForCondition[optionCategory])[0];
                initialAdditionalValues[optionCategory] = firstKey; // Storing the value
            });
            setSelectedAdditionalOptions(initialAdditionalValues);
        }
        else if (deratingCategory === 'Buried') {

            const numberOfCircuits = deratingOptionsValues['NUMBER OF CIRCUITS'];
    
            if (numberOfCircuits !== 1 && numberOfCircuits !== 0) {

                let additionalBuriedOptions = {};

                if (selectedInstallation === "Underground wiring seperate enclosure") {
                    // Logic for "Underground wiring seperate enclosure"
                    additionalBuriedOptions = deratingOptionAdditional_buried_seperateenclosure[cableType] || {};
                } else if (selectedInstallation === "Underground wiring enclosure") {
                    // Logic for "Underground wiring enclosure"
                    additionalBuriedOptions = deratingOptionAdditional_buried_combined[cableType] || {};
                } else {
                    // Default case for other buried installations
                    additionalBuriedOptions = deratingOptionAdditional_buried_numberofcircuits[cableType] || {};
                }


                
                setAdditionalOptions(additionalBuriedOptions);
    
                // Set initial values for additional buried options
                const initialBuriedValues = {};
                Object.keys(additionalBuriedOptions).forEach(optionCategory => {
                    const optionValues = additionalBuriedOptions[optionCategory];
                    initialBuriedValues[optionCategory] = Object.values(optionValues)[0];
                });
                setSelectedAdditionalOptions(initialBuriedValues);
            } else {
                // Reset additional options if the number of circuits is '1'
                setAdditionalOptions({});
                setSelectedAdditionalOptions({});
            }
        }
    }
 
    }, [deratingOptionsValues, deratingCategory, cableType, selectedInstallation]);




    
    useEffect(() => {


        installationDetails1.selectedInstallation = null;

    }, [deratingOptionsValues["INSTALLATION CONDITIONS"]],deratingCategory);













    useEffect(() => {
        if (deratingCategory === 'In Air') {
            let trayInstallationFactor = 1; // Default value
            let bunchedInstallationFactor = 1;
    
            // Calculate the derating factor for tray installation
            if (deratingOptionsValues['INSTALLATION CONDITIONS'] === "IN TRAYS, RACKS, CLEATS OR OTHER SUPPORTS") {
                const installation = selectedAdditionalOptions['Installation'];
                const numberOfTiers = selectedAdditionalOptions['Number of tiers or rows of cable supports'];
                const numberOfCircuits = selectedAdditionalOptions['Number of circuits per tier or row'];
    
                if (deratingFactorValuesForTrays[cableType] &&
                    deratingFactorValuesForTrays[cableType][installation] &&
                    deratingFactorValuesForTrays[cableType][installation][numberOfTiers] &&
                    deratingFactorValuesForTrays[cableType][installation][numberOfTiers][numberOfCircuits]) {
                    trayInstallationFactor = deratingFactorValuesForTrays[cableType][installation][numberOfTiers][numberOfCircuits];
                }
            }
    
            if (deratingOptionsValues['INSTALLATION CONDITIONS'] === "BUNCHED CIRCUITS") {
                const installation = selectedAdditionalOptions['Arrangement of cables'];
                const numberOfCircuits = selectedAdditionalOptions['Number of circuits'];
    
                if (deratingFactorValuesForBunchedCircuit[installation] &&
                    deratingFactorValuesForBunchedCircuit[installation][numberOfCircuits]) {
                    bunchedInstallationFactor = deratingFactorValuesForBunchedCircuit[installation][numberOfCircuits];
                }
            }
    
            // Calculate the derating factor for air ambient temperature
            const airTemperatureOption = deratingOptionsValues['AIR AMBIENT TEMPERATURES'];
            let airTemperatureFactor = 1; // Default value
            const insulationType = insulationToInstallationMapping[insulation][0]; // Get the first matching insulation type
    
            if (deratingFactorValuesForAirAmbientTemp[insulationType] &&
                deratingFactorValuesForAirAmbientTemp[insulationType][airTemperatureOption]) {
                airTemperatureFactor = deratingFactorValuesForAirAmbientTemp[insulationType][airTemperatureOption];
            }
    
            setderatingFactorair({
                trayInstallation: trayInstallationFactor,
                airTemperature: airTemperatureFactor,
                Bunchedcircuitinair: bunchedInstallationFactor
            });
        }
    
        if (deratingCategory === 'Buried') {


            let Numberofburiedcircuit = 1;

           
            
          // Default value
            // Other buried conditions factors like Soiltemperature, Depthoflaying, Thermalresistivuty can be defined here if needed

            // Calculate the derating factor for buried installation
                const spaceBetweenCableGroups = selectedAdditionalOptions['Space between cable groups'];
                const numberofcircuits = deratingOptionsValues['NUMBER OF CIRCUITS']
                const resistivityoption = deratingOptionsValues['THERMAL RESISTIVITY OF THE SOIL']
                const depthoflayingoption = deratingOptionsValues['DEPTH OF LAYING']

                if (showDeratingOptions) {
    
                if (selectedInstallation === "Underground wiring separate enclosure" || selectedInstallation === "Underground wiring enclosure") {
                    if (deratingFactorValuesForBuriedCircuitspecialenclosure[selectedInstallation] &&
                        deratingFactorValuesForBuriedCircuitspecialenclosure[selectedInstallation][spaceBetweenCableGroups]) {
                        Numberofburiedcircuit = deratingFactorValuesForBuriedCircuitspecialenclosure[selectedInstallation][spaceBetweenCableGroups][numberofcircuits];
                    }
                } else {
                    if (deratingFactorValuesForBuriedCircuit[cableType] &&
                        deratingFactorValuesForBuriedCircuit[cableType][spaceBetweenCableGroups]) {
                        Numberofburiedcircuit = deratingFactorValuesForBuriedCircuit[cableType][spaceBetweenCableGroups][numberofcircuits];
                    }
                }
            }
            

                const soilTemperatureOption = deratingOptionsValues['SOIL AMBIENT TEMPERATURE'];
                let Soiltemperature = 1; // Default value
                const insulationType = insulationToInstallationMapping[insulation][0]; // Get the first matching insulation type
        
                if (deratingFactorValuesForSoilAmbientTemp[insulationType] &&
                    deratingFactorValuesForSoilAmbientTemp[insulationType][soilTemperatureOption]) {
                        Soiltemperature = deratingFactorValuesForSoilAmbientTemp[insulationType][soilTemperatureOption];
                }

                let Thermalresistivuty = 1;
                if (deratingFactorValuesForResistivity[selectedInstallation]&&deratingFactorValuesForResistivity[selectedInstallation][cableType] && deratingFactorValuesForResistivity[selectedInstallation][cableType][resistivityoption] ){
                    Thermalresistivuty = deratingFactorValuesForResistivity[selectedInstallation][cableType][resistivityoption];
                }

                let Depthoflaying = 1;
                if(deratingFactorValuesFordepth[depthoflayingoption]){
                    Depthoflaying = deratingFactorValuesFordepth[depthoflayingoption];
                }

    
            // Update state with buried installation derating factor
            // Assuming setderatingFactor is a state updater function, add the buried condition's value
            setderatingFactorsoil({
                Numberofburiedcircuit: Numberofburiedcircuit,
                Soiltemperature: Soiltemperature,
                Thermalresistivuty: Thermalresistivuty,
                Depthoflaying: Depthoflaying
        });
        }

    }, [deratingCategory, deratingOptionsValues, selectedAdditionalOptions, cableType, insulation]);










      
    const handleAdditionalOptionChange = (optionCategory, key) => {

        setSelectedAdditionalOptions(prev => ({ ...prev, [optionCategory]: key }));
      
    };

    const renderAdditionalOptions = () => {

        
        if (Object.keys(additionalOptions).length === 0) {
            return null;
        }
        if (showDeratingOptions && Object.keys(additionalOptions).length > 0) {
        return Object.entries(additionalOptions).map(([optionCategory, values]) => (
            <FormControl fullWidth key={optionCategory} style={{ marginTop: '10px' }}>
                <InputLabel>{optionCategory}</InputLabel>
                <Select
                    value={selectedAdditionalOptions[optionCategory] || ''}
                    onChange={(e) => handleAdditionalOptionChange(optionCategory, e.target.value)}
                    label={optionCategory}
                >
                    {Object.entries(values).map(([key, label]) => (
                        <MenuItem key={key} value={label}>{label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        ));
        }

    };






    const handleDeratingOptionChange = (subcategory, value) => {
        
        setDeratingOptionsValues(prev => ({ ...prev, [subcategory]: value }));
    };



      const handleSubCategoryChange = (event) => {
        setDeratingSubCategory(event.target.value);
        // Reset the derating option when subcategory changes
        setDeratingOption('');
      };
    
    
      const handleCheckboxChange = (event) => {
        setShowDeratingOptions(event.target.checked);
      };

    


  
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleSelectInstallation = (installationName) => {
      setSelectedInstallation(installationName);
      updateParent(installationName);
      handleClose();
    };
  
    const updateParent = (installationName) => {
      onChange({ installationName });
    };




  
 

    const modalStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      overflowY: 'auto',
      maxHeight: '80%'
    };
  
    return (
      <Paper style={{ padding: '20px', margin: '20px 0' }}>
        <Typography variant="h5">INSTALLATION DETAILS</Typography>
        <TextField
          label="Installation Method"
          value={selectedInstallation}
          onClick={handleOpen}
          disabled={!cableType || !insulation}
          fullWidth
          style={{ marginTop: '10px', marginBottom: '10px' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDownIcon />
              </InputAdornment>
            ),
            readOnly: true,
          }}
        />
        <Modal open={open} onClose={handleClose}>
          <Box sx={modalStyle}>
            <List>
              {Object.entries(installationMethods).map(([name, imagePath]) => (
                <ListItem button onClick={() => handleSelectInstallation(name)} key={name}>
                  <ListItemAvatar>
                    <Avatar src={imagePath} alt={name} style={{ width: '90px', height: '90px' }} />
                  </ListItemAvatar>
                  <ListItemText primary={name} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Modal>


        <FormGroup>
                <FormControlLabel
                    control={<Checkbox checked={showDeratingOptions} onChange={handleCheckboxChange} />}
                    label="Show Derating Options"
                />
            </FormGroup>

            {showDeratingOptions && deratingCategory && (
            Object.entries(deratingOptions[deratingCategory] || {}).map(([subcategory, options]) => (
                <div key={subcategory}>
                    <FormControl fullWidth style={{ marginTop: '10px' }}>
                        <InputLabel>{subcategory}</InputLabel>
                        <Select
                            value={deratingOptionsValues[subcategory] || ''}
                            onChange={(e) => handleDeratingOptionChange(subcategory, e.target.value)}
                            label={subcategory}
                        >
                            {Object.entries(options).map(([key, value]) => (
                                <MenuItem key={key} value={value}>{value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {subcategory === 'INSTALLATION CONDITIONS' && (
                        <Typography variant="subtitle2" style={{ marginLeft: '10px' }}>
                        Derating Factor: {
                            deratingFactorair.Bunchedcircuitinair !== 1 
                            ? deratingFactorair.Bunchedcircuitinair.toFixed(2) 
                            : deratingFactorair.trayInstallation.toFixed(2)
                        }
                    </Typography>
                    
                    )}
                    {subcategory === 'AIR AMBIENT TEMPERATURES' && (
                        <Typography variant="subtitle2" style={{ marginLeft: '10px' }}>
                            Derating Factor: {deratingFactorair.airTemperature.toFixed(2)}
                        </Typography>
                    )}
                      {subcategory === 'NUMBER OF CIRCUITS' && (
                        <Typography variant="subtitle2" style={{ marginLeft: '10px' }}>
                            Derating Factor: {deratingFactorsoil.Numberofburiedcircuit.toFixed(2)}
                        </Typography>
                    )}
                     {subcategory === 'SOIL AMBIENT TEMPERATURE' && (
                        <Typography variant="subtitle2" style={{ marginLeft: '10px' }}>
                            Derating Factor: {deratingFactorsoil.Soiltemperature.toFixed(2)}
                        </Typography>
                    )}
                    {subcategory === 'DEPTH OF LAYING' && (
                        <Typography variant="subtitle2" style={{ marginLeft: '10px' }}>
                            Derating Factor: {deratingFactorsoil.Depthoflaying.toFixed(2)}
                        </Typography>
                    )}
                     {subcategory === 'THERMAL RESISTIVITY OF THE SOIL' && (
                        <Typography variant="subtitle2" style={{ marginLeft: '10px' }}>
                            Derating Factor: {deratingFactorsoil.Depthoflaying.toFixed(2)}
                        </Typography>
                    )}
                </div>
            ))
        )}

        {renderAdditionalOptions()}

        {/* Display Final Derating Factor */}
        <Typography variant="h6" style={{ marginTop: '20px' }}>
    Final Derating Factor: 
    {deratingCategory === 'In Air' ? 
        (deratingFactorair.trayInstallation * deratingFactorair.airTemperature * deratingFactorair.Bunchedcircuitinair).toFixed(2) :
        (deratingFactorsoil.Numberofburiedcircuit * deratingFactorsoil.Soiltemperature * deratingFactorsoil.Thermalresistivuty * deratingFactorsoil.Depthoflaying).toFixed(2)
    }
</Typography>
    </Paper>
);
  }








  
  export default InstallationComponent;
