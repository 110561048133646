import React, { useState } from 'react';
import { Stage, Layer, Circle, Line, Rect } from 'react-konva';

const NetworkCanvas = () => {
  const [nodes, setNodes] = useState([]);
  const [lines, setLines] = useState([]);
  const [newLine, setNewLine] = useState(null);

  // Function to handle node creation
  const addNode = (type) => {
    const newNode = {
      id: nodes.length + 1,
      type,
      x: 100,
      y: 100,
      width: type === 'bus' ? 100 : null,
      height: type === 'bus' ? 10 : null
    };
    setNodes([...nodes, newNode]);
  };

  // Function to handle node click
  const handleNodeClick = (nodeId, nodeX, nodeY) => {
    if (!newLine) {
      // Start drawing a new line
      setNewLine({
        startNode: nodeId,
        points: [nodeX, nodeY, nodeX, nodeY],
      });
    } else {
      // Finish drawing the line by connecting to the clicked node
      setLines([
        ...lines,
        {
          startNode: newLine.startNode,
          endNode: nodeId,
          points: [newLine.points[0], newLine.points[1], nodeX, nodeY],
        },
      ]);
      setNewLine(null);
    }
  };

  // Function to update the line's end point as you move the mouse
  const handleMouseMove = (e) => {
    if (newLine) {
      const stage = e.target.getStage();
      const { x, y } = stage.getPointerPosition();
      const updatedLine = {
        ...newLine,
        points: [newLine.points[0], newLine.points[1], x, y],
      };
      setNewLine(updatedLine);
    }
  };

  return (
    <div>
      {/* Toolbar */}
      <div style={{ marginBottom: '20px' }}>
        <button onClick={() => addNode('generator')}>Add Generator</button>
        <button onClick={() => addNode('load')}>Add Load</button>
        <button onClick={() => addNode('bus')}>Add Bus</button>
      </div>

      {/* Canvas */}
      <Stage
        width={window.innerWidth}
        height={window.innerHeight}
        onMouseMove={handleMouseMove}
      >
        <Layer>
          {nodes.map((node) => {
            if (node.type === 'bus') {
              return (
                <Rect
                  key={node.id}
                  x={node.x}
                  y={node.y}
                  width={node.width}
                  height={node.height}
                  fill="gray"
                  draggable
                  onDragEnd={(e) => {
                    const updatedNodes = nodes.map((n) =>
                      n.id === node.id
                        ? { ...n, x: e.target.x(), y: e.target.y() }
                        : n
                    );
                    setNodes(updatedNodes);
                  }}
                  onClick={(e) => handleNodeClick(node.id, node.x, node.y)}
                />
              );
            } else {
              return (
                <Circle
                  key={node.id}
                  x={node.x}
                  y={node.y}
                  radius={20}
                  fill={node.type === 'generator' ? 'green' : 'blue'}
                  draggable
                  onDragEnd={(e) => {
                    const updatedNodes = nodes.map((n) =>
                      n.id === node.id
                        ? { ...n, x: e.target.x(), y: e.target.y() }
                        : n
                    );
                    setNodes(updatedNodes);
                  }}
                  onClick={(e) => handleNodeClick(node.id, node.x, node.y)}
                />
              );
            }
          })}

          {lines.map((line, index) => {
            const startNode = nodes.find((n) => n.id === line.startNode);
            const endNode = nodes.find((n) => n.id === line.endNode);
            return (
              <Line
                key={index}
                points={[startNode.x, startNode.y, endNode.x, endNode.y]}
                stroke="black"
              />
            );
          })}

          {/* Line being drawn */}
          {newLine && (
            <Line
              points={newLine.points}
              stroke="black"
              dash={[4, 4]}
              pointerLength={10}
            />
          )}
        </Layer>
      </Stage>
    </div>
  );
};

export default NetworkCanvas;
