import React from 'react';
import Styles from './results.module.scss';

function Results({ InputDetails, results }) {
    const { phase } = InputDetails;

    let phaseOutput;
    switch (phase) {
        case 'Single-phase':
            phaseOutput = (
                <>
                    <p><strong>Total RMS current:</strong> {results?.total_current_active?.toFixed(3)} &lt; {results?.total_current_active_angle?.toFixed(3)}&nbsp;&deg; A </p>
                    <p><strong>Total power factor:</strong> {results?.total_powerfactor_active?.toFixed(3)}</p>
                    <p><strong>Total harmonic distortion:</strong> {results?.total_harmonic_distortion?.toFixed(3)} %</p>

                    {/* Add more single-phase specific calculation results here */}
                </>
            );
            break;
        case 'Three-phase':
            if (InputDetails.isBalanced) {
                phaseOutput = (
                    <>
                        <p><strong>Total RMS current per phase:</strong> {results?.total_current_active?.toFixed(3)} &lt; {results?.total_current_active_angle?.toFixed(3)}&nbsp;&deg; A</p>
                        <p><strong>Total power factor per phase:</strong> {results?.total_powerfactor_active?.toFixed(3)} </p>
                        <p><strong>Total harmonic distortion:</strong> {results?.total_harmonic_distortion?.toFixed(3)} %</p>
                        {/* Add more balanced three-phase specific calculation results here */}
                    </>
                );
            } else if (InputDetails.isUnbalanced) {
                phaseOutput = (
                    <>
                        <p><strong>Total RMS current Phase L1:</strong> {results?.total_current_phaseA?.toFixed(3)} &lt; {results?.total_current_active_angle_phaseA?.toFixed(3)}&nbsp;&deg; A</p>
                        <p><strong>Total power factor Phase L1:</strong> {results?.total_powerfactor_active_phaseA?.toFixed(3)}</p>
                        <p><strong>Total harmonic distortion:</strong> {results?.total_harmonic_distortion_phaseA?.toFixed(3)} %</p>
                        {/* Add more unbalanced three-phase specific calculation results here */}
                    </>
                );
            }
            break;
        default:
            phaseOutput = null; // Handle other cases if needed
    }

    return (
        <div className={Styles.resultContainer}>
            <h2>Results</h2>
            {/* Display calculation results */}
            <div className={Styles.calculationResults}>
                {phaseOutput}
            </div>
        </div>
    );
}

export default Results;
