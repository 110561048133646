import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

function ResultsComponent({ activeResults, earthResults, cableSize, protectiveDeviceDetails }) {
  
    // Helper function to process nested objects
    const processNestedObject = (nestedObj, parentKey) => {
      return Object.entries(nestedObj).map(([key, value]) => {
        const fullKey = `${parentKey}.${key}`; // Create a combined key
        if (typeof value === 'object') {
          return processNestedObject(value, fullKey); // Recursively process nested objects
        }
        return { key: fullKey, value: String(value) }; // Convert value to string
      }).flat(); // Flatten the array of arrays
    };

  
    // Function to convert result object into table rows

    const Active_cable_size = activeResults.selectedCableSize
    const Active_cable_mode = cableSize.activeSizeMode
    const Circuit_capacity_derated =  activeResults.currentCarryingCapacity.circuitRatingDerated
    const spare_capacity = Math.round(activeResults.currentCarryingCapacity.spareCapacity * 100) / 100
    const number_of_active_circuits = activeResults.number_of_circuits
    const current_carrying_cap_ok = activeResults.currentCarryingCapacity.ok
    const current_carrying_cap_ref = activeResults.currentCarryingCapacity.current_capacity_ref


    const Voltage_drop =  Math.round(activeResults.voltageDrop.voltageDropCable * 100) / 100
    const voltage_drop_percent =  Math.round(activeResults.voltageDrop.voltageDropPercent * 100) / 100
    const max_length_cable =  Math.round(activeResults.voltageDrop.maxLengthOfCable * 100) / 100
    const resistance_active = activeResults.voltageDrop.resistancePerKm
    const reactance_active = activeResults.voltageDrop.reactancePerKm
    const operating_temp_calculation = Math.round(activeResults.voltageDrop.operatingTempCalculated * 100) / 100
    const voltage_drop_cap_ok = activeResults.voltageDrop.ok
    const resistance_active_ref = activeResults.voltageDrop.resistance_per_km_ref
    const reactance_active_ref = activeResults.voltageDrop.reactance_per_km_ref

    const short_circuit_soure = activeResults.shortCircuitCapacity.faultLevelSupply
    const short_circuit_load =  Math.round(activeResults.shortCircuitCapacity.faultLevelLoad * 100) / 100
    const K_value = activeResults.shortCircuitCapacity.KValue
    const source_impedance = activeResults.shortCircuitCapacity.sourceImpedance
    const total_impedance = activeResults.shortCircuitCapacity.totalImpedance
    const short_circuit_capacity = Math.round(activeResults.shortCircuitCapacity.ICalculation * 100) / 100
    const short_circuit_cap_ok = activeResults.shortCircuitCapacity.ok
    const K_value_ref = activeResults.shortCircuitCapacity.KValue_ref



    const Earth_cable_size = earthResults.Earth_cable_size
    const Earth_cable_mode = cableSize.earthSizeMode
    const numberof_earth_cable = earthResults.number_of_earth_circuits
    const resistance_earth = earthResults.resistance_per_km_earth
    const reactance_earth = earthResults.reactance_per_km_earth
    const Z_int =  Math.round(earthResults.Z_int * 1000) / 1000
    const Z_ext =  Math.round(earthResults.Z_ext * 1000) / 1000
    const loop_impedance =  Math.round(earthResults.Loop_impedance * 1000) / 1000
    const Loop_impedance_check = earthResults.Loop_impedance_check
    const earth_short_circuit_cap_check = earthResults.short_circuit_ok
    const earth_short_circuit_cap = Math.round(earthResults.short_circuit_cap* 100) / 100
    const max_allowed_loop_impedance = Math.round(earthResults.Max_allowed_value * 1000) / 1000
    const resistance_earth_ref = earthResults.resistance_per_km_earth_ref
    const reactance_earth_ref = earthResults.reactance_per_km_earth_ref


    const active_cable_check = current_carrying_cap_ok && voltage_drop_cap_ok && short_circuit_cap_ok




    const protective_device_present = protectiveDeviceDetails.addUpstreamDevice



    const renderConditionStatus = (isOk) => {
      return (
        <span style={{ color: isOk ? 'inherit' : 'red' }}>
            {isOk ? '' : ' (Fail)'}
        </span>
    );
};



  const renderValueWithCondition = (value, isOk) => {
    return (
        <span style={{ color: isOk ? 'inherit' : 'red' }}>
            {value}
        </span>
    );
};


const renderReference_earth = (refAutomatic_table5_1, refAutomatic_faultloop, refManual) => {
  return Earth_cable_mode === 'automatic' ? (protective_device_present ? refAutomatic_faultloop : refAutomatic_table5_1) : (Earth_cable_mode === 'manual' ? refManual : '');
};

const renderReference_active = (refAutomatic, refManual) => {
  return Active_cable_mode === 'automatic' ? refAutomatic : (Active_cable_mode === 'manual' ? refManual : '');
};








  return (
    <div>
    <Paper elevation={3} sx={{ margin: 0, padding: 2 }}>
      <Typography variant="h5" style={{ marginBottom: '20px' }} gutterBottom>
        CALCULATION RESULTS
      </Typography>
      <TableContainer component={Paper} style={{ maxHeight: '40vh'  }}> {/* Adjust maxHeight as needed */}
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em',backgroundColor: '#f5f5f5',color: '#333',  }}><Typography>Parameter</Typography></TableCell>
                            <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em',backgroundColor: '#f5f5f5',color: '#333',  }}><Typography>Value</Typography></TableCell>
                            <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em',backgroundColor: '#f5f5f5',color: '#333',  }}><Typography>Unit</Typography></TableCell>
                            <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em',backgroundColor: '#f5f5f5',color: '#333',  }}><Typography>Ref</Typography></TableCell>
                        </TableRow>
          </TableHead>

          <TableBody>
          <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Active Cable Size:</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{renderValueWithCondition(number_of_active_circuits,active_cable_check)} {renderValueWithCondition("x",active_cable_check)} {renderValueWithCondition(Active_cable_size,active_cable_check)}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>mm<sup>2</sup></Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{renderReference_active('Section 2.2 (AS3008)','User selection')}</Typography></TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Neutral Cable Size:</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{number_of_active_circuits} x {Active_cable_size}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>mm<sup>2</sup></Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Section 3.5.2 (AS3000)</Typography></TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Earth Cable Size:</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{renderValueWithCondition(numberof_earth_cable,Loop_impedance_check)} {renderValueWithCondition("x",Loop_impedance_check)} {renderValueWithCondition(Earth_cable_size,Loop_impedance_check)}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>mm<sup>2</sup></Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{renderReference_earth('Table 5.1 (AS3000)','Section B4.4 (AS3000)','User selection')}</Typography></TableCell>
            </TableRow>

            <TableRow>
            <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em',backgroundColor: '#f5f5f5',color: '#333',  }} colSpan={4}><Typography>Current Carrying Capacity  {renderConditionStatus(current_carrying_cap_ok)}</Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Circuit Capacity (Derated):</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography> {renderValueWithCondition(`${Circuit_capacity_derated*number_of_active_circuits}`, current_carrying_cap_ok)}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>A</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{current_carrying_cap_ref} (AS3008)</Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Spare Capacity:</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{renderValueWithCondition(`${spare_capacity*number_of_active_circuits}`, current_carrying_cap_ok)}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold' , fontSize: '1.1em'}}><Typography>A</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography></Typography></TableCell>
            </TableRow>

            <TableRow>
            <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em',backgroundColor: '#f5f5f5',color: '#333', }} colSpan={4}><Typography>Voltage Drop {renderConditionStatus(voltage_drop_cap_ok)}</Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' , fontSize: '1.1em'}}><Typography>Voltage Drop:</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em'}}><Typography>{renderValueWithCondition(`${Voltage_drop} (${voltage_drop_percent}%)`, voltage_drop_cap_ok)}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold' , fontSize: '1.1em'}}><Typography>V</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Section 4.5 (AS3000)</Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Maximum Length of Cable:</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{renderValueWithCondition(max_length_cable, voltage_drop_cap_ok)}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>m</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography></Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Cable Operating temperature:</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold' , fontSize: '1.1em'}}><Typography>{operating_temp_calculation}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography><sup>o</sup>C</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Section 4.4 (AS3000)</Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' , fontSize: '1.1em'}}><Typography>Active - Resistance:</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{resistance_active}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold' , fontSize: '1.1em'}}><Typography>ohms/km</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold' , fontSize: '1.1em'}}><Typography>{resistance_active_ref} (AS3008)</Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' , fontSize: '1.1em'}}><Typography>Active - Reactance:</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em'}}><Typography>{reactance_active}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>ohms/km</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{reactance_active_ref} (AS3008)</Typography></TableCell>
            </TableRow>

            <TableRow>
            <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em',backgroundColor: '#f5f5f5',color: '#333', }} colSpan={4}><Typography>  Short Circuit Capacity {!protective_device_present ? renderConditionStatus(short_circuit_cap_ok) : renderConditionStatus(short_circuit_cap_ok && earth_short_circuit_cap_check)}</Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' , fontSize: '1.1em'}}><Typography>Short Circuit Capacity (Active):</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{renderValueWithCondition(short_circuit_capacity, short_circuit_cap_ok)}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em'}}><Typography>A</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Section 5.3 (AS3008)</Typography></TableCell>
            </TableRow>


            {protective_device_present && (
        <>
        <TableRow>
          <TableCell style={{ fontWeight: 'bold' , fontSize: '1.1em'}}><Typography>Short Circuit Capacity (Earth):</Typography></TableCell>
          <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{renderValueWithCondition(earth_short_circuit_cap, earth_short_circuit_cap_check)}</Typography></TableCell>
          <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em'}}><Typography>A</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Section 5.3 (AS3008)</Typography></TableCell>
              </TableRow>
        </>
        
      )}
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Fault Level at Supply:</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold' , fontSize: '1.1em'}}><Typography>{short_circuit_soure}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>A</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography></Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Fault Level at Load:</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em'}}><Typography>{short_circuit_load}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>A</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography></Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>K value: </Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold' , fontSize: '1.1em'}}><Typography>{K_value}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography></Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{K_value_ref} (AS3008)</Typography></TableCell>
            </TableRow>


            <TableRow>
            <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em',backgroundColor: '#f5f5f5',color: '#333', }} colSpan={4}><Typography>Fault Loop Impedance Calculation {renderConditionStatus(Loop_impedance_check)} </Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Earth - Resistance:</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{resistance_earth}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em'}}><Typography>ohms/km</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{resistance_earth_ref} (AS3008)</Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Earth - Reactance:</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{reactance_earth}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em'}}><Typography>ohms/km</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{reactance_earth_ref} (AS3008)</Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' , fontSize: '1.1em'}}><Typography>Z internal (Zint):</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>{Z_int}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold' , fontSize: '1.1em'}}><Typography>ohms</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography></Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em'}}><Typography>Z external (Zext):</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold' , fontSize: '1.1em'}}><Typography>{Z_ext}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em'}}><Typography>ohms</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Section B5.2 (AS3000)</Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Earth Fault Loop Impedance:</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em'}}><Typography>{renderValueWithCondition(loop_impedance,Loop_impedance_check)}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold' , fontSize: '1.1em'}}><Typography>ohms</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Section B4.4 (AS3000)</Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Max Allowed EFL:</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em'}}><Typography>{renderValueWithCondition(max_allowed_loop_impedance,Loop_impedance_check)}</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold' , fontSize: '1.1em'}}><Typography>ohms</Typography></TableCell>
              <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}><Typography>Section B4.5 (AS3000)</Typography></TableCell>
            </TableRow>
            

          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
    </div>
  );
}

export default ResultsComponent;
