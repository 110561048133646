import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid } from "@material-ui/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Styles to make the charts and UI more beautiful
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  resultsPaper: {
    padding: "30px",
    boxShadow: "0px 0px 15px rgba(0,0,0,0.2)",
    marginTop: theme.spacing(3),
    backgroundColor: "#f4f7f9", // Light background color for a clean look
  },
  paper: {
    padding: "20px",
    margin: "20px 0",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    borderRadius: "8px", // Rounded corners for a modern look
  },
}));

// Utility function to bin data dynamically
const binData = (data) => {
  const min = Math.min(...data);
  const max = Math.max(...data);
  const binCount = Math.ceil(Math.sqrt(data.length)); // Use square root rule for bin count
  const binSize = (max - min) / binCount;
  const bins = [];
  const frequencies = new Array(binCount).fill(0); // Initialize frequencies for each bin

  // Create the bins based on the bin size
  for (let i = 0; i < binCount; i++) {
    bins.push(min + i * binSize);
  }

  // Count the values in each bin
  data.forEach((value) => {
    const binIndex = Math.floor((value - min) / binSize); // Find the corresponding bin
    if (binIndex >= 0 && binIndex < binCount) {
      frequencies[binIndex]++;
    }
  });

  return { bins, frequencies, binSize };
};

const ResultComponent = ({ results }) => {
  const classes = useStyles();

  // Extracted peak data from the response
  const maxPeakProfile = results.maxPeak.profile;
  const minPeakProfile = results.minPeak.profile;
  const peakValues = results.peaks;
  const maxPeakValue = results.maxPeak.value;
  const minPeakValue = results.minPeak.value;

  // Bin the peak data dynamically
  const { bins: peakBins, frequencies: peakFrequencies, binSize } = binData(peakValues);

  // Calculate the 95th percentile value
  const sortedPeaks = [...peakValues].sort((a, b) => a - b);
  const percentile95Index = Math.floor(sortedPeaks.length * 0.95);
  const percentile95Value = sortedPeaks[percentile95Index];

  // Calculate the x position for the percentile line
  let percentile95BinIndex = 0;
  for (let i = 0; i < peakBins.length - 1; i++) {
    if (percentile95Value >= peakBins[i] && percentile95Value < peakBins[i + 1]) {
      percentile95BinIndex = i;
      break;
    }
  }

  // Highcharts options for the load profile (Peak only)
  const loadProfileOptions = {
    chart: {
      type: "line",
      backgroundColor: "transparent", // Transparent background for a clean look
      style: {
        fontFamily: "Arial, sans-serif",
      },
    },
    title: {
      text: "",
      style: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#333",
      },
    },
    xAxis: {
      title: {
        text: "Index",
        style: {
          fontSize: "14px",
          color: "#555",
        },
      },
      categories: [...Array(maxPeakProfile.length)], // Using the length of the profiles as the categories
      gridLineWidth: 0, // Remove grid lines for a cleaner look
    },
    yAxis: {
      title: {
        text: "Values",
        style: {
          fontSize: "14px",
          color: "#555",
        },
      },
      gridLineColor: "#ddd", // Light grid lines
      gridLineWidth: 1, // Slight grid lines for visual aid
    },
    legend: {
      itemStyle: {
        fontSize: "14px",
        color: "#333",
      },
    },
    series: [
      {
        name: "Max Peak Simulation",
        data: maxPeakProfile,
        type: "line",
        color: "#FF5733", // Orange color for max peak
        lineWidth: 3,
        marker: {
          enabled: false, // Disable markers for a cleaner look
        },
      },
      {
        name: "Min Peak Simulation",
        data: minPeakProfile,
        type: "line",
        color: "#33B5FF", // Blue color for min peak
        lineWidth: 3,
        marker: {
          enabled: false, // Disable markers for a cleaner look
        },
      },
      {
        name: "Max Peak Value",
        data: Array(maxPeakProfile.length).fill(maxPeakValue),
        type: "line",
        color: "#FF0000", // Red color for max peak line
        lineWidth: 2,
        dashStyle: "ShortDash", // Dashed line style
      },
      {
        name: "Min Peak Value",
        data: Array(minPeakProfile.length).fill(minPeakValue),
        type: "line",
        color: "#008000", // Green color for min peak line
        lineWidth: 2,
        dashStyle: "ShortDash", // Dashed line style
      },
    ],
  };

  // Highcharts options for the histogram (Peak values only)
  const histogramOptions = {
    chart: {
      type: "column",
      backgroundColor: "transparent", // Transparent background for a clean look
    },
    title: {
      text: "",
      style: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "#333",
      },
    },
    xAxis: {
      title: {
        text: "Values",
        style: {
          fontSize: "14px",
          color: "#555",
        },
      },
      categories: peakBins
        .map((bin) => {
          return `${bin.toFixed(2)} - ${(bin + binSize).toFixed(2)}`;
        })
        .map((label) => label), // Round bins to two significant digits
      gridLineWidth: 1,
      gridLineColor: "#ddd", // Light grid lines for the x-axis
    },
    yAxis: {
      title: {
        text: "Frequency",
        style: {
          fontSize: "14px",
          color: "#555",
        },
      },
      gridLineWidth: 1,
      gridLineColor: "#ddd", // Light grid lines for the y-axis
    },
    series: [
      {
        name: "Histogram Of Peaks From Simulation",
        data: peakFrequencies,
        type: "column",
        color: "#33B5FF", // Blue color for the histogram bars
        borderRadius: 5, // Rounded corners for the bars
      },
    ],
    annotations: [
      {
        label: {
          text: `95th Percentile: ${percentile95Value.toFixed(2)} kW`,
          style: {
            color: "#FF00FF", // Magenta color for the label
            fontSize: "14px",
          },
        },
        shape: "path",
        points: [
          {
            x: peakBins[percentile95BinIndex], // Position of the vertical line (95th percentile)
            y: 0,
            xAxis: 0,
            yAxis: 1,
          },
        ],
        shapeOptions: {
          stroke: "#FF00FF", // Magenta color for the line
          strokeWidth: 2,
          dashStyle: "Dot", // Dotted line style
        },
      },
    ],
  };

  return (
    <Paper className={classes.resultsPaper}>
      <Grid container spacing={3}>
        {/* Load Profile Chart */}
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <HighchartsReact
              highcharts={Highcharts}
              options={loadProfileOptions}
            />
          </Paper>
        </Grid>

        {/* Histogram Chart */}
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <HighchartsReact
              highcharts={Highcharts}
              options={histogramOptions}
            />
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ResultComponent;
